import {ReferenceModel} from '../reference-model/reference.model';

/**
 * Created by nitin on 14/12/17.
 */

export class FeatureModel {
  algorithm: string;
  background: string;
  description: string;
  difficulty: number;
  feature_bundle_id: number;
  icon: string;
  id: number;
  interface_type: string;
  price: number;
  feature_price: number;
  reference_urls: Array<ReferenceModel>;
  selected: boolean;
  status: string;
  stream_media: boolean;
  template_ids: Array<string>;
  title: string;
  user_requested: boolean;
  weeks: number;
  feature_screenshots: any;
  mvpSelected: boolean;
  new_feature_added: boolean;
  feature_weeks: number;
  disabled: boolean;
  is_mvp_feature: boolean;
  platforms: any;
  phaseId: any;
  feature_bundle_name: string;
  is_recommended: boolean;
  is_repeatable: any;
  repeatable_count: any;
  feature_count: any;
  breakup_duration: number;
  breakup_price: number;
  duration: number;
  feature_note: any;
  feature_attachment: Array<String>;
  file_name_array: Array<String>;
  url_array: Array<String>;
  download_link: Array<String>;
  feature_set_alias_name: string;
  feature_set_id: number;
}

import {
  Component, OnDestroy, OnInit, ViewChild, AfterViewInit, EventEmitter, Output,
  HostListener, ElementRef
} from '@angular/core';
import {Angulartics2Segment} from 'angulartics2/segment';
import {PhaseService} from '@shared/services/phase.service';
import {DataService} from '@shared/services/data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiRequests} from '@shared/services/api-requests.service';
import {UserModel} from '@models/user-model/user.model';
import {ModelMapperService} from '@shared/services/model-mapper.service';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {AppDataService} from '@rootmodule/app-data.service';
import {Constants} from '@shared/utils/constants';
import {HttpRequests} from '@shared/services/http-requests.service';
import {environment} from '@env/environment';
import { Intercom } from 'ng-intercom';
import {CommonService} from '../../../../shared/shared-module/services/common.service';
import {SeoService} from '../../../../shared/shared-module/services/seo.service';
import {PaymentBuilderService} from '@featuremodules/payment-builder-module/payment-builder.service';
import parsePhoneNumber from 'libphonenumber-js';
import { postcodeValidator } from 'postcode-validator';
import {BillingDetailsService} from '@shared/services/billing-details.service';
import {Subscription} from 'rxjs';
import {TaxDetailsComponent} from '@shared/shared-components/tax-details/tax-details.component';
import {TaxDetailsService} from '@shared/services/tax-details.service';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  showSignContracts = false;
  showBillingDetails = false;
  public uploadedDocument: object = {};
  public isDocumentUploaded: object = {};
  public selectedContractId = '';
  public showLoader: object = {};
  public invalidFileError: object = {};
  @ViewChild('mobileNumber') public mobileNumber;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  @ViewChild('form') form;
  public countrySTDCode = '';
  public isSaveButtonClicked = false;
  public searchKeyword = 'name';
  showStateDropDown = false;
  showCountryDropDown = false;
  enterAddressManually = false;
  latitude = 40.7414688;
  longitude = -74.0033873;
  @Output() scrollToBillingSection = new EventEmitter();
  selectedStates = [];
  userCountryData = [];
  number: string[];
  isUserUpdatingAddress = false;
  isContractSigned = false;
  docsToBeReset = [];
  disableSaveOnAddressUpdate = false;
  options: any;
  searchedText = '';
  @ViewChild('searchTextState') searchTextState: ElementRef;
  @ViewChild('searchTextCountry') searchTextCountry: ElementRef;
  @ViewChild('searchTextCity') searchTextCity: ElementRef;
  showCityDropDown = false;
  isContinueEnable = false;
  checkToDisable = false;
  showMSATooltip = false;
  showCompanyDropDown = false;
  companiesList = [];
  searchCompaniesSubscription: Subscription;
  areCompaninesAvailable = false;
  @ViewChild('searchTextCompany') searchTextCompany: ElementRef;
  showTaxDetailsOnBillingPage = true;

  constructor(public analyticsSegment: Angulartics2Segment,  public apiRequests: ApiRequests,
              public phaseService: PhaseService, public dataService: DataService, public router: Router,
              public apiRequest: ApiRequests,  public modelMapperService: ModelMapperService, private _route: ActivatedRoute,
              public appDataService: AppDataService, public httpRequest: HttpRequests, public commonService: CommonService ,
              public seoService: SeoService, public paymentService: PaymentBuilderService,
              public billingService: BillingDetailsService, public taxDetailsService: TaxDetailsService) {
                this.getCountryviseState()
             this.dataService.currentPage = 'billingDetails';
    this.seoService.noIndexMeta();
    this.billingService.isCompanyAddress = false;
    if (this.dataService.user) {
      phaseService.billingInfo.firstname = this.dataService.user.first_name;
      phaseService.billingInfo.lastname = (this.dataService.user.address && this.dataService.user.address.last_name) ? this.dataService.user.address.last_name : this.dataService.user.last_name;
      phaseService.billingInfo.email = this.dataService.user.email;
      phaseService.billingInfo.billing_entity = this.dataService.user.billing_entity;
      if (this.dataService.user.address && this.dataService.user.address.phone) {
        const phoneNumber = this.dataService.user.address.phone.split('-');
        if (phoneNumber && phoneNumber.length === 2) {
          const countryCode = phoneNumber[0].replace('+', '') ;
          this.dataService.countryNameCode = countryCode;
        }
      }
      this.setUserAddress();
    }
  }

  getCountryviseState() {
    this.apiRequest.getUserCountryData().subscribe((data: any) => {
     this.userCountryData = data.data;
      if ((this.dataService.user.currency.id === 4) || (data.data && (data.data.length === 1))) {
        if (this.dataService.user.currency.id === 4) {
          this.phaseService.billingInfo.country = data['data'].find(country => country.code === 'GB')['name'];
        } else {
          this.phaseService.billingInfo.country = data['data'][0]['name'];
        }
      } else if ((this.dataService.user.currency.id === 2) && !this.dataService.user.address) {
        this.phaseService.billingInfo.country = data['data'].find(country => country.code === 'US')['name'];
      }
      const fillState = (this.userCountryData && ((this.userCountryData.length === 1) ||
        (this.dataService.user.currency.id === 2)) && !this.dataService.user.address);
      this.availablestate(fillState ? '' : 'dontFillState');
    }, error => {
      this.commonService.showError();
    });
  }

  setAddressOptions() {
     const countryCode = this.dataService.user? this.dataService.user.available_country ? this.getCountryCode() : []: [];
     this.options= {
      componentRestrictions:{
        country:countryCode
      }
    }
  }

  getCountryCode() {
    return this.dataService.user.available_country.map((country)=>{
      return country.code;
    })
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const elementId = (event.target as Element).id;
    if (elementId.includes('stateBox') || elementId.includes('countryBox') || elementId.includes('cityBox')) {
      return false;
    }
    this.showStateDropDown = false;
    this.showCountryDropDown = false;
    this.showCityDropDown = false;
  }

  public ngOnInit() {
    this.setAddressOptions();
    this.phaseService.fetchUserShowData();
    this.phaseService.setComponentTitle(this.analyticsSegment, Constants.PAGE_TITLES.billing.code);
      if (!this.dataService.buildCardData.uniq_code) {
        this.getBuildCardIDOnReload();
      }
      this.phaseService.checkIfAddressSubmitted();
      if ((this.phaseService.isRedirectingSignContract || this.phaseService.isRedirectingMasterContract) && this.phaseService.addressSubmitted) {
        this.showSignContracts = true;
        this.showBillingDetails = false;
      }
      this.phaseService.proceedToContinuePayment = false;
      if (this.dataService.buildCardData.uniq_code) {
        const uniqueCode = this.dataService.buildCardData.uniq_code;
        this.dataService.showHideLoader(true);
        this.fetchBillingDetails(uniqueCode);
        this.apiRequest.fetchSingleBuildCardData(uniqueCode).subscribe((data: any) => {
          if (data.progress.buildcardphases && (data.progress.buildcardphases.length === 1) && (data.progress.buildcardphases[0].title === 'Professional Prototype')
            && data.progress.buildcardphases[0].is_tailor_made) {
            this.appDataService.urlParameters.isCustomPrototype = "true";
          } else {
            this.appDataService.urlParameters.isCustomPrototype = null;
          }
          if (this.dataService.buildCardData && this.dataService.buildCardData.nda_signed) {
            this.phaseService.setContractTermsAndConditions();
            this.paymentService.showMSATooltip = false;
            this.paymentService.isMSALinkCLicked = true;
          } else if (this.dataService.buildCardData && !this.dataService.buildCardData.nda_signed) {
            this.phaseService.resetTermsAndConditions();
          }
          this.phaseService.fetchContractDetails(uniqueCode);
          this.phaseService.fetchProposalInstallments();
          // this.phaseService.skipSignNDA = data.skip_signing_contract;
        }, error => {
          this.commonService.showError();
        });
      }
    if (this.phaseService.addressSubmitted) {
      this.apiRequest.getUserforBilling().subscribe();
    } else {
      this.apiRequest.clearBitApiCall(encodeURIComponent(this.dataService.user.email), this.appDataService.getRequestOptionArgs('', false, true))
        .subscribe(
          (resp) => {
            let userDetails = null;
            if (resp) {
              userDetails = resp.person ? resp.person : resp.company;
              if (userDetails && userDetails.geo) {
                this.phaseService.billingInfo.street = userDetails.geo.streetName ? userDetails.geo.streetName : '';
                this.phaseService.billingInfo.city = userDetails.geo.city ? userDetails.geo.city : '';
                this.phaseService.billingInfo.apartment = userDetails.geo.streetNumber ? userDetails.geo.streetNumber : '';
                this.phaseService.billingInfo.state = userDetails.geo.state ? userDetails.geo.state : '';
                this.phaseService.billingInfo.pincode = userDetails.geo.postalCode ? userDetails.geo.postalCode : '';
                const country = this.userCountryData.find(c => c.code === userDetails.geo.countryCode);
                if (country && this.phaseService.billingInfo.country) {
                  this.phaseService.billingInfo.country = country.name;
                }
              }
            }
          }, error => {

          });
    }

  }

  public ngAfterViewInit() {
    this.phaseService.checkPaymentExitIntentTimer();
  }

  public setUserAddress() {
    const userAddress = this.dataService.user.address,
      billingInfo = this.phaseService.billingInfo;
    billingInfo.address = userAddress ? userAddress.address : '';
    billingInfo.contact = userAddress ? userAddress.phone.replace('-', '') : '';
    billingInfo.street = userAddress ? decodeURIComponent(userAddress.street) : '';
    billingInfo.apartment = userAddress ? decodeURIComponent(userAddress.apartment) : '';
    billingInfo.state = userAddress ? userAddress.state : '';
    billingInfo.city = userAddress ? userAddress.city : '';
    billingInfo.pincode = userAddress ? userAddress.pincode : '';
    billingInfo.country = userAddress ? userAddress.country : '';
    billingInfo.companyName = userAddress ? userAddress.company_name : '';
    billingInfo.gstNumber = userAddress ? userAddress.gst_number : '';
    billingInfo.address_line_2 = userAddress ? userAddress.address_line_2 : '';
    billingInfo.firstname = userAddress && userAddress.first_name ? userAddress.first_name : billingInfo.firstname;
    billingInfo.lastname = userAddress && userAddress.last_name ? userAddress.last_name : billingInfo.lastname;
    billingInfo.billed_as = ((userAddress && userAddress.billed_as) ? userAddress.billed_as : 'company');
    if (billingInfo.gstNumber && billingInfo.companyName) {
      this.phaseService.isGSTCheckboxChecked = true;
    }
    if (billingInfo.billed_as === 'company') {
      this.billingService.isCompanyAddress = true;
    }
    if (!this.billingService.citiesList && userAddress && (userAddress.country === 'United States of America')) {
      this.getCitiesList();
    }
  }


  public checkGSTChecked(){
    if (this.phaseService.billingInfo.gstNumber && this.phaseService.billingInfo.companyName) {
      this.phaseService.isGSTCheckboxChecked = true;
    }
  }

  public fetchBillingDetails(uniqueCode) {
    const user = this.dataService.user;
    if (!this.getBillingInfo().contact && user && user.phone_number) {
      this.phaseService.billingInfo.contact = user.phone_number.includes('+') ? user.phone_number.replace('-', '') :  '+' + user.phone_number.trim().replace('-', '');
    }
    this.onFlagChange();
    if (!this.dataService.isContractAPICalled) {
      this.phaseService.fetchContractDetails(uniqueCode);
      this.dataService.isContractAPICalled = false;
    }
  }

  public selectState(state) {
    this.phaseService.billingInfo.state = state.name;
    this.showStateDropDown = false;
    this.setCity();
  }

  renderCitiesInDropdown() {
    const state = this.phaseService.billingInfo.state;
    if (this.phaseService.billingInfo.state && this.billingService.citiesList[state]) {
      return this.billingService.citiesList[state].sort((a, b) => {
        if (a > b) {
          return 1;
        } else if (a < b) {
          return -1;
        }
      });
    } else {
      return ['Not Available'];
    }
  }

  selectCountry(country) {
    this.phaseService.billingInfo.country = country.name;
    this.showCountryDropDown = false;
    this.availablestate();
    this.setCity();
  }

  setCity() {
    const billingInfo = this.phaseService.billingInfo;
    if (this.checkIfUSCustomer() && billingInfo.state && this.billingService.citiesList[billingInfo.state]) {
      billingInfo.city = this.billingService.citiesList[billingInfo.state][0];
    } else {
      billingInfo.city = '';
    }
  }

  selectCity(city) {
    this.phaseService.billingInfo.city = city;
    this.showCityDropDown = false;
  }

  checkIfUSCustomer() {
    return (this.phaseService.billingInfo.country === 'United States of America');
  }

  availablestate(dontFillState?) {
    const index = this.userCountryData.findIndex((country)=>{
      return this.phaseService.billingInfo.country.toLowerCase() === country.name.toLowerCase();
    })
    if( index !== -1) {
      this.selectedStates = this.userCountryData[index].states.states;
      if (!dontFillState) {
        this.phaseService.billingInfo.state = this.selectedStates[1].name;
        if (!this.billingService.citiesList && this.checkIfUSCustomer()) {
          this.getCitiesList();
        }
      }
    } else if (!this.dataService.user.address) {
      this.phaseService.billingInfo.country = '';
    }
  }

  getCitiesList() {
    if (this.dataService.user.currency.id !== 2) { return false; }
    this.apiRequest.getCitiesList().subscribe((resp) => {
      this.billingService.citiesList = resp.data;
      if (!this.phaseService.billingInfo.city) {
        this.phaseService.billingInfo.city = (this.billingService.citiesList.length ? this.billingService.citiesList[0] : '');
      }
    });
  }

  public handleAddressChange(address: Address) {
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();
    this.getFormattedAddress(address);
    this.enterAddressManually = true;
  }

  getFormattedAddress(place) {
    const billingInfo = this.phaseService.billingInfo;
  //  billingInfo.companyName = this.isGSTCheckboxChecked ? place.name : '';
    billingInfo.address = place['formatted_address'];
    billingInfo.street = this.placesRef['el'].nativeElement.value;
    billingInfo.pincode = '';
    billingInfo.state = '';
    billingInfo.country = '';
    billingInfo.city = '';
    billingInfo.apartment = '';
    for (const i in place.address_components) {
      const item = place.address_components[i];
      if (item['types'].indexOf('locality') > -1) {
          billingInfo.city = item['long_name'];
      } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
          billingInfo.state = item['long_name'];
      } else if ((item['types'].indexOf('country') > -1) && this.dataService.userCurrencyAndCountries.available_country.map(country => country.code).includes(item['short_name'])) {
          billingInfo.country = item['long_name'];
      } else if (item['types'].indexOf('postal_code') > -1) {
          billingInfo.pincode = item['short_name'];
      } else if ((item['types'].indexOf('sublocality') > -1) && !billingInfo.city) {
          billingInfo.city = item['short_name'];
      }
    }
    if (!this.selectedStates.map(state => state.name.toLowerCase()).includes(billingInfo.state.toLowerCase())) {
      billingInfo.state = '';
    }
    if (!this.userCountryData.map(country => country.name.toLowerCase()).includes(billingInfo.country.toLowerCase())) {
      billingInfo.country = '';
    }
  }

  public toggleInputFieldsForGST() {
    this.phaseService.isGSTCheckboxChecked = !this.phaseService.isGSTCheckboxChecked;
    if (!this.phaseService.isGSTCheckboxChecked) {
        this.phaseService.billingInfo.gstNumber = '';
    }
  }

  public getBuildCardIDOnReload(): void {
    this._route.parent.params.subscribe(params => {
      const uniq_code = params['id'];
      this.dataService.buildCardData.uniq_code = uniq_code;
      this.dataService.buildCardUniqueCode = uniq_code;
    });
  }

  public getBillingInfo() {
    if ( this.dataService.user.currency.code === 'INR') {
      this.phaseService.showGSTIN =
      (this.phaseService.billingInfo && this.phaseService.billingInfo.country
        && (this.phaseService.billingInfo.country.toLowerCase() === 'india')
      )
        ? true
        : false;
    }
    if (!this.phaseService.showGSTIN) {
      this.phaseService.billingInfo.gstNumber = '';
      this.phaseService.isGSTCheckboxChecked = false;
    }
    if (this.phaseService.billingInfo.street) {
      this.phaseService.billingInfo.street = decodeURIComponent(this.phaseService.billingInfo.street);
    }
    if (this.phaseService.billingInfo.apartment) {
      try {
        this.phaseService.billingInfo.apartment = decodeURIComponent(this.phaseService.billingInfo.apartment);
      } catch (e) {

      }
    }
    Object.keys(this.phaseService.billingInfo).map((key) => {
      if (this.phaseService.billingInfo[key] && ((this.phaseService.billingInfo[key] === 'null') || (this.phaseService.billingInfo[key] === undefined))) {
        this.phaseService.billingInfo[key]  = '';
      }
      if (this.phaseService.billingInfo[key] && key != 'contact') {
         this.phaseService.billingInfo[key] = this.phaseService.billingInfo[key].trim();
      }
      return this.phaseService.billingInfo[key];
    });
    return this.phaseService.billingInfo;
  }

  checkIfFOrmValueChanges() {
    const userAddress = this.dataService.user.address,
      billingInfo = this.phaseService.billingInfo;
    if ((billingInfo.contact !== userAddress.phone) || (billingInfo.street !== userAddress.street) ||
    (billingInfo.apartment !== userAddress.apartment) || (billingInfo.state !== userAddress.state) ||
    (billingInfo.city !== userAddress.city) || (billingInfo.pincode !== userAddress.pincode) ||
    (billingInfo.country !== userAddress.country) || (billingInfo.companyName !== userAddress.company_name) ||
    (billingInfo.gstNumber !== userAddress.gst_number) || (billingInfo.firstname !== userAddress.first_name) ||
    (billingInfo.lastname !== userAddress.last_name)) {
      return true;
    } else {
      return false;
    }
  }

  checkIfFormValid() {
    for (const key of Object.keys(this.phaseService.billingInfo)) {
      if ((key !== 'companyName') && (key !== 'address_line_2') && (key !== 'apartment') && (key !== 'billing_entity')) {
        if ((this.phaseService.billingInfo[key] === '')
          && ((this.phaseService.showGSTIN && this.phaseService.isGSTCheckboxChecked) ? ((key !== 'name') && (key !== 'address')) : !this.ifNotUkOrCyprus() ? ((key !== 'state') && (key !== 'gstNumber')) : ((key !== 'name') && (key !== 'companyName') && (key !== 'address') && (key !== 'apartment') && (key !== 'gstNumber')))) {
          return false;
        }
      }
      if ((this.phaseService.billingInfo[key] === '') && this.billingService.isCompanyAddress && ((key === 'companyName'))) {
        return false;
      }
    }
    if (!this.taxDetailsService.validateFormFields()) {
      return false;
    }
    return true;
  }

  updateBuildAsKey() {
    this.isSaveButtonClicked = true;
    if (!this.checkIfFormValid()) { return false; }
    const user = {
      billed_as: this.phaseService.billingInfo.billed_as,
      companyName: this.phaseService.billingInfo.companyName
    };
    this.apiRequests.updateBuildAsKey(user).subscribe(() => {
      this.dataService.submitAddress.next('go_to_payment_page');
    });
  }

  public submitAddress(dontGoToNextStep?) {
    if (this.dataService.user.address_disabled && this.phaseService.addressSubmitted) {
      this.gotToPaymentInfo();
      return;
    }
    this.hideStatesOrCountriesList();
    if (this.dataService.user.address && !this.checkIfFOrmValueChanges() ) {
      this.showBillingDetails = false;
      this.isUserUpdatingAddress = false;
      this.showSignContracts = true;
      return;
    }
    const countryCodes = ['IN', 'US', 'GB', 'CA', 'JP'];
    const billingInfo = this.phaseService.billingInfo;
    const isUkOrCyprusCountry = !this.ifNotUkOrCyprus();
    const ifCountriesAreAvailable = this.dataService.userCurrencyAndCountries && this.dataService.userCurrencyAndCountries['available_country'] && (this.dataService.userCurrencyAndCountries['available_country'].length > 0);
    this.isSaveButtonClicked = true;
    if (isUkOrCyprusCountry) {
      delete billingInfo.state;
    }
    if ((this.form && !this.form.valid) || !this.checkIfFormValid() || this.checkIfPhoneInValid()) {
      return;
    }
    if (ifCountriesAreAvailable && !this.dataService.userCurrencyAndCountries['available_country'].map(country => country.name.toLowerCase()).includes(billingInfo.country.toLowerCase())) {
      billingInfo.country = '';
      billingInfo.state = '';
      return;
    }
    if (!isUkOrCyprusCountry && !this.selectedStates.map(state => state.name.toLowerCase()).includes(billingInfo.state.toLowerCase())) {
      billingInfo.state = '';
      return;
    }
    const selectedCountry = this.dataService.userCurrencyAndCountries['available_country'].find(country => country.name.toLowerCase() === billingInfo.country.toLowerCase());
    if (countryCodes.includes(selectedCountry.code) && !postcodeValidator(billingInfo.pincode, selectedCountry.code)) {
      billingInfo.pincode = '';
      return;
    }
    if (!isUkOrCyprusCountry && billingInfo.state && !this.selectedStates.map(state => state.name.toLowerCase()).includes(billingInfo.state.toLowerCase())) {
      billingInfo.state = '';
      return;
    }
    if (!this.phaseService.isGSTCheckboxChecked) {
      this.phaseService.billingInfo.gstNumber = '';
    }
    if (this.paymentService.checkIfAddressUpdated() && !this.isUserUpdatingAddress && this.checkIfAnyContractIsSigned()) {
      this.isUserUpdatingAddress = true;
      return;
    }
    if (billingInfo.state) {
      billingInfo.state = billingInfo.state['code'] ? billingInfo.state['name'] : billingInfo.state;
    }
    let taxDetails;
    if (this.taxDetailsService.isShowTaxDetailsOnBillingPage && !this.dataService.user.address?.tax_details_disabled) {
      taxDetails = this.taxDetailsService.createPayload();
    }
    this.dataService.showHideLoader(true);
    this.apiRequest.updateUserAddress(this.phaseService.billingInfo, false, taxDetails).subscribe(data => {
      let userObj: UserModel = new UserModel();
      data.user = this.modelMapperService.getMappedModel(userObj, data.user);
      this.dataService.user = data.user;
      this.dataService.storeUserObject();
      this.phaseService.addressSubmitted = true;
      this.isUserUpdatingAddress = false;
      this.gotToPaymentInfo();
      this.enableProceedToPayment();
      this.showBillingDetails = false;
      this.showSignContracts = true;
      this.disableSaveOnAddressUpdate = false;
      this.phaseService.enablePayment = true;
      this.taxDetailsService.isShowTaxDetailsOnBillingPage = false;
      this.dataService.showHideLoader(false);
      if (!dontGoToNextStep) {
        this.redirectToPaymentPage();
      }
    });
  }

  public isSpecingFlow() {
    if (this.dataService.buildCardData && this.dataService.buildCardData.specing && !this.dataService.buildCardData.specing_paid) {
      return true;
    }
    return false;
  }

  public enableProceedToPayment() {
    if (this.phaseService.enablePayment && this.phaseService.addressSubmitted) {
      this.phaseService.proceedToContinuePayment = true;
      this.phaseService.isValidToProceedForPayment = true;
    } else {
      this.phaseService.proceedToContinuePayment = false;
      this.phaseService.isValidToProceedForPayment = false;
    }
  }


  public processNDAFlow() {
    this.closePopup();
    this.hideOptionList();
    if (!this.dataService.user) {
      this.phaseService.showSignin = true;
      this.phaseService.openCommonAuth = true;
      this.phaseService.isNdaFlow = true;
    } else {
      this.fetchDocusignUrl();
    }
  }

  public closePopup() {
    this.dataService.showCurrencyPopup = false;
  }

  public hideOptionList() {
    this.phaseService.showList = false;
  }

  public fetchDocusignUrl() {
    this.phaseService.isNdaFlow = false;

    const params = {redirect_url: '', build_card_id: 0};
    if (this.dataService.buildCardData.uniq_code) {
      params.build_card_id = this.dataService.buildCardData.id;
      params.redirect_url = this.phaseService.getRedirectUrl() +  '&uniq_code=' + this.dataService.buildCardData.uniq_code +
        (this.appDataService.urlParameters.isCustomPrototype ? '&is_custom_prototype=true' : '') + '&redirect_key=' + `buildcard-payment/${this.dataService.buildCardData.uniq_code}/signcontract`;
    }

    this.apiRequests.fetchDocuSignUrl(params).subscribe((data: any) => {
      if (this.dataService.docuSignUrl) {
        window.location.href = this.dataService.docuSignUrl;
      }
    });
  }

  public updateShowSignContracts(showSignContracts: boolean) {
      this.showSignContracts = showSignContracts;
      if (this.showSignContracts) {
        this.showBillingDetails = false;
      }
  }

  public updateShowBillingDetails(showBillingDetails: boolean) {
    this.showBillingDetails = showBillingDetails;
    if (this.showBillingDetails) {
      this.showSignContracts = false;
    }
  }

  public viewPdf(contract, type) {
    let url = '';
    if (type === 'NDA') {
      url = contract.nda_link;
    } else if (type === 'contract') {
      url = contract.link;
    }
    window.open(url, '_blank', "noopener");
  }

  public uploadDocument(contractType: string, event: Event, contract) {
    this.phaseService.uploadDocOnNDAClicked = true;
    const files: any = event.target;
    if (files.files[0].type === 'application/pdf') {
      this.isDocumentUploaded[contractType] = true;
      this.uploadedDocument[contractType] = files.files[0];
      this.selectedContractId = contract.id;
      this.invalidFileError[contractType] = '';
      this.sendDocuments(contractType);
    } else {
      this.invalidFileError[contractType] = 'Invalid File type. Only .pdf files allowed';
      this.phaseService.uploadDocOnNDAClicked = false;
    }
  }

  public sendDocuments(contractType) {
    this.showLoader[contractType] = true;
    this.apiRequests.uploadContracts(contractType, this.dataService.buildCardData.id, this.uploadedDocument[contractType])
      .subscribe(
        () => {
          this.apiRequests.fetchContractDetails(this.dataService.buildCardData.uniq_code)
            .subscribe(
              (contracts) => {
                this.phaseService.allContracts = contracts;
                this.phaseService.checkForDisabledPayment();
                this.showLoader[contractType] = false;
                this.phaseService.uploadDocOnNDAClicked = false;
              },
              (error) => {
                this.showLoader[contractType] = false;
                this.phaseService.uploadDocOnNDAClicked = false;
              }
            );
        },
        (error) => {
          this.showLoader[contractType] = false;
          this.phaseService.uploadDocOnNDAClicked = false;
        }
      );
  }

  public removeDocument(contractType) {
    this.isDocumentUploaded[contractType] = false;
    this.uploadedDocument[contractType] = null;
    this.selectedContractId = '';
  }

  public processMasterContractFlow(mastercontract) {
    this.closePopup();
    this.hideOptionList();
    if (!this.dataService.user) {
      this.phaseService.showSignin = true;
      this.phaseService.openCommonAuth = true;
      // this.phaseService.isNdaFlow = true;
    } else {
      this.fetchMasterContractDocusignUrl(mastercontract);
    }
  }

  public fetchMasterContractDocusignUrl(mastercontract) {
    const params = { redirect_url: '', record_id: '', build_card_id: 0, is_custom_prototype: this.appDataService.urlParameters.isCustomPrototype ? 'true' : false };
    if (this.dataService.buildCardData.uniq_code) {
      params.build_card_id = this.dataService.buildCardData.id;
      params.redirect_url = this.phaseService.getRedirectUrl() +  '&uniq_code=' + this.dataService.buildCardData.uniq_code +
        (this.appDataService.urlParameters.isCustomPrototype ? '&is_custom_prototype=true' : '') + '&redirect_key=' + `buildcard-payment/${this.dataService.buildCardData.uniq_code}/signcontract`;
    }
    if (!this.appDataService.urlParameters.isCustomPrototype) {
      delete params.is_custom_prototype;
    }
    if (mastercontract.id) {
      params.record_id = mastercontract.id;
    }
    this.apiRequests.fetchMasterContractDocuSignUrl(params).subscribe((data: any) => {
      if (this.dataService.mastercontractdocuSignUrl) {
        window.location.href = this.dataService.mastercontractdocuSignUrl;
      }
    });
  }

  public onFlagChange() {
    if (this.mobileNumber && this.mobileNumber.valid && this.mobileNumber.valueAccessor) {
      this.phaseService.countryNameCode = this.mobileNumber.valueAccessor.selectedCountry.countryCode;
      this.dataService.countryNameCode = this.mobileNumber.valueAccessor.selectedCountry.dialCode;
    }
  }

  public toggleSkipSignNDA() {
    this.phaseService.skipSignNDA = !this.phaseService.skipSignNDA;
    const API = environment.API_URL + Constants.apiEndPoints.build_card + '/' + this.dataService.buildCardData.id;
    const payloadData = {build_card: {skip_signing_contract: this.phaseService.skipSignNDA, pricing_params: null }, event: '', pricing_params: ''};
    this.httpRequest.put(API, payloadData, this.appDataService.getRequestOptionArgs()).subscribe();
  }

  resetDocsAndSubmitAddress() {
    this.disableSaveOnAddressUpdate = true;
    this.resetDocument(this.docsToBeReset, true);
  }

  checkIfAnyContractIsSigned() {
    this.docsToBeReset = [];
    let isAnyContrctSigned = false;
    if (this.phaseService.checkIfAllContractTermsSelected()) {
        isAnyContrctSigned = true;
    }
    // this.phaseService.allContracts.forEach(ndaContract => {
    //   if (ndaContract.document_status === 'Signed') {
    //     this.docsToBeReset.push('NDA');
    //     isAnyContrctSigned = true;
    //   }
    //   if (ndaContract.contracts.every(this.checkIfMSAUploaded)) {
    //     this.docsToBeReset.push('MSA');
    //     isAnyContrctSigned = true;
    //   }
    // });
    return isAnyContrctSigned;
  }

  checkIfMSAUploaded(element) {
    return (element.document_status === 'Signed');
  }

  resetDocument(documentType, isActionFromPopup?) {
    const reqParams = { nda_signed: false , is_custom_prototype: this.appDataService.urlParameters.isCustomPrototype ? true : false  };
    this.apiRequests.setResetContractsSigned(this.dataService.buildCardData.id, reqParams).subscribe((resp) => {
      this.submitAddress(true);
      this.phaseService.resetTermsAndConditions();
      this.phaseService.fetchContractDetails(this.dataService.buildCardData.uniq_code);
      this.isDocumentUploaded = {};
    }, error => {
      console.log(error);
      this.disableSaveOnAddressUpdate = false;
    });
  }

  closeResetMessagePopup() {
    this.isUserUpdatingAddress = false;
  }

  exitBillingDetails() {
    if (this.phaseService.countExitBillingDetails < 1) {
      /*this.phaseService.isShowPaymentExitIntent = true;*/
      this.phaseService.countExitBillingDetails = 1;
    }
  }

  showStatesList(name?) {
    this.searchedText = '';
    this.showCityDropDown = false;
    if (name === 'states') {
      this.availablestate('dontFillState');
      this.showStateDropDown = !this.showStateDropDown;
      this.showCountryDropDown = false;
      this.searchTextState.nativeElement.focus();
    } else if (name === 'zipCode') {
      this.hideStatesOrCountriesList();
    } else {
      this.showCountryDropDown = !this.showCountryDropDown;
      this.showStateDropDown = false;
      this.searchTextCountry.nativeElement.focus();
    }
  }

  showCityDropdown() {
    this.searchedText = '';
    this.showCityDropDown = !this.showCityDropDown;
    this.searchTextCity.nativeElement.focus();
    this.showCountryDropDown = false;
    this.showStateDropDown = false;
  }

  hideStatesOrCountriesList(isScroll?) {
    if (isScroll) {
      this.scrollToBillingSection.emit(true);
    }
    this.showStateDropDown = false;
    this.showCountryDropDown = false;
  }

  public ngOnDestroy() {
    this.dataService.isContractAPICalled = false;
    this.taxDetailsService.isShowTaxDetailsOnBillingPage = false;
  }

  termsAndConditionsClicked(event) {
    if (event.target.checked) {
      this.phaseService.contractTermsConditionsChecked = true;
    } else {
      this.phaseService.contractTermsConditionsChecked = false;
    }
    this.phaseService.checkForDisabledPayment();
  }

  public msapopupOpen() {
  this.phaseService.msaPopupScr = !this.phaseService.msaPopupScr;
}

  public ndapopupOpen() {
    this.phaseService.ndaPopupScr = !this.phaseService.ndaPopupScr;
  }

  public MsaNdaOpen(){
    window.open('https://bstudio-assets.azureedge.net/assets-builder/Builder%20Studio%20Terms%20and%20Conditions.pdf', '_blank', "noopener");
  }

  checkIfFormDisabled() {
    if (this.dataService.user.address_disabled && this.phaseService.addressSubmitted) {
      return false;
    }
  }

  showAllAddressFields() {
    this.enterAddressManually = true;
  }

  gotToPaymentInfo() {
    this.paymentService.showBillingInfo = false;
    this.paymentService.showPaymentScreen = true;
  }

  isShowApproveBotton() {
    if (this.dataService.currentPage !== 'paymentPlan' || !this.dataService.modifiedBuildCardData || (this.dataService.modifiedBuildCardData && this.dataService.buildCardData.modification_request.status !== 'initial') || !this.dataService.isEditBuildCardAfterSecurityPaid()) {
      return false;
    } else {
      return true;
    }
  }

  showApproveEditBuildCardAfterSecurityPaid() {
    const postData = {promotion_id: this.phaseService.selectedHash.promotion_id};
    this.apiRequest.createUpdateBuildCard(postData, this.phaseService.inProgressBuildCardId, '', 'modificationPending')
      .subscribe((data: any) => {
          this.phaseService.isShowApproveChangeModalAfterSecurityPaid = true;
          this.phaseService.preventClick = false;
          this.dataService.unsavedChanges = false;
          this.phaseService.isReadyToProceed = true;
        }, (error) => {

        });
  }

  goToDashboard() {
    this.router.navigate(['dashboard']);
  }

  checkIfAddressDetailsNotFilled() {
    const address = this.phaseService.billingInfo;
    if (!address.firstname || !address.lastname || !address.email || !address.street || !address.apartment || !address.pincode
      || !address.city || !address.state || !address.country || !address.contact) {}
  }

  checkIfPhoneInValid() {
    const phoneNumber = parsePhoneNumber(this.getBillingInfo().contact.toString(), this.dataService.countryISOCode);
    if (phoneNumber) {
      return !(phoneNumber.isValid() && this.getBillingInfo().contact.includes('+' + phoneNumber.countryCallingCode));
    } else {
      return true;
    }
  }

  setUserContact(event) {
    this.getBillingInfo().contact = event;
  }

  ShowDropdown(){
    this.showStateDropDown = true;
  }

  openTermsAndConditions(){
    window.open('https://www.builder.ai/terms/msa', '_blank', "noopener");
  }

  ifNotUkOrCyprus() {
    return ((this.dataService.user.currency.id !== 4) && (this.phaseService.billingInfo.country.toLocaleLowerCase() !== 'Cyprus'.toLocaleLowerCase()));
  }

  handleBackSpace(event, isState?) {
    if (event.keyCode === 8) {
      this.showStatesList(isState);
    }
  }

  changeAddressType(addressType) {
    this.phaseService.billingInfo.billed_as = addressType;
    if (addressType === 'company') {
      this.billingService.isCompanyAddress = true;
    } else {
      this.billingService.isCompanyAddress = false;
    }
  }

  toggleToolTip(showMsg) {
    if (showMsg && !this.paymentService.checkIfOnlyProfessionalPrototype()) {
      this.isContinueEnable = true;
      this.checkToDisable = !this.checkIfFormValid();
      if (!this.checkToDisable && this.phaseService.checkIfBuildCardForRapidPrototype() &&
        !this.paymentService.checkIfOnlyProfessionalPrototype()) {
        this.showMSATooltip = !this.paymentService.isMSALinkCLicked;
      }
    } else {
      this.isContinueEnable = false;
      this.checkToDisable = false;
      this.showMSATooltip = false;
    }
  }

  isUserInviteeOfBuildCard() {
    return this.dataService.buildCardData && !this.dataService.buildCardData.owner;
  }

  continueToPayment() {
    if (this.isUserInviteeOfBuildCard() || this.dataService.isEditBuildCardAfterSecurityPaid()) {
      return;
    }
    if (this.phaseService.checkIfBuildCardForRapidPrototype() && !this.paymentService.checkIfOnlyProfessionalPrototype() && !this.paymentService.isMSALinkCLicked) {
      return false;
    }
    if (this.dataService.currentPage === 'billingDetails') {
      if (!this.checkIfFormValid() && (this.dataService.currentPage === 'billingDetails')) {
        if (screen.width < 768) {
          this.isContinueEnable = true;
          this.checkToDisable = this.phaseService.skipSignNDA ? false : this.phaseService.proceedToContinuePayment ? false : true;
        }
        return false;
      }
      if (!this.dataService.user.address_disabled && (!this.dataService.user.address || this.paymentService.checkIfAddressUpdated() || (this.taxDetailsService.isShowTaxDetailsOnBillingPage && !this.dataService.user.address?.tax_details_disabled))) {
        this.submitAddress();
      } else if (this.dataService.user.address_disabled && (this.taxDetailsService.isShowTaxDetailsOnBillingPage && !this.dataService.user.address?.tax_details_disabled)) {
        this.saveTaxdetails();
      } else {
        this.redirectToPaymentPage();
      }
    }
  }

  redirectToPaymentPage() {
    if (this.phaseService.checkIfBuildCardForRapidPrototype() && !this.paymentService.checkIfOnlyProfessionalPrototype()) {
      this.dataService.submitAddress.next('go_to_payment_page');
    } else {
      const installmentType = { frequency: 'upfront' };
      if ((installmentType.frequency !== this.dataService.buildCardData.frequency) && this.dataService.buildCardData.subscription_flow && this.dataService.isUnpaidCard()) {
        this.dataService.isShowUpfrontPaymentPlan = true;
        this.apiRequests.createUpdateBuildCard(installmentType, this.phaseService.inProgressBuildCardId, '', 'rename_build_card').subscribe(() => {
          this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.uniq_code}/` + 'paymentPlan']);
        });
      } else {
        this.dataService.isShowUpfrontPaymentPlan = true;
        this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.uniq_code}/` + 'paymentPlan']);
      }
    }
  }

  getCompaniesList(searchedtext) {
    if (this.dataService.user.currency.id !== 4) { return false; }
    if (searchedtext.length < 3) { return false; }
    if (this.searchCompaniesSubscription) {
      this.searchCompaniesSubscription.unsubscribe();
    }
    this.dataService.showHideLoader(true);
    this.searchCompaniesSubscription = this.apiRequests.getCompanies(searchedtext).subscribe((resp => {
      this.companiesList = resp['data']['items'];
      this.dataService.showHideLoader(false);
    }));
  }

  selectCompany(company) {
    this.phaseService.billingInfo.companyName = company.name;
    const isRegCompany = this.companiesList.find(e => e.name.toLowerCase() === this.phaseService.billingInfo.companyName.toLowerCase());
    if (isRegCompany) {
      this.commonService.twoPayCompanyDetails = isRegCompany;
      this.setAddressWithCreditCheck(company);
    } else {
      this.commonService.twoPayCompanyDetails = null;
    }
    this.showCompanyDropDown = false;
  }

  showCompanyDropdown() {
    this.showCompanyDropDown = true;
    this.showStateDropDown = false;
    this.showCountryDropDown = false;
    this.showCityDropDown = false;
  }

  setAddressWithCreditCheck(company) {
    const payload = {
      'national_identifier': company['id'],
      'company_name': company['name'],
      'amount': this.dataService.buildCardData.bcard_price,
      'discounted_amount' : this.dataService.buildCardData.bcard_discounted_price
    };
    this.apiRequests.creditCheck(payload).subscribe(resp => {
      const address = resp['address'],
        billingInfo = this.phaseService.billingInfo;
      billingInfo.city = address['city'];
      billingInfo.pincode = address['postalCode'];
      billingInfo.street = address['streetAddress'];
    }, error => {});
  }

  saveTaxdetails() {
    this.dataService.showHideLoader(true);
    this.apiRequest.updateTaxOrPreferencesData(this.taxDetailsService.createPayload()).subscribe(resp => {
      const userObj: UserModel = new UserModel();
      resp.user = this.modelMapperService.getMappedModel(userObj, resp.user);
      this.dataService.user = resp.user;
      this.dataService.storeUserObject();
      this.dataService.showHideLoader(false);
      this.redirectToPaymentPage();
    }, error => {
    });
  }

}

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiRequests } from "@shared/services/api-requests.service";
import { DataService } from "@shared/services/data.service";
import { PhaseService } from "@shared/services/phase.service";
import { TemplateDetailModel } from "@models/template-detail-model/template.detail.model";
import { DragScrollComponent } from "ngx-drag-scroll";
import { Constants } from "@shared/utils/constants";
import { AppDataService } from "@rootmodule/app-data.service";
import { Angulartics2Segment } from "angulartics2/segment";
import { Intercom } from "ng-intercom";
import { CommonService } from "@shared/services/common.service";
import { ComponentLoaderService } from "@shared/services/component-loader.service";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { HomeTemplateService } from "@shared/services/home-template.service";
import { ModelMapperService } from "@shared/services/model-mapper.service";
import { TeamsModel } from "@models/teams-model/teams.model";
import { Subscription } from "rxjs";
import { BuildCardModel } from "@models/build-card-model/build-card.model";
import { SpecCallModel } from "src/app/shared/shared-module/models/spec-call-model/spec-call.model";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { environment } from "@env/environment";

@Component({
  selector: "studio-store-template-detail",
  templateUrl: "./studio-store-template-detail.component.html",
  styleUrls: ["./studio-store-template-detail.component.scss"],
})
export class StudioStoreTemplateDetailComponent
  implements OnInit, AfterViewInit
{
  leftPlatformNavDisabled = false;
  rightPlatformNavDisabled = false;
  leftFeatureNavDisabled = false;
  rightFeatureNavDisabled = false;
  leftImageNavDisabled = false;
  rightImageNavDisabled = false;
  leftMobileFeatureNavDisabled = false;
  rightMobileFeatureNavDisabled = false;
  leftMobileImageNavDisabled = false;
  rightMobileImageNavDisabled = false;
  selectedFeature = -1;
  innerWidth = 0;
  public currentUrl;
  public limit = 150;
  scrolledItemsIndex = 5;
  activityFeed = false;
  @ViewChild("navPlatformSlider", { read: DragScrollComponent })
  ds: DragScrollComponent;
  @ViewChild("navFeatureSlider", { read: DragScrollComponent })
  fds: DragScrollComponent;
  @ViewChild("navImageSlider", { read: DragScrollComponent })
  ids: DragScrollComponent;
  @ViewChild("navImageSliderMobile", { read: DragScrollComponent })
  navImageSliderMobile: DragScrollComponent;
  @ViewChild("navFeatureSliderPopUp", { read: DragScrollComponent })
  navFeatureSliderPopUp: DragScrollComponent;
  @ViewChild("studioStorePrev") studioStorePrev: ElementRef;
  @ViewChild("rightTopSection") rightTopSection: ElementRef;
  @ViewChild("featureSliderHolder") featureSliderHolder: ElementRef;
  isBuildCardUpdating = false;
  isShowBottomBar = false;
  isShowChangePlatformModal = false;
  selectedPlatform = "app";
  tempSelectedPlatform = "";
  featureDisplaySet = 0;
  featureDisplaySetCount = 5;
  isCurrencyModified = false;
  @Input("appDetails") appDetails: TemplateDetailModel;
  private questionShownIndex: number;
  public isFeatureHoverActive = false;
  private currencyChangeSubscription: Subscription;
  PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
  };
  selectedImage;
  selectedImageIndex = 0;
  imageSet = 4;
  imageSetCount = 0;
  imagesToShow: any;
  totalImageSetCount: number;
  screenshots = [];
  public selectedPreviewPlatform = "android";
  isPayStarterPlan = false;
  shouldStartNatashaSession = false;
  natashaDesignMessage = { appFeature: {} };
  safeSrc: SafeResourceUrl;
  showUpfrontPaymentPrice = false;
  isShowTemplateChangePopup = false;

  constructor(
    public route: ActivatedRoute,
    public apiRequest: ApiRequests,
    public renderer: Renderer2,
    public dataService: DataService,
    public phaseService: PhaseService,
    public router: Router,
    public appDataService: AppDataService,
    public analyticsSegment: Angulartics2Segment,

    public homeTemplateService: HomeTemplateService,
    public commonService: CommonService,
    private componentLoader: ComponentLoaderService,
    private elementRef: ElementRef,
    private modelMapperService: ModelMapperService,
    private sanitizer: DomSanitizer
  ) {}

  moveTo(direction, isMobile?) {
    const remainingFeatures =
      this.dataService.appDetails.features.length - this.scrolledItemsIndex;
    if (direction === "right") {
      this.scrolledItemsIndex =
        remainingFeatures < 5
          ? this.scrolledItemsIndex + remainingFeatures
          : this.scrolledItemsIndex + 5;
      this.featureDisplaySet =
        remainingFeatures < 5
          ? this.featureDisplaySet + remainingFeatures
          : this.featureDisplaySet + 5;
    } else {
      this.scrolledItemsIndex =
        this.featureDisplaySet < 5 ? 5 : this.scrolledItemsIndex - 5;
      this.featureDisplaySet =
        this.featureDisplaySet < 5 ? 0 : this.featureDisplaySet - 5;
    }
    if (isMobile) {
      this.navFeatureSliderPopUp.moveTo(this.featureDisplaySet);
    } else {
      this.fds.moveTo(this.featureDisplaySet);
    }
  }

  ngOnDestroy(): void {
    // if (!this.dataService.checkIfCPEUser()) {
    //   this.phaseService.reset(true);
    // }
    this.dataService.isFromAppDetails = false;
    this.phaseService.showViewPrototypeSS = false;
    // this.renderer.removeClass(document.getElementById('app-component'), 'hide-scroll-bar');
    if (
      !(
        this.appDataService.urlParameters.cpe_user &&
        this.appDataService.urlParameters.uniq_code
      )
    ) {
      this.dataService.buildCardData = new BuildCardModel();
    }
    this.phaseService.showRentalPaymentFlow = false;
    this.dataService.isShowTemplateModal = false;
    this.dataService.appDetails = null;
    this.appDataService.urlParameters.is_dls_store_flow = false;
  }

  isShowSignUp() {
    if (this.appDataService.urlParameters.show_signup) {
      this.openSignupModal();
      // this.componentLoader
      //   .loadComponent("welcome-to-studio")
      //   .then((componentEl) => {
      //     this.elementRef.nativeElement
      //       .querySelector("#studioStore")
      //       .appendChild(componentEl);
      //   });
    }
  }

  ngOnChanges() {
    this.selectedImage = this.screenshots[0];
    this.imagesToShow = this.screenshots.slice(0, this.imageSet);
    this.totalImageSet();
  }

  selectPlateform() {
    const devices = this.isPayStarterPlan
      ? this.appDetails.starter_platforms
      : this.appDetails.template_device;
    devices && devices.length === 2
      ? (this.selectedPlatform = "all")
      : (devices && devices.length && devices[0]) === "Web"
      ? (this.selectedPlatform = "web")
      : (this.selectedPlatform = "app");
    if (this.selectedPlatform === "app") {
      this.selectedPreviewPlatform = "android";
    } else if (this.selectedPlatform === "web") {
      this.selectedPreviewPlatform = "web";
    }
  }

  ngOnInit() {
    // open intercom
    // const evt = new CustomEvent('MyEventType', {detail: '1234'});
    // window.dispatchEvent(evt);
    let defaultHeroImageUrl = this.checkIfNatashaProductionEnv()
      ? "https://api-hero-image-editor.builder.ai/studio-store/feature/581/web"
      : "https://node-service.mimo.stage.eastus.az.svc.builder.ai/studio-store/feature/581/web";
    // console.log('defaultHeroImageUrl- ', defaultHeroImageUrl);
    this.safeSrc =
      this.sanitizer.bypassSecurityTrustResourceUrl(defaultHeroImageUrl);

    if (this.appDataService.urlParameters.pay_starter) {
      this.isPayStarterPlan = true;
    }
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "studio_store_page_visited",
      {
        user_id: this.dataService.user?.id,
        referrer_url: "",
      }
    );
    this.phaseService.showViewPrototypeSS = true;
    if (this.dataService.currentPage === "templateDetail") {
      this.selectPlateform();
      this.getSelectedImage();
      let featureIndex = this.screenshots.findIndex((f) => f.featureId === 581);
      this.selectedImage =
        featureIndex >= 0 && this.checkIfNatashaShownInStudioStore()
          ? this.screenshots[featureIndex]
          : this.screenshots[0];
      this.selectedImageIndex =
        featureIndex >= 0 && this.checkIfNatashaShownInStudioStore()
          ? featureIndex
          : 0;
      if (featureIndex >= 0 && this.checkIfNatashaShownInStudioStore()) {
        if (featureIndex >= this.imageSet * (this.imageSetCount + 1)) {
          while (featureIndex >= this.imageSet * (this.imageSetCount + 1)) {
            this.changeImageSet("next");
          }
        } else if (featureIndex < this.imageSet * this.imageSetCount) {
          while (featureIndex < this.imageSet * this.imageSetCount) {
            this.changeImageSet("previous");
          }
        }
      } else {
        this.imagesToShow = this.screenshots.slice(0, this.imageSet);
      }

      // this.imagesToShow = ((featureIndex >= 0) && this.checkIfNatashaShownInStudioStore()) ? this.screenshots.slice(featureIndex, this.imageSet) : this.screenshots.slice(0, this.imageSet);
      this.totalImageSet();
      this.phaseService.showTemplateLoader = false;
      setTimeout(() => {
        this.checkForDirectPaymentFlow();
        this.checkforStarterPlan();
        if (this.dataService.loggedInViaStorePage) {
          this.phaseService.fetchUserShowData();
          this.payNowForRental(this.dataService.appDetails);
        }
        // console.log('dataService.appDetails- ', this.dataService.appDetails);
        // console.log('appDetails- ', this.appDetails);
        this.createBuildCardIfUserLoggedIn();
      }, 2000);

      return;
    }

    this.dataService.buildCardData = new BuildCardModel();
    this.dataService.appDetails = new TemplateDetailModel();
    this.phaseService.inProgressBuildCardId = false;
    // this.renderer.addClass(document.getElementById('app-component'), 'hide-scroll-bar');
    this.innerWidth = window.innerWidth;
    this.innerWidth > 768
      ? (this.featureDisplaySetCount = 5)
      : (this.featureDisplaySetCount = 1);

    if (this.dataService.templateSlug) {
      if (this.dataService.user) {
        this.appDataService.urlParameters.promotionCode =
          this.dataService.user.currency.default_store_promo_code;
      }
      this.appDataService.urlParameters.expCode = this.dataService.homePageData
        .multiplier_experiment
        ? this.dataService.homePageData.multiplier_experiment.code
        : "";
      const currency: any = this.dataService.homePageData.currency.id;
      this.appDataService.urlParameters.currency_id = currency.toString();
      if (!this.dataService.homePageData.currency) {
        this.apiRequest.fetchHomePageData().subscribe((data: any) => {
          this.setDataAndFetchTemplate();
        });
      } else {
        this.setDataAndFetchTemplate();
      }
      this.setUpCurrencyChangeSubscription();
      this.phaseService.rapidPrototypePlatforms = this.dataService.homePageData
        .prototype_platforms
        ? Object.assign(
            [],
            [...this.dataService.homePageData.prototype_platforms]
          )
        : [];
    }
    this.checkForDirectPaymentFlow();
    // this.checkforStarterPlan();
  }

  checkforStarterPlan() {
    if (this.isPayStarterPlan) {
      this.payNowForRental(this.dataService.appDetails, "Summary bar");
    }
  }

  setDataAndFetchTemplate() {
    this.dataService.dataLoadingCurrency = Object.assign(
      {},
      this.dataService.homePageData.currency
    );
    this.phaseService.platformPrices = Array.apply(
      null,
      Array(this.dataService.homePageData.platforms.length)
    );
    this.phaseService.platformPrices.map((x, i) => {
      return i;
    });
    if (this.dataService.homePageData.currency) {
      this.phaseService.specing_price =
        this.dataService.homePageData.currency.specing_price;
    }
    this.getTemplateAlias();
  }

  checkForDirectPaymentFlow() {
    if (this.appDataService.urlParameters.show_login) {
      this.payNowForRental(this.dataService.appDetails, "Summary bar");
    }
  }

  getTemplateAlias() {
    this.openTemplate(this.dataService.templateSlug, false);
  }

  setUpCurrencyChangeSubscription() {
    this.currencyChangeSubscription = this.phaseService
      .getCurrencyUpdatedCallback()
      .subscribe((isCurrencyChanged) => {
        if (
          isCurrencyChanged &&
          !(
            this.phaseService.checkIfRentalPricingModal() ||
            this.phaseService.isRentalBuildCard()
          )
        ) {
          this.dataService.showPriceLoader = true;
          this.dataService.showBuilderCarePriceLoader = true;
          this.isCurrencyModified = true;
          this.apiRequest
            .fetchTemplateDetails(this.dataService.templateSlug, true)
            .subscribe((data: any) => {
              this.appDetails = this.dataService.templateDetailModel;
              this.dataService.appDetails = this.appDetails;
              this.phaseService.selectedApps = [];
              this.phaseService.updatePrice();
            });
        }
      });
  }

  openTemplate(templateAlias, currencyFlag) {
    this.phaseService.rentalTemplateAlias = templateAlias;
    this.apiRequest.fetchTemplateDetails(templateAlias, currencyFlag).subscribe(
      (data: any) => {
        this.appDetails = this.dataService.templateDetailModel;
        const devices = this.appDetails.template_device;
        devices && devices.length === 2
          ? (this.selectedPlatform = "all")
          : (devices && devices.length && devices[0]) === "Web"
          ? (this.selectedPlatform = "web")
          : (this.selectedPlatform = "app");
        this.dataService.appDetails = this.appDetails;
        this.dataService.isDirectCheckOut = this.appDetails.direct_checkout;
        this.dataService.isRestrictEdit = this.appDetails.restrict_edit;
        this.phaseService.showTemplateLoader = false;
        this.dataService.appDetails['slug'] = this.dataService.templateSlug;
        this.selectPlateform();
        this.getSelectedImage();
        let featureIndex = this.screenshots.findIndex(
          (f) => f.featureId === 581
        );
        this.selectedImage =
          featureIndex >= 0 && this.checkIfNatashaShownInStudioStore()
            ? this.screenshots[featureIndex]
            : this.screenshots[0];
        this.selectedImageIndex =
          featureIndex >= 0 && this.checkIfNatashaShownInStudioStore()
            ? featureIndex
            : 0;
        if (featureIndex >= 0 && this.checkIfNatashaShownInStudioStore()) {
          if (featureIndex >= this.imageSet * (this.imageSetCount + 1)) {
            while (featureIndex >= this.imageSet * (this.imageSetCount + 1)) {
              this.changeImageSet("next");
            }
          } else if (featureIndex < this.imageSet * this.imageSetCount) {
            while (featureIndex < this.imageSet * this.imageSetCount) {
              this.changeImageSet("previous");
            }
          }
        } else {
          this.imagesToShow = this.screenshots.slice(0, this.imageSet);
        }
        // this.imagesToShow = ((featureIndex >= 0) && this.checkIfNatashaShownInStudioStore()) ? this.screenshots.slice(featureIndex, this.imageSet) : this.screenshots.slice(0, this.imageSet);
        this.totalImageSet();
        this.fetchTeams();
      },
      (error) => {
        this.router.navigate(["apps"]);
        this.dataService.showHideLoader(true);
      }
    );
  }

  setFeaturesData() {

  }

  public getSelectedImage() {
    if (
      (this.selectedPlatform == "web" &&
        !this.checkIfNatashaShownInStudioStore()) ||
      ((this.selectedPlatform == "web" || this.selectedPlatform == "all") &&
        this.checkIfNatashaShownInStudioStore())
    ) {
      this.dataService.appDetails.features.forEach((feature) => {
        let obj;
        if (feature.id === 581) {
          obj = {
            imageURL: feature.feature_screenshots.web,
            featureId: feature.id,
            iframeURL: this.safeSrc,
          };
        } else {
          obj = {
            imageURL: feature.feature_screenshots.web,
            featureId: feature.id,
          };
        }
        this.screenshots.push(obj);
      });
    } else {
      this.dataService.appDetails.features.forEach((feature) => {
        let obj;
        if (feature.id === 581) {
          obj = {
            imageURL: feature.feature_screenshots.android,
            featureId: feature.id,
            iframeURL: this.safeSrc,
          };
        } else {
          obj = {
            imageURL: feature.feature_screenshots.android,
            featureId: feature.id,
          };
        }
        this.screenshots.push(obj);
      });
    }
  }

  totalImageSet() {
    this.totalImageSetCount = Math.ceil(
      this.screenshots.length / this.imageSet
    );
  }

  displayInfoblock() {
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "feature_tooltip_viewed",
      {
        user_id: this.dataService.user?.id,
        feature_tooltip:
          this.dataService.appDetails.features[this.selectedImageIndex].title,
      }
    );
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "template_view_details_feature_info_clicked ",
      {
        user_id: this.dataService.user?.id,
        buildcard_category: this.homeTemplateService.templateType,
      }
    );
    this.activityFeed = !this.activityFeed;
  }

  changeSelectedImage(type) {
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "template_view_details_feature_carousel_interacted",
      {
        user_id: this.dataService.user?.id,
        buildcard_category: this.homeTemplateService.templateType,
      }
    );
    if (type === "next") {
      this.selectedImageIndex++;
    } else {
      this.selectedImageIndex--;
    }
    this.selectedImage = this.screenshots[this.selectedImageIndex];

    if (this.selectedImageIndex >= this.imageSet * (this.imageSetCount + 1)) {
      this.changeImageSet("next");
    } else if (this.selectedImageIndex < this.imageSet * this.imageSetCount) {
      this.changeImageSet("previous");
    }
  }

  changeImageSet(type) {
    if (type === "next") {
      this.imageSetCount++;
    } else {
      this.imageSetCount--;
    }
    this.imagesToShow = this.screenshots.slice(
      this.imageSet * this.imageSetCount,
      this.imageSet * (this.imageSetCount + 1)
    );
  }

  selectImage(index) {
    const selectedIndex = this.imageSet * this.imageSetCount + index;
    this.selectedImageIndex = selectedIndex;
    this.selectedImage = this.screenshots[selectedIndex];
  }

  fetchTeams() {
    this.apiRequest.fetchTeams().subscribe((data: any) => {
      data.teams = this.modelMapperService.getTeamMappedModel(
        new TeamsModel(),
        data.teams
      );
      this.phaseService.selectedTeam = data.teams.default;
      this.phaseService.selectedHash.team_id = data.teams.default.id;
      this.preSelectOptions();
      if (
        this.phaseService.checkIfRentalPricingModal() ||
        this.phaseService.isRentalBuildCard()
      ) {
        this.phaseService.selectedHash.cloud_opted = false;
      } else {
        this.phaseService.getCloudDetails(true);
      }
      this.phaseService.getCloudDetails(true);
    });
  }

  preSelectOptions() {
    this.phaseService.selectedHash.application_ids = [];
    this.phaseService.selectedApps = [];
    this.phaseService.mandatoryFeatures = [];
    this.phaseService.selectedHash.application_ids.push(this.appDetails.id);
    if (this.appDetails.features && this.appDetails.features.length > 0) {
      this.phaseService.selectedFeatures = Object.assign(
        [],
        [...this.appDetails.features]
      );
      this.phaseService.mandatoryFeatures = this.appDetails.features.filter(
        (feature) => feature.is_mvp_feature
      );
      this.preSelectPlatforms();
      this.phaseService.selectDefaultPhases();
      if (!this.isCurrencyModified) {
        this.dataService.homePageData.speed.reverse();
      }
      if (!this.phaseService.selectedSpeed) {
        this.phaseService.selectedSpeed =
          this.dataService.homePageData.speed[0];
        this.phaseService.selectedHash.speed_id =
          this.phaseService.selectedSpeed.id;
      }
      this.phaseService.selectedHash.source = "app_detail";
      this.phaseService.initialAppPhases = Object.assign([
        ...this.phaseService.selectedHash.build_phase_vars,
      ]);
      this.phaseService.fetchFeatures();
      this.phaseService.updatePrice();
      this.dataService.redirectionUrl = this.phaseService
        .getRedirectionLink(this.dataService.appDetails)
        .replace(window.location.host, "");
      // this.isReadyToProceed = (this.dataService.homePageData.platforms.filter(x => x.selected === true).length > 0);
    } else {
      this.router.navigate(["apps"]);
    }
    if (this.phaseService.isRentalBuildCard()) {
      if (this.dataService.buildCardData.upfront) {
        this.phaseService.selectUpfrontPackage = true;
        this.phaseService.selectMonthlyPackage = false;
      } else {
        this.phaseService.selectMonthlyPackage = true;
        this.phaseService.selectUpfrontPackage = false;
      }
    }
  }

  public preSelectPlatforms() {
    this.phaseService.selectedPlatforms = [];
    this.phaseService.selectedHash.platform_ids = [];
    if (this.dataService.homePageData.platforms) {
      const platforms = this.dataService.homePageData.platforms;
      if (
        this.dataService.appDetails &&
        this.dataService.appDetails.platforms &&
        this.dataService.appDetails.platforms.length > 0
      ) {
        for (const platform of platforms) {
          platform.selected = false;
          this.dataService.appDetails.platforms.forEach((element, index) => {
            if (element.id === platform.id) {
              platform.selected = true;
              this.phaseService.toggleSelectedPlatform(platform);
              this.phaseService.toggleSelectedPlatformId(platform.id);
            }
          });
        }
      } else {
        platforms[0].selected = true;
        this.phaseService.toggleSelectedPlatform(platforms[0]);
        this.phaseService.toggleSelectedPlatformId(platforms[0].id);
      }
    }
  }

  public ngAfterViewInit() {
    if (!this.dataService.user) {
      this.isShowSignUp();
    }
    if (this.checkIfNatashaShownInStudioStore()) {
      this.shouldStartNatashaSession = true;
    }
  }

  // toggleSelectedAppsById(app: any): void {
  //   const selectedApp = this.phaseService.selectedApps.find(x => x.id === app.id);
  //   if (selectedApp) {
  //     const index = this.phaseService.selectedApps.indexOf(selectedApp);
  //     this.phaseService.selectedApps.splice(index, 1);
  //   } else {
  //     this.phaseService.selectedApps.push(app);
  //     if (this.dataService.buildCardData && !this.dataService.buildCardData.id) {
  //       if (this.dataService.user && (this.phaseService.selectedApps[0].pricing_modal === 'rental_price')) {
  //         this.dataService.homePageData.currency = this.dataService.user.currency;
  //       } else if (!this.dataService.user && (this.phaseService.selectedApps[0].pricing_modal === 'rental_price')) {
  //         this.dataService.homePageData.currency = this.appDetails.currency;
  //       }
  //     }
  //   }
  // }

  moveLeft(source: string) {
    switch (source) {
      case "imageSlider":
        this.ids.moveLeft();
        break;
      case "featureSlider":
        this.fds.moveLeft();
        break;
      case "mobileImageSlider":
        this.navImageSliderMobile.moveLeft();
        break;
      case "mobilefeatureSlider":
        this.navFeatureSliderPopUp.moveLeft();
        break;
      default:
        break;
    }
  }

  moveRight(source: string) {
    switch (source) {
      case "imageSlider":
        this.ids.moveRight();
        break;
      case "featureSlider":
        this.fds.moveRight();
        break;
      case "mobileImageSlider":
        this.navImageSliderMobile.moveRight();
        break;
      case "mobilefeatureSlider":
        this.navFeatureSliderPopUp.moveRight();
        break;
      default:
        break;
    }
  }

  leftBoundStat(reachesLeftBound: boolean, source: string) {
    switch (source) {
      case "imageSlider":
        this.leftImageNavDisabled = reachesLeftBound;
        break;
      case "platformSlider":
        this.leftPlatformNavDisabled = reachesLeftBound;
        break;
      case "featureSlider":
        this.leftFeatureNavDisabled = reachesLeftBound;
        break;
      case "mobilefeatureSlider":
        this.leftMobileFeatureNavDisabled = reachesLeftBound;
        break;
      case "mobileImageSlider":
        this.leftMobileImageNavDisabled = reachesLeftBound;
        break;
      default:
        break;
    }
  }

  rightBoundStat(reachesRightBound: boolean, source: string) {
    switch (source) {
      case "imageSlider":
        this.rightImageNavDisabled = reachesRightBound;
        break;
      case "platformSlider":
        this.rightPlatformNavDisabled = reachesRightBound;
        break;
      case "featureSlider":
        this.rightFeatureNavDisabled = reachesRightBound;
        break;
      case "mobilefeatureSlider":
        this.rightMobileFeatureNavDisabled = reachesRightBound;
        break;
      case "mobileImageSlider":
        this.rightMobileImageNavDisabled = reachesRightBound;
        break;
      default:
        break;
    }
  }

  builderCloudPrice() {
    const cloudPrice = this.dataService.buildCardData.cloud_price
      ? this.dataService.buildCardData.cloud_price
      : this.phaseService.selectedHash.cloud_price;

    return cloudPrice > 0 ? cloudPrice.toFixed(2) : 0;
  }

  // setInfoPositionOnFeature() {
  //   this.isFeatureHoverActive = true;
  // }

  createBuildCard(signUpClickedSrc?) {
    this.phaseService.selectedHash.frequency = "monthly";
    this.phaseService.selectedHash.project_name = this.appDetails.title;
    this.phaseService.selectedHash.promotion_id =
      this.dataService.homePageData?.promotion?.id;
    this.dataService.isDirectCheckOut = this.appDetails.direct_checkout;
    this.dataService.isFromAppDetails = true;
    this.phaseService.isInstallmentsChanged = true;
    this.phaseService.selectedHash.status =
      Constants.buildCardStatus.CARD_COMPLETED;
    this.phaseService.selectedHash.source = "app_detail";
    if (this.dataService.user) {
      this.dataService.showDeliveryLoading = false;
      this.phaseService.setDataForFeaturePlatformAndTeam();
    } else {
      this.dataService.signupSrcName = signUpClickedSrc;
      this.phaseService.preventClick = false;
      if (this.dataService.homePageData?.block_sign_up) {
        this.phaseService.showSignin = true;
        this.phaseService.showRegister = false;
      } else {
        this.phaseService.showRegister = true;
        this.phaseService.showSignin = false;
      }
      this.phaseService.openCommonAuth = true;
      this.phaseService.isNdaFlow = false;
    }
  }

  // public getLocaledPrice(price: any): any {
  //   return this.dataService.getLocaledPrice(price);
  // }

  payNowForRental(appDetails, eventNme?) {
    this.phaseService.selectedSpeed = this.dataService.homePageData.speed.find(
      (speed) => speed.title === "Relaxed"
    );
    if (this.dataService.user && this.dataService.user.enterprise_lead) {
      this.phaseService.selectedHash.care_type = "enterprise_care_plus";
    } else {
      this.phaseService.selectedHash.care_type = "standard_care";
    }
    this.phaseService.meetNowForRentalClicked = false;
    const pamentType = this.phaseService.selectedHash.upfront
      ? "Upfront"
      : "Monthly";
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "payment_viewed",
      {
        user_id: this.dataService.user?.id,
        payment_plan: pamentType,
      }
    );
    if (this.phaseService.inProgressBuildCardId) {
      this.apiRequest
        .pollBuildCardStatus(this.phaseService.inProgressBuildCardId)
        .subscribe(
          (data) => {
            this.phaseService.isRentalCardPaid = data.is_paid;
            if (data.is_paid) {
              this.phaseService.showRentalPaymentFlow = true;
              this.phaseService.handleNextPrevForRentalFlow(4);
              return;
            } else {
              this.proceedForRentalFlow(appDetails);
            }
          },
          (error) => {
            this.proceedForRentalFlow(appDetails);
          }
        );
    } else {
      this.proceedForRentalFlow(appDetails);
    }
  }

  proceedForRentalFlow(appDetails) {
    this.renderer.addClass(
      document.getElementById("app-component"),
      "hide-scroll-bar"
    );
    this.dataService.isExpertClicked = "Continue to Payment";
    if (this.dataService.user) {
      this.phaseService.selectedHash.create_spec_call = "false";
      this.phaseService.selectedHash.application_ids = [];
      this.phaseService.selectedHash.application_ids.push(appDetails.id);
      this.dataService.loginNewModule = true;
      if (this.dataService.buildCardData && this.dataService.buildCardData.id) {
        this.phaseService.showRentalPaymentFlow = true;
        this.phaseService.handleNextPrevForRentalFlow(1);
      } else {
        this.dataService.showHideLoader(true);
        this.createBuildCard("appDetails");
      }
    } else {
      this.phaseService.selectedSectionIndex = 0;
      this.phaseService.showRentalPaymentFlow = true;
    }
  }

  toggleUpfrontSelection() {
    this.phaseService.selectUpfrontPackage = true;
    this.phaseService.selectMonthlyPackage = false;
    if (this.phaseService.selectUpfrontPackage) {
      this.phaseService.selectedHash.upfront = true;
    } else {
      this.phaseService.selectedHash.upfront = false;
    }
    this.updateUpFront();
  }

  updateUpFront() {
    const pamentType = this.phaseService.selectedHash.upfront
      ? "Upfront"
      : "Monthly";
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "payment_plan_selected",
      {
        user_id: this.dataService.user?.id,
        payment_plan: pamentType,
      }
    );
    if (this.dataService.buildCardData && this.dataService.buildCardData.id) {
      this.isBuildCardUpdating = true;
      const upfront = {
        build_card: { upfront: this.phaseService.selectedHash.upfront },
      };
      this.apiRequest.updateRentalFrequency(upfront).subscribe(
        (resp) => {
          this.isBuildCardUpdating = false;
          this.phaseService.paymentData = {
            pending_installment: {},
            general_info: {},
            installments: [],
          };
        },
        (error) => {
          this.isBuildCardUpdating = false;
        }
      );
    }
  }

  toggleMonthlySelection() {
    this.phaseService.selectMonthlyPackage = true;
    this.phaseService.selectUpfrontPackage = false;
    if (this.phaseService.selectUpfrontPackage) {
      this.phaseService.selectedHash.upfront = true;
    } else {
      this.phaseService.selectedHash.upfront = false;
    }
    this.updateUpFront();
  }

  isMonthlySelected() {
    return this.phaseService.selectMonthlyPackage;
  }

  isUpFrontSelected() {
    return this.phaseService.selectUpfrontPackage;
  }

  startMeetNowFlow(appDetails) {
    this.dataService.isShowTemplateModal = false;
    this.phaseService.selectedHash.promotion_id =
      this.dataService.homePageData.promotion?.id;
    this.phaseService.trackEvent(
      this.analyticsSegment,
      "feature_extra_requested"
    );
    this.phaseService.selectedHash.application_ids = [];
    this.phaseService.selectedHash.application_ids.push(appDetails.id);
    this.dataService.openMeetNowFromAppDetail = true;
    this.dataService.loginNewModule = true;
    if (!this.appDataService.urlParameters.uniq_code) {
      this.appDataService.urlParameters.cpe_user = "";
    }
    if (!this.dataService.user) {
      this.phaseService.openCommonAuth = true;
      if (this.dataService.homePageData?.block_sign_up) {
        this.phaseService.showSignin = true;
        this.phaseService.showRegister = false;
      } else {
        this.phaseService.showRegister = true;
        this.phaseService.showSignin = false;
      }
      this.dataService.openMeetNowModal = true;
    } else if (
      !this.appDataService.urlParameters.cpe_user &&
      !this.appDataService.urlParameters.uniq_code
    ) {
      this.phaseService.createBuildCardForCPE = true;
      this.dataService.showHideLoader(true);
      this.createBuildCard("appDetails");
      this.phaseService.specCallCreationData = new SpecCallModel();
    } else if (
      this.appDataService.urlParameters.cpe_user &&
      this.appDataService.urlParameters.uniq_code
    ) {
      sessionStorage.setItem("show_natasha_again", "not_from_template_page");
      this.router.navigate(["feature"], {
        queryParams: {
          uniq_code: this.appDataService.urlParameters.uniq_code,
          cpe_user: "true",
        },
      });
    } else {
      sessionStorage.setItem("show_natasha_again", "not_from_template_page");
      this.router.navigate(["feature"]);
    }
  }
  startMeetNowFlowForRental(appDetails) {
    this.phaseService.meetNowForRentalClicked = true;

    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "feature_extra_requested",
      {
        user_id: this.dataService.user?.id,
      }
    );

    this.dataService.isExpertClicked = "Continue";
    if (this.dataService.user) {
      this.dataService.isShowTemplateModal = false;
      this.phaseService.selectedHash.application_ids = [];
      this.phaseService.selectedHash.application_ids.push(appDetails.id);
      this.dataService.loginNewModule = true;
      if (
        this.phaseService.isMeetNowForRental === false &&
        !this.dataService.buildCardData.id
      ) {
        this.phaseService.isMeetNowForRental = true;
        this.dataService.showHideLoader(true);
        this.createBuildCard("appDetails");
        this.phaseService.specCallCreationData = new SpecCallModel();
      } else if (this.dataService.buildCardData) {
        this.dataService.showMeetNowModel = true;
      }
    } else {
      this.phaseService.selectedSectionIndex = 0;
      this.phaseService.showRentalPaymentFlow = true;
      // this.phaseService.openCommonAuth = true;
      // this.phaseService.showRegister = true;
      // this.phaseService.showSignin = false;
      // this.dataService.openMeetNowModal = true;
    }
  }

  // openChangePlatformModal(eventNme) {
  //   this.tempSelectedPlatform = this.selectedPlatform;
  //   this.isShowChangePlatformModal = true;
  // }

  closeChangePlatFormModal() {
    this.isShowChangePlatformModal = false;
  }

  changePlatform(platform) {
    this.tempSelectedPlatform = platform;
  }

  applyChangedPlatform() {
    this.isShowChangePlatformModal = false;
    this.selectedPlatform = this.tempSelectedPlatform
      ? this.tempSelectedPlatform
      : "";
    let redirectUrl = "";
    this.selectedPlatform === "all"
      ? (redirectUrl = this.appDetails.complete_app_url)
      : this.selectedPlatform === "web"
      ? (redirectUrl = this.appDetails.web_app_url)
      : (redirectUrl = this.appDetails.mobile_app_url);
    if (redirectUrl) window.location.href = redirectUrl;
  }

  showAnswer(e) {
    this.questionShownIndex =
      this.questionShownIndex == e.dataset.index ? null : e.dataset.index;
    this.phaseService.trackEventForObject(this.analyticsSegment, "faq_viewed", {
      user_id: this.dataService.user?.id,
      faq_clicked:
        this.dataService.appDetails?.builder_faqs[this.questionShownIndex],
    });
  }

  // scrollToFrequenctSelection() {
  //   window.scrollTo(0, this.rightTopSection.nativeElement.offsetHeight);
  // }

  // openFeaturePopup(feature, index) {
  //   if (this.innerWidth < 768) {
  //     this.selectedFeature = index;
  //     setTimeout(() => {
  //       this.navFeatureSliderPopUp.moveTo(index);
  //     });
  //   }
  // }
  closeFeaturePopup() {
    this.selectedFeature = -1;
  }

  public toggleLayoutView(platform) {
    this.screenshots = [];
    if (this.selectedPreviewPlatform !== platform) {
      this.selectedPreviewPlatform = platform;
    }
    if (platform == "web") {
      this.dataService.appDetails.features.forEach((feature) => {
        let obj = {
          imageURL: feature.feature_screenshots.web,
          featureId: feature.id,
        };
        this.screenshots.push(obj);
      });
      this.imagesToShow = this.screenshots.slice(0, this.imageSet);
      this.selectedImage = this.screenshots[this.selectedImageIndex];
    } else {
      this.dataService.appDetails.features.forEach((feature) => {
        let obj = {
          imageURL: feature.feature_screenshots.android,
          featureId: feature.id,
        };
        this.screenshots.push(obj);
      });
      this.imagesToShow = this.screenshots.slice(0, this.imageSet);
      this.selectedImage = this.screenshots[this.selectedImageIndex];
    }
  }

  getCarePrice() {
    return Number(this.dataService.appDetails.rental_builder_care_price * 12);
  }

  toggleNatashaSession() {
    this.shouldStartNatashaSession = !this.shouldStartNatashaSession;
  }

  checkIfNatashaShownInStudioStore() {
    //return this.dataService.user && this.appDetails && (this.appDetails.id === 9785) && (this.appDetails.title === 'E Commerce App & Website');
    return (
      this.dataService.user &&
      this.appDetails &&
      this.appDataService.urlParameters.is_dls_store_flow
    );
  }

  createBuildCardIfUserLoggedIn() {
    if (this.checkIfNatashaShownInStudioStore()) {
      this.phaseService.isCreateBuildCardForNatashaFlow = true;
      this.createBuildCard();
    }
  }

  public showNatshaDesignInIframe(message) {
    let feature = this.dataService.appDetails.features.filter(
      (f) => f.id === message.feature_id
    );
    this.natashaDesignMessage.appFeature = feature;
    let safeSrcURL = this.sanitizer.bypassSecurityTrustResourceUrl(message.web);
    let screenshotFeatureIndex = this.screenshots.findIndex(
      (f) => f.featureId === message.feature_id
    );
    this.screenshots[screenshotFeatureIndex]["iframeURL"] = safeSrcURL;
    // this.selectImage(screenshotFeatureIndex);
    this.selectedImage = this.screenshots[screenshotFeatureIndex];
    this.selectedImageIndex = screenshotFeatureIndex;
    if (this.selectedImageIndex >= this.imageSet * (this.imageSetCount + 1)) {
      while (
        this.selectedImageIndex >=
        this.imageSet * (this.imageSetCount + 1)
      ) {
        this.changeImageSet("next");
      }
    } else if (this.selectedImageIndex < this.imageSet * this.imageSetCount) {
      while (this.selectedImageIndex < this.imageSet * this.imageSetCount) {
        this.changeImageSet("previous");
      }
    }
    // this.imagesToShow = this.screenshots.slice(screenshotFeatureIndex, this.imageSet);
    // message['showLoader'] = false;
    setTimeout(() => {
      this.phaseService.showLoaderInNatashaDesignIFrame = false;
    }, 5000);
  }

  navigateToExampleWebsite() {
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "view_examples_website",
      {
        user_id: this.dataService.user?.id,
      }
    );
    window.open("https://www.builder.ai/demo/ecommerce", "_blank", "noopener");
  }

  shouldShowStarterPlanSelection(value) {
    this.isPayStarterPlan = value;
  }

  checkIfNatashaProductionEnv() {
    // console.log('DLS_STORE_NATASHA_URL- ', environment.DLS_STORE_NATASHA_URL);
    if (
      environment.DLS_STORE_NATASHA_URL ==
      "wss://studio-ai-assistant-apis.studio.prod.eastus.az.svc.builder.ai/cable"
    ) {
      return true;
    }
    return false;
  }

  navigateToCatalogueFeature() {
    let featureIndex = this.screenshots.findIndex((f) => f.featureId === 581);
    if (featureIndex >= 0) {
      this.selectedImage = this.screenshots[featureIndex];
      this.selectedImageIndex = featureIndex;
      if (featureIndex >= this.imageSet * (this.imageSetCount + 1)) {
        while (featureIndex >= this.imageSet * (this.imageSetCount + 1)) {
          this.changeImageSet("next");
        }
      } else if (featureIndex < this.imageSet * this.imageSetCount) {
        while (featureIndex < this.imageSet * this.imageSetCount) {
          this.changeImageSet("previous");
        }
      }
    }
  }

  showHTMLParsedBodyContent(htmlString) {
    let regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
    let isValid = regexForHTML.test(htmlString);
    if (isValid) {
      const parser = new DOMParser();
      let parsedBodyContent = parser.parseFromString(htmlString, "text/html");
      let parsedContent = <HTMLElement>parsedBodyContent.body.firstChild;
      let finalParsedContent = parsedContent.innerHTML;
      return finalParsedContent;
    } else {
      return htmlString;
    }
  }

  toggleUpfrontPaymentPrice() {
    this.showUpfrontPaymentPrice = !this.showUpfrontPaymentPrice;
    if (this.showUpfrontPaymentPrice) {
      this.toggleUpfrontSelection();
    } else {
      this.toggleMonthlySelection();
    }
  }

  selectWebOnlyTab() {
    if (this.selectedPlatform === "all") {
      this.isShowTemplateChangePopup = true;
    }
  }

  selectWebAndAppTab() {
    if (this.selectedPlatform === "web") {
      this.isShowTemplateChangePopup = true;
    }
  }

  closeTemplateChangePopup() {
    this.isShowTemplateChangePopup = false;
  }

  navigateToNewTemplate() {
    if (this.selectedPlatform === "web") {
      window.open(this.appDetails.complete_app_url, "_blank", "noopener");
    } else {
      window.open(this.appDetails.web_app_url, "_blank", "noopener");
    }
    this.closeTemplateChangePopup();
  }

  scrollToStorePlanSection() {
    let element = document.getElementById("dlsStorePlans");
    element.scrollIntoView();
  }

  navigateToTalkToExpertLink() {
    this.phaseService.trackEventForObject(
      this.analyticsSegment,
      "talk_to_an_expert",
      {
        user_id: this.dataService.user?.id,
        clicked_source: "Footer",
      }
    );

    window.open(
      "https://www.builder.ai/dls-talk-to-an-expert",
      "_blank",
      "noopener"
    );
  }

  openSignupModal() {
    this.phaseService.openCommonAuth = true;
    if (this.dataService.homePageData?.block_sign_up) {
      this.phaseService.showSignin = true;
      this.phaseService.showRegister = false;
    } else {
      this.phaseService.showRegister = true;
      this.phaseService.showSignin = false;
    }
  }

}

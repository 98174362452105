import { EventEmitter, Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Constants } from '../utils/constants';
import { ApiRequests } from './api-requests.service';
import { FeatureModel } from '../models/feature-model/feature.model';
import { AppModel } from '../models/app-model/app.model';
import { CategoryModel } from '../models/category-model/category.model';
import { BundleModel } from '../models/bundle-model/bundle-model';
import { PhaseModel } from '../models/phase-model/phase.model';
import { TeamModel } from '../models/teams-model/team.model';
import { SpeedModel } from '../models/speed-model/speed.model';
import { ProductModel } from '../models/product-model/product.model';
import { PlatformModel } from '../models/platform-model/platform.model';
import { Observable, Subject, Subscription } from 'rxjs';
import moment from 'moment';
import { PromotionModel } from '@models/homepage-data-model/promotion.model';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Angulartics2Segment } from 'angulartics2/segment';
import { Title } from '@angular/platform-browser';
import { PhaseDataModel } from '@models/phase-model/phasedata.model';
import { AppDataService } from '@rootmodule/app-data.service';
import 'rxjs-compat/add/operator/finally';
import { DatePipe } from '@angular/common';
import { TeamsModel } from '@models/teams-model/teams.model';
import { ModelMapperService } from '@shared/services/model-mapper.service';
import { UrlParameterModel } from '@models/url-parameters-model/url-parameter.model';
import { ChangeFeatureRequestModel } from '@models/feature-model/change-feature-request.model';
import { CommonService } from '@shared/services/common.service';
import { SpecCallModel } from '@models/spec-call-model/spec-call.model';
import { Intercom } from 'ng-intercom';
import { CurrencyModel } from '@models/currency-model/currency.model';
import { BuilderCloudModel } from '@models/builder-cloud/cloud-model';
import { BehaviorSubject } from 'rxjs';

const COREFEATURES = 'coreFeatures';
const CUSTOMFEATURES = 'customFeatures';
const ID = 'id';
const USER_REQUESTED = 'user_requested';
const DESCRIPTION = 'description';
const COMPLEXITY = 'complexity';
const INTERFACE_TYPE = 'interface_type';
const TITLE = 'title';
const PRICE_MULTIPLIER = 'price_multiplier';
const WEEK_MULTIPLIER = 'week_multiplier';
const FEATURE_COUNT = 'feature_count';
const REPEATABLE_COUNT = 'repeatable_count';
const ALGORITHM = 'algorithm';
const DIFFICULTY = 'difficulty';
const FEATURE_NOTE = 'feature_note';
const FEATURE_ATTACHMENT = 'feature_attachment';
const FEATURE_SET_ID = 'feature_set_id';

@Injectable()
export class PhaseService {
  changeRequestBody =
  {
    build_card_id: 0,
    change_request: {
      weeks: 0,
      price: 0,
      build_phase_data: {},
      reason: {},
      build_card_data: { features: [] },
      customisation_price: 0,
      custom_feature_price: 0,
      status: ''
    }
  }
  public deviceInfo = {
    deviceType: '',
    browser: ''
  };
  public showTaxAndBilling = false;
  public phaseTitle = [];
  public deviceType: string;
  public browserName: string;
  public priceLoader = false;
  public isSettingPage = false;
  public showViewPrototypeSS = false;
  public emailConfirmedPoupup = false;
  public userAlreadyVeriied = false;
  public viewPrototypeClicked = false;
  public emailFromToken: string;
  public showCancellationPopup : boolean;
  public usernameFromToken: string;
  public userRegistered = false;
  public freemiumCardWithToken = false;
  public subscriptionId;
  public filteredPromotionList: PromotionModel[] = [];
  public buildCardUpdated = false;
  public cardStatusSelected = false;
  public IsFromFreemium = false;
  public DocUploadedName: string;
  public SelectedDoc: File;
  DocNotUploaded = true;
  public showDocUploadArea = false;
  public updatedInstamentsClicked = false;
  public allQuestionsAnswered = false;
  public Feedback_Answers = {};
  public chatBotShown = false;
  newSelectedProblemsApps = [];
  newSelectedApps = [];
  applicationSectionId = '';
  firstClicked = '';
  appListApps = [];
  offerHeaderEvent = new EventEmitter();
  offersArr = [];
  isSignupByChatbot = false;
  public showSpinner = false;
  public newHomePagePromotion;
  public searchApp = false;
  public newSelectedAppIds = [];
  public newSelectedProblemsIds = [];
  public newSelectedProblemsAppsIds = [];
  public newProblemsData = [];
  public newSelctedProductId;
  public authenticated;
  public price;
  public buttonEnabled;
  public selectedHash;
  public breadcrumb_links = [];
  public showSaveProgress = false;
  public gotoDashBoard = false;
  public gotoCreateNew = false;
  public logoutToHome = false;
  public showAll = false;
  public selectedDataForSidePanel = [];
  public defaultTeamShow;
  public topNavPhases;
  public currentActive;
  public showCurrency = true;
  public showNDA = false;
  public showList = false;
  public isNdaFlow = false;
  public successTitle;
  public successDesc;
  public featuresToShow = [];
  public categoriesToShow = [];
  public eaiButtonToShow;
  public originalPrice;

  public promoCode;
  public inProgressBuildCardId = null;
  public paidPhaseId = null;
  public buildCardEditMode = false;
  public recentSearchList = [];
  public selectedCategories: Array<CategoryModel>;
  public selectedApps: Array<AppModel>;
  public selectedFeatures: Array<FeatureModel>;
  public selectedBundles: Array<BundleModel>;
  public selectedPhases: Array<PhaseModel>;
  public selectedPlatforms: Array<PlatformModel>;
  public unSelectedPlatforms: Array<PlatformModel>;
  public phaseData: Array<PhaseDataModel>;
  public featureData = [];
  public selectedTeam: TeamModel;
  public selectedSpeed: SpeedModel;
  public selectedProduct: ProductModel;
  public buildCardSelectedPhases = [];
  public selectedPhasesIds = [];
  public promoCodeShow;
  public finalPricenWeeks;
  public supportMaintenanceWeeks = 1;
  public currentMultipliers;
  public myTimeZone = false;
  public reachedUptoPhases = false;
  public navigateNormally = false;
  public platformPrices;
  public flatPrice;
  public discountedPrice;
  public customizationCost = 0;
  public customFeaturesPrice = 0;
  public featureReusableCost = 0;
  public builderCarePrice = 0;
  public subscriptionCost = 0;
  public totalCarePrice = 0;
  public userDetails;
  public addedFeature;
  public promotionValid;
  public showRightPanel = true;
  public showHourCard = false;
  public teamandspeedproper = false;
  public addedTemplate = { id: '', category_ids: [], title: '', reference_urls: [], platform_ids: [], selected: false };
  public totalHourlyPrice;
  public skillsList;
  public addnewfeature = false;
  public requestedFeatureList: Array<FeatureModel>;
  public requestedCategoryList = [];
  public filesList = [];
  public requestedTemplateList = [];
  public showAddNewTemplate = false;
  public natashaSearchedText;
  public difficultyText = 'Moderate';
  public showSignin = false;
  public showV2SignUpPopup = false;
  public showV2LoginPopup = false;
  public showV2RegisterPopup = false;
  public showRegister = false;
  public forceLogin = false;
  public showForgotPassword = false;
  public isSpecingFlow = false;
  public isProfile = false;
  public isDashboardShow = false;
  public holdOfferFlow = false;
  public holdOfferPopup = false;
  public specing_price = 0;
  public fromDashBoard = false;
  public summaryScreen = false;
  public showCategories = false;
  public templatesToShow = [];
  public selectedCategory;
  public preventClick = false;
  public isBuildCardSetup = false;
  public loadDashBoard = false;
  public invitePopup = false;
  public sharePopup = false;
  public currentCardInProgress: any;
  public comingFromProjectProgress = false;
  public showLearnMore = false;
  public learnMoreIndex = 0;
  public emailsToShareInvite = [];
  public cardToShareInvite = null;
  public billingInfo = {
    name: '',
    firstname: '',
    lastname: '',
    email: '',
    contact: '',
    address: '',
    street: '',
    apartment: '',
    state: '',
    city: '',
    pincode: '',
    country: '',
    gstNumber: '',
    companyName: '',
    billing_entity: '',
    address_line_2: '',
    billed_as: 'company'
  };
  public dashboardOpen = false;
  public promotionApplied = false;
  public otpConfirmationMessage = false;
  public isEditDoneOnBuildCard = false;
  public ownershipTransferPopup = false;
  public showOwnershipConfirmationDashboard = false;
  public showOwnershipConfirmationSummary = false;
  public isInviteEdited = false;
  public manualPromoCode: string;
  public finalPromoApplied: String;
  public isManualPromotionClicked = false;
  public manualPromotionApplied = false;
  public isShowCreateCardForOthersPopup = false;
  public becomePartner = false;
  public openCommonAuth = false;
  public hideOffers = false;
  public SignUpProviders = '';
  public showPromobar = true;
  public showclose = false;
  public showMobileClose = false;
  public showLockDiscount = false;
  public appliedPromotion = false;
  public phaseBreakupData = [];
  public sideNavClose: EventEmitter<boolean> = new EventEmitter();
  public inProgressUniqCode = '';
  public skipSignNDA = false;
  createBuildCardForCPE = false;
  public storeUrl = environment.STORE_URL;
  public providers = {
    google: {
      clientId: '399522936347-01vsdt9ffp5kvf4bm1c05g0ut0m7homi.apps.googleusercontent.com',
    },
    linkedin: {
      clientId: '81tlb8hu6bb7xu',
    },
    facebook: {
      clientId: '1889590484589531',
      apiVersion: 'v2.9',
    }
  };
  public inProgressFlow = false;
  public totalPrice;
  public dataService;
  public isBuildPhases = false;
  public installments = [];
  public deposit;
  public supportMaintenance = 0;
  public showTemplateSecondInput = false;
  public selectedTemplates = {
    firstTemplate: {} as any,
    secondTemplate: {} as any
  };
  public isWelcomePopupOpen = false;
  private featureAddSubject = new Subject<any>();
  public queryToSave;
  public buildCardPage: any = {};
  public perPageCardCount = 10;
  public currentSelectedBuildCardStatus;
  public linkCopied = false;
  public priceCalculated = false;
  public showPromotionDropdown = false;
  public paymentData: any = { pending_installment: {}, general_info: {}, installments: [] };
  public currencySymbol;
  public showPaymentStatus = false;
  public paymentBuildCard;
  public showTimer = false;
  public openSideNav = false;
  private currencyChangeSubject = new Subject<any>();
  public viewPrototypeSubject: Subject<boolean> = new Subject();
  public selectedCountry;
  public isInvitedUserPopupOpened = false;
  public ownershipTranferMessage = false;
  public isOpenSpecing = false;
  public showPlatformPopup = false;
  public isShowSpecPopup = false;
  public progressSubUrl = '';
  public country_code = '';
  public popupPromocode = null;
  public promotionCurrencySubject: Subject<boolean> = new Subject();
  public showHelloText = true;
  public signupPopup = false;
  public clickedsignupPopup = false;
  public popupCoupon = false;
  public clickedpopupsignin = false;
  public isPopupPromotionApplied = false;
  public popupCouponApplied = false;
  public checkAutoLoginPopup = true;
  public isAdvancedVersion = false;
  public promotionValidStatus = false;
  private timer;
  public promotionExpiryDays = 0;
  public enteredEmailonExitIntent;
  public isAppDiscountApplied = false;
  public AppDiscountSuccessfullyApplied = false;
  public isAppLocked = false;
  public isManualPromotionApplied = false;
  public promotionError;
  public promoError;
  public buildCardSelections: any;
  public showWelcomeScreen = false;
  public signupNextStep = false;
  public countryNameCode = '';
  public paymentModuleStarted = false;
  public proceedToContinuePayment = false;
  public addressSubmitted = false;

  public isUrlPromotionValid = false;
  public urlBanner = null;
  public installmentCount = 0;
  public timeSpent = 0;
  public enablePayment = false;
  public costWithoutDiscount = 0;
  public totalWeeks = 0;
  public isRedirectingSignContract = false;
  public isRedirectingMasterContract = false;
  public promotionLoader = false;
  public builderCareAmount = 0;
  public installmentAmt = 0;
  public noOfInstallments = 0;
  public isInstallmentsAPIResolved = false;
  public isContractApiResolved = false;
  public isValidToProceedForPayment = false;
  public dateOfFirstPayment = null;
  public pipe = new DatePipe('en-US');
  public allContracts;
  public showAppInBreadCrumb = true;
  public promotionChangedOnMaxBuildCard = false;
  public showCouponLoader = false;
  public showBottomBar = false;
  public showHideBottomBarLoader = true;
  public mobileStepName = '';
  public mobileTotalSteps;
  public showMobileSteps = false;
  public mobUserLogin = false;
  public currentProjectName: string;
  public showProjectNamePopup = false;
  public isReadyToProceed = false;
  public isInstallmentsChanged = false;
  public noexperimentpromotions = false;
  public productAndApp = { product: 0, app: 0 };
  public selectedProductFromHome;
  public depositAmount = '';
  public installmentID = '';
  isFromSpecPayment: boolean;
  isFromInstantPrototype = false;
  weeklyDeposit: any;
  monthlyDeposit: any;
  upfrontInstallment: any;
  public perMonthCost = 0;
  isPlatformBrowser = false;
  getHelpClicked = true;
  public isShowPaymentExitFeedback = false;
  public countExitBillingDetails = 0;
  public countExitPaymentPlan = 0;
  public exitIntentTimeSpent = 0;
  public isShowPaymentExitOffer = false;
  public isExitPaymentOffer = false;
  public specCallCreationData: SpecCallModel;
  public uploadDocOnNDAClicked = false;
  public paymentExitIntentTimeSpent = 0;
  public exitPopupBuildCardPrice = 0;
  public exitPopupBuildCardDiscountPrice = 0;
  public showCalendlyPopup = false;
  public exitIntentInterval;
  public paymentExitIntentInterval;
  public specCallData: SpecCallModel;
  public showMeetNowSearchingScreen = false;
  public isPaymentDone = false;
  public isPaymentFailed = false;
  mandatoryFeatures: FeatureModel[] = [];
  setMvpSelected: boolean;
  currencyForCAT: object;
  isFeatureTutorialActive: boolean;
  public pricingModal: string;
  public initialAppPhases = [];
  public isBuilderCloudChecked = true;
  public selectedCurrency;
  public signUpCurrencyForBNow = false;
  public showCurrencyOptionsForBnow = false;
  public rapidPrototypePlatforms: Array<PlatformModel> = [];
  public selectedPlatformsForRapidPrototype: Array<PlatformModel> = [];
  assignSameSpeed = false;
  public monthsDiscountForRental = null;
  public isPayNowClickedForRental = false;
  public showRentalPaymentFlow = false;
  public showCallBookedSuccess: boolean;
  public selectedSectionIndex = 0;
  public isRentalCardPaid = false;
  public isMeetNowForRental = false;
  public meetNowForRentalClicked = false;
  public selectMonthlyPackage = true;
  public selectUpfrontPackage = false;
  private apiPollSub: Subscription;
  private paymentStatusInterval: any;
  transactionId: any;
  windowToCLose;
  public additionalRentalFeatures = [];
  proceedClickedFromRental = false;
  public currencyForNow: CurrencyModel;
  public showSignNDAPopup = false;
  public contractTermsConditionsChecked = false
  public contractTermsConditions = { conditionOne: false, conditionTwo: false, conditionThree: false, conditionFour: false, conditionFive: false };
  public isToggleBuilderCloud = false;
  public builderCloudDetails: BuilderCloudModel;
  public builderCloudDetailsForUSD: BuilderCloudModel;
  public selectedIndexForCloudPrice = 0;
  ndaPopupScr = false;
  msaPopupScr = false;
  customPhaseCount = 0;
  public ownershipTransferCurrencyMismatchMessage = false;
  public ownershipTransferUserNotAvailableMessage = false;
  public ownershipTransferInvitationSentMessage = false;
  public emailToEnterForOwnershipTransfer = null;
  public isShowApproveChangeModalAfterSecurityPaid = false;
  public previousSelectedFeatureCount: number;
  talkToSalesClicked = false;
  rentalTemplateAlias: any;
  public recommendedFeaturesActivity: Array<{ id, record_text, created_at }> = [];
  public recommendedFeaturesHistory: Array<{ id, record_text, created_at }> = [];
  buildCardUpdateOnFeatureAdded = false;
  showInstantSpecCallFlow = false;
  showScheduleCallFlow = false;
  userInputAndResult: Array<{ input, resultRecords, createdAt }> = [];
  natashaChatsList = {};
  natashaIntroMessagesList = [];
  natashaTempIntroMessagesList = [];
  recommendedFeaturesSliderIndex = 0;
  showTemplateSearchBox = false;
  buildCardCreationStarted = false;
  isEmptyBuildCard = false;
  natashaAddedFeatureMessages = [
    "Yes, that should be really handy, I have added \"[featureName]\".",
    "I like that feature too, have added \"[featureName]\".",
    "Great choice, I’ve just added \"[featureName]\" to your Buildcard™.",
    "Added \"[featureName]\", that should really improve your idea.",
    "Your Buildcard™ really taking shape, added \"[featureName]\"."
  ];
  natashaCurrentAddedFeatureMessages = [];
  natashaIdleQuestionCount;
  natashaIdlQuestionTimeInterval = 50000;
  clonedCardCurrency: CurrencyModel;
  cardIdToBeCloned: string;
  freemiumSubDomainExists = false;
  isCpeCloningCard = false;
  isFromDashBard = false;
  SSCardRenamed = false;
  isFromDelivery = false;
  isNatashaFirstBundleResponse = true;

  isNewBuildCard = false;
  errMessage = 'Entered sub-domain already exists';
  natashaGroupIdentifier = '';
  natashaGroupChatId = 1;
  natashaGroupIdText = 'natashaGroupId';
  fiveDaySecondsConstant = 5 * 24 * 3600;
  specCallPopupAppeared = false;
  isFromDeliveryPage = false;
  isViewUpdatedInstallmets = false;
  specCallInterval: any;
  specCallApiSubPoll: Subscription;
  specCallIsScheduled = false;
  scheduleCallData: any;
  natashaMobileGroupIdentifier = '';
  natashaMobileGroupChatId = 1;
  showTemplateNatashaMobileAnimation = false;
  public showNav = false;
  isCollapseTemplateHistory = false;
  isCustomFeatureFlowFromNatasha = false;
  customFeatureObjectFromNatasha = {
    id: '',
    title: '',
    description: '',
    template_ids: [],
    difficulty: 6,
    algorithm: 'none',
    stream_media: false,
    interface_type: 'backend',
    reference_urls: [],
    selected: false
  };
  isShowCustomFeatureTitleQuestion = false;
  isShowCustomFeatureDescriptionQuestion = false;
  isShowCustomFeatureSimilarAppsQuestion = false;
  templateNatashaSearchText: string;
  handleLoaderOnPrice: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  handleDashboardLoading: BehaviorSubject<any> = new BehaviorSubject<any>(true);
  showTemplateLoader = true;
  moment: any;
  infoForWhichPrototype: string;
  isPopUpBlockerEnabled = false;
  showIntercomChatWindow = true;
  featureRequestUniqueCode: string;
  isShowPaymentOptionsLoader = false;
  bookSpecCallClicked = false;
  carePrices: any;
  isDemoCall = false;
  isFromDashboard = false;
  dontSetCareConfig = false;
  donationOpted: boolean;
  isShowDashboardMenuForMobileOrTablet = false;
  totalDepositAmount = null;
  showGSTIN = false;
  isGSTCheckboxChecked = false;
  totalDiscountedPriceWithCare: any;
  showBeforeLeavingSchedulerPopup = false;
  isShowFeatureRecommendationsModal = false;
  isClearCustomFeatureModalValues = false;
  pricingSubscrition: Subscription;
  speedPrices = null;
  showSpeedLoader = false;
  subscriptionDuration = 0;
  monthlySubscriptionPrice = 0;
  changeRequestPlatformHash: any;
  isCountChanged = false;
  isCreateBuildCardForNatashaFlow = false;
  showLoaderInNatashaDesignIFrame = false;
  public preKickOffDateModified = new Subject<void>();
  shouldBlurDlsStoreNatashaBackground = true;
  recommendedCoreFeaturesToBeAdded = [];
  isShowLoaderOnCustomFeatureFooter = false;

  constructor(private appDataService: AppDataService, private titleService: Title, dataService: DataService, public intercom: Intercom,
    public apiRequest: ApiRequests, public router: Router, public analyticsSegment: Angulartics2Segment, public modelMapperService: ModelMapperService,
    private commonService: CommonService) {

    this.requestedFeatureList = [];
    this.requestedCategoryList = [];
    this.requestedTemplateList = [];
    this.selectedCategories = [];
    this.selectedApps = [];
    this.selectedFeatures = [];
    this.selectedBundles = [];
    this.selectedPhases = [];
    this.selectedPlatforms = [];
    this.unSelectedPlatforms = [];
    this.addedFeature = {
      id: '',
      title: '',
      description: '',
      template_ids: [],
      difficulty: 6,
      algorithm: 'none',
      stream_media: false,
      interface_type: 'backend',
      reference_urls: [],
      selected: false,
      dependent_features: []
    };

    this.userDetails = { name: '', countryCode: '1', phone_number: '', email: '' };
    this.authenticated = { value: true };
    this.dataService = dataService;
    this.successTitle = { value: '' };
    this.eaiButtonToShow = true;
    this.successDesc = { value: '' };
    this.currentActive = { value: 'Home' };
    this.totalPrice = { value: 0 };
    this.selectedDataForSidePanel = [];
    this.promoCode = { id: null, multiplier: 1, discount: 0 };
    this.buttonEnabled = true;
    this.featuresToShow = [];
    this.totalHourlyPrice = 0;
    this.finalPricenWeeks = {
      total: 0,
      perWeek: 0,
      weeks: 0,
      actual_weeks: 0,
      bcard_discounted_price: 0,
      payment_weeks: 0,
      first_delivery_time: 0,
    };
    this.originalPrice = { total: null, perWeek: null };
    this.promoCodeShow = false;
    this.currentMultipliers = { price: 1, weeks: 1 };
    this.selectedHash = {
      project_name: 'My Builder Project',
      description: null,
      target_user_group: '',
      customized_template_name: '',
      skip_freemium_validation: false,
      utm_source: null,
      utm_campaign: null,
      utm_medium: null,
      support_maintenance_weeks: 0,
      startup: false,
      self_funded: false,
      npo_ngo: false,
      specing: false,
      product_id: '',
      audience_id: null,
      project_status_id: null,
      application_ids: [],
      platform_ids: [],
      feature_ids: [],
      team_id: null,
      speed_id: null,
      build_phase_vars: [],
      promotion_id: null,
      region_skill_ids: [],
      competitors: [],
      app_style_ids: [],
      category_ids: [],
      startup_urls: [],
      attachments_attributes: [],
      quote_price: 0,
      status: 'card_initialized',
      nda_signed: false,
      build_card_phases: [],
      is_under_support: true,
      care_type: this.dataService.checkIfEnterpriseUserOrCard() ? 'enterprise_care_plus' : 'standard_care',
      buildcardphases: [],
      is_advance: false,
      pricing_params: {},
      build_card_phases_attributes: [],
      multiplier_experiment_id: '',
      currency_id: null,
      prototype_promo: false,
      cloud_price: 0,
      cloud_opted: true,
      cloud_user_count: 0,
      source: 'home',
      create_spec_call: 'false',
      upfront: false,
      template_query_string: '',
      tailor_made: false,
      pre_kick_off_date: '',
    };
    this.skillsList = [];
    this.setSelectedCountry();
    this.moment = moment;
    window.addEventListener('MyEventType', function (evt: any) {
      this.showIntercomChatWindow = false;
    }, false);
  }

  public headerBreadCrumb = [
    {
      title: 'Features',
      selected: false,
      link: 'features',
      titleToShow: 'Refine features'
    },
    {
      title: 'Delivery and Deliverables',
      selected: false,
      link: 'delivery',
      titleToShow: 'Plan delivery'
    },
  ];

  public setSelectedCountry() {
    const currentDate = new Date() + '';
    if (currentDate.indexOf('GMT+0530') !== -1) {
      this.selectedCountry = 'IN';
    } else if (currentDate.indexOf('GMT+0') !== -1 || currentDate.indexOf('GMT+00') !== -1 || currentDate.indexOf('GMT+0000') !== -1 || currentDate.indexOf('GMT+0100') !== -1 || currentDate.indexOf('GMT+0200') !== -1 || currentDate.indexOf('GMT+0300') !== -1) {
      this.selectedCountry = 'UK';
    } else if (currentDate.indexOf('GMT-0400') !== -1 || currentDate.indexOf('GMT-0500') !== -1 || currentDate.indexOf('GMT-0600') !== -1 || currentDate.indexOf('GMT-0700') !== -1 || currentDate.indexOf('GMT-0800') !== -1 || currentDate.indexOf('GMT-0900') !== -1 || currentDate.indexOf('GMT-0930') !== -1 || currentDate.indexOf('GMT-1000') !== -1) {
      this.selectedCountry = 'US';
    }
  }

  public getCurrentDate() {
    return new Date();
  }


  public getAppToken() {
    return environment.PAYMENT_APP_TOKEN;
  }

  public getInstallmentWithTax(installment: any) {
    if (installment.type === 'specing' || this.dataService.currentPage === 'summary') {
      return Number(installment.installment_amount);
    } else if ((installment.type === 'support') && (this.dataService.currentPage !== 'dashboard')) {
      return Number(!this.dataService.buildCardData.new_care_flow ? installment.installment_amount : (installment.installment_amount * 12));
    } else if ((installment.type === 'support') && (this.dataService.currentPage === 'dashboard')) {
      return (Number(installment.installment_amount) + Number(installment.tax_amount)) * 12;
    } else if (installment.type === 'deposit') {
      this.totalDepositAmount = Number(installment.installment_amount) + Number(installment.tax_amount);
      return this.totalDepositAmount;
    } else {
      return Number(installment.installment_amount) + Number(installment.tax_amount);
    }
  }

  public getOriginalAmount(installment: any) {
    if (installment.type === 'deposit' && installment.original_amount && parseFloat(installment.installment_amount) != parseFloat(installment.original_amount)) {
      return Number(installment.original_amount);
    } else {
      return null;
    }
  }

  public showTimeKitWindow(isDemo?: boolean, specingID?: number, callType?: string) {
    this.showCalendlyPopup = true;
    let params = '';
    if (this.dataService && this.dataService.user) {
      const user = this.dataService.user;
      params += user.first_name ? ('&name=' + user.first_name + (user.last_name ? (' ' + user.last_name) : '')) : '';
      params += user.email ? ('&email=' + user.email + '') : '';
      params += user.phone_number ? ('&phone=' + user.phone_number) : '';
      params += user.organisation ? ('&company=' + user.organisation) : '';
      params += user.enterprise_lead ? ('&enterprise_tag=true') : ('&enterprise_tag=false');
    }
    if (this.dataService.buildCardData.description){
      this.natashaSearchedText = this.dataService.buildCardData.description;
    }
    if (this.natashaSearchedText) {
      params += '&project_idea=' + this.natashaSearchedText;
    }
    if (this.dataService.buildCardData.type === 'rental_price' && this.dataService.buildCardData.source === 'app_detail') {
      params += '&store=true';
    }
    if (isDemo) {
      switch (this.selectedCountry) {
        case 'IN':
          window.open('https://calendly.com/engineer-demo/book-a-quick-demo-now?month=2020-04Sales', '_blank', 'height=600,width=600');
          break;
        case 'UK':
        case 'US':
          window.open('https://calendly.com/engineer-demo/book-a-quick-demo-now?month=2020-04Sales', '_blank', 'height=600,width=600');
          break;
        default:
          window.open('https://calendly.com/engineer-demo/book-a-quick-demo-now?month=2020-04Sales', '_blank', 'height=600,width=600');
          break;
      }
    } else {
      if (specingID && specingID > 0) {
        return (this.getSpecCalendlyLink(callType) + '&generic_id=' + specingID + params);
        // window.open(this.getSpecCalendlyLink(callType) + '&generic_id=' + specingID + params, '_blank', 'height=740,width=740');
      } else {
        return (this.getSpecCalendlyLink(callType) + '&generic_id');
        // window.open(this.getSpecCalendlyLink(callType) + '&generic_id', '_blank', 'height=740,width=740');
      }
    }
  }

  public getSpecCalendlyLink(callType) {
    let typeOfCall = callType ? callType : 'spec';
    let url = environment.CALENDLY_URL.replace("{type}", typeOfCall);
    return url;
  }

  public getTaxOnTotal() {
    return Math.round(((Number(this.getDiscountedPriceForCards(this.dataService.buildCardData)) * Number(this.dataService.buildCardData.currency.tax)) / 100));
  }

  public getTaxOnDiscountedPrice(discountedPrice) {
    return Math.round((Number(discountedPrice) * 18) / 100);
  }

  public getTotalWithTax() {
    if (this.dataService.currentPage === 'summary') {
      return Number(this.getDiscountedPriceForCards(this.dataService.buildCardData));
    } else {
      return Number(this.getDiscountedPriceForCards(this.dataService.buildCardData)) + Number(this.getTaxOnTotal());
    }
  }

  getTaxOnTotalForPaymentArea() {
    return Math.round(((Number(this.paymentData.general_info.total_amount) * Number(this.paymentData.general_info.tax)) / 100));
  }

  getTotalWithTaxForPaymentArea() {
    if (this.dataService.currentPage === 'summary') {
      return Number(this.paymentData.general_info.total_amount);
    } else {
      if (this.paymentBuildCard.subscription_flow) {
        return Number(this.paymentData.general_info.build_card_tax_amount)
          + Number(this.paymentData.general_info.build_card_total_amount);
      } else {
        return Number(this.paymentData.general_info.total_amount) + Number(this.getTaxOnTotalForPaymentArea());
      }
    }
  }

  public getBalanceAmount() {
    let balanceAmount = 0.00;
    for (const installment of this.paymentData.installments) {
      if (installment.status !== 'paid') {
        balanceAmount += this.getInstallmentWithTax(installment);
      }
    }
    return balanceAmount;
  }

  public getTotalAmountPaid() {
    let totalPaid = 0.00;
    for (const installment of this.paymentData.installments) {
      if (installment.status === 'paid') {
        totalPaid += this.getInstallmentWithTax(installment);
      }
    }
    return totalPaid;
  }

  public initializeBuilder(isFromBuildCardCreationFlow?) {
    if (!this.dataService.shareUrlData) {
      if (this.appDataService.urlParameters.promotionCode && this.dataService.homePageData.promotion && this.dataService.homePageData.promotion.banner) {
        this.urlBanner = this.dataService.homePageData.promotion.banner;
      }
      if (this.dataService.homePageData.promotion && this.isValidPromotion()) {
        if (!this.isBuildCardSetup && this.appDataService.urlParameters.promotionCode) {
          this.noexperimentpromotions = true;
          this.isUrlPromotionValid = true;
        }
      }
    }
    if (this.isValidPromotion()) {
      this.promotionValid = true;
      if (this.dataService.homePageData.promotion.expires_at) {
        this.promotionExpiryDays = this.promotionExpiry(this.dataService.homePageData.promotion.expires_at);
      } else {
        this.promotionExpiryDays = 0;
      }
      this.flatPrice = this.dataService.homePageData.promotion.discount;
      this.selectedHash.promotion_id = this.dataService.homePageData ?.promotion ?.id;
    } else {
      this.promotionValid = false;
      this.flatPrice = 0;
      this.selectedHash.promotion_id = '';
    }

    if (this.appDataService.urlParameters) {
      if (this.appDataService.urlParameters.name) {
        this.userDetails.name = this.appDataService.urlParameters.name;
      }
      if (this.appDataService.urlParameters.email) {
        this.userDetails.email = this.appDataService.urlParameters.email;
      }
      if (this.appDataService.urlParameters.phone_number) {
        if (this.appDataService.urlParameters.phone_number.indexOf('+') !== -1 && this.appDataService.urlParameters.phone_number.indexOf('-') !== -1) {
          this.userDetails.countryCode = this.appDataService.urlParameters.phone_number.substring(1, this.appDataService.urlParameters.phone_number.indexOf('-'));
          this.userDetails.phone_number = this.appDataService.urlParameters.phone_number.substring(this.appDataService.urlParameters.phone_number.indexOf('-') + 1,
            this.appDataService.urlParameters.phone_number.length);
        } else {
          this.userDetails.phone_number = this.appDataService.urlParameters.phone_number;
        }
      }
      if (this.appDataService.urlParameters.countryCode) {
        this.userDetails.countryCode = this.appDataService.urlParameters.countryCode;
      }

    }
  }

  public getTotalWithSpecing() {
    if (this.dataService.currentPage === 'templateDetail') {
      if (this.checkIfRentalPricingModal()) {
        return this.getRentalPrice();
      } else if (this.conditionForMonthlyPricingModal()) {
        return this.getMonthlyTotalPrice();
      }
      return Number(this.finalPricenWeeks.total);
    }
    if (this.dataService.appDetails && (this.selectedApps && (this.selectedApps.length > 0))) {
      const monthlyPrice = (this.dataService.appDetails && (this.dataService.currentPage !== 'features')) ? this.dataService.appDetails.monthly_price : this.selectedApps[0].monthly_price;
      return Number(this.appDataService.urlParameters.cpe_user ? (this.finalPricenWeeks.total + monthlyPrice) : this.finalPricenWeeks.total);
    } else {
      return this.finalPricenWeeks.total;
    }
  }

  public getDiscountedPrice() {
    return this.finalPricenWeeks.payment_weeks > 0 ? (Number(this.finalPricenWeeks.bcard_discounted_price) / this.finalPricenWeeks.payment_weeks) : 0;
  }

  public getDiscountedWithSpecing() {
    if (this.conditionForMonthlyPricingModal() && this.dataService.currentPage === 'templateDetail') {
      return this.getRentalOrMonthlyDiscountedPrice();
    }
    return this.discountedPrice;
  }

  public getChangeAmount() {
    return this.dataService.buildCardData.change_price_status ? this.dataService.buildCardData.change_amount : -this.dataService.buildCardData.change_amount;
  }

  public getTotalDays(weeks: number): number {
    if (weeks >= 1) {
      const flooredWeeks = Math.floor(weeks);
      const decimalWeeks = weeks - flooredWeeks;
      if (decimalWeeks > 0) {
        return Number(Number(flooredWeeks * 7) + Number(this.getDecimalDays(Math.round(decimalWeeks * 10) / 10)));
      } else {
        return Number(flooredWeeks * 7);
      }
    } else {
      return this.getDecimalDays(Math.round(weeks * 10) / 10);
    }
  }

  public getDecimalDays(decValue) {
    let days = 0;
    switch (decValue) {
      case 0.1:
        days = 1;
        break;
      case 0.2:
        days = 2;
        break;
      case 0.3:
        days = 3;
        break;
      case 0.4:
      case 0.5:
        days = 4;
        break;
      case 0.6:
        days = 5;
        break;
      case 0.7:
        days = 5;
        break;
      case 0.8:
        days = 6;
        break;
      case 0.9:
        days = 7;
        break;
      case 1:
        days = 7;
        break;
    }
    return days;
  }

  public handleSidePanelData(dataToAdd: any, titleToCompare) {
    if (this.selectedDataForSidePanel.length) {
      const templateIndex = this.selectedDataForSidePanel.findIndex(item => {
        return item.title === titleToCompare;
      });
      if (templateIndex != -1) {
        this.selectedDataForSidePanel.splice(templateIndex, 1, dataToAdd);
      } else {
        this.selectedDataForSidePanel.push(dataToAdd);
      }
    } else {
      this.selectedDataForSidePanel.push(dataToAdd);
    }
  }

  public getRedirectUrl() {
    return window.location.origin + '?admin_access=true';
  }


  public generateRandomNumber() {
    return Math.floor(Math.random() * 30) + 1;
  }

  public setBreadcrumbLink(key) {
    const obj = { text: '', url: '' };
    if (key === 'apps') {
      obj.text = 'Templates';
    } else if (key === 'build-card') {
      obj.text = 'Build Card';
    } else if (key === 'features') {
      obj.text = 'Features';
    } else if (key === 'delivery') {
      obj.text = 'Delivery';
    }
    obj.url = key;
    if (obj.url === 'build-card') {
      obj.url = 'build-card/' + this.inProgressBuildCardId;
    }
    const links = this.breadcrumb_links;
    const index = links.indexOf(links.filter((item) => {
      return item.text === obj.text;
    })[0]);
    if (index < 0) {
      links.push(obj);
    } else {
      links.splice(index + 1, links.length);
    }
  }

  public selectSpecing() {
    if (this.dataService.user) {
      this.isOpenSpecing = true;
    } else {
      this.isShowSpecPopup = true;
      this.showSignin = true;
      this.openCommonAuth = true;
    }
  }

  public hideHoldOffer() {
    this.holdOfferPopup = false;
    this.holdOfferFlow = false;
  }

  public handleHoldOfferFlow(router) {
    this.inProgressBuildCardId = this.dataService.buildCardData.id;
    this.dataService.unsavedChanges = false;
    this.breadcrumb_links = [];
    this.holdOfferPopup = false;
    router.navigate([`buildcard-payment/${this.dataService.buildCardData.id}`]);
  }

  public checkForSignupPopup(analyticsSegment) {
    if (!this.dataService.user && this.dataService.currentPage === this.appDataService.urlParameters.signup_stage && !this.isWelcomePopupOpen) {
      this.showRegister = true;
      this.dataService.signupSrcName = 'default_sign_up';
      this.openCommonAuth = true;
    }
  }

  public fetchSelectedBundles() {
    return this.dataService.featureBundles.filter((bundle) => {
      return bundle.selected_feature_count > 0;
    });
  }

  public getEditedBuildCardTimeline(buildCard) {
    if ((buildCard.unseen_section_count && buildCard.unseen_section_count > 0) || (buildCard.updated_section_count && buildCard.updated_section_count > 0)) {
      this.dataService.showHideLoader(true);
      this.apiRequest.getTimeline(buildCard.id)
        .subscribe(
        (data) => {
          this.dataService.buildCardUpdates = data.build_card_updates;
          this.dataService.showHideLoader(false);
        },
        (error) => {
          this.dataService.showHideLoader(false);
        }
        );
    }
  }

  public gotoBuildcard(analyticsSegment, router, keysToUpdate?) {
    this.isFromDelivery = true;
    if (!this.dataService.buildCardData.owner) {
      this.isNewBuildCard = false;
    }
    if (this.dataService.buildCardData.project_name === 'My Builder Project') {
      this.isNewBuildCard = true;
    }
    else {
      this.isNewBuildCard = false;
    }
    this.dataService.showDeliveryLoading = true;
    if (!this.preventClick) {
      if (!this.isFeatureRequest && (!this.dataService.buildCardData.progress.features.length || this.isNewBuildCard)) {
        this.showProjectNamePopup = true;
      }
      this.preventClick = true;
      this.selectedHash.status = '';
      let updatedSelectedHash;
      if (this.buildCardEditMode && keysToUpdate) {
        updatedSelectedHash = this.updatedBuildCardData(keysToUpdate, this.selectedHash);
      } else {
        updatedSelectedHash = this.selectedHash;
        updatedSelectedHash.pricing_params = this.buildCardSelections;
      }
      this.dataService.showDeliveryLoading = true;
      this.apiRequest.createUpdateBuildCard(updatedSelectedHash, this.inProgressBuildCardId).subscribe((data: any) => {
        this.preventClick = false;
        this.paymentData = { pending_installment: {}, general_info: {}, installments: [] };
        this.selectedDataForSidePanel = [];
        this.buildCardEditMode = false;
        this.dataService.isEditBuildCard = false;
        this.inProgressFlow = false;
        this.showPlatformPopup = false;
        this.setUpInFullBuildCard();
        this.getEditedBuildCardTimeline(this.dataService.buildCardData);
        this.dataService.unsavedChanges = false;
        this.isEditDoneOnBuildCard = true;
        this.dataService.editedFeatures = [];
        this.dataService.editedTemplates = [];
        this.dataService.buildCardUpdates = null;
        // this.trackEvents(analyticsSegment, intercom);
        this.dataService.totalSelectedFeatures = 0;
        if (!this.showProjectNamePopup) {
          this.dataService.showDeliveryLoading = false;
          router.navigate(['build-card/' + this.getIdOrUniqCode()]);
        } else {
          this.isReadyToProceed = true;
        }
      }, (error) => {
        if (error.status === 403 || error.status === 401) {
          this.dataService.user = null;
          this.dataService.unsetCookie();
          this.clearLocalStorage();
          this.showSignin = true;
          this.openCommonAuth = true;
          this.reachedUptoPhases = true;
          this.preventClick = false;
        } else {
          this.preventClick = false;
        }
        this.dataService.showDeliveryLoading = false;
      });
    }
  }

  public getIdOrUniqCode() {
    return (this.dataService.buildCardData.uniq_code ? this.dataService.buildCardData.uniq_code : this.inProgressBuildCardId);
  }

  public isValidPromotion() {
    const promotion = this.getPromotionObject();
    if (promotion && promotion.code) {
      let code = promotion.code;
      if (this.manualPromoCode) {
        code = this.manualPromoCode.toLowerCase();
      } else if (this.dataService.manualPromotion && this.dataService.manualPromotion.code) {
        code = this.dataService.manualPromotion.code.toLowerCase();
      } else if (this.appDataService.urlParameters.promotionCode) {
        code = this.appDataService.urlParameters.promotionCode.toLowerCase();
      }
      this.finalPromoApplied = code;
      if (promotion.code.toLowerCase() === code) {
        this.promotionValid = true;
      } else {
        this.promotionValid = false;
      }

    } else {
      this.promotionValid = false;
    }
    return this.promotionValid;
  }

  public promotiontobeApplied() {
    if (!this.isBuildCardSetup && !this.buildCardEditMode) {
      if (!this.promotionValid) {
        this.dataService.homePageData.promotion = null;
        this.dataService.manualPromotion = null;
        this.dataService.manualPromotion = null;
        if (this.dataService.user && !this.AppDiscountSuccessfullyApplied) {
          this.dataService.couponObject = null;
          this.dataService.couponName = null;
          this.isPopupPromotionApplied = false;
        }
      }
    }
  }


  public toggleArray(array, id) {
    const index = array.indexOf(id);
    if (index === -1) {
      array.push(id);
    } else {
      array.splice(index, 1);
    }
    // this.updatePrice();
  }

  public removeFromArray(array, id) {
    const index = array.indexOf(id);
    if (index != -1) {
      array.splice(index, 1);
      // this.updatePrice();
    }
  }

  public addIntoArray(array, id) {
    const index = array.indexOf(id);
    if (index === -1) {
      array.push(id);
      // this.updatePrice();
    }
  }

  public toggleSelectedPlatformId(id) {
    this.toggleArray(this.selectedHash.platform_ids, id);
  }

  public toggleSelectedPlatform(id) {
    this.toggleArray(this.selectedPlatforms, id);
  }

  public setSelectedItems(category) {
    const arr = [];
    let selectedItems;
    let items;
    const service = this.dataService;
    let userRequestedItems;
    switch (category) {
      case 'selectedCategories': {
        selectedItems = this.selectedCategories;
        items = service.categories;
        userRequestedItems = this.requestedCategoryList;
        break;
      }
      case 'selectedApps': {
        selectedItems = this.selectedApps;
        items = service.apps;
        userRequestedItems = this.requestedTemplateList;
        for (const sItem of selectedItems) {
          for (const item of items) {
            if ((item.id === sItem.id) || (item.id === sItem)) {
              item.selected = true;
              arr.push(item);
              break;
            }
          }
        }
        if (userRequestedItems) {
          for (const sItem of selectedItems) {
            for (const item of userRequestedItems) {
              if ((item.id === sItem.id) || (item.id === sItem)) {
                item.selected = true;
                arr.push(item);
                break;
              }
            }
          }
        }
        return;
      }
      case 'selectedPlatforms': {
        selectedItems = this.selectedPlatforms;
        items = service.homePageData.platforms;
        break;
      }
      case 'selectedFeatures': {
        selectedItems = this.selectedFeatures;
        items = service.featureBundles;
        userRequestedItems = this.requestedFeatureList;
        for (const sItem of selectedItems) {
          for (const bundle of items) {
            for (const feature of bundle.features) {
              if (feature.id === sItem.id) {
                feature.selected = true;
                arr.push(feature);
              }
            }
          }
        }
        if (userRequestedItems) {
          for (const sItem of selectedItems) {
            for (const item of userRequestedItems) {
              if ((item.id === sItem.id) || (item.id === sItem)) {
                item.selected = true;
                arr.push(item);
                break;
              }
            }
          }
        }
        this[category] = arr;
        return;
      }
      case 'selectedPhases': {
        selectedItems = this.selectedPhases;
        items = service.homePageData.build_phases;
        break;
      }
      case 'selectedSpeed': {
        items = service.homePageData.speed;
        selectedItems = this.selectedSpeed;
        for (const item of items) {
          if (item.id === selectedItems.id) {
            item.selected = true;
            selectedItems = item;
            this[category] = selectedItems;
          }
        }
        const buildCardMultiplier = this.dataService.buildCardData.multiplier_experiment;
        if (!this.isRentalBuildCard() && (this.dataService.user && this.dataService.user.enterprise_lead && this.dataService.isUnpaidCard() && this.dataService.checkIfBuildCardisinvited() && (buildCardMultiplier && buildCardMultiplier.is_default) || !buildCardMultiplier)) {
          const speedForEnt = this.setSpeedForMultiplier();
          this.selectedSpeed = this.dataService.homePageData.speed.find(speed => speed.id === speedForEnt);
          if (!this.dataService.buildCardData.is_advance) {
            this.dataService.homePageData.build_phases.forEach(p => {
              p.speed = this.selectedSpeed;
            });
          }
        }
        this.setSelectedSpeedId(this.selectedSpeed.id);
        return;
      }
      case 'selectedTeam': {
        items = service.teams;
        selectedItems = this.selectedTeam;
        for (const team of items.all) {
          if (team.id === selectedItems.id) {
            team.selected = true;
            selectedItems = team;
            break;
          }
        }
        if (items.popular && items.popular.all) {
          for (const team of items.popular.all) {
            if (team.id === selectedItems.id) {
              team.selected = true;
              break;
            }
          }
        }
        this[category] = selectedItems;
        return;
      }
      case 'skillsList': {
        items = service.usRegionSkills;
        selectedItems = this.skillsList;
        if (items) {
          for (const sItem of selectedItems) {
            for (const item of items) {
              if ((item.id === sItem.id) || (item.id === sItem)) {
                item.selected = true;
                item.region = service.usRegion.title;
                arr.push(item);
              }
            }
          }
        }
        items = service.asiaRegionSkills;
        if (items) {
          for (const sItem of selectedItems) {
            for (const item of items) {
              if ((item.id === sItem.id) || (item.id === sItem)) {
                item.selected = true;
                item.region = service.asiaRegion.title;
                arr.push(item);
                break;
              }
            }
          }
        }
        this[category] = arr;
        return;
      }
      case 'selectedPlatformsForRapidPrototype': {
        selectedItems = this.selectedPlatformsForRapidPrototype;
        items = service.homePageData.prototype_platforms;
        break;
      }
    }
    for (const sItem of selectedItems) {
      for (const item of items) {
        if ((item.id === sItem.id) || (item.id === sItem)) {
          item.selected = true;
          arr.push(item);
          break;
        }
      }
    }

    if (userRequestedItems) {
      for (const sItem of selectedItems) {
        for (const item of userRequestedItems) {
          if ((item.id === sItem.id) || (item.id === sItem)) {
            item.selected = true;
            arr.push(item);
            break;
          }
        }
      }
    }
    this[category] = arr;
  }

  public toggleSelectedApps(id) {
    this.toggleArray(this.selectedApps, id);
  }

  public toggleSelectedAppsById(app: any): void {
    const selectedApp = this.selectedApps.find(x => x.id === app.id);
    if (selectedApp) {
      const index = this.selectedApps.indexOf(selectedApp);
      this.selectedApps.splice(index, 1);
    } else {
      this.selectedApps.push(app);
    }
    this.selectedFeatures = [].concat.apply([], this.selectedApps.map(a => a.features));
    this.selectedHash.platform_ids = [... new Set([].concat.apply([], this.selectedApps.map(a => a.platform_ids)))];
    if (this.selectedApps.length) {
      this.updatePrice();
    }

  }

  public toggleSelectedAppid(id) {
    this.toggleArray(this.selectedHash.application_ids, id);
  }

  public toggleSelectedFeatureId(id) {
    this.toggleArray(this.selectedHash.feature_ids, id);
  }

  public toggleSelectedFeatures(id) {
    this.toggleArray(this.selectedFeatures, id);
  }

  public setSelectedSpeedId(id) {
    this.selectedHash.speed_id = id;
    this.updatePrice();
  }

  public setSelectedTeamId(id) {
    this.selectedHash.team_id = id;
    this.updatePrice();
  }

  public toggleSelectedBuildId(id) {
    this.toggleArray(this.selectedHash.build_phase_vars, id);
  }

  public toggleSelectedBuildIds(id) {
    this.toggleArray(this.selectedPhasesIds, id);
  }

  public toggleSelectedBuild(id) {
    this.toggleArray(this.selectedPhases, id);
  }

  public removeSelectedBuildId(id) {
    this.removeFromArray(this.selectedHash.build_phase_vars, id);
  }

  public removeSelectedBuildIds(id) {
    this.removeFromArray(this.selectedPhasesIds, id);
  }

  public removeSelectedBuild(id) {
    this.removeFromArray(this.selectedPhases, id);
  }

  public addSelectedBuildId(id) {
    this.addIntoArray(this.selectedHash.build_phase_vars, id);
  }

  public addSelectedBuildIds(id) {
    this.addIntoArray(this.selectedPhasesIds, id);
  }

  public addSelectedBuild(id) {
    this.addIntoArray(this.selectedPhases, id);
  }

  public getSplicedValues(data) {
    let finalText = '';
    if (data && data.length > 0) {
      if (data.length > 1) {
        if (data.length === 2) {
          finalText = data[0] + ' and ' + data[1];
        } else if (data.length === 3) {
          finalText = data[0] + ', ' + data[1] + ' and ' + data[2];
        } else {
          finalText = data[0] + ', ' + data[1] + ' and ' + (data.length - 2) + ' more ';
        }
      } else {
        finalText = data;
      }
    }
    return finalText;
  }

  public isEditableAndPayable() {
    const card = this.dataService.buildCardData;
    return (card && (card.owner && card.status !== Constants.buildCardStatus.STARTED && card.status !== Constants.buildCardStatus.UNDER_DEVELOPMENT && card.status !== Constants.buildCardStatus.UNDER_SUPPORT_AND_MAINTENANCE) || (!card.owner && this.isStatusBeforePayment(card)) || this.dataService.isEditBuildCardAfterSecurityPaid()) && !this.isProjectProgressScreen();
  }

  public isProjectProgressScreen() {
    return window.location.href.indexOf('project-progress') > -1;
  }

  public isStatusBeforePayment(card: any): boolean {
    return card.status === Constants.buildCardStatus.CARD_INITIALISED || card.status === Constants.buildCardStatus.CARD_COMPLETED;
  }

  public handleHeaderSidePanel(isReset) {
    if (isReset) {
      this.dataService.unsavedChanges = false;
      this.priceCalculated = false;
    } else {
      this.dataService.unsavedChanges = true;
      if (this.isBuildCardSetup) {
        this.priceCalculated = false;
      } else {
        this.priceCalculated = true;
      }
    }
  }


  public calculateCostForMvp(phase, isBuilderCare?) {
    if (phase.features && phase.platforms && phase.speed) {
      phase.price = this.calculateFeatureCostAndWeeks(phase.features).price
        * this.setPlatformPriceMultiplier(phase.platforms) * phase.price_multiplier
        * this.setSpeedMultiplier(phase, isBuilderCare).price;

      phase.weeks = this.calculateFeatureCostAndWeeks(phase.features).weeks
        * this.calculatePlatformWeekMultiplier(phase) * phase.week_multiplier
        * this.setSpeedMultiplier(phase, isBuilderCare).weeks;
    } else {
      phase.price = 0;
      phase.weeks = 0;
    }
  }


  public setPlatformPriceMultiplier(platforms) {
    if (platforms && platforms.length > 0) {
      return (1 + (platforms.length - 1) * 0.5);
    } else {
      return 1;
    }
  }

  public calculatePlatformWeekMultiplier(phase) {
    if (phase.platforms && phase.platforms.length > 1) {
      return 1 + (phase.platforms[0].week_multiplier - 1) * (phase.platforms.length - 1);
    } else {
      return 1;
    }
  }

  public setSpeedMultiplier(phase, isBuilderCare?) {
    if (isBuilderCare && this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
      const relaxedSpeed = this.dataService.homePageData.speed.find(speedphase => speedphase.title === 'Relaxed');
      if (relaxedSpeed) {
        return { price: relaxedSpeed.price_multiplier, weeks: relaxedSpeed.week_multiplier };
      }
    } else {
      if (phase.speed) {
        return { price: phase.speed.price_multiplier, weeks: phase.speed.week_multiplier };
      }
    }
  }

  public updatePrice(isReset?) {
    this.showHideBottomBarLoader = true;
    this.handleHeaderSidePanel(isReset);

    if ((!this.isBuildCardSetup && !this.buildCardEditMode)) {
      const promoValid = this.isValidPromotion();
      if (!promoValid && !this.popupPromocode) {
        this.promotiontobeApplied();
      }
    }
    if (this.selectedApps && this.selectedApps.length === 1) {
      this.pricingModal = this.selectedApps[0].pricing_modal;
    }
    this.checkTeamAndSpeed();
    this.createBuildCardPriceHash();
    if (this.buildCardSelections.features && this.buildCardSelections.features.length > 0) {
      this.getBuildCardPrice(this.buildCardSelections);
    } else {
      this.showHideBottomBarLoader = false;
      this.showSpeedLoader = false;
      this.customizationCost = 0;
      this.featureReusableCost = 0;
      this.subscriptionCost = 0;
      this.dataService.priceWithoutCareAndSubs = 0;
      this.totalCarePrice = 0;
      this.builderCarePrice = 0;
      this.finalPricenWeeks = {
        total: 0,
        perWeek: 0,
        weeks: 0,
        actual_weeks: 0,
        bcard_discounted_price: 0,
        payment_weeks: 0,
        first_delivery_time: 0
      };
      this.isShowLoaderOnCustomFeatureFooter = false;
    }
  }

  public computeChangedWeeks(weeksToBeChanged) {
    if (this.dataService.buildCardData && this.dataService.buildCardData.change_week_status) {
      weeksToBeChanged += this.dataService.buildCardData.change_week;
    } else {
      weeksToBeChanged -= this.dataService.buildCardData.change_week;
    }
    return weeksToBeChanged;
  }



  public checkTeamAndSpeed() {
    this.teamandspeedproper = true;
  }

  public sortPhases() {
    this.selectedPhases = this.selectedPhases.sort((a, b) => {
      return a.position - b.position;
    });
  }

  public getFeaturePrice(feature) {
    if (this.dataService.homePageData.pricing_logic === 'new') {
      return feature.price;
    } else {
      let phasePriceMultiplier = 0.0;
      if (this.selectedPhasesIds.indexOf(1) != -1) {
        const build = this.dataService.getBuildByIndex(1);
        phasePriceMultiplier += build.price_multiplier;
      }
      if (this.selectedPhasesIds.indexOf(2) != -1) {
        const build = this.dataService.getBuildByIndex(2);
        phasePriceMultiplier += build.price_multiplier;
      }
      if (this.selectedPhasesIds.indexOf(4) != -1) {
        const build = this.dataService.getBuildByIndex(4);
        phasePriceMultiplier += build.price_multiplier;
      }
      if ((this.selectedPhasesIds.indexOf(0) !== -1) || (this.selectedPhasesIds.indexOf(3) !== -1) || (this.selectedPhasesIds.indexOf(this.selectedPhasesIds.find(e => e > 4)) !== -1)) {
        let build;
        if ((this.selectedPhasesIds.indexOf(0) != -1) || (this.selectedPhasesIds.indexOf(this.selectedPhasesIds.find(e => e > 4)) !== -1)) {
          build = this.dataService.getBuildByIndex(0);
        } else if (this.selectedPhasesIds.indexOf(3) !== -1) {
          build = this.dataService.getBuildByIndex(3);
        }
        phasePriceMultiplier += build.price_multiplier;
      }
      let speedMultiplier;
      if (this.selectedSpeed) {
        speedMultiplier = this.selectedSpeed.price_multiplier;
      } else if (this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
        speedMultiplier = this.dataService.homePageData.speed.find((speed) => {
          return speed.title === 'Relaxed';
        }).price_multiplier;
      }
      return feature.price * phasePriceMultiplier * speedMultiplier;
    }
  }

  public getFeatureWeeks(feature) {
    let phaseWeekMultiplier = 0.0;
    if (this.selectedPhasesIds.indexOf(1) !== -1) {
      const build = this.dataService.getBuildByIndex(1);
      phaseWeekMultiplier += build.week_multiplier;
    }
    if (this.selectedPhasesIds.indexOf(2) !== -1) {
      const build = this.dataService.getBuildByIndex(2);
      phaseWeekMultiplier += build.week_multiplier;
    }
    if (this.selectedPhasesIds.indexOf(4) !== -1) {
      const build = this.dataService.getBuildByIndex(4);
      phaseWeekMultiplier += build.week_multiplier;
    }

    if ((this.selectedPhasesIds.indexOf(0) !== -1) || (this.selectedPhasesIds.indexOf(3) !== -1) || (this.selectedPhasesIds.indexOf(this.selectedPhasesIds.find(e => e > 4)) !== -1)) {
      let build;
      if ((this.selectedPhasesIds.indexOf(0) !== -1) || (this.selectedPhasesIds.indexOf(this.selectedPhasesIds.find(e => e > 4)) !== -1)) {
        build = this.dataService.getBuildByIndex(0);
      } else if (this.selectedPhasesIds.indexOf(3) !== -1) {
        build = this.dataService.getBuildByIndex(3);
      }
      phaseWeekMultiplier += build.week_multiplier;
    }

    let speedMultiplier;
    if (this.selectedSpeed) {
      speedMultiplier = this.selectedSpeed.week_multiplier;
    } else if (this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
      speedMultiplier = this.dataService.homePageData.speed.find((speed) => {
        return speed.title === 'Relaxed';
      }).week_multiplier;
    }
    return feature.weeks * phaseWeekMultiplier * speedMultiplier;
  }

  public reset(isselectApps?) {
    if (this.appDataService.urlParameters.redirect_to_login) {
      return;
    }
    if (this.dataService.homePageData.build_phases) {
      this.dataService.homePageData.build_phases = this.dataService.homePageData.build_phases.slice(0, 5);
    }
    this.customPhaseCount = 0;
    this.newSelectedProblemsApps = [];
    this.newSelectedApps = [];
    this.appListApps = [];
    this.applicationSectionId = '';
    this.firstClicked = '';
    this.offersArr = [];
    this.newHomePagePromotion = '';
    this.searchApp = false;
    this.newSelectedAppIds = [];
    this.newSelectedProblemsIds = [];
    this.newSelectedProblemsAppsIds = [];
    this.newProblemsData = [];
    this.newSelctedProductId = '';
    this.dataService.shareUrlData = null;
    this.supportMaintenance = 0;
    this.supportMaintenanceWeeks = 1;
    this.dataService.paymentStatusFlow = false;
    this.appDataService.urlParameters.paymentStatus = null;
    this.appDataService.urlParameters.paymentType = null;
    this.buildCardSelectedPhases = [];
    this.isFeatureTutorialActive = false;
    this.teamandspeedproper = true;
    this.dataService.normalFlow = true;
    this.appDataService.urlParameters.ndaSigned = null;
    this.showCategories = false;
    this.isInviteEdited = false;
    this.dataService.showHideLoader(false);
    this.inProgressBuildCardId = null;
    this.showHourCard = false;
    this.successTitle = { value: '' };
    this.successDesc = { value: '' };
    this.featuresToShow = [];
    this.promoCode = { id: null, multiplier: 1, discount: 0 };
    this.originalPrice = { total: null, perWeek: null };
    this.promoCodeShow = false;
    this.dataService.categories = [];
    this.dataService.apps = [];
    if (!this.dataService.isFromViewPrototype && !(this.dataService.currentPage === 'features' && this.buildCardEditMode)) {
      this.dataService.featureBundles = [];
      this.breadcrumb_links = [];
      this.headerBreadCrumb = [
        {
          title: 'Features',
          selected: false,
          link: 'features',
          titleToShow: 'Refine features'
        },
        {
          title: 'Delivery and Deliverables',
          selected: false,
          link: 'delivery',
          titleToShow: 'Plan delivery'
        },
      ];
    }
    this.templatesToShow = [];
    this.featuresToShow = [];
    this.categoriesToShow = [];
    this.inProgressBuildCardId = null;
    this.buildCardEditMode = false;
    this.dataService.isEditBuildCard = false;
    this.selectedTemplates = {
      firstTemplate: {} as any,
      secondTemplate: {} as any
    };
    this.isUrlPromotionValid = false;
    this.showTemplateSecondInput = false;
    this.appliedPromotion = false;
    this.selectedHash = {
      project_name: 'My Builder Project',
      description: null,
      target_user_group: '',
      customized_template_name: '',
      utm_source: null,
      utm_campaign: null,
      support_maintenance_weeks: 0,
      startup: false,
      self_funded: false,
      npo_ngo: false,
      specing: false,
      product_id: '',
      audience_id: null,
      project_status_id: null,
      application_ids: [],
      platform_ids: [],
      feature_ids: [],
      team_id: null,
      speed_id: null,
      build_phase_vars: [],
      promotion_id: '',
      region_skill_ids: [],
      competitors: [],
      app_style_ids: [],
      category_ids: [],
      startup_urls: [],
      attachments_attributes: [],
      quote_price: 0,
      status: 'card_initialized',
      build_card_phases: [],
      is_under_support: true,
      is_advance: false,
      multiplier_experiment_id: '',
      currency_id: null,
      prototype_promo: false,
      source: 'home',
      create_spec_call: this.selectedHash.create_spec_call,
      upfront: this.selectedHash.upfront,
      cloud_opted: this.selectedHash.cloud_opted
    };
    this.selectedCategories = [];
    this.myTimeZone = false;
    if (!isselectApps) {
      this.selectedApps = [];
    }
    this.selectedFeatures = [];
    this.mandatoryFeatures = [];
    this.selectedPhases = [];
    this.selectedPhasesIds = [];
    this.selectedProduct = null;
    this.selectedTeam = null;
    this.selectedSpeed = null;
    this.selectedPlatforms = [];
    this.featuresToShow = [];
    this.buttonEnabled = true;
    this.selectedDataForSidePanel = [];
    this.currentActive.value = 'Home';
    this.eaiButtonToShow = true;
    this.dataService.isEnterpise = false;
    this.dataService.isHireADev = false;
    this.dataService.isHireDevOps = false;
    this.skillsList = [];
    this.totalHourlyPrice = 0;
    this.requestedFeatureList = [];
    this.requestedCategoryList = [];
    this.requestedTemplateList = [];
    this.finalPricenWeeks.total = 0;
    this.finalPricenWeeks.bcard_discounted_price = 0;
    this.finalPricenWeeks.perWeek = 0;
    this.finalPricenWeeks.weeks = 0;
    this.finalPricenWeeks.payment_weeks = 0;
    this.finalPricenWeeks.first_delivery_time = 0;
    this.promotionApplied = false;
    this.teamandspeedproper = false;
    this.promotionValid = false;
    this.promotionError = '';
    this.dataService.manualPromotion = null;
    if (!isselectApps) {
      this.dataService.homePageData.promotion = null;
    }
    this.isSignupByChatbot = false;
    this.isAppDiscountApplied = false;
    this.dataService.landingPage = '';
    this.handleHeaderSidePanel(true);
    this.dataService.buildCardCountData = 0;
    this.dataService.specCallCount = 0;
    this.dataService.demoCallCount = 0;
    this.dataService.loadFilteredData = false;
    this.dataService.dashboardCards = {};
    this.dataService.specCards = [];
    this.dataService.demoCards = [];
    this.dataService.completedCards = [];
    this.dataService.paidCards = [];
    this.dataService.inProgressCards = [];
    this.dataService.invitedCards = [];
    this.dataService.archivedCards = [];
    this.hidesignupPopup();
    this.popupCoupon = false;
    this.isPopupPromotionApplied = false;
    this.dataService.couponObject = null;
    this.dataService.couponName = null;
    this.AppDiscountSuccessfullyApplied = false;
    this.appDataService.urlParameters.promotionCode = null;
    this.appDataService.urlParameters.currency_id = null;
    this.dataService.currencyInUrl = '';
    this.dataService.isIncompleteCardEditMode = false;
    this.setMvpSelected = false;
    this.commonService.isTwoPayRegCompany = false;
    this.commonService.twoPayInstallments = null;
    if (!this.buildCardUpdateOnFeatureAdded) {
      this.recommendedFeaturesActivity = [];
    }
    this.resetNatashaBot();
  }

  public getSupportMaintenancePrice() {
    return this.supportMaintenance;
  }

  public setUpInProgressBuildCard() {
    this.showWeeklyOrMonthly();
    this.reset();
    this.isBuildPhases = false;
    this.inProgressFlow = true;
    this.selectedDataForSidePanel = [];
    this.inProgressBuildCardId = this.dataService.buildCardData.id;
    this.inProgressUniqCode = this.dataService.buildCardData.uniq_code;
    this.finalPricenWeeks.total = this.getPriceForCards(this.dataService.buildCardData) + this.getChangeAmount();
    this.finalPricenWeeks.bcard_discounted_price = this.getDiscountedPriceForCards(this.dataService.buildCardData);
    this.finalPricenWeeks.weeks = this.dataService.buildCardData.weeks;
    this.finalPricenWeeks.payment_weeks = this.setPaymentWeeks();
    if (this.finalPricenWeeks.total > 0) {
      this.finalPricenWeeks.perWeek = (this.finalPricenWeeks.total / this.finalPricenWeeks.weeks);
    } else {
      this.finalPricenWeeks.perWeek = 0;
    }

    if (this.dataService.buildCardData.promotion) {
      this.dataService.homePageData.promotion = this.dataService.buildCardData.promotion;
      this.promotionValid = true;
      this.promotionApplied = true;
      this.flatPrice = this.dataService.buildCardData.promotion.discount;
      this.selectedHash.promotion_id = this.dataService.buildCardData.promotion.id;
    } else {
      this.promotionValid = false;
      this.promotionApplied = false;
      this.flatPrice = 0;
      this.selectedHash.promotion_id = '';
    }

    for (const key in this.dataService.buildCardData.progress) {
      if (key === 'buildphases') {
        this.isBuildPhases = true;
        break;
      }
    }
    if (this.dataService.homePageData.build_phases && !this.isBuildPhases) {
      for (const buildType of this.dataService.homePageData.build_phases) {
        if (buildType.title.indexOf('Support') === -1) {
          this.toggleSelectedBuildIds(buildType.index);
          this.toggleArray(this.buildCardSelectedPhases, buildType.index);
        }
      }
    }
    for (const key in this.selectedHash) {
      if (this.dataService.buildCardData.hasOwnProperty(key)) {
        if (this.dataService.buildCardData[key]) {
          this.selectedHash[key] = this.dataService.buildCardData[key];
        }
      }
    }
    for (const key in this.dataService.buildCardData.progress) {
      if (key === 'product') {
        this.selectedProduct = this.dataService.buildCardData.progress[key];
        if (this.selectedProduct) {
          this.selectedHash.product_id = this.selectedProduct.id;
          this.selectedDataForSidePanel.push({
            id: 1,
            title: 'What',
            price: '0',
            value: this.selectedProduct.title,
            phase: 'home'
          });
          this.dataService.isEnterpise = this.selectedProduct.type === 'Enterprise';
        }
      } else if (key === 'categories') {
        this.selectedCategories = this.dataService.buildCardData.progress[key];
      } else if (key === 'apps') {
        this.selectedApps = this.dataService.buildCardData.progress[key];
        if (this.selectedApps) {
          this.setBreadcrumbLink(key);
          const collection = [];
          for (const app of this.selectedApps) {
            this.toggleSelectedAppid(app.id);
            app.selected = true;
            collection.push(app.title);
            if (app.user_requested && !(app.status === 'approved')) {
              this.requestedTemplateList.push(app);
            }
          }
          const phaseObj = {
            title: 'Templates',
            price: '0',
            collection: collection,
            phase: 'apps'
          };
          this.selectedDataForSidePanel.push(phaseObj);
        } else {
          this.selectedApps = [];
        }
      } else if (key === 'platforms') {
        this.selectedPlatforms = this.dataService.buildCardData.progress[key];
        if (this.selectedPlatforms) {
          const collection = [];
          for (const platform of this.selectedPlatforms) {
            this.toggleSelectedPlatformId(platform.id);
            collection.push(platform.title);
          }
          const phaseObj = {
            title: 'Platforms',
            price: this.finalPricenWeeks.total,
            collection: collection,
            phase: 'platforms'
          };
          this.selectedDataForSidePanel.push(phaseObj);
        } else {
          this.selectedPlatforms = [];
        }
      } else if (key === 'features') {
        this.selectedFeatures = this.dataService.buildCardData.progress[key];
        if (this.selectedFeatures) {
          this.setBreadcrumbLink(key);
          const collection = [];
          let price = 0;
          for (const feature of this.selectedFeatures) {
            if (feature.user_requested && !(feature.status === 'approved')) {
              this.requestedFeatureList.push(feature);
            }
            this.toggleSelectedFeatureId(feature.id);
            collection.push(feature.title);
            price = price + feature.price;
          }
          const phaseObj = {
            title: 'Features',
            price: price,
            collection: collection,
            phase: 'features'
          };
          this.selectedDataForSidePanel.push(phaseObj);
        } else {
          this.selectedFeatures = [];
        }
      } else if (key == 'team') {
        this.selectedTeam = this.dataService.buildCardData.progress[key];
      } else if (key == 'speed') {
        this.selectedSpeed = this.dataService.buildCardData.progress[key];
        if (this.selectedTeam && this.selectedSpeed) {
          this.setBreadcrumbLink('delivery');
          let teamTitle = '';
          if (this.selectedTeam) {
            teamTitle = this.selectedTeam.title;
            this.selectedHash.team_id = this.selectedTeam.id;
          }
          let speedTitle = '';
          if (this.selectedSpeed) {
            speedTitle = this.selectedSpeed.title;
            this.selectedHash.speed_id = this.selectedSpeed.id;
          }
          const collection = [];
          collection.push('Team :' + teamTitle);
          collection.push('Speed :' + speedTitle);
          const phaseObj = {
            title: 'Team & Speed',
            price: '0',
            collection: collection,
            phase: 'teamspeed'
          };
          this.selectedDataForSidePanel.push(phaseObj);
        }
      } else if (key === 'buildphases') {
        this.setBreadcrumbLink('delivery');
        this.selectedPhases = this.dataService.buildCardData.progress[key];
        if (!this.selectedPhases) {
          this.selectedPhases = [];
        }
      } else if (key === 'buildcardphases') {
        this.selectedHash.build_card_phases = this.dataService.buildCardData.progress[key];
        if (!this.selectedHash.build_card_phases) {
          this.selectedHash.build_card_phases = [];
        } else {
          this.setBuildPhaseDataInPhases();
        }
      }
    }
  }
  public emptyPromoVariables() {
    this.promotionValid = false;
    this.promotionApplied = false;
    this.flatPrice = 0;
    this.selectedHash.promotion_id = '';
  }

  public setBuilderCareAndBuilderCloud() {
    //set builder care
    if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.is_under_support !== null) {
      this.selectedHash.is_under_support = this.dataService.modifiedBuildCardData.is_under_support;
      this.selectedHash.care_type = this.dataService.buildCardData.modification_request.modify_data.care_type['add'];
    } else if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.is_under_support == null) {
      this.selectedHash.is_under_support = this.dataService.buildCardData.modification_request.base_build_card_data.is_under_support;
      this.selectedHash.care_type = this.dataService.buildCardData.modification_request.base_build_card_data.care_type;
    } else {
      this.selectedHash.is_under_support = this.dataService.buildCardData.is_under_support;
      this.selectedHash.care_type = this.dataService.buildCardData.care_type;
    }
    if (this.selectedHash.is_under_support && !this.selectedHash.care_type) {
      if (this.checkIfUnPaidMaxCardPage()) {
        this.selectedHash.care_type = (this.dataService.buildCardData && (this.dataService.buildCardData.enterprise_lead === 'true')) ? 'enterprise_care_plus' : 'standard_care';
      } else {
        this.selectedHash.care_type = this.dataService.checkIfEnterpriseUserOrCard() ? 'enterprise_care_plus' : 'standard_care';
      }
    }
    //set builder cloud
    if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.cloud_opted !== null) {
      this.selectedHash.cloud_opted = this.dataService.modifiedBuildCardData.cloud_opted;
    } else if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.cloud_opted === null) {
      this.selectedHash.cloud_opted = this.dataService.buildCardData.modification_request.base_build_card_data.cloud_opted;
    } else {
      this.selectedHash.cloud_opted = this.dataService.buildCardData.cloud_opted;
    }

  }

  public setUpInFullBuildCard() {
    this.showWeeklyOrMonthly();
    this.mandatoryFeatures = [];
    this.buildCardEditMode = true;
    this.dataService.isEditBuildCard = true;
    this.reset();
    this.isBuildCardSetup = true;
    this.promotionValid = false;
    this.flatPrice = 0;
    this.selectedHash.promotion_id = '';
    if (this.dataService.buildCardData.id) {
      this.inProgressBuildCardId = this.dataService.buildCardData.id;
    }
    if (this.dataService.buildCardData.uniq_code) {
      this.inProgressUniqCode = this.dataService.buildCardData.uniq_code;
    }
    for (const key in this.selectedHash) {
      if (this.dataService.buildCardData.hasOwnProperty(key)) {
        if (this.dataService.buildCardData[key]) {
          this.selectedHash[key] = this.dataService.buildCardData[key];
        }
      }
    }
    if (this.dataService.buildCardData.promotion) {
      this.finalPromoApplied = this.dataService.buildCardData.promotion.code;
      this.dataService.homePageData.promotion = this.dataService.buildCardData.promotion;
      this.promotionValid = true;
      this.promotionApplied = true;
      this.flatPrice = this.dataService.buildCardData.promotion.discount;
      this.selectedHash.promotion_id = this.dataService.buildCardData.promotion.id;
      this.discountedPrice = this.getDiscountedPriceForCards(this.dataService.buildCardData);
    } else {
      this.emptyPromoVariables();
    }
    this.setBuilderCareAndBuilderCloud();
    this.finalPricenWeeks.total = this.getPriceForCards(this.dataService.buildCardData) + this.getChangeAmount();
    this.finalPricenWeeks.bcard_discounted_price = this.getDiscountedPriceForCards(this.dataService.buildCardData);
    this.finalPricenWeeks.weeks = this.dataService.buildCardData.weeks;
    this.finalPricenWeeks.payment_weeks = this.setPaymentWeeks();
    this.finalPricenWeeks.actual_weeks = this.dataService.buildCardData.actual_weeks;
    this.finalPricenWeeks.perWeek = this.finalPricenWeeks.total / this.finalPricenWeeks.weeks;
    this.discountedPrice = this.getDiscountedPriceForCards(this.dataService.buildCardData);
    this.supportMaintenance = this.dataService.buildCardData.support_maintenance_price;
    if (this.dataService.checkIfEnterpriseUserOrCard() && this.dataService.buildCardData.subscription_flow
      && (this.dataService.buildCardData.status === 'card_initialized')) {
      this.selectedHash.is_under_support = true;
    }
    for (const key in this.dataService.buildCardData.progress) {
      if (key === 'product') {
        this.selectedProduct = this.dataService.buildCardData.progress[key];
        if (this.selectedProduct) {
          this.selectedHash.product_id = this.selectedProduct.id;
        }
      } else if (key === 'categories') {
        this.selectedCategories = this.dataService.buildCardData.progress[key];
      } else if (key === 'apps') {
        this.selectedApps = this.dataService.buildCardData.progress[key];
        if (this.selectedApps) {
          this.requestedTemplateList = [];
          for (const app of this.selectedApps) {
            this.toggleSelectedAppid(app.id);
            app.selected = true;
            if (app.user_requested && !(app.status === 'approved')) {
              this.requestedTemplateList.push(app);
            }
          }
        } else {
          this.selectedApps = [];
        }
      } else if (key === 'platforms') {
        this.selectedPlatforms = this.dataService.buildCardData.progress[key];
        if (this.dataService.modifiedBuildCardData) {
          this.addPlateformAfterSecurityPaid();
          this.removePlateformAfterSecurityPaid();
        }
        if (this.selectedPlatforms) {
          for (const platform of this.selectedPlatforms) {
            this.toggleSelectedPlatformId(platform.id);
          }
        } else {
          this.selectedPlatforms = [];
        }
      } else if (key === 'features') {
        // debugger;
        this.selectedFeatures = JSON.parse(JSON.stringify(this.dataService.buildCardData.progress[key]));
        this.dataService.buildCardData.progress['buildcardfeatures'].forEach(feature => {
          const ftr = this.selectedFeatures.find(f => feature['feature_id'] === f.id);
          const ftrFromApproved = this.dataService.buildCardData.progress['features'].find(f => feature['feature_id'] === f.id);
          if (ftr && feature['feature_count']) {
            ftr.feature_count = feature['feature_count'];
            ftrFromApproved.feature_count = feature['feature_count'];
            if (this.isEditFeatureRequest()) {
              ftr.repeatable_count = feature['feature_count'];
              ftrFromApproved.repeatable_count = feature['feature_count'];
            }
          }
        });
        this.previousSelectedFeatureCount = this.dataService.buildCardData.progress[key].length;
        if (!this.dataService.buildCardData.kick_off_date && this.dataService.modifiedBuildCardData) {
          this.removeDeleteRequestedFeatureAfterSecurityPaid();
          this.setAddRequestedFeatureAfterSecurityPaid();
        }
        if (this.selectedFeatures) {
          for (const feature of this.selectedFeatures) {
            feature.selected = true;
            if (feature.user_requested && feature.status !== 'approved') {
              this.requestedFeatureList.push(feature);
            }
            this.selectedHash.feature_ids.push(feature.id);
          }
          if (this.selectedApps) {
            for (const app of this.dataService.buildCardData.progress.apps) {
              if (app.features && app.features.data) {
                const features = app.features.data.map(feature => feature.attributes);
                const mvpFeatures = features.filter(feature => feature.is_mvp_feature);
                if (mvpFeatures && mvpFeatures.length > 0) {
                  mvpFeatures.forEach((feature) => {
                    const findIndex = this.selectedFeatures.findIndex(selectedfeature => selectedfeature.id === feature.id);
                    if (findIndex !== -1) {
                      this.mandatoryFeatures.push(feature);
                    }
                  });
                }
              }
            }
          }
        } else {
          this.selectedFeatures = [];
        }
      } else if (key === 'team') {
        this.selectedTeam = this.dataService.buildCardData.progress[key];
        if (this.selectedTeam) {
          this.selectedHash.team_id = this.selectedTeam.id;
        }
      } else if (key === 'speed') {
        this.selectedSpeed = this.dataService.buildCardData.progress[key];
        if (this.selectedSpeed) {
          this.selectedHash.speed_id = this.selectedSpeed.id;
        }
        if (this.selectedTeam && this.selectedSpeed) {
          this.checkTeamAndSpeed();
        }
      } else if (key === 'buildphases') {
        this.selectedPhases = this.dataService.buildCardData.progress[key];
        this.setPhaseIdInSelectedPhase();
        if (!this.selectedPhases) {
          this.selectedPhases = [];
        }
      } else if (key === 'buildcardphases') {
        this.selectedHash.build_card_phases = this.dataService.buildCardData.progress[key];
        if (!this.selectedHash.build_card_phases) {
          this.selectedHash.build_card_phases = [];
        } else {
          this.dataService.buildCardData.progress['buildcardphases'] = this.dataService.buildCardData.progress['buildcardphases'].filter(phase => ((phase['phase_type'] === 'custom') && (phase['title'] !== 'MVP'))).reverse().concat(this.dataService.buildCardData.progress['buildcardphases'].filter(phase => ((phase.build_phase_id))));
          this.dataService.buildCardData.progress['buildcardphases'].forEach((phase, index) => {
            if ((phase['phase_type'] === 'custom') && (phase['title'] !== 'MVP')) {
              this.setCustomPhaseToSelectedPhase(phase);
            } else if (phase.title === 'Customer designs') {
              phase.platforms = this.selectedPlatforms;
              phase.features = this.selectedFeatures;
            }
          });
          if (this.dataService.modifiedBuildCardData) {
            if (this.dataService.buildCardData.modification_request.status !== 'approved') {
              this.setAddRequestedPhasesAfterSecurityPaid();
              this.removeDeleteRequestePhaseAfterSecurityPaid();
              this.setPhasesChanges();
            }
            this.setPromotionAfterSecurityPaid();
          }
        }
      } else if (key === 'feature_activities' && !this.buildCardUpdateOnFeatureAdded) {
        this.recommendedFeaturesHistory = this.dataService.buildCardData.progress[key];
      }
    }
    this.setBuildPhaseDataInPhases();
    if (this.dataService.isFromViewPrototype && this.isEditableAndPayable()) {
      this.buildCardEditMode = true;
      this.dataService.isEditBuildCard = true;
      this.fromDashBoard = false;
      this.showCurrency = false;
      if (this.dataService.buildCardData.promotion) {
        this.selectedHash.promotion_id = this.dataService.buildCardData.promotion.id;
      }
    }
    if (this.dataService.buildCardData.pre_kick_off_date) {
      this.selectedHash.pre_kick_off_date = this.dataService.buildCardData.pre_kick_off_date;
      this.preKickOffDateModified.next();
    }
  }

  public setPhaseIdInSelectedPhase() {
    this.selectedPhases.forEach((buildPhase) => {
      const result = this.dataService.buildCardData.progress['buildcardphases'].find((phase) => {
        return buildPhase.title === phase.title;
      });
      if (result) {
        buildPhase['phase_id'] = result.id;
      }
    });
  }

  public setCustomPhaseToSelectedPhase(phase) {
    phase.build_phase_id = 6 + this.customPhaseCount;
    const customPhase = Object.assign({}, phase);
    customPhase['phase_id'] = phase.id;
    customPhase.id = customPhase.build_phase_id;
    customPhase['index'] = 5 + this.customPhaseCount;
    customPhase['price'] = 0;
    customPhase['weeks'] = 0;
    customPhase['type'] = 'custom_phase';
    if (phase.hasOwnProperty('build_card_phase_features_attributes')) {
      customPhase['features'] = phase.build_card_phase_features_attributes;
      phase = this.formatPlatformAndFeatureIds(phase);
      phase = this.selectedHash.build_card_phases.push(phase);
    }
    this.selectedPhases.push(customPhase);
    const customPhaseHomePageData = Object.assign({}, customPhase);
    this.dataService.homePageData.build_phases.push(customPhaseHomePageData);
    this.customPhaseCount++;
  }

  public formatPlatformAndFeatureIds(phase) {
    phase['features'] = phase.build_card_phase_features_attributes;
    let platform_ids = [];
    const feature_ids = [];
    phase.features.forEach((item) => {
      platform_ids = platform_ids.concat(item.platform_ids);
      feature_ids.push(item.feature_id);
    });
    phase['platform_ids'] = new Set(platform_ids);
    phase['feature_ids'] = feature_ids;
    return phase;
  }

  public addPlateformAfterSecurityPaid() {
    this.dataService.modifiedBuildCardData.platforms.add.data.forEach((plateform) => {
      const index = this.selectedPlatforms.findIndex((selectedPlatform) => {
        return plateform.id.toString() === selectedPlatform.id.toString();
      });
      if (index === -1) {
        this.selectedPlatforms.push(plateform.attributes);
      }
    });
  }

  public removePlateformAfterSecurityPaid() {
    this.dataService.modifiedBuildCardData.platforms.remove.data.forEach((plateform) => {
      const index = this.selectedPlatforms.findIndex((selectedPlatform) => {
        return plateform.id.toString() === selectedPlatform.id.toString();
      });
      if (index !== -1) {
        this.selectedPlatforms.splice(index, 1);
      }
    });
  }

  public removeDeleteRequestedFeatureAfterSecurityPaid() {
    this.dataService.modifiedBuildCardData.feature_ids.remove.forEach((id) => {
      const index = this.selectedFeatures.findIndex((feature) => {
        return feature.id === id;
      });
      if (index !== -1) {
        this.selectedFeatures.splice(index, 1);
      }
    });
  }

  public setAddRequestedFeatureAfterSecurityPaid() {
    this.dataService.modifiedBuildCardData.features.add.data.forEach((addedFeature) => {
      const index = this.selectedFeatures.findIndex((selectedFeature) => {
        return parseInt(addedFeature.id, 10) === selectedFeature.id;
      });
      if (index !== -1) {
        this.selectedFeatures[index]['type'] = 'new';
        const feature: any = this.selectedFeatures.splice(index, 1);
        this.selectedFeatures.unshift(feature[0]);
      } else {
        addedFeature = addedFeature.attributes;
        addedFeature['type'] = 'new';
        this.selectedFeatures.unshift(addedFeature);
      }
    });
  }

  public removeDeleteRequestePhaseAfterSecurityPaid() {
    this.dataService.modifiedBuildCardData.build_phases.remove.forEach((phase) => {
      let index = this.selectedPhases.findIndex((selectedPhase) => {
        return phase.id === selectedPhase.phase_id;
      });
      if (index !== -1) {
        this.selectedPhases.splice(index, 1);
      }
      index = this.dataService.homePageData.build_phases.findIndex((selectedPhase) => {
        return phase.id === selectedPhase.phase_id;
      });
      if (index !== -1) {
        this.dataService.homePageData.build_phases.splice(index, 1);
      }
    });
  }

  public setAddRequestedPhasesAfterSecurityPaid() {
    this.dataService.modifiedBuildCardData.build_phases.add.forEach((addedPhase) => {
      if (addedPhase && addedPhase.phase_type === 'custom') {
        const index = this.selectedPhases.findIndex((phase) => {
          return addedPhase.title === phase.title;
        });
        if (index === -1) {
          this.setCustomPhaseToSelectedPhase(addedPhase);
        }
      } else {
        const index = this.selectedPhases.findIndex((selectedPhase) => {
          return addedPhase.build_phase_id === selectedPhase.id;
        });
        if (index === -1) {
          if (addedPhase.build_phase_id === 4) {
            addedPhase['features'] = addedPhase.build_card_phase_features_attributes;
          }
          this.selectedHash.build_card_phases.push(addedPhase);
          addedPhase['id'] = addedPhase.build_phase_id;
          this.selectedPhases.push(JSON.parse(JSON.stringify(addedPhase)));
        }
        this.setPhaseIdInSelectedPhase();
      }
    });
  }

  public setPhasesChanges() {
    if (this.dataService.modifiedBuildCardData.is_advance !== null) {
      this.isAdvancedVersion = this.dataService.modifiedBuildCardData.is_advance;
      this.selectedHash.is_advance = this.dataService.modifiedBuildCardData.is_advance;
    }
    if (this.dataService.modifiedBuildCardData.speed_id.add) {
      const index = this.dataService.homePageData.speed.findIndex((speed) => {
        return speed.id === this.dataService.modifiedBuildCardData.speed_id.add;
      });
      if (index !== -1) {
        this.selectedSpeed = this.dataService.homePageData.speed[index];
      }
    }
    const buildCradIds = Object.keys(this.dataService.modifiedBuildCardData.build_card_phases);
    buildCradIds.forEach((id) => {
      const result = this.selectedHash.build_card_phases.findIndex((phase) => {
        return phase.id.toString() === id.toString();
      });
      if (result !== -1) {
        const phase = this.dataService.modifiedBuildCardData.build_card_phases[id];
        const selectedPhase = this.selectedHash.build_card_phases[result];
        if (phase.speed_id.add) {
          selectedPhase.speed_id = phase.speed_id.add;
        }
        if (phase.is_tailor_made) {
          selectedPhase.is_tailor_made = phase.is_tailor_made;
        }
        if (phase.feature_ids.add.length) {
          if (!selectedPhase.feature_ids) {
            selectedPhase['feature_ids'] = [];
          }
          selectedPhase['feature_ids'] = selectedPhase['feature_ids'].concat(phase.feature_ids.add);
        }
        if (phase.feature_ids.remove.length) {
          phase.feature_ids.remove.forEach(feature_id => {
            if (!selectedPhase.feature_ids) {
              selectedPhase.feature_ids = selectedPhase.features.map(f => f.feature_id);
            }
            const index = selectedPhase.feature_ids.findIndex((selected_feature_id) => {
              return feature_id === selected_feature_id;
            });
            if (index !== -1) {
              selectedPhase.feature_ids.splice(index, 1);
            }
          });
        }
        if (phase.platform_ids.add.length) {
          selectedPhase['platform_ids'] = selectedPhase['platform_ids'].concat(phase.platform_ids.add);
        }
        if (phase.platform_ids.remove.length) {
          phase.platform_ids.remove.forEach(platform_id => {
            const index = selectedPhase.platform_ids.findIndex((selected_platform_id) => {
              return platform_id === selected_platform_id;
            });
            if (index !== -1) {
              selectedPhase.platform_ids.splice(index, 1);
            }
          });
        }
        if (phase.features.add.length) {
          selectedPhase['features'] = selectedPhase['features'].concat(phase.features.add);
        }
        if (phase.features.remove.length) {
          phase.features.remove.forEach(feature => {
            const index = selectedPhase.features.findIndex((selected_feature) => {
              return feature.feature_id === selected_feature.feature_id;
            });
            if (index !== -1) {
              selectedPhase.features.splice(index, 1);
            }
          });
        }
      }
    });
    if (!this.dataService.buildCardData.kick_off_date && this.dataService.modifiedBuildCardData && (this.dataService.buildCardData.modification_request.status !== 'approved')) {
      if (this.dataService.modifiedBuildCardData['build_card_features'] && this.dataService.modifiedBuildCardData['build_card_features'].length) {
        const buildCardFeatires = this.dataService.modifiedBuildCardData['build_card_features'].map(f => f.feature_id);
        this.selectedFeatures = this.selectedFeatures.filter(feature => {
          if (buildCardFeatires.includes(feature.id)) {
            return feature;
          }
        });
      }
    }
  }

  public setBuildPhaseDataInPhases() {
    this.selectedPlatformsForRapidPrototype = Object.assign([], [...this.dataService.homePageData.prototype_platforms]);
    if (this.selectedPhases) {
      this.selectedPhases.forEach(phase => {
        phase.features = [];
        phase.platforms = [];
        phase.price = 0;
        phase.weeks = 0;
        if (this.dataService.homePageData.build_phases) {
          this.dataService.homePageData.build_phases.forEach(phase => {
            phase.selected = false;
          });
        }
        const buildPhase = this.selectedHash.build_card_phases.find(p => p.build_phase_id === phase.id);
        if (buildPhase && (this.selectedHash.build_card_phases.length === 1) && this.selectedHash.build_card_phases[0]['is_tailor_made'] && (this.selectedHash.build_card_phases[0].title === 'Professional Prototype')) {
          buildPhase.platform_ids = [];
          this.selectedPlatformsForRapidPrototype.forEach(p => {
            buildPhase.platform_ids.push(p.id);
          });
        }
        if (buildPhase) {
          if ((buildPhase['phase_type'] === 'custom') || (buildPhase['title'] === 'MVP')) {
            buildPhase.features.forEach(id => {
              const feature = this.selectedFeatures.find(f => f.id === id.feature_id);
              if (feature) {
                if (buildPhase.platform_ids.length === 1) {
                  if (!feature.platforms) { feature.platforms = []; }
                  if (!feature.phaseId) { feature.phaseId = []; }
                } else {
                  feature.platforms = [];
                  feature.phaseId = [];
                }
                this.dataService.homePageData.platforms.forEach(p => {
                  id.platform_ids = id.platform_ids.map(x => parseInt(x));
                  if (id.platform_ids.includes(p.id)) {
                    feature.platforms.push(p);
                    this.selectedFeatures.forEach(ftr => {
                      if (ftr.id === feature.id) {
                        feature.phaseId.push(buildPhase.build_phase_id + '' + p.id);
                      }
                    });
                  }
                });
                phase.features.push(JSON.parse(JSON.stringify(this.selectedFeatures.find(f => f.id === id.feature_id))));
              }
            });
          } else {
            buildPhase.feature_ids.forEach(id => {
              const feature = this.selectedFeatures.find(f => f.id === id);
              if (feature) {
                phase.features.push(this.selectedFeatures.find(f => f.id === id));
              }
            });
          }

          phase.is_parallel_platform = buildPhase.is_parallel_development;
          if ((phase.id === 3) && buildPhase.is_tailor_made) {
            phase.is_tailor_made = buildPhase.is_tailor_made;
          }

          buildPhase.platform_ids.forEach(id => {
            if (this.selectedHash.build_card_phases.length === 1 && this.selectedHash.build_card_phases[0]['is_tailor_made'] && this.selectedHash.build_card_phases[0].title === 'Professional Prototype') {
              const platform = this.selectedPlatformsForRapidPrototype.find(p => p.id === id);
              if (platform) {
                phase.platforms.push(platform);
              }
            } else {
              const platform = this.dataService.homePageData.platforms.find(p => p.id === parseInt(id));
              if (platform) {
                phase.platforms.push(platform);
              }
            }
          });
          if (this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
            phase.speed = this.dataService.homePageData.speed.find(sp => sp.id === buildPhase.speed_id);
          }
          phase.selected = true;
        }
        this.toggleSelectedBuildId(phase.index);
        this.toggleSelectedBuildIds(phase.index);
      });

      this.dataService.homePageData.build_phases.forEach(phase => {
        const selectedPhase = this.selectedPhases.find(ph => ph.id === phase.id);
        const mandatoryFeaturesIds = this.mandatoryFeatures.map(feature => feature.id);
        phase.features = [];
        phase.platforms = [];
        if (selectedPhase) {
          if ((phase.id === 3) && selectedPhase.is_tailor_made) {
            phase.is_tailor_made = selectedPhase.is_tailor_made;
          }
          phase.features.push(...selectedPhase.features);
          if ((phase.title === 'MVP') || (phase['phase_type'] === 'custom')) {
            this.selectedFeatures.forEach(feature => {
              feature.mvpSelected = true;
            });
            this.mandatoryFeatures.forEach((manFeature) => {
              const findIndex = phase.features.findIndex(phaseFeature => {
                return manFeature.id === phaseFeature.id;
              });
              if (findIndex === -1) {
                manFeature.selected = false;
              } else {
                manFeature.selected = true;
              }
            });
          }
          if ((phase.title === 'MVP') || (phase.title === 'Full Build')) {
            phase.features.forEach(feature => {
              if (mandatoryFeaturesIds.indexOf(feature.id) !== -1) {
                feature.is_mvp_feature = true;
              }
            });
          }
          phase.platforms.push(...selectedPhase.platforms);
          phase.speed = selectedPhase.speed;
          phase.is_parallel_platform = selectedPhase.is_parallel_platform;

        } else {
          if (phase.title.includes('MVP')) {
            phase.features = [];
            if ((this.selectedFeatures.length > 0) && (this.mandatoryFeatures.length === 0)) {
              phase.features.push(this.selectedFeatures[0]);
            } else if ((this.mandatoryFeatures.length > 0) && (this.dataService.homePageData.build_phases.length === 5)) {
              phase.features = this.mandatoryFeatures;
            }
          } else {
            phase.features.push(...this.selectedFeatures);
          }
          phase.features.forEach(feature => {
            if (mandatoryFeaturesIds.indexOf(feature.id) !== -1) {
              feature.is_mvp_feature = true;
            }
          });
          if (this.selectedHash.is_advance) {
            phase.platforms.push(this.dataService.homePageData.platforms[0]);
            if (this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
              phase.speed = this.dataService.homePageData.speed.find(sp => sp.title === 'Relaxed');
            }
          } else {
            if (this.selectedSpeed) {
              phase.speed = this.selectedSpeed;
            } else if (this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
              phase.speed = this.dataService.homePageData.speed.find(sp => sp.title === 'Relaxed');
            }
          }
          if (phase.title.includes('MVP')) {
            phase.platforms = [];
            if (this.selectedPlatforms.length > 0) {
              phase.platforms.push(this.selectedPlatforms[0]);
              phase.features.forEach(feature => {
                feature.phaseId = [];
                feature.platforms = Object.assign([], phase.platforms);
                feature.phaseId.push(phase.id + '' + phase.platforms[0].id);
                this.selectedFeatures.forEach(ftr => {
                  if (ftr.id === feature.id) {
                    ftr.phaseId = [];
                    ftr.platforms = Object.assign([], phase.platforms);
                    ftr.phaseId.push(phase.id + '' + phase.platforms[0].id);
                  }
                });
              });
            }
          } else if (!this.selectedHash.is_advance) {
            if (this.checkIfSelectedPlatformsAreDevices() && (phase.title === 'Professional Prototype')) {
              phase.platforms.push(...this.selectedPlatforms);
            } else if (this.checkIfSelectedPlatformsAreDevices() && (phase.title !== 'Professional Prototype')) {
              phase.platforms.push(this.dataService.homePageData.platforms[0]);
            } else {
              phase.platforms.push(...this.selectedPlatforms);
            }
          }
        }
      });
      this.dataService.homePageData.build_phases.forEach(phase => {
        if ((phase.title === 'MVP') || (phase['phase_type'] === 'custom')) {
          const phasePlatforms = phase.platforms.map(platform => platform.id);
          phase.features.forEach(feature => {
            if (!feature.platforms) { feature.platforms = []; }
            const featurePlatforms = feature.platforms.map(p => p.id);
            featurePlatforms.forEach(() => {
              feature.platforms.forEach((platform, index) => {
                if (phasePlatforms.indexOf(platform.id) === -1) {
                  feature.platforms.splice(index, 1);
                  feature.phaseId.splice(feature.phaseId.findIndex(p => (phase.id + '' + platform.id)), 1)
                }
              });
            });
          });
        }
      });
      this.dataService.homePageData.build_phases.forEach(phase => {
        const buildCardPhase = this.selectedPhases.find(p =>
          p.id === phase.id
        );
        if (buildCardPhase) {
          phase.selected = true;
          phase.build_card_phase_id = buildCardPhase.id;
        } else {
          phase.build_card_phase_id = null;
        }
      });
    }
    this.setSelectedPlatformInsideFeature();
  }

  checkIfSelectedPlatformsAreDevices() {
    const devices = this.selectedPlatformsForRapidPrototype.map(p => p.title);
    const areDevices = [];
    this.selectedPlatforms.forEach(platform => {
      if (devices.indexOf(platform.title) !== -1) {
        areDevices.push(platform.title);
      }
    });
    return areDevices.length ? true : false;
  }

  setSelectedPlatformInsideFeature() {
    this.dataService.homePageData.build_phases.forEach(phase => {
      if (phase.hasOwnProperty('build_card_phase_features_attributes')) {
        phase.build_card_phase_features_attributes.forEach(item => {
          const plateformArray = [];
          item.platform_ids.forEach(platformId => {
            const result = this.dataService.homePageData.platforms.find((platform) => {
              return platformId === platform.id;
            });
            if (result !== -1) {
              plateformArray.push(result);
            }
          });
          const index = phase.features.findIndex((feature) => {
            return item.feature_id === feature.id;
          });
          if (index !== -1) {
            phase.features[index].platforms = plateformArray;
          }
        });
      }
    });
  }

  public loadDashBoardData(analyticsSegment, status?) {
    this.dataService.showNoCompletedMessage = false;
    this.dataService.showNoProgressMessage = false;
    this.apiRequest.fetchBuildCards(null, null, status).subscribe((data: any) => {
      this.parseUserProperties({ saved_projects_count: this.dataService.inProgressCards.length });
      this.parseUserProperties({ completed_projects_count: this.dataService.completedCards.length });
    }, (error) => {
      if (this.commonService.isPlatformBrowser && (error.status === 403 || error.status === 401)) {
        this.dataService.user = null;

        // remove cookie
        this.dataService.unsetCookie();
        this.clearLocalStorage();
        this.showSignin = true;
        this.openCommonAuth = true;
        this.loadDashBoard = true;
      }
    });
  }

  public showInvitePopup(card) {
    if (this.router.url.indexOf('build-card') > -1) {
      this.trackEventForObject(this.analyticsSegment, "build_card_others_invited", {
        user_id: this.dataService.user ?.id
      });
    }

    this.invitePopup = true;
    this.cardToShareInvite = card;
    this.emailsToShareInvite = [];
  }

  public openOwnerTranferPopup(card) {
    this.cardToShareInvite = card;
    this.ownershipTransferPopup = true;
    this.emailsToShareInvite = [];
  }

  public showSharePopup(card) {
    this.currentSelectedBuildCardStatus = card.status;
    this.sharePopup = true;
    this.cardToShareInvite = card;
    this.emailsToShareInvite = [];
  }

  public checkMandateLogin() {
    if (!this.dataService.resetPassword) {
      if (!this.dataService.user) {
        this.showRegister = false;
        this.signUpCurrencyForBNow = false;
        this.showSignin = true;
        this.openCommonAuth = true;
        this.showclose = true;
      }
    }
  }

  returnHeadingAndDataForPrototype(headingOrData) {
    const professional = {
      heading: 'Get a fully functional design prototype to test the design hypothesis and end-user journey. This includes designs for prototypes.',
      data: 'Prototypes are realistic design representations of your ideas. We design interactive prototypes for iPhone, iPad, Android, and Web. Creation of prototypes is more specific, measurable, quick, and intensive than just describing the design. Test how the user will interact with an environment comparable to the final product.'
    };
    const rapid = {
      heading: 'Get a prototype to test the design hypothesis and application\'s major flows.',
      data: 'Prototypes are realistic design representation of your ideas. We design interactive prototypes for iPhone, iPad, Android, and Web. Creation of prototypes is more specific, measurable, quick, and intensive than just describing the design. Test how the user will interact with an environment comparable to the final product.'
    };
    const IsTailorMadePhase = this.dataService.homePageData.build_phases[2].is_tailor_made;
    const prototype = this.infoForWhichPrototype;
    if ((IsTailorMadePhase && (prototype !== 'Professional Prototype')) || (prototype === 'Tailor-made Prototype')) {
      if (headingOrData === 'heading') {
        return rapid.heading;
      } else if (headingOrData === 'data') {
        return rapid.data;
      }
    } else {
      if (headingOrData === 'heading') {
        return professional.heading;
      } else if (headingOrData === 'data') {
        return professional.data;
      }
    }
  }

  returnPrototypeName() {
    const IsTailorMadePhase = this.dataService.homePageData.build_phases[2].is_tailor_made;
    const prototype = this.infoForWhichPrototype;
    if ((IsTailorMadePhase && (prototype !== 'Professional Prototype')) || (prototype === 'Tailor-made Prototype')) {
      return 'Tailor-made Prototype';
    } else {
      return 'Professional Prototype';
    }
  }

  getLearnMoreData(): any[] {
    return [{
      popupHeading: 'Product Roadmap',
      popupIcon: './../../../../../assets/images/phase1.png',
      content: {
        title: 'Roadmap',
        heading: 'Why do you need a product roadmap? Add it and we help you define the use cases for every feature in your Buildcard. If you skip it, you’ll need to know exactly what you expect each feature to do before we can start building your app.It also gives you an in-depth view of where your product is going. Includes a product timeline so everyone can see all the key dates on the way to get you there.',
        data: 'Can help you secure funding, improve collaboration and streamline the whole app building process. Also useful for making long-term strategic decisions and planning a successful launch.',
        imagePath: './../../../../../assets/images/product_roadmap.png',
        documentUrl: 'https://s3.ap-south-1.amazonaws.com/engineer.ai/Product_Roadmap_-_Buildcard_name.pdf'
      }
    }, {
      popupHeading: 'Design',
      popupIcon: './../../../../../assets/images/phase2.png',
      content: {
        title: 'Design',
        heading: 'Trust us to do the wireframing of your concept and design a seamless experience. Get a fully scalable UI/UX',
        data: 'We combine visual principles, data, color psychology, and decades worth of experience to create aesthetic interfaces that will drive the growth of your product. We are experts in creating human-centric designs that allow customers to intuitively use your product and have a great product experience.',
        imagePath: './../../../../../assets/images/design.png'
      }
    },
    {
      popupHeading: this.returnPrototypeName(),
      popupIcon: './../../../../../assets/images/phase2.png',
      content: {
        title: 'Prototype',
        heading: this.returnHeadingAndDataForPrototype('heading'),
        data: this.returnHeadingAndDataForPrototype('data'),
        imagePath: './../../../../../assets/images/prototyping.png'
      }
    },
    {
      popupHeading: 'MVP',
      popupIcon: './../../../../../assets/images/phase3.png',
      content: {
        title: 'MVP',
        heading: 'Ship the first build of your idea and get early adopters to try out your product',
        data: 'We help design a Minimum Viable Product as a proof of concept to satisfy early customers and provide feedback for future development. It’s an easy way to build a product with a minimum set of features to test the market. Collect the maximum amount of validated learning about your customers with the least effort.',
        imagePath: './../../../../../assets/images/MVP.png'
      }
    },
    {
      popupHeading: 'Full Build',
      popupIcon: './../../../../../assets/images/phase3.png',
      content: {
        title: 'Full Build',
        heading: 'We will do end to end designing and development of your idea. Get a market-ready product',
        data: 'We build a full-fledged product based on the product roadmap and the features laid out in the specification document. We will also perfect the product based on user feedback received on the Minimum Viable Product. We will ship a fully mature, responsive, scalable, business-ready and a user-friendly product.',
        imagePath: './../../../../../assets/images/full_build.png'
      }
    },
    {
      popupHeading: 'Specifications',
      popupIcon: './../../../../../assets/images/phase1.png',
      content: {
        title: 'Specifications',
        heading: 'Get a detailed document with technical specifications of the product that you want to build',
        data: 'Specification document gives an overview of the technicalities involved in building your product. We cover design requirement, feature list, resources, protocols, frameworks, and languages used in the development process. Get a holistic understanding of your product along with the technologies used in it.',
        imagePath: './../../../../../assets/images/specifications.png',
        documentUrl: 'https://s3.ap-south-1.amazonaws.com/engineer.ai/Sample+Specification+Document.pdf'
      }
    }];
  }

  public updateFeaturedAddedMessage(message: any) {
    this.featureAddSubject.next({ feature: message });
  }

  public clearMessage() {
    this.featureAddSubject.next();
  }

  public getAddedFeature(): Observable<any> {
    return this.featureAddSubject.asObservable();
  }

  public updateCurrencyChange(message: any) {
    this.currencyChangeSubject.next({ is_currency_updated: message });
  }

  public clearCurrencyUpdateMessage() {
    this.currencyChangeSubject.next();
  }

  public getCurrencyUpdatedCallback(): Observable<any> {
    return this.currencyChangeSubject.asObservable();
  }

  public clickedViewPrototype(message: any) {
    this.viewPrototypeSubject.next(true);
  }

  public getViewPrototypeCallback(): Observable<any> {
    return this.viewPrototypeSubject.asObservable();
  }

  public isNumberKey(evt) {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    const spaceChar = [8, 37, 39, 46];
    if ((charCode >= 48 && charCode <= 57) || spaceChar.indexOf(charCode) >= 0) {
      return true;
    } else {
      evt.preventDefault();
      return false;
    }
  }


  public setComponentTitle(analyticsSegment: Angulartics2Segment, pageName: string) {
    const pageCode = pageName ? pageName : this.dataService.currentPage;
    if (pageCode && Constants.PAGE_TITLES[pageCode]) {
      const title = Constants.PAGE_TITLES[pageCode].title;
      if (title) {
        this.titleService.setTitle(title);
        if (pageName) {
          this.trackPage(analyticsSegment, title);
        }
      }
    }
  }

  public trackEvent(analyticsSegment, title: string, key?: string, value?: any): void {
    const eventTitle = title.trim();
    if (key && value && title) {
      const eventKey = key.trim();
      analyticsSegment.eventTrack(eventTitle, { [eventKey]: value });
    } else if (title) {
      analyticsSegment.eventTrack(eventTitle, {});
    }
  }

  public trackPage(analyticsSegment, pageTitle: any) {
    analyticsSegment.pageTrack(pageTitle);
  }

  public trackEventForObject(analyticsSegment, title: string, obj): void {
    const eventTitle = title.trim();
    obj.user_browser = this.deviceInfo.browser;
    obj.user_device = this.deviceInfo.deviceType;
      if (this.dataService.buildCardData.id) {
      obj.build_card_id = this.dataService.buildCardData.id;
    }
    if (obj && title) {
      analyticsSegment.eventTrack(eventTitle, obj);
      // intercom.trackEvent('[Builder] ' + eventTitle, obj);
    } else if (title) {
      analyticsSegment.eventTrack(eventTitle, {});
      // intercom.trackEvent('[Builder] ' + eventTitle, {});
    }
  }

  public updatedBuildCardDataName(updatedKeys, selectedHash) {
    const updatedData = {};
    for (const key of updatedKeys) {
      updatedData[key] = selectedHash[key];
    }
    return updatedData;
  }

  public updatedBuildCardData(updatedKeys, selectedHash) {
    let updatedData = {};
    updatedData = { 'product_id': selectedHash.product_id };
    for (const key of updatedKeys) {
      updatedData[key] = selectedHash[key];
    }

    if (this.dataService.editedFeatures.length > 0) {
      updatedData['custom_feature_ids'] = this.dataService.editedFeatures;
    }

    if (this.dataService.editedTemplates.length > 0) {
      updatedData['custom_template_ids'] = this.dataService.editedTemplates;
    }

    if (this.selectedHash.promotion_id) {
      updatedData['promotion_id'] = this.selectedHash.promotion_id;
      updatedData['pricing_params'] = this.selectedHash.pricing_params;
    }

    if (this.selectedHash.competitors) {
      updatedData['competitors'] = this.selectedHash.competitors;
    }

    if (this.selectedHash.build_card_phases.length > 0) {
      updatedData['build_card_phases'] = this.selectedHash.build_card_phases;
    }

    if (this.selectedHash.build_phase_vars.length > 0) {
      updatedData['build_phase_vars'] = this.selectedHash.build_phase_vars;
    }

    if (this.selectedHash.application_ids.length > 0) {
      updatedData['application_ids'] = this.selectedHash.application_ids;
    }
    return updatedData;
  }

  public showHideRemoveAllPopup() {
    this.showOwnershipConfirmationSummary = false;
    this.showOwnershipConfirmationDashboard = false;
    this.ownershipTranferMessage = false;
    this.ownershipTransferCurrencyMismatchMessage = false;
    this.ownershipTransferUserNotAvailableMessage = false;
    this.ownershipTransferInvitationSentMessage = false;
    this.emailToEnterForOwnershipTransfer = null;
    location.reload();
  }

  public isAdminDashboard() {
    return this.router.url.indexOf('admin-dashboard') > -1;
  }

  public isPartnerDashboard() {
    return this.router.url.indexOf('become-a-partner') > -1;
  }

  public setSignupAnalytics(field, provider?, currency?) {
    switch (field) {
      case 'start_button':
        break;
      case 'talk_to_sales_click':
        this.trackEventForObject(this.analyticsSegment, 'book_a_demo_call_clicked', {
          screen_source: this.dataService.currentPage,
          user_id: this.dataService.user ?.id
      });
        break;
      case 'talk_to_product_expert_click':
        break;
    }
  }

  public isEmailValid(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return (true);
    }
    return (false);
  }

  public setTempateName() {
    this.selectedHash.customized_template_name = '';
    let index = 0;
    for (const app of this.selectedApps) {
      if (index > 0) {
        if (index === this.selectedApps.length - 1) {
          this.selectedHash.customized_template_name += ' and ';
        } else {
          this.selectedHash.customized_template_name += ', ';
        }
      }
      this.selectedHash.customized_template_name += app.title;
      index++;
    }
  }

  onNextClicked() {
    if (this.dataService.homePageData.promotion) {
      if (this.isValidPromotion()) {
        this.promotionValid = true;
        this.flatPrice = this.dataService.homePageData.promotion.discount;
        this.selectedHash.promotion_id = this.dataService.homePageData ?.promotion.id;
      } else {
        this.promotionValid = false;
        this.flatPrice = 0;
        this.selectedHash.promotion_id = '';
      }
    }
    this.setTempateName();
    this.showCurrency = false;
  }

  public trackSignupEvents(gtag, fbq) {

    // @ts-ignore
    this.analyticsSegment.setAlias(this.dataService.user && this.dataService.user.id ? this.dataService.user.id : '', this.dataService.anonymousId);
    this.parseUserProperties({
      'email': this.dataService.user.email,
      'userId': this.dataService.user && this.dataService.user.id ? this.dataService.user.id : '',
      'name': this.dataService.user.first_name + ' ' + this.dataService.user.last_name,
      first_name: this.dataService.user.first_name,
      last_name: this.dataService.user.last_name,
      phone: this.dataService.user.phone_number,
      utm_campaign: this.appDataService.urlParameters.utm_campaign,
      utm_source: this.appDataService.urlParameters.utm_source,
      utm_medium: this.appDataService.urlParameters.utm_medium,
      utm_term: this.appDataService.urlParameters.utm_term,
      utm_content: this.appDataService.urlParameters.utm_content,
      traits: {
        email: this.dataService.user.email,
        phone: this.dataService.user.phone_number
      }
    });
    let signUpEvent = this.appDataService.urlParameters.signup_stage;
    if (signUpEvent === 'home') {
      signUpEvent = 'signedup_abhomescreen';
    } else if (signUpEvent === 'features') {
      signUpEvent = 'signedup_abfeatures';
    } else if (signUpEvent === 'buildphases') {
      signUpEvent = 'signedup_abphases';
    } else {
      signUpEvent = 'signedup_' + this.dataService.currentPage;
    }
    if ((window.location.origin === 'https://beta.engineer.ai') || (window.location.origin === 'https://builder.engineer.ai') || (window.location.origin === 'https://studio.builder.ai')) {
      gtag('event', 'BuilderSignupDone', { 'send_to': 'AW-800126066/KpvGCPSdtYQBEPLow_0C' });
      fbq('track', 'CompleteRegistrationSignup');
    }
  }

  public trackSigninEvents() {
    this.parseUserProperties({
      'email': this.dataService.user.email,
      'userId': this.dataService.user?.id,
      'name': this.dataService.user.first_name,
      first_name: this.dataService.user.first_name,
      last_name: this.dataService.user.last_name,
      utm_campaign: this.appDataService.urlParameters.utm_campaign,
      utm_source: this.appDataService.urlParameters.utm_source,
      utm_medium: this.appDataService.urlParameters.utm_medium,
      utm_term: this.appDataService.urlParameters.utm_term,
      utm_content: this.appDataService.urlParameters.utm_content,
      phone: this.dataService.user.phone_number,
      traits: {
        email: this.dataService.user.email,
        phone: this.dataService.user.phone_number
      }
    });
  }


  get signUpLoginCloseEvent() {
    const signUpLoginCloseEvent = this.appDataService.urlParameters.signup_stage;
    if (signUpLoginCloseEvent === 'home') {
      return 'ab_home';
    } else if (signUpLoginCloseEvent === 'features') {
      return 'ab_features';
    } else if (signUpLoginCloseEvent === 'phases') {
      return 'ab_phases';
    } else {
      return this.dataService.currentPage;
    }
  }

  get signUpEvent() {
    const signUpEvent = this.appDataService.urlParameters.signup_stage;
    if (signUpEvent === 'home') {
      return 'login_abhomescreen';
    } else if (signUpEvent === 'features') {
      return 'login_abfeatures';
    } else if (signUpEvent === 'buildphases') {
      return 'login_abphases';
    } else {
      return 'login_' + this.dataService.currentPage;
    }
  }

  trackSocialSigninEvent(provider) {
    // @ts-ignore

    this.parseUserProperties({
      'email': this.dataService.user.email,
      'userId': this.dataService.user?.id,
      'name': this.dataService.user.first_name,
      first_name: this.dataService.user.first_name,
      last_name: this.dataService.user.last_name,
      utm_campaign: this.appDataService.urlParameters.utm_campaign,
      utm_source: this.appDataService.urlParameters.utm_source,
      utm_medium: this.appDataService.urlParameters.utm_medium,
      utm_term: this.appDataService.urlParameters.utm_term,
      utm_content: this.appDataService.urlParameters.utm_content,
      phone: this.dataService.user.phone_number,
      traits: {
        email: this.dataService.user.email,
        phone: this.dataService.user.phone_number
      }
    });

  }

  public isSupportAndMaintenance() {
    if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.is_under_support !== null) {
      return this.dataService.modifiedBuildCardData.is_under_support || this.selectedHash.is_under_support;
    } else if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.is_under_support == null) {
      return this.dataService.buildCardData.modification_request.base_build_card_data.is_under_support;
    } else {
      return this.selectedHash.is_under_support;
    }
  }

  setCareTypeSelected() {
    if (!this.dataService.user?.enterprise_lead && this.dataService.buildCardData.enterprise_lead && (this.dataService.buildCardData.status == 'card_initialized' || this.dataService.buildCardData.status == 'card_completed' ) && !this.selectedHash.care_type.includes('standard')) {
      return 'standard_care';
    }else if (this.dataService.user?.enterprise_lead && !this.dataService.buildCardData.enterprise_lead && (this.dataService.buildCardData.status == 'card_initialized' || this.dataService.buildCardData.status == 'card_completed')) {
      return 'enterprise_care_plus';
    }
    else{
      return this.selectedHash.care_type;
    }
  }

  public isCloudOpted(page?) {
    if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.cloud_opted !== null) {
      return this.dataService.modifiedBuildCardData.cloud_opted || this.selectedHash.cloud_opted;
    } else if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.cloud_opted === null) {
      return this.dataService.buildCardData.modification_request.base_build_card_data.cloud_opted;
    } else {
      if (page === 'delivery') {
        return this.isBuilderCloudChecked;
      } else if (this.dataService.buildCardData.id) {
        return this.dataService.buildCardData.cloud_opted;
      } else {
        return this.selectedHash.cloud_opted;
      }
    }
  }


  public calculateFeatureCostAndWeeks(features) {
    let featureCost = 0;
    let featureWeeks = 0;
    if (features) {
      for (const feature of features) {
        if (feature.feature_price && feature.feature_weeks && !this.isFeatureRequest) {
          featureCost += feature.feature_price;
          featureWeeks += feature.feature_weeks;
        } else if (feature.feature_price && feature.feature_weeks && this.isFeatureRequest && !feature.disabled && feature.selected) {
          featureCost += feature.feature_price;
          featureWeeks += feature.feature_weeks;
        }
      }
      return { price: featureCost, weeks: featureWeeks };
    }
  }

  public toggleSupportAndMaintainence(isForEMI: boolean, careType?) {
    if (this.dataService.buildCardData.subscription_flow) {
      this.selectedHash.is_under_support = !this.selectedHash.is_under_support;
    } else {
      this.selectedHash.is_under_support = true;
    }
    this.selectedHash.care_type = careType;
    this.selectedHash['pricing_params']['care_type'] = careType;
    if (isForEMI) {
      this.preventClick = true;
      this.continueToUpdateBuildCard();
    }
    this.updatePrice();
  }

  public toggleBuilderCloud() {
    this.selectedHash.cloud_opted = !this.selectedHash.cloud_opted;
    const modifiedData = this.dataService.modifiedBuildCardData;
    if (modifiedData && modifiedData.minimum_cloud_price.new) {
      this.selectedHash.minimum_cloud_price = modifiedData.minimum_cloud_price.new;
      this.selectedHash.maximum_cloud_price = modifiedData.maximum_cloud_price.new;
    } else if (modifiedData && modifiedData.cloud_price.new && !modifiedData.minimum_cloud_price.new) {
      this.selectedHash.cloud_price = modifiedData.cloud_price.new;
    } else {
      this.selectedHash.minimum_cloud_price = this.dataService.buildCardData.minimum_cloud_price;
      this.selectedHash.maximum_cloud_price = this.dataService.buildCardData.maximum_cloud_price;
    }
    this.isToggleBuilderCloud = true;
    this.preventClick = true;
    this.continueToUpdateBuildCard();
  }

  public continueToUpdateBuildCard() {
    this.selectedHash.status = '';
    this.selectedHash.update_from_payment = true;
    this.dataService.unsavedChanges = false;
    const updateData = {};
    if (this.isToggleBuilderCloud) {
      updateData['cloud_opted'] = this.selectedHash.cloud_opted;
      if (this.selectedHash.minimum_cloud_price) {
        updateData['minimum_cloud_price'] = this.selectedHash.minimum_cloud_price;
        updateData['maximum_cloud_price'] = this.selectedHash.maximum_cloud_price;
      } else if (this.selectedHash.cloud_price) {
        updateData['cloud_price'] = this.selectedHash.cloud_price;
      }
    } else {
      this.createBuildCardPriceHash();
      updateData['care_type'] = this.selectedHash.care_type;
      updateData['is_under_support'] = this.selectedHash.is_under_support;
      updateData['pricing_params'] = this.selectedHash.pricing_params;
    }

    updateData['update_from_payment'] = this.selectedHash.update_from_payment;
    updateData['status'] = this.selectedHash.status;
    this.donationOpted = this.dataService.buildCardData.donation_opted;
    const donationPercentage = this.dataService.buildCardData.donation_percentage;
    this.apiRequest.createUpdateBuildCard(updateData, this.inProgressBuildCardId, '', 'updatefromemi').subscribe((data: any) => {
      this.preventClick = false;
      const card = this.dataService.buildCardData;
      if (!card.donation_amount && (card.status === 'card_completed')) {
        this.dataService.buildCardData.donation_opted = this.donationOpted;
        this.dataService.calculateDonationAmount(donationPercentage ? donationPercentage : 5);
      }
      this.isToggleBuilderCloud = false;
      this.fetchProposalInstallments();
      this.fetchInstallments(true);
    }, (error) => {
      if (error.status === 403 || error.status === 401) {
        this.dataService.user = null;
        // remove cookie here
        this.dataService.unsetCookie();
        this.clearLocalStorage();
        this.showSignin = true;
        this.openCommonAuth = true;
      }
      this.preventClick = false;
      this.dataService.showHideLoader(false);
    });
  }

  public getSelectedTemplatesFromUrl() {
    if (this.dataService.shareUrlData) {
      this.dataService.shareUrlData.templateIds.forEach(template => {
        this.toggleSelectedApps(template);
        this.toggleSelectedAppid(template.id);
      });
    }
  }

  public setPhasesUsingSharedUrl() {
    if (this.dataService.shareUrlData) {
      this.selectedPhases = [];
      this.selectedPhasesIds = [];
      this.selectedFeatures = [];
      this.selectedHash.feature_ids = [];
      this.selectedApps = [];
      this.selectedHash.application_ids = [];
      this.selectedTeam = this.dataService.shareUrlData.team;
      this.defaultTeamShow = this.dataService.shareUrlData.team;
      this.selectedHash.is_under_support = this.dataService.shareUrlData.support;
      this.selectedHash.is_advance = this.dataService.shareUrlData.is_advance;
      this.selectedPlatforms = this.dataService.shareUrlData.platformIds;
      this.selectedSpeed = this.dataService.shareUrlData.speed;
      this.selectedHash.cloud_opted = this.dataService.shareUrlData.cloud_opted;
      this.selectedHash.minimum_cloud_price = this.dataService.shareUrlData.minimum_cloud_price;
      this.selectedHash.maximum_cloud_price = this.dataService.shareUrlData.maximum_cloud_price;
      this.selectedHash.cloud_user_count = this.dataService.shareUrlData.cloud_user_count;
      this.dataService.homePageData.build_phases.forEach(phase => {
        phase.platforms = [];
        phase.features = [];
        const selectedPhase = this.dataService.shareUrlData.buildphases.find((ph) => {
          return ph.id === phase.id;
        });
        if (selectedPhase) {
          phase.features = selectedPhase.features;
          phase.platforms = selectedPhase.platforms;
          phase.speed = selectedPhase.speed;
          phase.selected = true;
          phase.active = true;
          phase.is_parallel_platform = selectedPhase.is_parallel_platform;
          this.toggleSelectedBuild(phase);
          this.toggleSelectedBuildId(phase.index);
          this.toggleSelectedBuildIds(phase.index);
        } else {
          phase.features = this.dataService.shareUrlData.featureIds;
          phase.platforms = this.dataService.shareUrlData.platformIds;
          if (this.selectedHash.is_advance && this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
            phase.speed = this.dataService.homePageData.speed.find(sp => sp.title === 'Relaxed');
          } else {
            if (this.selectedSpeed) {
              phase.speed = this.selectedSpeed;
            } else if (this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
              phase.speed = this.dataService.homePageData.speed.find(sp => sp.title === 'Relaxed');
            }
          }
        }
      });
      this.dataService.shareUrlData.featureIds.forEach(ftr => {
        this.toggleSelectedFeatureId(ftr.id);
        this.toggleSelectedFeatures(ftr);
        ftr.selected = true;
      });
      this.getSelectedTemplatesFromUrl();
      this.updatePrice();

    }
  }

  public sendFilteredObject(arrObject, typeOfArr, extraFields?) {
    let allowFields = [];
    if (typeOfArr === 'features') {
      allowFields = ['effective_cost', 'algorithm', 'user_requested', FEATURE_SET_ID, FEATURE_COUNT, REPEATABLE_COUNT, 'effective_weeks', 'feature_price', 'feature_weeks', ID, 'price', 'weeks', 'disabled', 'selected', TITLE, 'platforms'];
    } else if (typeOfArr === 'platforms' || typeOfArr === 'speed') {
      allowFields = [ID, PRICE_MULTIPLIER, WEEK_MULTIPLIER];
    } else if (typeOfArr === CUSTOMFEATURES) {
      allowFields = ['selected', 'disabled', FEATURE_SET_ID, ID, FEATURE_COUNT, REPEATABLE_COUNT, ALGORITHM, USER_REQUESTED, DESCRIPTION, COMPLEXITY, INTERFACE_TYPE, TITLE, DIFFICULTY,FEATURE_ATTACHMENT, FEATURE_NOTE].concat(extraFields);
    } else if (typeOfArr === COREFEATURES) {
      allowFields = [ID, FEATURE_COUNT, FEATURE_SET_ID, REPEATABLE_COUNT, USER_REQUESTED, TITLE, FEATURE_NOTE, FEATURE_ATTACHMENT].concat(extraFields);
    }

    if (arrObject && allowFields.length > 0) {
      return allowFields.map(keyName => keyName in arrObject ? { [keyName]: arrObject[keyName] } : {})
        .reduce((res, o) => Object.assign(res, o), {});
    }
    return {};
  }

  public sendFilteredArray(arrValues, typeOfArr, extraFields?) {
    let filteredArr = [];
    if (Array.isArray(arrValues) && arrValues.length > 0) {
      filteredArr = arrValues.map(object => {
        return this.sendFilteredObject(object, typeOfArr, extraFields);
      });
    }
    return filteredArr;
  }

  checkWhichPage() {
    return (this.dataService.currentPage && (this.dataService.currentPage === 'paymentPlan' || this.dataService.currentPage === 'summary'));
  }

  setSpeedForMultiplier() {
    if (this.dataService.buildCardData.id && !this.dataService.checkIfBuildCardisinvited() && !this.dataService.isUnpaidCard()) {
      return false;
    }
    let speedFromME;
    const buildCardMultiplier = this.dataService.buildCardData.multiplier_experiment;
    const globalMul = this.dataService.homePageData.multiplier_experiment;
    const isSpeedInCard = (this.dataService.buildCardData.progress && this.dataService.buildCardData.progress.speed);
    if ((buildCardMultiplier && buildCardMultiplier.is_default && globalMul && (globalMul.id !== buildCardMultiplier.id)) || !buildCardMultiplier || !isSpeedInCard) {
      speedFromME = this.dataService.homePageData.multiplier_experiment.speed_id;
    } else {
      speedFromME = this.selectedSpeed ? this.selectedSpeed.id : 1;
    }
    return speedFromME ? speedFromME : 1;
  }

  setCarePercentageForEnt() {
    let mulExpData;
    const buildCardMultiplier = this.dataService.buildCardData.multiplier_experiment;
    if (((buildCardMultiplier && buildCardMultiplier.is_default && this.dataService.isUnpaidCard()) && this.dataService.checkIfBuildCardisinvited()) || !buildCardMultiplier) {
      mulExpData = this.dataService.homePageData.multiplier_experiment;
    } else {
      mulExpData = buildCardMultiplier;
    }
    if (mulExpData && mulExpData['care_percentage']) {
      this.buildCardSelections['care_percentage'] = mulExpData['care_percentage'];
      this.buildCardSelections['care_plus_percentage'] = mulExpData['care_plus_percentage'];
    }
  }

  public createBuildCardPriceHash() {
    let priceMultiplier, weekMultiplier;
    const build_phases = this.dataService.homePageData.build_phases;
    this.setCurrencyInPricingHash();
    const usdCurrency = this.dataService.homePageData ?.currencies ?.filter(currency => {
      return currency.code === 'USD';
    })[0];
    if (usdCurrency) {
      this.currencyForCAT = { code: usdCurrency.code, exchange_rate: usdCurrency.exchange_rate };
    }
    if (!this.dataService.buildCardData.id && !this.dataService.shareUrlData && this.selectedHash.cloud_price > 0) {
      this.convertCloudPriceBasedOnUserCurrency();
    }
    if (this.dataService.currentPage === 'delivery') {
      this.buildCardSelections.speed_prices = true;
    }
    if (this.selectedTeam) {
      this.buildCardSelections.team = {
        id: this.selectedTeam.id,
        price_multiplier: this.selectedTeam.price_multiplier,
        week_multiplier: this.selectedTeam.week_multiplier
      };
    } else {
      this.buildCardSelections.team = {
        price_multiplier: 1,
        week_multiplier: 1
      };
    }
    this.buildCardSelections.partner_multiplier = this.dataService.homePageData.builder_partner.partner_multiplier;
    this.buildCardSelections.build_card_phases = {};
    if (this.selectedFeatures && this.selectedFeatures.length > 0) {
      this.selectedFeatures = this.uniqueArrayObjectSet(this.selectedFeatures);
    }
    if (this.dataService.homePageData.pricing_logic === 'new') {
      let recommendedFeaturesAdded = this.selectedFeatures.filter(obj => obj.is_recommended === true);
      this.dataService.recommendedFeatureIdsAdded = this.getArrayOfOneFieldFromArrayOfObj(recommendedFeaturesAdded, 'id');
      this.setPhasesInPricingHashForNewPricingLogic(build_phases);
    } else {
      const featurePriceWeeksForOtherPhases = this.calculateFeatureCostAndWeeks(this.selectedFeatures);
      if (this.dataService.homePageData.build_phases && this.dataService.homePageData.build_phases.length > 0) {
        this.dataService.homePageData.build_phases.forEach(phase => {
          if (phase.selected) {
            const phaseTitle = phase.title.toLowerCase().replace(/ /g, '_');
            if (phase.title !== 'MVP' && phase.title !== 'Full Build') {
              this.buildCardSelections.build_card_phases[phaseTitle] = {
                features_price: featurePriceWeeksForOtherPhases.price,
                features_weeks: featurePriceWeeksForOtherPhases.weeks,
                price_multiplier: phase.price_multiplier,
                week_multiplier: phase.week_multiplier,
                platforms: this.sendFilteredArray(phase.platforms, 'platforms')
              };
              if (phase.speed) {
                this.buildCardSelections.build_card_phases[phaseTitle].speed = this.sendFilteredObject(phase.speed, 'speed');
              }
            } else {
              this.buildCardSelections.build_card_phases[phaseTitle] = {
                platforms: this.sendFilteredArray(phase.platforms, 'platforms'),
                speed: this.sendFilteredObject(phase.speed, 'speed'),
                week_multiplier: phase.week_multiplier,
                price_multiplier: phase.price_multiplier
              };
            }
            if (phase.features && phase.features.length > 0) {
              phase.features = this.uniqueArrayObjectSet(phase.features);
            }
            if (phase['title'] === 'MVP') {
              priceMultiplier = phase.price_multiplier;
              weekMultiplier = phase.week_multiplier;
            }
            if ((phase['title'] === 'MVP') || (phase['type'] === 'custom_phase')) {
              this.buildCardSelections.build_card_phases[phaseTitle].features = this.sendFilteredArray(phase.features, 'features');
            }
            this.buildCardSelections.build_card_phases[phaseTitle].is_parallel_development = phase.is_parallel_platform;
            this.buildCardSelections.build_card_phases[phaseTitle].id = phase.id;
          }
        });
      }
    }
    this.setMandatoryFeatures(build_phases);
    this.buildCardSelections.application_ids = this.selectedHash.application_ids;
    if (this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
      this.buildCardSelections.relaxed_speed = this.sendFilteredObject(this.dataService.homePageData.speed.find(speed => speed.title === 'Relaxed'), 'speed');
    }
    if ((!(this.checkIfRentalPricingModal() && (this.selectedHash.source === 'app_detail')) && !this.isFeatureRequest) || this.appDataService.urlParameters.cpe_user) {
      this.setPromotionInBuildCardHash();
    }
    if (!this.checkIfRentalPricingModal()) {
      this.setMultiplierExperimentInBuildCardHash();
    }
    this.selectedHash.pricing_params = this.buildCardSelections;
    this.selectedHash.currency_id = this.dataService.homePageData.currency.id;
    this.selectedHash['version'] = 3;
    this.selectedHash.currency_id = this.dataService.buildCardData.currency ? this.dataService.buildCardData.currency.id : this.dataService.homePageData.currency.id;
    if (this.appDataService.urlParameters && this.appDataService.urlParameters.prototype_promo) {
      this.selectedHash.prototype_promo = this.appDataService.urlParameters.prototype_promo;
    }
    const promotion = this.getPromotionObject();
    if (this.isFeatureRequest && !this.isPlatformRequest) {
      if ((this.dataService.currentPage === 'summary') && (promotion && promotion.code)) {
        this.apiRequest.buildCardSectionForRental = JSON.parse(JSON.stringify(this.selectedHash.pricing_params));
        this.setHasForRentalPromotion(this.apiRequest.buildCardSectionForRental);
      }
      this.setHashForRequestedFeatures(this.buildCardSelections);
      this.setParamsForCustomFeatures(priceMultiplier, weekMultiplier);
    } else {
      if (this.dataService.homePageData.pricing_logic === 'new') {
        this.setFeaturesInPricingHash(priceMultiplier, weekMultiplier);
      } else {
        this.setParamsForCustomFeatures(priceMultiplier, weekMultiplier);
        if (this.selectedFeatures.length > 0) {
          this.buildCardSelections['features'] = this.sendFilteredArray(this.selectedFeatures, 'features');
        }
      }
    }
    if (this.dataService.homePageData.pricing_logic === 'new') {
      this.buildCardSelections['version'] = 3;
      this.buildCardSelections['feature_breakup_needed'] = true;
    }
    if (this.getBuilderCareShowHide() && this.dataService.buildCardData.new_care_flow) {
      this.buildCardSelections['care_prices'] = true;
      this.buildCardSelections['care_type'] = this.selectedHash.care_type;
    }
    if (!this.isFeatureRequest) {
      this.buildCardSelections['build_card_id'] = this.dataService.buildCardData.id;
    }
    if (this.isPlatformRequest) {
      this.buildCardSelections['source'] = 'platform_addition';
      this.buildCardSelections['build_card_id'] = this.dataService.buildCardData.id;
    }
    if (this.dataService.checkIfEnterpriseUserOrCard() || this.checkEnterpriseForPaidCards()) {
      this.buildCardSelections['enterprise_lead'] = true;
    }
    const buildCardData = this.dataService.buildCardData;
    if ((buildCardData.status === 'card_initialized' || buildCardData.status === 'card_completed')) {
      this.selectedHash['new_care_flow'] = true;
      this.buildCardSelections['new_care_flow'] = true;
      if (this.dataService.buildCardData.subscription_flow) {
        this.selectedHash['subscription_flow'] = true;
        this.buildCardSelections['subscription_flow'] = true;
      }
    }
    this.setFeatureCountForAddFeatures();
    if (this.isFeatureRequest) {
      this.buildCardSelections['new_care_flow'] = this.dataService.buildCardData.new_care_flow;
    }
    if (this.dataService.buildCardData.subscription_flow && (this.isFeatureRequest || this.dataService.isEditBuildCardAfterSecurityPaid())) {
      this.buildCardSelections['subscription_flow'] = this.dataService.buildCardData.subscription_flow;
    }
    if (this.isStoreFeatureRequest) {
      this.buildCardSelections['store_change_request'] = true;
    }
    if (this.templateNatashaSearchText || this.dataService.buildCardData.template_query_string) {
      this.selectedHash.template_query_string = this.templateNatashaSearchText ? this.templateNatashaSearchText : this.dataService.buildCardData.template_query_string;
    }
    if (!this.checkIfOnlyMvpOrCustomPhase()) {
      this.sendphasesFeaturesGlobally();
    }
    if (this.selectedHash.is_advance) {
      this.buildCardSelections['is_advance'] = true;
    }
    const isUserEnterprise = this.dataService.user && this.dataService.user.enterprise_lead;
    const isCardEnterprise = (this.dataService.buildCardData && (this.dataService.buildCardData.enterprise_lead === 'true'));
    if (isUserEnterprise || isCardEnterprise) {
      this.setCarePercentageForEnt();
    }
    this.selectedHash.tailor_made = false;
    if (this.addnewfeature) {
      let currentFeaturesInPayload = [...this.buildCardSelections['features']];
      this.buildCardSelections['features'] = [...currentFeaturesInPayload, ...this.recommendedCoreFeaturesToBeAdded];
    }

  }

  checkEnterpriseForPaidCards() {
    if (this.dataService.isEditBuildCardAfterSecurityPaid() || this.isFeatureRequest) {
      return (this.dataService.buildCardData && (this.dataService.buildCardData.enterprise_lead === 'true'));
    }
  }

  setFeatureCountForAddFeatures() {
    if (this.isEditFeatureRequest() && this.dataService.additionalFeatures?.length) {
      this.dataService.homePageData.build_phases.forEach(phase => {
        if ((phase['title'] === 'MVP') || (phase['type'] === 'custom_phase')) {
          const phaseTitle = phase.title.toLowerCase().replace(/ /g, '_'),
            buildPhases = this.buildCardSelections.build_card_phases;
          if (buildPhases[phaseTitle] && buildPhases[phaseTitle]['features']) {
            buildPhases[phaseTitle]['features'].forEach(feature => {
              const addedFeature = this.dataService.additionalFeatures.find(f => f.id === feature.id);
              const approvedFeature = this.dataService.buildCardData['progress']['features'].find(f => f.id === feature.id);
              if (addedFeature && approvedFeature && addedFeature.feature_count && feature.feature_count && !this.isCountChanged) {
                feature.feature_count = addedFeature.feature_count - approvedFeature.feature_count;
                this.isCountChanged = true;
              }
            });
          }
        }
      });
      this.dataService.additionalFeatures.forEach(feature => {
        const approvedFeature = this.dataService.buildCardData['progress']['features'].find(f => f.id === feature.id);
        if (feature.feature_count && approvedFeature && approvedFeature.feature_count && !this.isCountChanged) {
          feature.feature_count = feature.feature_count - approvedFeature.feature_count;
          this.isCountChanged = true;
        }
      });
    }
  }

  toggleSupportAndMaintainenceForOldCards(isForEMI: boolean) {
    this.selectedHash.is_under_support = !this.selectedHash.is_under_support;
    if (!this.selectedHash.is_under_support) {
      this.trackEventForObject(this.analyticsSegment, 'builder_care_unselected', {
        user_id: this.dataService.user ?.id,
        phase_selected: this.getPhaseTitle()
      });
    }
    if (isForEMI) {
      this.preventClick = true;
      this.continueToUpdateBuildCard();
    }
  }


  getBuilderCareShowHide() {
    const mvp = this.selectedPhases.find(
      phase => (phase.title === 'MVP') || (phase['type'] === 'custom_phase')
    );
    const fullBuild = this.selectedPhases.find(
      phase => phase.title === 'Full Build'
    );
    if ((mvp && mvp.selected) || (fullBuild && fullBuild.selected)) {
      if (this.dataService.buildCardData.new_care_flow) {
        if (!this.dataService.buildCardData.subscription_flow) {
          this.selectedHash.is_under_support = true;
        }
        if (!this.selectedHash.care_type && this.dataService.checkIfEnterpriseUserOrCard()) {
          this.selectedHash.care_type = 'enterprise_care_plus';
        } else if (!this.selectedHash.care_type) {
          this.selectedHash.care_type = 'standard_care';
        }
      }
      return true;
    } else {
      this.selectedHash.is_under_support = false;
      return false;
    }
  }

  checkIfOnlyMvpOrCustomPhase() {
    const otherThanMvpPhase = [];
    this.dataService.homePageData.build_phases.forEach((phase) => {
      if ((phase.id <= 5) && (phase.id !== 4) && phase.selected) {
        otherThanMvpPhase.push(phase.title);
      }
    });
    return otherThanMvpPhase.length ? true : false;
  }

  sendphasesFeaturesGlobally() {
    if (!Object.values(this.buildCardSelections.build_card_phases).length) { return; }
    const selectedPhaseFeatures = [];
    const buildCardPhases = JSON.parse(JSON.stringify(this.buildCardSelections.build_card_phases));
    const customPhases = buildCardPhases['custom_phases'] ? buildCardPhases['custom_phases'] : [];
    delete buildCardPhases['custom_phases'];
    Object.values(buildCardPhases).concat(Object.values(customPhases)).forEach(phase => {
      phase['features'].forEach(f => {
        if (selectedPhaseFeatures.indexOf(f.id) === -1) {
          selectedPhaseFeatures.push(f.id);
        }
      });
    });
    this.selectedFeatures.forEach(() => {
      this.buildCardSelections.features.forEach((feature, index) => {
        if (selectedPhaseFeatures.indexOf(feature.id) === -1) {
          this.buildCardSelections.features.splice(index, 1);
        }
      });
    });
  }

  setCurrencyInPricingHash() {
    let buildCardCurrency;
    const user = this.dataService.user;
    if (this.dataService.isEditRequestedFeatures) {
      buildCardCurrency = this.dataService.changeFeatureRequestModel.build_card.currency;
    } else {
      buildCardCurrency = this.dataService.buildCardData.currency;
    }
    const buildCardType = this.buildCardEditMode || this.dataService.isIncompleteCardEditMode || this.isFeatureRequest;
    this.buildCardSelections = {
      currency: {
        multiplier: (buildCardType || this.checkWhichPage()) && buildCardCurrency ? buildCardCurrency.multiplier : this.dataService.defaultCurrency.multiplier,
        exchange_rate: (buildCardType || this.checkWhichPage()) && buildCardCurrency ? buildCardCurrency.exchange_rate : this.dataService.defaultCurrency.exchange_rate,
        code: (buildCardType || this.checkWhichPage()) && buildCardCurrency ? buildCardCurrency.code : this.dataService.defaultCurrency.code,
        custom_prototype_price: (user && user.currency && !this.dataService.checkIfCPEUser()) ? user.currency.custom_prototype_price : this.getDefaultCurrency()
      }
    };
  }

  getDefaultCurrency() {
    const buildCardStatus = this.buildCardEditMode || this.dataService.isIncompleteCardEditMode;
    if (buildCardStatus) {
      return this.dataService.buildCardData.currency.custom_prototype_price;
    } else {
      return this.dataService.defaultCurrency.custom_prototype_price;
    }
  }

  setPhasesInPricingHashForNewPricingLogic(build_phases) {
    if (build_phases && build_phases.length > 0) {
      this.dataService.homePageData.build_phases.forEach(phase => {
        if (phase.selected && phase.features.length > 0) {
          const phaseTitle = phase.title.toLowerCase().replace(/ /g, '_');
          if (phase.title !== 'MVP' && (phase['type'] !== 'custom_phase')) {
            this.buildCardSelections.build_card_phases[phaseTitle] = {
              platform_ids: this.getArrayOfOneFieldFromArrayOfObj(phase.platforms, 'id')
            };
            if (phase.speed) {
              this.buildCardSelections.build_card_phases[phaseTitle].speed = this.sendFilteredObject(phase.speed, 'speed');
            }
            this.buildCardSelections.build_card_phases[phaseTitle].id = phase.id;
            if ((phase.title === 'Professional Prototype') && phase.is_tailor_made) {
              this.buildCardSelections.build_card_phases[phaseTitle].is_tailor_made = phase.is_tailor_made;
            }
          } else {
            this.buildCardSelections.build_card_phases[phaseTitle] = {
              speed: this.sendFilteredObject(phase.speed, 'speed'),
            };
          }
          if (phase.features && phase.features.length > 0) {
            phase.features = this.uniqueArrayObjectSet(phase.features);
          }
          if ((phase['title'] === 'MVP') || (phase['type'] === 'custom_phase')) {
            phase.features = JSON.parse(JSON.stringify(phase.features));
            const features = phase.features.map(f => f.id);
            features.forEach(() => {
              phase.features.forEach(featureData => {
                if (featureData.phaseId?.length) {
                  const phaseIDs = featureData.phaseId.map(id => id.charAt(1));
                  phaseIDs.forEach(() => {
                    featureData.phaseId.forEach((ph, index1) => {
                      if (ph.charAt(0) !== phase.id.toString()) {
                        featureData.phaseId.splice(index1, 1);
                      }
                    });
                  });
                  const ftrPlatforms = featureData.platforms.map(p => p.id);
                  ftrPlatforms.forEach(() => {
                    featureData.platforms.forEach((plt, index2) => {
                      const ftrPhasePlatformsIDs = featureData.phaseId.map(id => id.charAt(1));
                      if (!ftrPhasePlatformsIDs.includes(plt.id.toString())) {
                        featureData.platforms.splice(index2, 1);
                      }
                    });
                  });
                }
              });
            });
            let customFeatures = phase.features.filter(obj => obj.user_requested === true);
            let coreFeatures = phase.features.filter(obj => (obj.user_requested === false) && (this.selectedFeatures.map(f => f.id).includes(obj.id)));
            customFeatures = this.sendFilteredArray(customFeatures, CUSTOMFEATURES, ['platforms']);
            coreFeatures = this.sendFilteredArray(coreFeatures, COREFEATURES, ['platforms']);
            this.buildCardSelections.build_card_phases[phaseTitle].features = [...coreFeatures, ...customFeatures];

            if (this.buildCardSelections.build_card_phases[phaseTitle].features && this.buildCardSelections.build_card_phases[phaseTitle].features.length > 0) {
              this.buildCardSelections.build_card_phases[phaseTitle].features.map(obj => {
                obj['platforms'] = this.getArrayOfOneFieldFromArrayOfObj(obj['platforms'], 'id');
                return obj;
              });
              this.buildCardSelections.build_card_phases[phaseTitle].features.map(obj => {
                obj['platform_ids'] = obj['platforms'];
                delete obj['platforms'];
                // console.log('obj for platforms- ', obj);
                return obj;
              });
            }
            this.buildCardSelections.build_card_phases[phaseTitle].is_parallel_development = phase.is_parallel_platform;
            this.buildCardSelections.build_card_phases[phaseTitle].id = phase.id;
            this.buildCardSelections.build_card_phases[phaseTitle].platforms = phase.platforms;
          }
        }
      });
    }
  }

  setFeaturesInPricingHash(priceMultiplier, weekMultiplier) {
    this.setParamsForCustomFeatures(priceMultiplier, weekMultiplier);
    if (this.selectedFeatures.length > 0) {
      let customFeatures = this.selectedFeatures.filter(obj => obj.user_requested === true);
      let coreFeatures = this.selectedFeatures.filter(obj => obj.user_requested === false);
      customFeatures = this.sendFilteredArray(customFeatures, CUSTOMFEATURES);
      coreFeatures = this.sendFilteredArray(coreFeatures, COREFEATURES);
      this.buildCardSelections['features'] = [...coreFeatures, ...customFeatures];
    }
  }

  setMandatoryFeatures(build_phases) {
    if (this.mandatoryFeatures.length) {
      if (build_phases[4].selected) {
        this.setIsMvpTrue(4);
        this.mandatoryFeaturesForOldAndNewPrice(build_phases, 4);
      } else if (build_phases[3].selected) {
        this.setIsMvpTrue(3);
        this.mandatoryFeaturesForOldAndNewPrice(build_phases, 3);
      }
    }
  }

  mandatoryFeaturesForOldAndNewPrice(build_phases, index) {
    if (this.dataService.homePageData.pricing_logic === 'new') {
      this.buildCardSelections['mvp_feature_ids'] = this.getArrayOfOneFieldFromArrayOfObj(build_phases[index].features.filter(feature => feature.is_mvp_feature), 'id');
    } else {
      this.buildCardSelections['mvp_features'] = build_phases[index].features.filter(feature => feature.is_mvp_feature);
    }
  }

  setHasForRentalPromotion(data) {
    const requestedFeatures = [];
    this.apiRequest.buildCardSectionForRental['build_card_phases'] = JSON.parse(JSON.stringify(this.apiRequest.buildCardSectionForRental['build_card_phases']));
    const buildCardPhases = Object.keys(data['build_card_phases']);
    if (this.appDataService.urlParameters.cpe_user) {
      const appFeatures = this.selectedApps[0].features['data'].map(ftr => parseInt(ftr.id));
      this.dataService.buildCardData.progress.features.forEach((feature, index) => {
        if (appFeatures.indexOf(feature.id) === -1) {
          requestedFeatures.push(feature);
        }
      });
      if (requestedFeatures.length) {
        const priceAadWeeks = this.calculateFeatureCostAndWeeks(requestedFeatures);
        for (const buildCardPhase of buildCardPhases) {
          for (const phase of Object.values(data['build_card_phases'])) {
            data['build_card_phases'][buildCardPhase].features = requestedFeatures;
            data['build_card_phases'][buildCardPhase].features_price = priceAadWeeks.price;
            data['build_card_phases'][buildCardPhase].features_weeks = priceAadWeeks.weeks;
          }
        }
        this.buildCardSelections['features'] = requestedFeatures;
      } else {
        for (const buildCardPhase of buildCardPhases) {
          for (const phase of Object.values(data['build_card_phases'])) {
            data['build_card_phases'][buildCardPhase].features = [];
          }
        }
        this.buildCardSelections['features'] = [];
      }
    }
  }

  setParamsForCustomFeatures(priceMultiplier, weekMultiplier) {
    const customPhases = {};
    for (const phase of Object.values(this.buildCardSelections.build_card_phases)) {
      if (phase['id'] > 5) {
        phase['price_multiplier'] = priceMultiplier;
        phase['week_multiplier'] = weekMultiplier;
        const index = Object.values(this.buildCardSelections.build_card_phases).indexOf(phase);
        if (Object.values(this.buildCardSelections.build_card_phases)[index] && Object.values(this.buildCardSelections.build_card_phases)[index]['platforms'].length
          && Object.values(this.buildCardSelections.build_card_phases)[index]['features'].length) {
          customPhases[Object.keys(this.buildCardSelections.build_card_phases)[index]] = Object.values(this.buildCardSelections.build_card_phases)[index];
        }
        delete this.buildCardSelections.build_card_phases[Object.keys(this.buildCardSelections.build_card_phases)[index]];
      }
    }
    this.buildCardSelections.build_card_phases['custom_phases'] = customPhases;
    if (!Object.keys(customPhases).length) {
      delete this.buildCardSelections.build_card_phases['custom_phases'];
    }
  }

  setIsMvpTrue(index) {
    const mandatoryFeatureIds = this.mandatoryFeatures.map(item => item.id);
    this.dataService.homePageData.build_phases[index].features.filter((feature) => {
      if (mandatoryFeatureIds.indexOf(feature.id) > -1) {
        feature.is_mvp_feature = true;
      }
    });
  }

  setDisabledAttrForSelectedFeatures() {
    const changedRequestFeatures = this.dataService.buildCardData.change_requests;
    const requestedFeaturesIDs = changedRequestFeatures.map(feature => feature.id);
    const approvedFeatures = this.dataService.buildCardData.progress.features.map(f => f.id);
    this.selectedFeatures.forEach((feature) => {
      const featureIndex = requestedFeaturesIDs.indexOf(feature.id);
      if (approvedFeatures.includes(feature.id) ? this.checkIfCountUpdate() : (featureIndex !== -1)) {
        feature.disabled = false;
      } else {
        feature.disabled = true;
      }
      return feature;
    });
  }

  checkIfCountUpdate() {
    const updatedFeature = [];
    this.selectedFeatures.forEach(f => {
      const ftr = this.dataService.buildCardData.progress.buildcardfeatures.find(el => el.feature_id === f.id);
      if (f.feature_count && (f.feature_count !== ftr.feature_count)) {
        updatedFeature.push(f);
      }
    });
    return (updatedFeature.length ? true : false);
  }

  setHashForRequestedFeatures(data) {
    if (!this.dataService.buildCardData.change_requests) { return; }
    const buildCardPhases = Object.keys(data.build_card_phases);
    const requestedFeatures: FeatureModel[] = [];
    const reqIds = [];
    const approvedFeatures = this.dataService.buildCardData.progress.features.map(f => f.id);
    let change_requests = [];

    if (this.dataService.buildCardData.change_requests.length > 0 && this.dataService.buildCardData.change_requests[0].features) {
      const featuress = this.dataService.buildCardData.change_requests.filter(x => x.status === 'pending');
      if (featuress.length) {
        let customReqFeatures = featuress[0]['features'].filter(obj => obj.user_requested === true);
        let coreReqFeatures = featuress[0]['features'].filter(obj => obj.user_requested === false);
        customReqFeatures = this.sendFilteredArray(customReqFeatures, CUSTOMFEATURES);
        coreReqFeatures = this.sendFilteredArray(coreReqFeatures, 'features');
        change_requests = [...coreReqFeatures, ...customReqFeatures];
      }
    } else {
      let customReqFeatures = this.dataService.buildCardData.change_requests.filter(obj => obj.user_requested === true);
      let coreReqFeatures = this.dataService.buildCardData.change_requests.filter(obj => obj.user_requested === false);
      customReqFeatures = this.sendFilteredArray(customReqFeatures, CUSTOMFEATURES);
      coreReqFeatures = this.sendFilteredArray(coreReqFeatures, 'features');
      change_requests = [...coreReqFeatures, ...customReqFeatures];
    }
    if (change_requests && change_requests.length > 0) {
      this.dataService.additionalFeatures.forEach(feature => {
        const ftr = change_requests.find(f => feature['id'] === f.id);
        if (ftr && feature['feature_count']) {
          ftr.feature_count = feature['feature_count'];
          ftr.repeatable_count = 1;
        }
      });
    }
    if ((this.selectedFeatures.length === this.dataService.buildCardData.progress.features.length) && !this.checkIfCountUpdate()) {
      this.setDisabledAttrForSelectedFeatures();
    }
    const selectedFeatures = JSON.parse(JSON.stringify(this.selectedFeatures));
    let customFeatures = selectedFeatures.filter(obj => obj.user_requested === true);
    let coreFeatures = selectedFeatures.filter(obj => obj.user_requested === false);
    customFeatures = this.sendFilteredArray(customFeatures, CUSTOMFEATURES);
    coreFeatures = this.sendFilteredArray(coreFeatures, 'features');
    const allFeatures = [...coreFeatures, ...customFeatures];
    if (change_requests && change_requests.length > 0) {
      change_requests.forEach(feature => {
        const ftr = allFeatures.find(f => feature['id'] === f.id);
        if (!ftr) { allFeatures.push(ftr); }
      });
    }
    for (const feature of allFeatures) {
      if (feature.selected && !feature.disabled) {
        delete feature['disabled'];
        if (reqIds.indexOf(feature.id) === -1) {
          requestedFeatures.push(feature);
          reqIds.push(feature.id);
        }
      }
    }
    if (requestedFeatures.length) {
      const features = this.dataService.additionalFeatures.length ? this.dataService.additionalFeatures : this.selectedFeatures;
      features.forEach(feature => {
        const ftr = requestedFeatures.find(f => feature['id'] === f.id);
        if (ftr && feature['feature_count']) {
          ftr.feature_count = feature['feature_count'];
          ftr[REPEATABLE_COUNT] = feature[REPEATABLE_COUNT];
        }
      });
      const priceAadWeeks = this.calculateFeatureCostAndWeeks(requestedFeatures);
      this.dataService.additionalFeatureIds = [];
      this.dataService.additionalFeatures = requestedFeatures;
      this.dataService.additionalFeatures.forEach(x => {
        const addedFeature = this.dataService.buildCardData['progress']['features'].find(fe => fe.id === x.id);
        if (addedFeature) {
          const feature = { feature_id: x.id, feature_set_id: x.feature_set_id };
          if (x['feature_count']) {
            feature['feature_count'] = (x['feature_count'] + addedFeature.feature_count);
          }
          this.dataService.additionalFeatureIds.push(feature);
        } else {
          const feature = { feature_id: x.id, feature_set_id: x.feature_set_id };
          if (x['feature_count']) {
            feature['feature_count'] = x['feature_count'];
          }
          this.dataService.additionalFeatureIds.push(feature);
        }
      });
      for (const buildCardPhase of buildCardPhases) {
        for (const phase of Object.values(data.build_card_phases)) {
          if ((buildCardPhase === 'mvp') || buildCardPhase.includes('new_phase')) {
            requestedFeatures.forEach(feature => feature.platforms = data.build_card_phases[buildCardPhase].platforms);
            data.build_card_phases[buildCardPhase].features = requestedFeatures;
            if (data.build_card_phases[buildCardPhase].features && data.build_card_phases[buildCardPhase].features.length > 0) {
              data.build_card_phases[buildCardPhase].features.map(obj => {
                obj['platforms'] = this.getArrayOfOneFieldFromArrayOfObj(obj['platforms'], 'id');
                return obj;
              });
              data.build_card_phases[buildCardPhase].features.map(obj => {
                obj['platform_ids'] = obj['platforms'];
                delete obj['platforms'];
                // console.log('obj for platforms- ', obj);
                return obj;
              });
            }
          }
          data.build_card_phases[buildCardPhase].features_price = priceAadWeeks.price;
          data.build_card_phases[buildCardPhase].features_weeks = priceAadWeeks.weeks;
        }
      }
      this.buildCardSelections['features'] = requestedFeatures;
    } else {
      for (const buildCardPhase of buildCardPhases) {
        for (const phase of Object.values(data.build_card_phases)) {
          data.build_card_phases[buildCardPhase].features = [];
        }
      }
      this.dataService.additionalFeatures = [];
      this.buildCardSelections['features'] = [];
    }

  }

  setPromotionInBuildCardHash() {
    const promotion = this.getPromotionObject();
    if (promotion && promotion.code) {
      this.buildCardSelections.promotion = promotion;
      if (promotion.features) {
        const promotionFeatureIds = [];
        promotion.features.forEach(feature => {
          promotionFeatureIds.push(feature.id);
        });
        this.buildCardSelections.promotion.feature_ids = promotionFeatureIds;
      }
      if (promotion.platforms) {
        const promoPlatformIds = [];
        promotion.platforms.forEach(pltfrm => {
          promoPlatformIds.push(pltfrm.id);
        });
        this.buildCardSelections.promotion.platform_ids = promoPlatformIds;
      }

      if (promotion.applications && promotion.applications.length > 0) {
        const promoAppIds = [];
        promotion.applications.forEach(app => {
          promoAppIds.push(app.id);
        });
        this.buildCardSelections.promotion.application_ids = promoAppIds;
      }
      this.selectedHash.promotion_id = promotion.id;
    }
  }

  setMultiplierExperimentInBuildCardHash() {
    const urlParameters = this.appDataService.urlParameters;
    if (urlParameters.expCode || this.dataService.buildCardData.multiplier_experiment) {
      this.dataService.multiplierExperiment = (this.dataService.isEditBuildCard || this.dataService.isIncompleteCardEditMode || this.isFeatureRequest) ?
        this.dataService.buildCardData.multiplier_experiment : this.dataService.homePageData.multiplier_experiment;
      let multiplierExperiment = this.dataService.multiplierExperiment;
      const buildCardMultiplier = this.dataService.buildCardData.multiplier_experiment;
      if (((buildCardMultiplier && buildCardMultiplier.is_default && this.dataService.isUnpaidCard()) && this.dataService.checkIfBuildCardisinvited()) || !buildCardMultiplier) {
        multiplierExperiment = this.dataService.homePageData.multiplier_experiment;
      } else {
        multiplierExperiment = buildCardMultiplier;
      }
      if (multiplierExperiment) {
        this.buildCardSelections.duration_multiplier = multiplierExperiment.duration_multiplier ? parseFloat(multiplierExperiment.duration_multiplier) : '';
        this.buildCardSelections.price_multiplier = multiplierExperiment.price_multiplier ? parseFloat(multiplierExperiment.price_multiplier) : '';
        this.buildCardSelections.custom_price_multiplier = multiplierExperiment.custom_price_multiplier ? parseFloat(multiplierExperiment.custom_price_multiplier) : '';
      }
    }
    this.selectedHash.pricing_params = this.buildCardSelections;
    this.selectedHash.currency_id = this.dataService.homePageData.currency.id;
  }

  public setDiscountPrice(buildCardData) {
    if (buildCardData) {
      if (this.getPriceForCards(buildCardData) === this.getDiscountedPriceForCards(buildCardData) && buildCardData.actual_weeks === buildCardData.weeks && !this.isDiscountAppliedForRental()
        && (this.dataService.manualPromotion && (this.dataService.manualPromotion.type !== 'SpecialEventPromotion'))) {
        if (this.promotionValid && this.promotionApplied && this.isManualPromotionApplied) {
          if (this.dataService.manualPromotion) {
            this.dataService.manualPromotion = null;
            if (this.dataService.modifiedBuildCardData) {
              this.dataService.modifiedBuildCardData.promotion.add = null;
            }
          }
          this.setPromoErrorMessage();
        }
      }
      this.finalPricenWeeks = {
        total: (this.dataService.buildCardData && this.dataService.buildCardData.id && !this.isFeatureRequest) ? (this.getPriceForCards(buildCardData) + this.getChangeAmount()) : this.getPriceForCards(buildCardData),
        actual_weeks: buildCardData.actual_weeks,
        weeks: buildCardData.weeks,
        bcard_discounted_price: (this.dataService.buildCardData && this.dataService.buildCardData.id && !this.isFeatureRequest) ? (this.getDiscountedPriceForCards(buildCardData) + this.getChangeAmount()) : this.getDiscountedPriceForCards(buildCardData),
        first_delivery_time: buildCardData.first_delivery_time
      };
      this.pricePerWeeksCalculation(buildCardData);

      this.discountedPrice = (this.dataService.buildCardData && this.dataService.buildCardData.id && !this.isFeatureRequest) ? (this.getDiscountedPriceForCards(buildCardData) + this.getChangeAmount()) : this.getDiscountedPriceForCards(buildCardData);

      if (this.isFeatureRequest) {
        this.dataService.totalAdditionalFeaturesCost = this.finalPricenWeeks.total;
        this.dataService.totalAdditionalFeaturesWeek = this.finalPricenWeeks.weeks;
        this.dataService.totalAdditionalsupportMaintenance = buildCardData.builder_care_price;
      }
      if (this.isDiscountApplied() || this.isDiscountAppliedForRental() ||
        (this.dataService.manualPromotion && (this.dataService.manualPromotion.type === 'SpecialEventPromotion'))) {
        const promotion = this.getPromotionObject();
        if (promotion && promotion.code) {
          this.finalPromoApplied = promotion.code;
          this.promotionValid = true;
          this.promotionError = false;
          this.promotionApplied = true;
        }
      }
    }
  }

  public setPromoErrorMessage() {
    if ((this.dataService.manualPromotion && this.dataService.manualPromotion.code) || this.dataService.homePageData.promotion) {
      if (this.dataService.homePageData.promotion && this.dataService.homePageData.promotion.error_message) {
        this.promotionError = this.dataService.homePageData.promotion.error_message;
      } else if (this.dataService.manualPromotion && this.dataService.manualPromotion.error_message) {
        this.promotionError = this.dataService.manualPromotion.error_message;
      } else {
        this.promotionError = Constants.promotionError;
      }
    } else {
      this.promotionError = Constants.promotionError;
    }
    this.dataService.showHideLoader(false);
  }

  setDurationPriceInSelectedFeatures(selectedFeatures) {
    if (!this.featureData) {
      this.featureData = [];
    }
    selectedFeatures.forEach(sFeature => {
      const feature = this.featureData.find(f => f.id === sFeature.id);
      if (feature) {
        sFeature['breakup_duration'] = feature['duration'];
        if (this.isDiscountApplied()) {
          sFeature['breakup_price'] = parseFloat(feature['discounted_customisation'] + feature['discounted_fixed']).toFixed(2);
        } else {
          sFeature['breakup_price'] = parseFloat(feature['customisation'] + feature['fixed']).toFixed(2);
        }
        if (this.isStoreFeatureRequest) {
          const discount = (sFeature['breakup_price'] * this.dataService.homePageData.store_change_request_discount);
          sFeature['breakup_price'] = sFeature['breakup_price'] - discount;
        }
      }
    });
  }

  public getBuildCardPrice(buildCardSelections) {
    if (this.pricingSubscrition) {
      this.pricingSubscrition.unsubscribe();
    }
    const ifNotCarePlus = ((buildCardSelections.care_type === 'standard_care') || (buildCardSelections.care_type === 'enterprise_care'));
    if (this.dataService.buildCardData.subscription_flow && (!this.selectedHash.is_under_support || ifNotCarePlus)) {
      this.selectedHash.is_under_support = false;
      delete buildCardSelections.care_type;
    }
    if (this.isFeatureRequest && !this.isPlatformRequest) {
      if (buildCardSelections['build_card_phases']['full_build']) {
        delete buildCardSelections['build_card_phases']['mvp'];
      }
      delete buildCardSelections['build_card_phases']['custom_phases'];
    }
    this.pricingSubscrition = this.apiRequest.getBuildCardPrice(buildCardSelections)
      .subscribe(
      (data) => {
        this.priceLoader = false;
        this.dataService.errorMessage = false;
        this.preventClick = false;
        this.setDiscountPrice(data);
        this.phaseData = new Array<PhaseDataModel>();
        this.featureData = [];
        this.totalDiscountedPriceWithCare = data['bcard_discounted_price']
        this.customizationCost = data['customisation_price'];
        this.customFeaturesPrice = data['custom_feature_price'];
        this.featureReusableCost = data['reusable_features_price'];
        this.subscriptionCost = data['subscription_price'];
        this.subscriptionDuration = data['subscription_duration'];
        this.monthlySubscriptionPrice = data['monthly_subscription_price'];
        this.totalCarePrice = data['total_care_price'];
        this.builderCarePrice = data['builder_care_price'];
        this.featureData = data['feature_data'];
        this.carePrices = data['care_prices'];
        this.dataService.priceWithoutCareAndSubs = data['price'];
        if (data['speed_prices']) {
          this.speedPrices = data['speed_prices'];
        }
        if (this.featureData) {
          this.setDurationPriceInSelectedFeatures(this.selectedFeatures);
        } else {
          this.selectedFeatures.forEach(sFeature => {
            delete sFeature['breakup_duration'];
            delete sFeature['breakup_price'];
          });
        }
        this.isShowLoaderOnCustomFeatureFooter = false;
        if (data.phase_data && data.phase_data.length > 0) {
          data.phase_data.forEach(phase => {
            const tempPhase = new PhaseDataModel();
            for (const key in phase) {
              tempPhase[key] = phase[key];
            }
            this.phaseData.push(tempPhase);
          });
        }

        this.supportMaintenance = data.builder_care_price;
        this.getNewPhaseDates();
        this.phaseBreakupData = [];
        this.dataService.homePageData.build_phases.forEach(phase => {
          if (phase.selected) {
            if (this.phaseData && this.phaseData.length > 0) {
              const phaseArr = this.phaseData.filter(phasedata => (phasedata.id === phase.id));
              if (phaseArr && phaseArr.length > 0) {
                phase.delivery_time = phaseArr[0].delivery_time;
                phase.price = phaseArr[0].price;
                phase.weeks = phaseArr[0].weeks;
              }
            }
          }
        });
        this.phaseBreakupData = Object.assign([], [...this.dataService.homePageData.build_phases]);
        this.showHideBottomBarLoader = false;
        this.showSpeedLoader = false;
        this.handleLoaderOnPrice.next(null);

        if ((this.isDiscountApplied() && this.dataService.manualPromotion) || this.finalPricenWeeks.actual_weeks > this.finalPricenWeeks.weeks
          || this.isDiscountAppliedForRental()) {
          this.sideNavClose.emit(true);
        }
        if (this.dataService.showAppDetailsLoader) {
          this.dataService.showAppDetailsLoader = false;
        }
        this.dataService.showPriceLoader = false;
        this.dataService.showBuilderCarePriceLoader = false;
        this.dataService.removePromoLoader = false;
        if (!this.dataService.isFromViewPrototype) {
          this.dataService.showHideLoader(false);
        }

      },
      error => {
        this.dataService.errorMessage = true;
        return false;
      });
  }

  public getNewPhaseDates() {
    this.selectedPhases = this.selectedPhases.filter(phase => phase.title !== 'Full Build').concat(this.selectedPhases.filter(phase => phase.title === 'Full Build'))

    const phasesCount = this.selectedPhases.length;
    if (this.selectedPhases && this.selectedPhases.length > 0) {
      this.sortPhases();
      let elapsedWeeks = 0;
      let moreThan1Weeks = 0;
      this.selectedPhases.forEach((phase, index) => {
        if (this.phaseData && this.phaseData.length > 0) {
          let phaseDataArr = [];
          phaseDataArr = this.phaseData.filter(data => (data.id === phase.id));
          if (phaseDataArr && phaseDataArr.length > 0) {
            if (this.isPlatformRequest && this.dataService.buildCardData?.progress?.buildcardphases && (this.dataService.buildCardData?.progress?.buildcardphases.length > 0)) {
              let buildPhases = this.dataService.buildCardData?.progress?.buildcardphases;
              let buildCardPhase = buildPhases.filter(data => (data.build_phase_id === phaseDataArr[0].id));
              if (buildCardPhase && buildCardPhase.length > 0) {
                phase.delivery_time = phaseDataArr[0].delivery_time + buildCardPhase[0].delivery_time;
              }
            } else {
              phase.delivery_time = phaseDataArr[0].delivery_time;
            }

            phase.calculated_weeks = Math.ceil(phaseDataArr[0].delivery_time / 7);
            elapsedWeeks += phase.calculated_weeks;

            if (index === 0) {
              phase.initial_date = this.isPlatformRequest? this.moment(this.dataService.buildCardData.kick_off_date) : this.moment(this.selectedHash.pre_kick_off_date);
            } else {
              phase.initial_date = this.moment(this.selectedPhases[index - 1].final_date);
            }
            phase.final_date = this.moment(phase.initial_date).add(phase.delivery_time, 'days');
          }
        }
      });

      let counter = 0;
      while (elapsedWeeks > this.finalPricenWeeks.weeks && this.selectedPhases.some(ph => ph.calculated_weeks > 1)) {
        const phase = this.selectedPhases[counter];

        if (phase.calculated_weeks > 1) {
          phase.calculated_weeks--;
          elapsedWeeks--;
        }
        counter = (counter + 1) % phasesCount;
      }

      if (this.selectedPhases[this.selectedPhases.length - 1]['type'] === 'custom_phase') {
        this.selectedPhases.forEach(phase => {
          const lastCustomPhaseDate = this.selectedPhases[this.selectedPhases.length - 1].final_date
          if (phase.id === 5) {
            this.selectedPhases[this.selectedPhases.length - 1].final_date = phase.final_date;
            phase.final_date = lastCustomPhaseDate;
          }
        });
      }
    }
  }

  public checkForUnsavedChanges() {
    if (this.selectedHash.feature_ids.length === 0 && this.selectedHash.application_ids.length === 0) {
      this.dataService.unsavedChanges = false;
    }
  }

  public getFinalDeliveryDate() {
    let daysOfAllPhases: any;
    if (this.selectedPhases && this.selectedPhases.length > 0) {
      this.selectedPhases.forEach((phase) => {
        if (!phase.final_date) {
          this.getNewPhaseDates();
        }
        if (!daysOfAllPhases) {
          daysOfAllPhases = phase.final_date;
        } else if (daysOfAllPhases < phase.final_date) {
          daysOfAllPhases = phase.final_date;
        }
      });
      return daysOfAllPhases ? this.moment(daysOfAllPhases).format('LL') : '';
    }
    return this.moment().format('LL');
  }


  public getFormattedDate(final_date: any) {
    return this.moment(final_date).format('DD-MMM-YYYY');
  }

  public checkIdlestate() {
    if (this.commonService.isPlatformBrowser) {
      setTimeout(() => {
        this.showSignupPopup();
      }, 5000);
    }
  }

  public showSignupPopup() {
    if (!this.dataService.user && this.signupPopup === false && this.clickedsignupPopup === false) {
      this.signupPopup = true;
    }
  }

  public hidesignupPopup() {
    this.signupPopup = false;
  }

  public setPromotionObject(couponcode) {
    if (couponcode && this.dataService.experimentPromotion[couponcode]) {
      this.dataService.couponObject = this.dataService.experimentPromotion[couponcode];
      this.dataService.couponName = this.dataService.couponObject.code.toUpperCase();
      this.promotionExpiryDays = this.promotionExpiry(this.dataService.couponObject.expires_at);
    }
  }

  public formatDate(date) {
    const thedate = (date == 'today' ? new Date() : new Date(date));
    let d = thedate,
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  public promotionExpiry(expiry_date) {
    if (expiry_date) {
      const theDate = expiry_date.split('-');
      expiry_date = this.formatDate(new Date(theDate[0], theDate[1] - 1, theDate[2]));
      const current_date = this.formatDate('today');
      return (Math.floor((Date.parse(expiry_date) - Date.parse(current_date)) / 86400000) + 1);
    }
    return 0;
  }

  public showSignUpForDiscount(applicationName: string, isDiscountApplied: boolean, isAppLocked: boolean, signUpClickedSrc?: string) {
    this.dataService.signupSrcName = signUpClickedSrc;
    this.popupPromocode = Constants.promotionCodeType.Application;
    this.setPromotionObject(Constants.promotionCodeType.Application);
    this.showclose = true;
    this.isAppDiscountApplied = isDiscountApplied;
    this.showSignin = false;
    this.openCommonAuth = true;
    this.showRegister = true;
    this.isAppLocked = isAppLocked;
  }

  public isDiscountApplied() {
    if (this.dataService.modifiedBuildCardData) {
      if (this.dataService.modifiedBuildCardData.promotion.add) {
        return true;
      } else {
        return false;
      }
    } else {
      return (
        (this.discountedPrice < this.finalPricenWeeks.total) || (this.finalPricenWeeks.weeks < this.finalPricenWeeks.actual_weeks));
    }
  }

  public weeksPromotionOnly() {
    return (this.finalPricenWeeks.weeks < this.finalPricenWeeks.actual_weeks && this.discountedPrice === this.finalPricenWeeks.total);
  }

  public showApplicationDiscount() {
    return (!this.dataService.user && !this.showLockDiscount && this.dataService.experimentPromotion.gamify && this.dataService.couponObject && !this.noexperimentpromotions && !this.isDiscountApplied());
  }

  public timerStart() {
    this.timer = new Date().getTime();
  }

  public stopAndCalcTime() {
    return new Date().getTime() - this.timer;
  }

  public getUrl(url: string) {
    return url;
  }

  public getInstallmentCount() {
    if (this.installments && this.installments.length > 0) {
      this.installmentCount = 0;
      const installments = this.installments;
      for (let i = 0; i < installments.length; i++) {
        if (installments[i].type === 'installment' && installments[i].installment_amount > 0) {
          this.installmentCount++;
        }
      }
    }
    return this.installmentCount;
  }

  public handlePageExitTimer() {
    if (this.commonService.isPlatformBrowser) {
      setInterval((value) => {
        this.timeSpent += 1;
      }, 1000);
    }
  }

  public updateBuildCardPromotion(isApplied, fromPayment?) {
    this.inProgressBuildCardId = this.dataService.buildCardData.id;
    this.createBuildCardPriceHash();
    const updatedData: any = {};
    if (isApplied) {
      updatedData['promotion_id'] = this.dataService.manualPromotion.id;
    } else {
      updatedData['promotion_id'] = null;
    }
    updatedData['pricing_params'] = this.selectedHash.pricing_params;
    if (!this.selectedHash.is_under_support) {
      delete updatedData.pricing_params.care_type;
    }

    if (this.checkIfStudioStorePromoModule() && this.isRentalBuildCard()) {
      updatedData['source'] = this.selectedHash.source;
    }
    const donationOpted = this.dataService.buildCardData.donation_opted;
    const donationPercentage = this.dataService.buildCardData.donation_percentage;
    this.apiRequest.createUpdateBuildCard(updatedData, this.inProgressBuildCardId, '', 'promotionApply')
      .finally(() => {
        const card = this.dataService.buildCardData;
        if (!card.donation_amount && (card.status === 'card_completed')) {
          this.dataService.buildCardData.donation_opted = donationOpted;
          this.dataService.calculateDonationAmount(donationOpted ? donationPercentage : 5);
        }
        if (isApplied) {
          this.promotionLoader = false;
          if (!this.promotionError) {
            if (card.promotion.type !== 'SpecialEventPromotion') {
              this.openSideNav = false;
            }
            this.isShowPaymentExitOffer = false;
            this.showHideBottomBarLoader = false;
            this.dataService.showHideLoader(false);
          }
        } else {
          this.isShowPaymentExitOffer = false;
          this.dataService.showHideLoader(false);
        }
      })
      .subscribe((data: any) => {
        if (this.dataService.buildCardData.promotion) {
          this.promotionValid = true;
          this.promotionApplied = true;
          this.isManualPromotionApplied = true;
        } else {
          if (isApplied) {
            this.setPromoErrorMessage();
            if (this.dataService.manualPromotion) {
              this.dataService.manualPromotion = null;
            }
          }
        }
        if (!this.dataService.modifiedBuildCardData) {
          this.setDiscountPrice(this.dataService.buildCardData);
        } else {
          this.setPromotionAfterSecurityPaid();
        }
        if ((this.dataService.currentPage !== 'features') && (this.dataService.currentPage !== 'delivery')) {
          this.fetchProposalInstallments();
        }
        this.getBuildCardPriceWithDiscount();
        if (!this.promotionError && !this.dataService.modifiedBuildCardData) {
          if (this.dataService.buildCardData.promotion) {
            this.flatPrice = this.dataService.buildCardData.promotion.discount;
            this.monthsDiscountForRental = this.dataService.buildCardData.promotion.months_discount;
          } else {
            this.unsetPromotion();
          }
          if (fromPayment) {
            this.fetchInstallments(fromPayment);
          }
        }

      });
  }

  setPromotionAfterSecurityPaid() {
    if (this.dataService.modifiedBuildCardData.promotion.add) {
      this.promotionError = null;
      this.setPromotionInFinalPromoVariable(this.dataService.modifiedBuildCardData.promotion.add);
    } else {
      this.emptyPromoVariables();
      this.dataService.buildCardData.promotion = null;
    }
  }

  setPromotionInFinalPromoVariable(promotion) {
    this.finalPromoApplied = promotion.code;
    this.promotionValid = true;
    this.promotionApplied = true;
    this.dataService.buildCardData.promotion = promotion;
    this.dataService.homePageData.promotion = promotion;
    this.selectedHash.promotion_id = promotion.id;
    this.dataService.manualPromotion = promotion;
  }

  checkIfNotEntUser() {
    return (this.dataService.user && !this.dataService.user.enterprise_lead);
  }


  public applyRemovePromotion(code, isApplied, fromPayment?) {
    if (this.dataService.modifiedBuildCardData) {
      code = this.dataService.modifiedBuildCardData.promotion.add ? this.dataService.modifiedBuildCardData.promotion.add.code : code;
    }
    if (code && code.length <= 20) {
      this.finalPromoApplied = code;
      if (!fromPayment) {
        this.promotionChangedOnMaxBuildCard = true;
      }
      if (isApplied) {
        this.promotionLoader = true;
      } else {
        this.dataService.showHideLoader(true);
      }
      this.promotionError = null;
      if (code && isApplied) {
        const promoErrMsg = 'Oops! Promo Code entered is Invalid.';
        this.apiRequest.fetchPromotionData(code, this.selectedHash.is_advance, this.finalPricenWeeks.weeks).subscribe(data => {
          if (data.message || (this.checkIfNotEntUser() && this.dataService.checkIfBuildCardisinvited() && data.promotion.is_enterprise_promo)) {
            this.promotionError = data.message ? data.message : promoErrMsg;
            this.promotionLoader = false;
            data.promotion = null;
            this.dataService.manualPromotion = null;
          } else {
            if (data.promotion) {

              if (this.checkIfStudioStorePromoModule() && this.isRentalBuildCard()) {
                if (data.promotion.type !== 'MonthDiscountPromotion') {
                  this.promotionLoader = false;
                  this.promotionError = promoErrMsg;
                  return;
                }
              }
              this.dataService.manualPromotion = data.promotion;
              if (data.promotion.expires_at) {
                this.promotionExpiryDays = this.promotionExpiry(data.promotion.expires_at);
              } else {
                this.promotionExpiryDays = 0;
              }
              this.updateBuildCardPromotion(isApplied, fromPayment);
            }
          }
        });
      } else {
        this.promotionValid = false;
        this.selectedHash.promotion_id = null;
        this.promotionApplied = false;
        this.isManualPromotionApplied = false;
        this.appliedPromotion = false;
        this.manualPromoCode = null;
        this.dataService.manualPromotion = null;
        this.dataService.homePageData.promotion = null;
        this.monthsDiscountForRental = null;
        this.updateBuildCardPromotion(false, fromPayment);
      }

    }

  }


  public unsetPromotion() {
    this.promotionValid = false;
    this.selectedHash.promotion_id = null;
    this.promotionApplied = false;
    this.isManualPromotionApplied = false;
    this.appliedPromotion = false;
    this.manualPromoCode = null;
    this.dataService.manualPromotion = null;
    this.dataService.homePageData.promotion = null;
    this.promotionError = null;
  }

  public fillInstallmentData() {
    this.paymentData.general_info = this.dataService.changeFeatureRequestModel.general_info;
    this.paymentData.installments = this.dataService.changeFeatureRequestModel.installments.filter(installment => installment.type !== 'custom_prototype');
    if (this.paymentData) {
      if (this.paymentData.general_info) {
        this.totalWeeks = this.paymentData.general_info.weeks ? this.paymentData.general_info.weeks : 0;
      }
      if (this.paymentData.installments && this.paymentData.installments.length > 0) {
        this.noOfInstallments = this.paymentData.installments.length;
      }
      this.dateOfFirstPayment = this.pipe.transform(this.paymentData.installments[0].due_date, 'dd-MMM-y')
    }
    this.installments = this.paymentData.installments;
    this.installmentAmt = this.installments && this.installments[0] && this.installments[0].installment_amount ? this.installments[0].installment_amount : 0;
    this.getInstallmentCount();
  }

  setAddFeaturePayload() {
    const payload = { card_id: this.inProgressBuildCardId };
    if (this.dataService.buildCardData.subscription_flow) {
      payload['subscription_price'] = this.subscriptionCost;
      if (this.selectedHash.is_under_support) {
        payload['builder_care_amount'] = this.carePrices[this.selectedHash.care_type];
      }
    }
    return payload;
  }

  public fetchInstallments(fromPayment?, additionalFeatureInstallments?) {
    if ((this.dataService.currentPage !== 'summary') && !this.showRentalPaymentFlow && (additionalFeatureInstallments || this.inProgressBuildCardId)) {
      this.dataService.showHideLoader(true);
    }
    if (additionalFeatureInstallments) {
      if (this.isFeatureRequest || this.dataService.onPageReload) {
        this.apiRequest.fetchAdditionalFeatureInstallments(this.setAddFeaturePayload(), this.isPlatformRequest).subscribe((data: any) => {
          if (data) {
            const changeFeatureRequestModel: ChangeFeatureRequestModel = new ChangeFeatureRequestModel();
            this.dataService.changeFeatureRequestModel =
              this.modelMapperService.getAdditionalFeaturesModelMapped(changeFeatureRequestModel, data.data.attributes);
            this.fillInstallmentData();
          }
          this.isInstallmentsAPIResolved = true;
          this.getBuildCardPriceWithDiscount();
          this.dataService.showHideLoader(false);

        }, (error) => {
          this.isInstallmentsAPIResolved = true;
          this.getBuildCardPriceWithDiscount();
          if (this.commonService.isPlatformBrowser && (error.status === 403 || error.status === 401 || error.status === 404)) {
            this.dataService.invalidAccess = true;
            this.router.navigate(['invalidaccess']);
          }
        });
      } else {
        this.fillInstallmentData();
        this.isInstallmentsAPIResolved = true;
        this.getBuildCardPriceWithDiscount();
        this.dataService.showHideLoader(false);
      }
    } else {
      if (this.inProgressBuildCardId) {
        this.apiRequest.fetchInstallments(this.inProgressBuildCardId, this.paidPhaseId).subscribe((data: any) => {
          if (data) {
            this.paymentData = data;
            if (this.getPriceForCards(this.dataService.buildCardData) !== this.getDiscountedPriceForCards(this.dataService.buildCardData)) {
              this.costWithoutDiscount =
                Number(this.getPriceForCards(this.dataService.buildCardData)) +
                Number(Math.round(((Number(this.getPriceForCards(this.dataService.buildCardData)) * Number(this.paymentData.general_info.tax))
                  / 100))
                );
            }
            if (this.paymentData) {
              if (this.paymentData.general_info) {
                this.totalWeeks = this.paymentData.general_info.weeks ? this.paymentData.general_info.weeks : 0;
              }
              if (this.paymentData.installments && this.paymentData.installments.length > 0) {
                this.noOfInstallments = this.paymentData.installments.filter(installment => installment.type === 'installment').length;
              }
              if (this.paymentData.pending_installment) {
                if (this.paymentData.pending_installment.invoice_params
                  && this.paymentData.pending_installment.invoice_params.invoice) {
                  this.dateOfFirstPayment = this.pipe.transform(this.paymentData.pending_installment.invoice_params.invoice.date, 'DD-MMM-YYYY');
                }
              }
            }
            this.installments = this.paymentData.installments.filter((installment) => {
              return installment.type === 'installment';
            });

            this.deposit = this.paymentData.installments.find(installmemt => {
              return installmemt.type === 'deposit';
            });

            this.supportMaintenance = this.paymentData.installments.find(installment => {
              return installment.type === 'support';
            });
            if (this.supportMaintenance) {
              this.builderCareAmount = this.supportMaintenance;
            } else if (this.dataService.buildCardData.subscription_flow) {
              this.builderCareAmount = this.dataService.buildCardData.support_maintenance_price;
            }
            this.installmentAmt = this.installments && this.installments[0] && this.installments[0].installment_amount ? this.installments[0].installment_amount : 0;
            this.getInstallmentCount();
          }
          this.isInstallmentsAPIResolved = true;
          this.getBuildCardPriceWithDiscount();
          if (fromPayment) {
            this.showCouponLoader = false;
            this.dataService.showHideLoader(false);
          }
        }, (error) => {
          this.isInstallmentsAPIResolved = true;
          this.getBuildCardPriceWithDiscount();
          if (fromPayment) {
            this.showCouponLoader = false;
            this.dataService.showHideLoader(false);
          }
        });
      }
    }
  }

  removeLoader() {
    if (this.isInstallmentsAPIResolved && this.isContractApiResolved) {
      if (!this.showCouponLoader) {
        this.dataService.showHideLoader(false);
      }
      return true;
    } else {
      return false;
    }
  }

  public setTheBuildCard() {
    this.setPromotionData();
    this.getEditedBuildCardTimeline(this.dataService.buildCardData);
    this.setUpBuildCard();
  }

  public setUpBuildCard() {
    if (this.dataService.buildCardData && !this.dataService.buildCardData.owner) {
      this.dataService.defaultCurrency = this.dataService.buildCardData.currency;
    }
    this.showNDA = true;
    this.summaryScreen = true;
    this.dataService.unsavedChanges = false;
  }

  public setPromotionData() {
    if (this.dataService.buildCardData.promotion) {
      this.manualPromoCode = this.dataService.buildCardData.promotion.code;
      this.dataService.manualPromotion = this.dataService.buildCardData.promotion;
      this.appliedPromotion = true;
    }
  }

  public fetchContractDetails(uniqueCode) {
    this.checkForDisabledPayment();
    this.isContractApiResolved = true;
  }

  public checkForDisabledPayment() {
    if (this.checkIfAllContractTermsSelected()) {
      this.trackEventForObject(this.analyticsSegment, "t&cs_confirmed", {
        user_id: this.dataService.user ?.id
      });
      this.enablePayment = true;
    } else {
      this.enablePayment = false;
    }
    this.checkIfAddressSubmitted();
    if (this.enablePayment && this.addressSubmitted) {
      this.isValidToProceedForPayment = true;
      this.proceedToContinuePayment = true;
    } else {
      this.isValidToProceedForPayment = false;
      this.proceedToContinuePayment = false;
      if (this.dataService.currentPage === 'payConfirm') {
        this.router.navigate([`buildcard-payment/${this.inProgressBuildCardId}/` + 'billingdetails']);
      }
    }
  }

  public checkIfAddressSubmitted() {
    if (!(this.dataService.user && this.dataService.user.address &&
      this.dataService.user.address.first_name && this.dataService.user.address.last_name &&
      this.dataService.user.address.phone && this.dataService.user.address.street &&
      this.dataService.user.address.pincode && this.dataService.user.address.country && this.dataService.user.address.city)) {
      this.addressSubmitted = false;
    } else if (this.ifNotUkOrCyprus() && !this.dataService.user.address.state) {
      this.addressSubmitted = false;
    } else {
      this.trackEventForObject(this.analyticsSegment, "billing_details_entered", {
        user_id: this.dataService.user ?.id
      });
      this.addressSubmitted = true;
    }
  }

  ifNotUkOrCyprus() {
    return ((this.dataService.user.currency.id !== 4) && (this.billingInfo.country.toLocaleLowerCase() !== 'Cyprus'.toLocaleLowerCase()));
  }

  validateEmail(email) {
    if (!email) {
      return;
    }
    const rest = email.substring(0, email.lastIndexOf('@'));
    if (email.indexOf(' ') >= 0) {
      return false;
    }
    if (rest.includes('..') && (rest.includes('"')) && ((rest.charAt(0) !== '"') || (rest.charAt(rest.length - 1) !== '"'))) {
      return false;
    } else if (rest.includes('..') && (rest.includes('\'')) && ((rest.charAt(0) !== '\'') || (rest.charAt(rest.length - 1) !== '\''))) {
      return false;
    } else if (rest.includes('..') && !(/^'|"/.test(rest))) {
      return false;
    }
    if ((rest.charAt(0) === '.') || (rest.charAt(rest.length - 1) === '.')) {
      return false;
    }
    if (email.includes('@') && !(email.split('@')[1].split('.').length >= 2) && (email.split('@')[1].split('.')[email.split('@').length - 1] !== '')) {
      return false;
    }
    if (!rest.includes('..') && (/[ !#$%&'*+-/=?^_`{|}~]/.test(rest) && !/["(),:;<>@[\]]/.test(rest))) {
      return true;
    } else if (rest.includes('..') && (/[ !#$%&'*+-/=?^_`{|}~]/.test(rest) && !/[(),:;<>@[\]]/.test(rest))) {
      return true;
    } else if (((rest.charAt(1) === '.') || (rest.charAt(rest.length - 2) === '.')) && ((rest.charAt(0) === '"') && (rest.charAt(rest.length - 1) === '"'))) {
      return true;
    } else if (/^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w{1,30})+$/.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  public showRequestDemo(tabName?, SpecCallId?) {
    if (window.innerWidth < 768) {
      this.getHelpClicked = false;
    }
    this.setSignupAnalytics('talk_to_sales_click');
    if (SpecCallId) {
      this.showTimeKitWindow(false, SpecCallId);
    } else {
      // this.showTimeKitWindow(true);
      if (!this.dataService.user) {
        this.openCommonAuth = true;
        if (this.dataService.homePageData?.block_sign_up) {
          this.showSignin = true;
          this.showRegister = false;
        } else {
          this.showRegister = true;
          this.showSignin = false;
        }
        this.dataService.openMeetNowModal = true;
        this.talkToSalesClicked = true;
      } else {
        this.dataService.showMeetNowModel = true;
        this.talkToSalesClicked = true;
      }
    }
  }

  public discountPrice() {
    return this.getTotalWithSpecing() - this.getDiscountedWithSpecing();
  }

  public arePhasesNotSelected(): boolean {
    return this.dataService.homePageData.build_phases && this.selectedPhasesIds.length === 0;
  }

  public checkAndSetDefaultPhases() {
    if (this.arePhasesNotSelected()) {
      this.selectDefaultPhases();
    }
  }

  public selectDefaultPhases(): void {
    for (const buildType of this.dataService.homePageData.build_phases) {
      if (this.appDataService.urlParameters.is_freemium && buildType.title.includes('Full Build')) {
        buildType.selected = true;
        this.setDataInPhases(buildType);
        this.toggleSelectedBuild(buildType);
        this.toggleSelectedBuildId(buildType.index);
        this.toggleSelectedBuildIds(buildType.index);
      } else if (!this.appDataService.urlParameters.is_freemium && (buildType.title.includes('Design') || buildType.title.includes('Full Build') || (this.mandatoryFeatures.length && buildType.title.includes('MVP')))) {
        buildType.selected = true;
        this.setDataInPhases(buildType);
        this.toggleSelectedBuild(buildType);
        this.toggleSelectedBuildId(buildType.index);
        this.toggleSelectedBuildIds(buildType.index);
      }
    }
  }

  public setDataInPhases(phase) {
    /*ToDo*/
    //Set false for non parallel platforms
    phase.is_parallel_platform = true;
    if (this.selectedPlatforms.length > 0) {
      phase.platforms = this.selectedPlatforms;
    } else {
      this.selectedPlatforms.push(this.dataService.homePageData.platforms[0]);
      phase.platforms = this.selectedPlatforms;
    }
    if (phase.title === 'MVP') {
      phase.features = this.mandatoryFeatures;
    } else {
      phase.features = this.selectedFeatures;
    }

    if (this.dataService.homePageData.speed && this.dataService.homePageData.speed.length > 0) {
      const templteData = this.dataService.templateDetailModel,
        isHomeOrTempDetail = ((this.dataService.currentPage=== 'templateDetail') || (this.dataService.currentPage=== 'home'));
      if (isHomeOrTempDetail && templteData && (templteData.pricing_modal === 'rental_price')) {
        phase.speed = this.dataService.homePageData.speed.find(speed => speed.title === 'Relaxed');
      } else {
        if (this.dataService.user && this.dataService.user.enterprise_lead) {
          const speedForEnt = this.setSpeedForMultiplier();
          phase.speed = this.dataService.homePageData.speed.find(speed => speed.id === speedForEnt);
        } else if ((this.dataService.user && this.dataService.user.enterprise_lead) || this.dataService.checkIfCardWithUsd()) {
          phase.speed = this.dataService.homePageData.speed.find(speed => speed.title === 'Standard');
        } else {
          phase.speed = this.dataService.homePageData.speed.find(speed => speed.title === 'Relaxed');
        }
      }
    }
  }

  public setDataForFeaturePlatformAndTeam() {
    if (this.selectedSpeed) {
      this.selectedHash.speed_id = this.selectedSpeed.id;
    } else {
      if (this.dataService.homePageData.speed && this.dataService.homePageData.speed[0].title !== 'Relaxed') {
        this.dataService.homePageData.speed.reverse();
      }
      this.selectedSpeed = this.dataService.homePageData.speed[0];
      this.selectedHash.speed_id = this.selectedSpeed.id;
    }
    this.fetchFeatures();
    this.fetchTeams();
  }

  public fetchTeams() {
    this.apiRequest.fetchTeams().subscribe((data: any) => {
      data.teams = this.modelMapperService.getTeamMappedModel(
        new TeamsModel(),
        data.teams
      );
      this.selectedHash.team_id = data.teams.default.id;
      this.proceedToBuildCard();
    });
  }

  public proceedToBuildCard() {
    if (this.checkIfRentalPricingModal() && !this.appDataService.urlParameters ?.cpe_user && !this.isPayNowClickedForRental) {
      this.showProjectNamePopup = false;
      this.selectedHash.frequency = 'monthly';
      if (!this.appDataService.urlParameters.is_freemium) {
        this.selectedHash.project_name = this.selectedApps[0].title;
      }
    } else if (!this.dataService.isFromViewPrototype) {
      this.showProjectNamePopup = !this.dataService.isDirectCheckOut;
    }
    if (this.dataService.appDetails && (this.dataService.appDetails.pricing_modal === 'rental_price')) {
      this.selectedHash.promotion_id = this.dataService.homePageData.promotion ?.id;
      this.showProjectNamePopup = false;
    }
    if (this.appDataService.urlParameters.pay_starter) {
      this.selectedHash.build_card_type = 'starter';
    }

    if (this.dataService.appDetails && this.viewPrototypeClicked) {
      if (!this.appDataService.urlParameters.is_freemium) {
        this.selectedHash.project_name = this.dataService.appDetails.title;
      } else {
        this.selectedHash.project_name = (this.dataService.appDetails.title + '_' + this.dataService.user.first_name).replace(' ', '_');
        this.selectedHash.skip_freemium_validation = true;
      }
    }
    this.selectedHash.build_card_features_attributes =
      this.selectedFeatures.map((feature) => {
        const fData = {
          feature_id: feature.id,
          feature_set_id: feature?.feature_set_id,
          feature_set_alias_name: feature?.feature_set_alias_name
        };
        if (feature.feature_count) {
          fData['feature_count'] = feature.feature_count;
        }
        return fData;
      });
    this.apiRequest.createUpdateBuildCard(this.selectedHash, this.inProgressBuildCardId)
      .subscribe(
      (data: any) => {
        this.appDataService.urlParameters.pay_starter = '';
        this.buildCardUpdated = true;
        let appsSelected = '';
        this.preventClick = false;
        this.dataService.landingPage = '';
        this.selectedDataForSidePanel = [];
        this.setUpInFullBuildCard();
        if (this.dataService.isFromViewPrototype) {
          this.dataService.unsavedChanges = false;
          if (this.featureData) {
            this.setDurationPriceInSelectedFeatures(this.selectedFeatures);
          } else {
            this.selectedFeatures.forEach(sFeature => {
              delete sFeature['breakup_duration'];
              delete sFeature['breakup_price'];
            });
          }
        }
        this.isReadyToProceed = true;
        this.dataService.isSocialSignUpForStudioStore = false;
        if (this.appDataService.urlParameters.is_freemium) {
          this.fetchInstallments();
        }
        if (this.checkIfRentalPricingModal() && !this.appDataService.urlParameters.cpe_user && !this.isPayNowClickedForRental) {
          this.dataService.showHideLoader(false);
          if (this.createBuildCardForCPE) {
            this.createBuildCardForCPE = false;
            this.buildCardEditMode = true;
            this.dataService.isEditBuildCard = true;
            this.appDataService.urlParameters.cpe_user = "true";
            this.appDataService.urlParameters.uniq_code = this.dataService.buildCardData.uniq_code;
            sessionStorage.setItem('show_natasha_again', 'not_from_template_page');
            sessionStorage.setItem('isReloadFeaturePage', 'true');
            this.router.navigate(['feature'], { queryParams: { uniq_code: this.appDataService.urlParameters.uniq_code, cpe_user: 'true' } });
          } else if (this.viewPrototypeClicked) {
            this.apiRequest.syncBuildcardToNow(this.dataService.buildCardData.id).subscribe((data) => {
              this.checkPopupBlockedAndRedirect(this.dataService.homePageData.builder_partner.builder_now_url + '/' + this.dataService.buildCardData.id);
              this.fetchInstallments();
              this.viewPrototypeClicked = false;
            });
          }
          else if (this.meetNowForRentalClicked) {
            this.dataService.showMeetNowModel = true;
            this.dataService.isShowTemplateModal = false;
          } else if (this.appDataService.urlParameters.is_freemium) {
            this.proceedClickedFromRental = true;
            this.handleNextPrevForRentalFlow();
          }
          else if (!this.viewPrototypeClicked && !this.isCreateBuildCardForNatashaFlow) {
            this.selectedSectionIndex = 1;
            this.showRentalPaymentFlow = true;
            this.proceedClickedFromRental = false;
          }
          this.dataService.showAppDetailsLoader = false;
          this.dataService.showDeliveryLoading = false;
        } else if (this.dataService.isFromViewPrototype) {
          this.dataService.showHideLoader(false);
          this.isPayNowClickedForRental = false;
          this.clickedViewPrototype(true);
          if (this.selectedApps) {
            appsSelected = this.getAppsTitle();
          }
          this.trackEventForObject(this.analyticsSegment, "prototype_selected", {
            user_id: this.dataService.user ?.id,
            screen_source: this.dataService.currentPage
          });
          this.checkPopupBlockedAndRedirect(this.dataService.homePageData.builder_partner.builder_now_url + '/' + this.dataService.buildCardData.id);
        } else if (this.dataService.isDirectCheckOut) {
          this.navigateNormally = true;
          this.isPayNowClickedForRental = false;
          this.breadcrumb_links = [];
          if (this.dataService.buildCardData.uniq_code) {
            this.paymentModuleStarted = true;
            const uniqueCode = this.dataService.buildCardData.uniq_code;
            this.fetchContractDetails(uniqueCode);
            this.dataService.isDirectCheckOut = false;
            this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.uniq_code}/` + 'paymentplan']);
          }
          this.dataService.isMobileModel = false;
        } else if (!this.showProjectNamePopup) {
          this.dataService.showDeliveryLoading = false;
          this.isPayNowClickedForRental = false;
          this.router.navigate([
            'build-card/' + this.getIdOrUniqCode()
          ]);
        }
        this.selectedApps = [];
        this.isCreateBuildCardForNatashaFlow = false;
      },
      error => {
        this.dataService.showHideLoader(false);
        if (error.status === 403 || error.status === 401) {
          this.dataService.user = null;
          this.dataService.unsetCookie();
          this.clearLocalStorage();
          this.showSignin = true;
          this.openCommonAuth = true;
          this.reachedUptoPhases = true;
          this.preventClick = false;
        } else {
          this.preventClick = false;
          if (this.appDataService.urlParameters.is_freemium && error.error.errors.sub_domain.length) {
            this.freemiumSubDomainExists = true;
            this.errMessage = error.error.errors.sub_domain[0];
          }
        }
        this.dataService.showDeliveryLoading = false;
        this.proceedClickedFromRental = false;
        this.isCreateBuildCardForNatashaFlow = false;
      }
      );
  }

  public fetchFeatures() {
    if (this.selectedFeatures.length > 0) {
      this.selectedHash.feature_ids = [];
      this.selectedFeatures.forEach(feature => {
        feature.platforms = this.selectedPlatforms;
        feature.phaseId = [];
        feature.platforms.forEach(platform => {
          feature.phaseId.push('4' + platform.id);     /*  4 has been used for MVP phase only   */
        });
        this.selectedHash.feature_ids.push(feature.id);
      });
    }
  }

  public calculateProductAndApps() {
    let count = 0;
    if (this.newSelctedProductId) {
      this.productAndApp.product = 1;
    }
    if (this.newSelectedAppIds || this.newSelectedProblemsIds) {
      count = this.newSelectedAppIds.length;
      if (this.newSelectedProblemsIds.length > 0) {
        this.newSelectedProblemsIds.forEach((item => {
          count = count + this.newSelectedProblemsAppsIds[item].length;
        }));
      }
      this.productAndApp.app = count;
    }
    return this.productAndApp;
  }

  public buildCardProcessAfterSignUp() {
    this.showProjectNamePopup = true;
    this.reachedUptoPhases = false;
    this.selectedHash.status = Constants.buildCardStatus.CARD_COMPLETED;
    this.dataService.showDeliveryLoading = true;
    this.apiRequest.createUpdateBuildCard(this.selectedHash, this.inProgressBuildCardId).subscribe((data: any) => {
      this.inProgressBuildCardId = this.dataService.buildCardData.id;
      this.dataService.unsavedChanges = false;
      this.selectedDataForSidePanel = [];
      this.setUpInFullBuildCard();
      this.isReadyToProceed = true;
      if (!this.showProjectNamePopup) {
        this.dataService.showDeliveryLoading = false;
        this.router.navigate(['build-card/' + this.dataService.buildCardData.uniq_code]);
      }
    });
  }

  getCurrencySymbol() {
    const buildCardStatus = this.buildCardEditMode || this.dataService.isIncompleteCardEditMode || this.isFeatureRequest;
    return buildCardStatus ? this.dataService.buildCardData.currency.symbol : this.dataService.homePageData.currency.symbol;
  }

  public showWeeklyOrMonthly() {
    if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.frequency.add) {
      const addedFrequency = this.dataService.modifiedBuildCardData.frequency.add;
      if (addedFrequency === 'monthly') {
        this.dataService.showMonthly = true;
        this.dataService.showWeekly = false;
        this.dataService.showUpfront = false;
      } else if (addedFrequency === 'weekly') {
        this.dataService.showMonthly = false;
        this.dataService.showWeekly = true;
        this.dataService.showUpfront = false;
      } else if (addedFrequency === 'upfront') {
        this.dataService.showMonthly = false;
        this.dataService.showWeekly = false;
        this.dataService.showUpfront = true;
      }
    } else {
      const frequency = this.dataService.buildCardData.frequency;
      const isSubscription = this.dataService.buildCardData.subscription_flow;
      if (this.dataService.buildCardData.id && ((frequency === 'upfront') || (isSubscription && !frequency))) {
        this.dataService.showMonthly = false;
        this.dataService.showWeekly = false;
        this.dataService.showUpfront = true;
      } else if (this.dataService.buildCardData.id && ((frequency === 'monthly') || (!isSubscription && !frequency))) {
        this.dataService.showMonthly = true;
        this.dataService.showWeekly = false;
        this.dataService.showUpfront = false;
      } else if (this.dataService.buildCardData.id && (frequency === 'weekly')) {
        this.dataService.showMonthly = false;
        this.dataService.showWeekly = true;
        this.dataService.showUpfront = false;
      }
    }
  }

  setdisabledKeyInFeatures(features) {
    features.map((feature) => {
      this.dataService.bundlesFeatures.find((data) => {
        if (feature.id === data.id) {
          const buildCardFeaturesIDs = this.dataService.buildCardData.progress.features.map(f => f.id);
          if ((data.selected === true) && buildCardFeaturesIDs.includes(data.id)) {
            feature['disabled'] = true;
          } else {
            feature['disabled'] = false;
          }
        }
      });
      return feature;
    });
  }

  get isFeatureRequest() {
    this.dataService.isChangeRequest = this.appDataService.urlParameters.featureRequest;
    return this.appDataService.urlParameters.featureRequest;
  }

  get isPlatformRequest() {
    this.dataService.isChangeRequest = this.appDataService.urlParameters.platformRequest;
    return this.appDataService.urlParameters.platformRequest;
  }

  public isEditFeatureRequest() {
    return (this.isFeatureRequest || this.dataService.isEditRequestedFeatures);
  }

  public getUrlParamsFromUrl(): UrlParameterModel {
    const paramsString = window.location.href.split('?')[1];
    if (paramsString) {
      const searchParams = new URLSearchParams(paramsString);
      const result = {};
      for (const [key, value] of searchParams) {
        result[key] = value;
      }
      return this.modelMapperService.mapToUrlParameterModel(
        new UrlParameterModel(),
        result
      );
    } else {
      return null;
    }
  }

  fetchProposalInstallments(paymentPage?, shouldUseReferralPointsFromNotPaymentPlan?) {
    this.apiRequest.getProposalInstallments(this.dataService.buildCardData.id).subscribe((resp) => {
      this.dataService.proposalInstallments = resp.data;
      this.dataService.proposalInstallments['first_shipment_date'] = resp.data.first_shipment_date !== null ? [resp.data.first_shipment_date]: [];
      if (this.dataService.modifiedBuildCardData) {
        this.weeklyDeposit = this.dataService.proposalInstallments['weekly'][0];
        this.monthlyDeposit = this.dataService.proposalInstallments['monthly'][0];
        if (this.dataService.proposalInstallments['upfront']) {
          this.upfrontInstallment = this.dataService.proposalInstallments['upfront'][0];
        }
      } else {
        this.weeklyDeposit = this.dataService.proposalInstallments['weekly'] ? this.dataService.proposalInstallments['weekly'].find(installmemt => {
          return installmemt.type === 'deposit';
        }) : null;
        this.monthlyDeposit = this.dataService.proposalInstallments['monthly'] ? this.dataService.proposalInstallments['monthly'].find(installmemt => {
          return installmemt.type === 'deposit';
        }) : null;
        if (this.dataService.proposalInstallments['upfront']) {
          this.upfrontInstallment = this.dataService.proposalInstallments['upfront'][0];
        }
      }
      this.dataService.proposalInstallments['weekly'] = this.dataService.proposalInstallments['weekly'] ? this.dataService.proposalInstallments['weekly'].filter((installment) => {
        return installment.type === 'installment' || installment.type === 'support';
      }) : null;
      this.dataService.proposalInstallments['monthly'] = this.dataService.proposalInstallments['monthly'] ? this.dataService.proposalInstallments['monthly'].filter((installment) => {
        return installment.type === 'installment' || installment.type === 'support';
      }) : null;
      if (this.dataService.proposalInstallments['upfront']) {
        this.dataService.proposalInstallments['upfront'] = this.dataService.proposalInstallments['upfront'][0];
      }
      if (!this.dataService.proposalInstallments['monthly'].length || !this.dataService.proposalInstallments['weekly'].length) {
        this.dataService.proposalInstallments['weekly'].push(this.weeklyDeposit);
        this.dataService.proposalInstallments['monthly'].push(this.monthlyDeposit);
        this.dataService.proposalInstallments['upfront'].push(this.upfrontInstallment);
      }
      if (this.dataService.proposalInstallments.monthly && (this.dataService.proposalInstallments.monthly.length > 0)) {
        this.perMonthCost =
          this.dataService.proposalInstallments.monthly[0].installment_amount;
      }
      if (paymentPage && !shouldUseReferralPointsFromNotPaymentPlan) {
        if (this.dataService.isUnpaidCard()) {
          this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.uniq_code}/` + 'billingdetails']);
        } else {
          this.router.navigate([`buildcard-payment/${this.dataService.buildCardData.uniq_code}/` + 'paymentPlan']);
        }
      }
      if (shouldUseReferralPointsFromNotPaymentPlan || (this.dataService.currentPage === 'paymentPlan')) {
        this.apiRequest.fetchSingleBuildCardData(this.dataService.buildCardData.uniq_code).subscribe((data: any) => {}, (error) => {});
      }
      this.dataService.isShowUpfrontPaymentPlan = false;
    }, (error) => {
      this.dataService.showHideLoader(false);
      this.commonService.showError();
    });
  }

  redirectToBuilderLive() {
    if (this.dataService.cookie_domain && this.dataService.cookie_domain === 'localhost') {
      window.location.href = `http://localhost:5000/#/project-progress/${this.dataService.buildCardData.uniq_code}/home`;
    } else {
      window.location.href = `${this.dataService.homePageData.builder_partner.builder_live_url}/#/project-progress/${this.dataService.buildCardData.uniq_code}/home`;
    }
  }

  getRedirectionLink(pSection) {
    const homePageData = this.dataService.homePageData, manualPromotion = this.dataService.manualPromotion,
      expCode = homePageData.multiplier_experiment ? ('?exp=' + homePageData.multiplier_experiment.code) : '';
    let promoCode = '';
    let proto_promo = '';
    if (homePageData.promotion && homePageData.promotion.code) {
      promoCode = '?promotion=' + homePageData.promotion.code;
    } else if (manualPromotion && manualPromotion.code) {
      promoCode = '?promotion=' + manualPromotion.code;
    } else if (pSection.pricing_modal === 'rental_price' && this.dataService.user ?.currency.default_store_promo_code) {
      promoCode = '?promotion=' + this.dataService.user.currency.default_store_promo_code;
    } else {
      promoCode = ''
    }
    if (this.appDataService.urlParameters.prototype_promo) {
      proto_promo = '?prototype_promo=' + this.appDataService.urlParameters.prototype_promo;
    }
    promoCode = expCode.includes('?') ? promoCode.replace('?', '&') : promoCode;
    const finalUrl = expCode + promoCode;
    proto_promo = finalUrl.includes('?') ? proto_promo.replace('?', '&') : proto_promo;
    let filteredSlug = '';
    if (pSection.slug) {
      const urlArray = pSection.slug.split('#');
      filteredSlug = urlArray[0];
    }
    const redirectionUrl = '/apps/' + filteredSlug + expCode + promoCode + proto_promo;
    return redirectionUrl.includes('?') ? (redirectionUrl + '&currency_id=' + homePageData.currency.id) : (redirectionUrl + '?currency_id=' + homePageData.currency.id);
  }

  getFreemiumRedirectionLink(pSection) {
    const homePageData = this.dataService.homePageData, manualPromotion = this.dataService.manualPromotion,
      expCode = homePageData.multiplier_experiment ? ('?exp=' + homePageData.multiplier_experiment.code) : '';
    let promoCode = '';
    let proto_promo = '';
    if (homePageData.promotion && homePageData.promotion.code) {
      promoCode = '?promotion=' + homePageData.promotion.code;
    } else if (manualPromotion && manualPromotion.code) {
      promoCode = '?promotion=' + manualPromotion.code;
    } else if (pSection.pricing_modal === 'rental_price') {
      promoCode = '?promotion=freethree';
    }
    if (this.appDataService.urlParameters.prototype_promo) {
      proto_promo = '?prototype_promo=' + this.appDataService.urlParameters.prototype_promo;
    }
    promoCode = expCode.includes('?') ? promoCode.replace('?', '&') : promoCode;
    const finalUrl = expCode + promoCode;
    proto_promo = finalUrl.includes('?') ? proto_promo.replace('?', '&') : proto_promo;
    let filteredSlug = '';
    if (pSection.slug) {
      const urlArray = pSection.slug.split('#');
      filteredSlug = urlArray[0];
    }
    const redirectionUrl = '/apps/' + filteredSlug + expCode + promoCode + proto_promo;
    return redirectionUrl.includes('?') ? (redirectionUrl + '&currency_id=' + homePageData.currency.id + '&is_freemium=true') : (redirectionUrl + '?currency_id=' + homePageData.currency.id);
  }

  public redirectToNowForPrototype(signUpClickedSrc?) {
    this.dataService.openMeetNowModal = false;
    if (this.dataService.currentPage === 'summary') {
      this.dataService.showHideLoader(true);
      if (this.dataService.buildCardData && this.dataService.buildCardData.id) {
        this.trackEventForObject(this.analyticsSegment,  "prototype_selected", {
          user_id: this.dataService.user ?.id,
          screen_source: this.dataService.currentPage
        });
        this.apiRequest.syncBuildcardToNow(this.dataService.buildCardData.id).subscribe((data) => {
          window.open(this.dataService.homePageData.builder_partner.builder_now_url + '/' + this.dataService.buildCardData.id, '_blank');
          this.dataService.showHideLoader(false);
        });
      }
    } else {
      if (this.selectedFeatures.length > 0) {
        this.dataService.isFromViewPrototype = true;
        this.selectedHash.status = Constants.buildCardStatus.CARD_COMPLETED;
        this.selectedHash.prototype_promo = true;
        if (this.dataService.user) {
          this.dataService.showHideLoader(true);
          if (!this.buildCardEditMode) {
            // -------Create the Buildcard™ && set the default Params
            this.setDataForFeaturePlatformAndTeam();
          } else {
            // ----- update the Buildcard™ to check whether promo is there in url
            if (this.inProgressBuildCardId && (this.dataService.currentPage === 'features' || this.dataService.currentPage === 'templateDetail' || this.dataService.currentPage === 'delivery')) {
              if (this.selectedSpeed) {
                this.selectedHash.speed_id = this.selectedSpeed.id;
              } else {
                if (this.dataService.homePageData.speed && this.dataService.homePageData.speed[0].title !== 'Relaxed') {
                  this.dataService.homePageData.speed.reverse();
                }
                this.selectedSpeed = this.dataService.homePageData.speed[0];
                this.selectedHash.speed_id = this.selectedSpeed.id;
              }
              this.createBuildCardPriceHash();
              this.fetchTeams();
              // this.proceedToBuildCard();
            }
          }
        } else {
          this.dataService.signupSrcName = signUpClickedSrc;
          this.preventClick = false;
          if (this.dataService.homePageData?.block_sign_up) {
            this.showSignin = true;
            this.showRegister = false;
          } else {
            this.showRegister = true;
            this.showSignin = false;
          }
          this.openCommonAuth = true;
          this.isNdaFlow = false;
        }
      } else {
        return;
      }
    }
  }

  clearLocalStorage() {
    if (this.isPlatformBrowser) {
      localStorage.clear();
    }
  }

  public preSelectPlatforms(appDetails) {
    this.selectedPlatforms = [];
    this.selectedHash.platform_ids = [];
    if (this.dataService.homePageData.platforms) {
      const platforms = this.dataService.homePageData.platforms;
      let defaultPlatform;
      if (appDetails && appDetails.platforms && appDetails.platforms.length > 0) {
        for (const platform of platforms) {
          platform.selected = false;
          appDetails.platforms.forEach((element, index) => {
            if (element.id === platform.id) {
              platform.selected = true;
              defaultPlatform = platform;
            }
          });
          if (platform.selected) {
            break;
          }
        }
      } else {
        this.dataService.homePageData.platforms[0].selected = true;
      }
      defaultPlatform = defaultPlatform ? defaultPlatform : platforms[0];
      this.toggleSelectedPlatform(defaultPlatform);
      this.toggleSelectedPlatformId(defaultPlatform.id);
    }
  }


  pricePerWeeksCalculation(buildCardData) {
    if (this.conditionForPaymentWeek()) {
      const selectedFeatureIds = this.selectedHash.feature_ids;
      const appFeatureIds = this.selectedApps[0].feature_ids;
      if (this.checkForArrayEquality(selectedFeatureIds, appFeatureIds)) {
        this.finalPricenWeeks.payment_weeks = this.finalPricenWeeks.weeks + this.selectedApps[0].week_difference;
      } else {
        this.finalPricenWeeks.payment_weeks = buildCardData.weeks;
      }
    } else {
      this.finalPricenWeeks.payment_weeks = buildCardData.weeks;
    }
  }

  conditionForPaymentWeek() {
    return this.selectedApps && this.selectedApps.length === 1 && this.selectedApps[0].week_difference;
  }

  setPaymentWeeks() {
    return this.dataService.buildCardData.payment_weeks ? this.dataService.buildCardData.payment_weeks : this.dataService.buildCardData.weeks;
  }


  checkForArrayEquality(_arr1, _arr2) {
    if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) {
      return false;
    }
    const arr1 = _arr1.concat().sort();
    const arr2 = _arr2.concat().sort();
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  public handleExitIntentTimer() {
    if (this.commonService.isPlatformBrowser) {
      this.exitIntentInterval = setInterval((value) => {
        this.exitIntentTimeSpent += 1;
        if (this.isPopUpActive()) {
          this.exitIntentTimeSpent = 0;
        }
      }, 1000);
    }
  }

  public checkExitIntentTimer() {
    if (this.commonService.isPlatformBrowser) {
      if (!this.dataService.hideExitIntentPopUp) {
        this.exitIntentTimeSpent = 0;
        clearInterval(this.exitIntentInterval);
        this.handleExitIntentTimer();
      } else {
        clearInterval(this.exitIntentInterval);
      }
    }
  }

  public handlePaymentExitIntentTimer() {
    if (this.commonService.isPlatformBrowser) {
      this.paymentExitIntentInterval = setInterval((value) => {
        this.paymentExitIntentTimeSpent += 1;
        if (this.isPopUpActive()) {
          this.paymentExitIntentTimeSpent = 0;
        }
      }, 1000);
    }
  }

  public checkPaymentExitIntentTimer() {
    if (this.commonService.isPlatformBrowser) {
      this.paymentExitIntentTimeSpent = 0;
      clearInterval(this.paymentExitIntentInterval);
      this.handlePaymentExitIntentTimer();
    }
  }

  closeExitIntentPopups() {
    if (this.countExitBillingDetails === 1) {
      this.countExitBillingDetails++;
    }
    if (this.countExitPaymentPlan === 1) {
      this.isShowPaymentExitOffer = false;
      this.isExitPaymentOffer = false;
      this.countExitPaymentPlan++;
    }
  }

  public isPopUpActive() {
    return !this.showSignNDAPopup && (this.dataService.openMeetNowModal || this.dataService.showMeetNowModel || this.dataService.isEditBuildCardAfterSecurityPaid()
      || this.showRegister || this.showSignin || this.showSaveProgress || this.dataService.showFeaturesList
      || this.dataService.openPlatform || this.dataService.showCurrencyAfterLogin || this.openSideNav
      || this.uploadDocOnNDAClicked || this.isEditFeatureRequest() || this.showCalendlyPopup || this.showLearnMore || this.countExitBillingDetails
      || this.showProjectNamePopup || this.showAddNewTemplate || this.addnewfeature || this.showRentalPaymentFlow || this.ndaPopupScr || this.msaPopupScr
      || (this.dataService.showMeetNowOverloading || (this.dataService.showMeetNowOverloadingOnFeature && this.dataService.currentPage === 'features')));
  }

  createNormallOrInstantSpecCall(specType) {
    let feedback = '';
    if (this.dataService.homeExitFeedbackSubmitted && this.dataService.homeExitFeedbackSubmitted.length > 0) {
      feedback = this.dataService.homeExitFeedbackSubmitted;
    }
    this.apiRequest.createSpecCall(specType, this.dataService.currentPage, feedback).subscribe((resp) => {
      this.specCallCreationData = resp;
      const specingId = this.specCallCreationData.id;
      if (specType === 'instant') {
        this.dataService.isSpecCallCreated = true;
        if (this.specCallCreationData.manager_availability) {
          this.handleSpecingStatus(specingId);
        }
      } else if (specType === 'normal') {
        this.showTimeKitWindow(false, specingId);
        this.showSignNDAPopup = true;
      }
      this.dataService.homeExitFeedbackSubmitted = '';
      this.dataService.showHideLoader(false);
    }, error => {
      this.dataService.showHideLoader(false);
    });
  }

  public getPromotionObject() {
    let promotionObject;
    if (this.checkIfRentalPricingModal() && this.selectedHash.source === 'app_detail') {
      promotionObject = this.dataService.manualPromotion && this.dataService.manualPromotion.type === 'MonthDiscountPromotion' ? this.dataService.manualPromotion
        : this.dataService.homePageData.promotion && this.dataService.homePageData.promotion.type === 'MonthDiscountPromotion' ?
          this.dataService.homePageData.promotion : null;
    } else if (this.dataService.manualPromotion && this.dataService.manualPromotion.code) {
      promotionObject = this.dataService.manualPromotion;
    } else if (this.dataService.homePageData.promotion && this.dataService.homePageData.promotion.code) {
      promotionObject = this.dataService.homePageData.promotion;
    }
    return promotionObject;
  }

  public fetchPromotionList() {
    this.apiRequest.fetchPercentagePromotionList().subscribe(ext => {
      this.filteredPromotionList = this.dataService.promotionList;
    });
  }

  public getBuildCardPriceWithDiscount() {
    if (!this.buildCardSelections) {
      this.dataService.showHideLoader(false);
      return false;
    }
    const buildCardSelections = JSON.parse(JSON.stringify(this.buildCardSelections));
    this.createBuildCardPriceHash();
    if (this.isFeatureRequest && !this.isPlatformRequest) {
      if (buildCardSelections['build_card_phases']['full_build']) {
        delete buildCardSelections['build_card_phases']['mvp'];
      }
      delete buildCardSelections['build_card_phases']['custom_phases'];
    }
    this.apiRequest.getBuildCardPrice(buildCardSelections)
      .subscribe(
      (data) => {
        this.customizationCost = data['customisation_price'];
        this.customFeaturesPrice = data['custom_feature_price'];
        this.exitPopupBuildCardPrice = this.getTotalWithTax();
        if (this.dataService.buildCardData.currency.code === 'INR') {
          this.exitPopupBuildCardDiscountPrice = Number(this.getDiscountedPriceForCards(data)) + Number(this.getTaxOnDiscountedPrice(this.getDiscountedPriceForCards(data)));
        } else {
          this.exitPopupBuildCardDiscountPrice = Number(this.getDiscountedPriceForCards(data));
        }
        this.setDiscountPrice(data);
        this.phaseData = new Array<PhaseDataModel>();
        this.featureData = [];
        this.featureReusableCost = data['reusable_features_price'];
        this.subscriptionCost = data['subscription_price'];
        this.subscriptionDuration = data['subscription_duration'];
        this.monthlySubscriptionPrice = data['monthly_subscription_price'];
        this.totalCarePrice = data['total_care_price'];
        this.customizationCost = data['customisation_price'];
        this.customFeaturesPrice = data['custom_feature_price'];
        this.builderCarePrice = data['builder_care_price'];
        this.featureData = data['feature_data'];
        this.carePrices = data['care_prices'];
        this.dataService.priceWithoutCareAndSubs = data['price'];
        this.setDurationPriceInSelectedFeatures(this.selectedFeatures);
        if (data.phase_data && data.phase_data.length > 0) {
          data.phase_data.forEach(phase => {
            const tempPhase = new PhaseDataModel();
            for (const key in phase) {
              tempPhase[key] = phase[key];
            }
            this.phaseData.push(tempPhase);
          });
        }
        this.supportMaintenance = data.builder_care_price;
        this.getNewPhaseDates();
        this.phaseBreakupData = [];
        this.dataService.homePageData.build_phases.forEach(phase => {
          if (phase.selected) {
            if (this.phaseData && this.phaseData.length > 0) {
              const phaseArr = this.phaseData.filter(phasedata => (phasedata.id === phase.id));
              if (phaseArr && phaseArr.length > 0) {
                phase.delivery_time = phaseArr[0].delivery_time;
                phase.price = phaseArr[0].price;
                phase.weeks = phaseArr[0].weeks;
              }
            }
          }
        });
        this.phaseBreakupData = Object.assign([], [...this.dataService.homePageData.build_phases]);
        if ((this.isDiscountApplied() && this.dataService.manualPromotion) || this.finalPricenWeeks.actual_weeks > this.finalPricenWeeks.weeks
          || this.isDiscountAppliedForRental()) {
          this.sideNavClose.emit(true);
        }
        this.showHideBottomBarLoader = false;
        this.dataService.showHideLoader(false);
      });
  }

  handleSpecingStatus(specingId) {
    this.dataService.showHideLoader(true);
    this.apiRequest.getSpecCallDetails(specingId).subscribe((data: SpecCallModel) => {
      this.specCallData = data;
      this.showMeetNowSearchingScreen = false;
      if (this.specCallData.status === 'paid') {
        this.isPaymentDone = true;
      } else {
        this.isPaymentFailed = true;
      }
      this.appDataService.urlParameters.specingId = '';
      this.dataService.showHideLoader(false);
    }, error => {
      this.dataService.showHideLoader(false);
    });
  }

  public parseUserProperties(obj) {
    const userProp = {};
    for (const prop in obj) {
      if (obj[prop]) {
        if (prop === 'utm_source') {
          userProp['utm_source[last touch]'] = obj['utm_source'];
        }
        userProp[prop] = obj[prop];
      }
    }
    this.analyticsSegment.setUserProperties(userProp);
  }

  public uniqueArrayObjectSet(arrOfObject) {
    return arrOfObject.filter((feature, index, self) =>
      index === self.findIndex((t) => (
        t.id === feature.id
      ))
    );
  }

  public showViewPrototype(typeofdevice?) {
    let showPrototype = false;
    const cardData = this.dataService.buildCardData;
    switch (this.dataService.currentPage) {
      case 'templateDetail':
        if (this.dataService.appDetails && this.dataService.appDetails.prototype_visible &&
          !this.appDataService.urlParameters.redirect_to_login) {
          showPrototype = true;
        }
        break;
      case 'features':
        if (cardData && cardData.progress && cardData.progress.apps && cardData.progress.apps.length) {
          showPrototype = true;
        }
        break;
      case 'delivery':
        if (cardData && cardData.progress && cardData.progress.apps && cardData.progress.apps.length) {
          showPrototype = true;
        }
        break;
      case 'summary':
        if (typeofdevice !== 'mobile' && this.dataService.buildCardData && this.dataService.buildCardData.progress &&
          this.dataService.buildCardData.progress.apps && this.dataService.buildCardData.progress.apps.length) {
          showPrototype = true;
        }
        break;
      default:
        break;
    }
    return showPrototype;
  }

  redirectWIPBuilderNow() {
    if (this.dataService.checkIfCPEUser()) {
      this.redirectToNowForPrototype('viewPrototype');
    } else {
      if (this.dataService.user) {
        this.trackEventForObject(this.analyticsSegment, "prototype_selected", {
          user_id: this.dataService.user ?.id,
          screen_source: this.dataService.currentPage
        });
        window.open(this.dataService.homePageData.builder_partner.builder_now_url + '/wip', '_blank');
      } else {
        this.dataService.comingViewPrototype = true;
        this.dataService.signupSrcName = 'viewPrototype';
        this.preventClick = false;
        if (this.dataService.homePageData?.block_sign_up) {
          this.showSignin = true;
          this.showRegister = false;
        } else {
          this.showRegister = true;
          this.showSignin = false;
        }
        this.openCommonAuth = true;
        this.isNdaFlow = false;
      }
    }
  }

  getAppsTitle() {
    let templateSelected = '';
    if (this.selectedApps.length > 0) {
      const appsList = this.selectedApps.map((app) => {
        return app.title;
      });
      if (appsList.length > 1) {
        const last = appsList.pop();
        templateSelected = appsList.join(', ') + ' and ' + last;
      } else {
        templateSelected = appsList[0];
      }
      return templateSelected;
    }
  }

  getMonthlyTotalPrice() {
    return Number((this.finalPricenWeeks.total / this.finalPricenWeeks.payment_weeks) * 4);
  }

  getRentalOrMonthlyDiscountedPrice() {
    return Number((this.finalPricenWeeks.bcard_discounted_price / this.finalPricenWeeks.payment_weeks) * 4);
  }

  checkIfRentalPricingModal() {
    if (this.selectedApps && this.selectedApps.length === 1 && (this.selectedApps[0].pricing_modal === 'rental_price')) {
      return true;
    }
    return false;
  }

  getRentalPrice() {

    if (this.isRentalBuildCard()) {
      return (this.dataService.buildCardData.build_card_type == 'starter') ? this.dataService.buildCardData.progress.apps[0].starter_monthly_price : this.dataService.buildCardData.progress.apps[0].monthly_price;
    } else if (this.checkIfRentalPricingModal()) {
      return this.selectedApps[0].monthly_price;
    }
    return 0;
  }

  getMonthlyOrRentalMonths() {
    if (this.isRentalBuildCard()) {
      return this.dataService.buildCardData.progress.apps[0].month_count;
    } else if (this.checkIfRentalPricingModal()) {
      if (this.selectedApps[0].month_count) {
        return this.selectedApps[0].month_count;
      }
    }
    return Math.ceil(this.finalPricenWeeks.weeks / 4);
  }

  conditionForMonthlyPricingModal() {
    return (this.selectedApps && this.selectedApps.length === 1 && (this.pricingModal === 'monthly_payment'));
  }

  checkIfDefaultPricingModal() {
    if (this.selectedApps && this.selectedApps.length === 1 && (this.pricingModal === 'total_price')) {
      return true;
    }
    return false;
  }

  builderCareConditionForMonthlyOrDefault() {
    return this.selectedApps && this.selectedApps.length === 1 && this.pricingModal !== 'rental_price';
  }

  getBuilderCareForOldCards() {
    if (this.builderCareConditionForMonthlyOrDefault()) {
      if (this.selectedApps[0].builder_care_price && !this.checkIfBuildCardChanged()) {
        return this.selectedApps[0].builder_care_price;
      } else {
        return this.supportMaintenance;
      }
    }
    return this.supportMaintenance;
  }

  toggleCare(isForEMI: boolean) {
    this.selectedHash.is_under_support = !this.selectedHash.is_under_support;
    if (!this.selectedHash.is_under_support) {
      this.trackEventForObject(this.analyticsSegment,  'builder_care_unselected', {
        user_id: this.dataService.user ?.id,
        phase_selected: this.getPhaseTitle()
      });
    }
    if (this.dataService.checkIfEnterpriseUserOrCard() || this.checkEnterpriseForPaidCards()) {
      this.selectedHash.care_type = 'enterprise_care_plus';
    } else {
      this.selectedHash.care_type = 'standard_care_plus';
    }
    if (isForEMI) {
      this.preventClick = true;
      this.dataService.showHideLoader(true);
      this.continueToUpdateBuildCard();
    }
  }

  isSupportAndMaintenanceForOldCards() {
    if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.is_under_support !== null) {
      return this.dataService.modifiedBuildCardData.is_under_support || this.selectedHash.is_under_support;
    } else if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.is_under_support == null) {
      return this.dataService.buildCardData.modification_request.base_build_card_data.is_under_support;
    } else {
      return this.selectedHash.is_under_support;
    }
  }

  getBuilderCareForMonthlyOrDefaultPricing(careType, showMonthly?) {
    if (this.builderCareConditionForMonthlyOrDefault()) {
      if (this.selectedApps[0].builder_care_price && !this.checkIfBuildCardChanged()) {
        return this.selectedApps[0].builder_care_price;
      } else {
        return showMonthly ? this.carePrices[careType] : (this.carePrices[careType] * 12);
      }
    }
    return showMonthly ? this.carePrices[careType] : (this.carePrices[careType] * 12);
  }

  checkIfBuildCardChanged() {
    if (this.checkForArrayEquality(this.selectedHash.platform_ids, this.selectedApps[0].platform_ids)
      && this.checkForArrayEquality(this.selectedHash.feature_ids, this.selectedApps[0].feature_ids) && this.selectedTeam && this.selectedTeam.title === 'Anywhere'
      && this.checkForArrayEquality(this.initialAppPhases, this.selectedHash.build_phase_vars) && this.selectedSpeed && this.selectedSpeed.title === 'Relaxed') {
      return false;
    }
    return true;
  }

  conditionToCreateAndSyncBuildCardToBNow() {
    if (this.appDataService.urlParameters.red && this.appDataService.urlParameters.red === 'now' && this.appDataService.urlParameters.application) {
      return true;
    }
    return false;
  }

  checkIfOnlyRapidPrototypeSelected() {
    this.selectedPhases = this.dataService.homePageData.build_phases.filter(phase => phase.selected);
    const prototype = this.dataService.homePageData.build_phases.find(
      phase => phase.title === 'Professional Prototype'
    );
    if (this.selectedPhases && (this.selectedPhases.length === 1) && prototype && prototype.selected) {
      return true;
    }
    return false;
  }

  public getCurrencies() {
    return this.dataService.homePageData.currencies.sort((a, b) => {
      if (b['name'] > a['name']) { return -1; }
      if (b['name'] < a['name']) { return 1; }
      return 0;
    });
  }

  public setSelectedCurrency(currency) {
    this.selectedCurrency = currency;
  }

  public isActiveCurrency(currency: CurrencyModel) {
    return (
      (this.dataService.homePageData.currency &&
        (this.dataService.homePageData.currency.id == currency.id &&
          !this.selectedCurrency)) ||
      (this.selectedCurrency && this.selectedCurrency.id === currency.id) || (!this.appDataService.urlParameters.is_sales_user && this.dataService.userCurrencyAndCountries['available_currency'].includes(currency.code))
    );
  }

  public checkUserHasCurrency() {
    if (Object.keys(this.dataService.user).length && !Object.keys(this.dataService.user.currency)) {
      this.openCommonAuth = true;
    }
  }

  public closeCurrencyChooserForUser(phone_number?, email?) {
    if (this.selectedCurrency) {
      this.dataService.user.currency = Object.assign({}, this.selectedCurrency);
    } else {
      this.dataService.user.currency = Object.assign({}, this.dataService.homePageData.currency);
    }
    if (this.dataService.user.currency) {
      this.specing_price = this.dataService.user.currency.specing_price;
    }
    this.dataService.showHideLoader(true);
    this.apiRequest
      .updateProfile(this.dataService.user, this.dataService.user.currency.id, phone_number, email)
      .subscribe((data: any) => {
        if (this.dataService ?.appDetails ?.pricing_modal === 'rental_price' ) {
          return;
        }
        this.openCommonAuth = false;
        const evt = new CustomEvent('CurrencySelected', { detail: true });
        if (this.isSignupByChatbot) {
          window.dispatchEvent(evt);
        }
        if (((this.appDataService.urlParameters.red === 'meet_now') || this.appDataService.urlParameters.red === 'ip_now') || (this.dataService.openMeetNowModal)) {
          this.dataService.showMeetNowModel = true;
        }
        this.promotionCurrencySubject.next(true);
        this.updateCurrencyChange(true);
        this.clearCurrencyUpdateMessage();
        this.saveData();
        if (this.dataService.showCardPopUpAfterCurrencySetup) {
          if (this.dataService.meetNowScheduleCall) {
            this.dataService.meetNowScheduleCall = false;
            // this.dataService.hideExitIntentPopUp = true;
            this.createNormallOrInstantSpecCall('normal');
          }
          if (this.dataService.meetNowConnectCall) {
            this.dataService.meetNowConnectCall = false;
            this.showMeetNowSearchingScreen = true;
            this.createNormallOrInstantSpecCall('instant');
          }
          if ((this.dataService.currentPage === 'templateDetail') && this.dataService.isFromAppDetails) {
            this.showProjectNamePopup = !this.dataService.isDirectCheckOut;
            this.dataService.showDeliveryLoading = true;
            this.setDataForFeaturePlatformAndTeam();
          } else if (this.dataService.isFromViewPrototype) {
            this.redirectToNowForPrototype(this.dataService.signupSrcName);
          }
        }
        if (this.isShowSpecPopup) {
          this.isOpenSpecing = true;
        }
        if (this.selectedHash.cloud_price > 0) {
          this.convertCloudPriceBasedOnUserCurrency();
        }
        this.fetchUserShowData();
      });
    this.dataService.showCurrencyAfterLogin = false;
    this.dataService.showUserConfirmationToast = false;
    if (this.conditionToCreateAndSyncBuildCardToBNow() && this.signUpCurrencyForBNow) {
      this.showCurrencyOptionsForBnow = false;
      this.signUpCurrencyForBNow = false;
      this.showRegister = false;
      this.openCommonAuth = false;
      this.signupNextStep = false;
    }
  }

  public saveData() {
    if (this.reachedUptoPhases && this.dataService.user && this.dataService.user.currency && !this.dataService.isDirectCheckOut) {
      this.buildCardProcessAfterSignUp();
    } else {
      this.dataService.showHideLoader(false);
    }
    this.dataService.setLanguage();
  }

  public showCurrencyDropDown() {
    this.dataService.showCurrencyPopup = !this.dataService.showCurrencyPopup;
  }

  public hideOptionList() {
    this.showList = false;
  }

  public getCurrency() {
    if ((this.dataService.currentPage === 'templateDetail' && this.dataService.appDetails ?.pricing_modal === 'rental_price') || this.dataService.isShowTemplateModal) {
      return this.currencyForNow;
    }
    this.currencyForNow = this.dataService.homePageData.currency;
    return this.dataService.homePageData.currency;
  }

  public getCurrencyCode() {
    return this.getCurrency().code;
  }

  public isShowCurrencyDropdown() {
    return (
      this.dataService.showCurrencyPopup &&
      (!this.getUser() || this.checkForCPEUserAndCurrentPage()) &&
      this.dataService.homePageData && this.getCurrencies() &&
      this.getCurrencies().length > 0
    );
  }

  public isShowCurrencyDropdownForBNow() {
    return (
      this.dataService.showCurrencyPopup &&
      this.dataService.homePageData && this.getCurrencies() &&
      this.getCurrencies().length > 0
    );
  }

  public getUser() {
    return this.dataService.user;
  }

  checkForCPEUserAndCurrentPage() {
    const currentPage = this.dataService.currentPage;
    return (this.dataService.checkIfCPEUser() && ((currentPage === 'apps') || (currentPage === 'home' || (currentPage === 'templateDetail'))));
  }

  public selectCurrency(i) {
    this.dataService.previousCurrency = this.dataService.homePageData.currency;
    this.dataService.homePageData.currency = this.dataService.homePageData.currencies[i];
    if (this.dataService.homePageData.currency) {
      this.specing_price = this.dataService.homePageData.currency.specing_price;
    }
    this.dataService.defaultCurrency = this.dataService.homePageData.currency;

    if (this.dataService.user && this.dataService.user.currency && !this.dataService.checkIfCPEUser()) {
      this.dataService.user.currency = this.dataService.homePageData.currency;
      this.specing_price = this.dataService.user.currency.specing_price;
    }

    this.parseUserProperties({
      currency_selected: this.dataService.homePageData.currency.name,
      utm_campaign: this.appDataService.urlParameters.utm_campaign,
      utm_source: this.appDataService.urlParameters.utm_source,
      utm_medium: this.appDataService.urlParameters.utm_medium,
      utm_term: this.appDataService.urlParameters.utm_term,
      utm_content: this.appDataService.urlParameters.utm_content
    });

    this.dataService.setLanguage();
    // this.updateCurrencyChange(true);
    this.clearCurrencyUpdateMessage();
    this.dataService.showCurrencyPopup = false;
    if (this.conditionToCreateAndSyncBuildCardToBNow() ||
      (this.dataService.currentPage === 'templateDetail') || this.dataService.isShowTemplateModal
      || (this.dataService.currentPage === 'beingReferred')) {
      this.showCurrencyOptionsForBnow = true;
      this.currencyForNow = this.dataService.homePageData.currency;
      if (this.dataService.currentPage === 'templateDetail' || this.dataService.isShowTemplateModal ||
        (this.dataService.currentPage === 'beingReferred')) {
        this.dataService.homePageData.currency = this.dataService.previousCurrency;
      }
    }
  }

  public isCurrentCurrency(currency: CurrencyModel) {
    return (
      this.dataService.homePageData &&
      this.dataService.homePageData.currency &&
      this.dataService.homePageData.currency.id == currency.id
    );
  }

  checkIfMvpOrFullBuildSelected() {
    let selectedPhases = this.dataService.homePageData.build_phases.filter(phase => phase.selected && ((phase.title === 'MVP') || (phase.title === 'Full Build')));
    if (selectedPhases && (selectedPhases.length > 0)) {
      return true;
    }
    return false;
  }

  checkIfBuildCardForRapidPrototype() {
    if (this.selectedPhases && (this.selectedPhases.length === 1) && this.selectedPhases[0].title === 'Professional Prototype') {
      return true;
    }
    return false;
  }

  public checkIfStudioStorePromoModule() {
    return (this.dataService.currentPage && (this.dataService.currentPage === 'paymentPlan' ||
      this.dataService.currentPage === 'summary'));
  }

  public isRentalBuildCard() {
    return this.dataService.buildCardData && this.dataService.buildCardData.type === 'rental_price' && this.dataService.buildCardData.source === 'app_detail';
  }

  public isDiscountAppliedForRental() {
    if ((this.isRentalBuildCard() && (this.dataService.buildCardData && this.dataService.buildCardData.promotion && (this.dataService.buildCardData.promotion ?.type === 'MonthDiscountPromotion') ))
      || (this.dataService.currentPage === 'templateDetail' && this.checkIfRentalPricingModal() && ((this.dataService.manualPromotion
        && (this.dataService.manualPromotion ?.type === 'MonthDiscountPromotion'))
        || (this.dataService.homePageData.promotion && (this.dataService.homePageData.promotion ?.type === 'MonthDiscountPromotion'))))) {
      return true;
    }
    return false;
  }

  public getRentaMonthsAfterDiscount() {
    let rentalMonths;
    if (this.isRentalBuildCard() && (this.dataService.buildCardData && this.dataService.buildCardData.promotion && this.dataService.buildCardData.promotion.type === 'MonthDiscountPromotion')) {
      rentalMonths = this.getMonthlyOrRentalMonths() - this.dataService.buildCardData.promotion.months_discount;
    } else if (this.dataService.manualPromotion && this.dataService.manualPromotion.type === 'MonthDiscountPromotion') {
      rentalMonths = this.getMonthlyOrRentalMonths() - this.dataService.manualPromotion.months_discount;
    } else if (this.dataService.homePageData.promotion && this.dataService.homePageData.promotion.type === 'MonthDiscountPromotion') {
      rentalMonths = this.getMonthlyOrRentalMonths() - this.dataService.homePageData.promotion.months_discount;
    }
    return rentalMonths;
  }

  public getTotalUpfrontPrice() {
    return (this.getRentalPrice() * this.getMonthlyOrRentalMonths());
  }

  public getDiscountedUpfrontPrice() {
    let upfrontMonths = this.selectedApps[0].upfront_discount_month || this.dataService.buildCardData.progress.apps[0].upfront_discount_month;
    let discountedMonths = this.getRentaMonthsAfterDiscount();
    let discountedUpfrontMonths;
    if (discountedMonths) {
      discountedUpfrontMonths = discountedMonths - upfrontMonths;
    } else {
      discountedUpfrontMonths = this.getMonthlyOrRentalMonths() - upfrontMonths;
    }
    return (this.getRentalPrice() * discountedUpfrontMonths);
  }

  handleNextPrevForRentalFlow(index?) {
    this.proceedClickedFromRental = false;
    index ? this.selectedSectionIndex = index : this.selectedSectionIndex++;
  }

  startPollingForPayment(isStop?) {
    if (isStop) {
      clearInterval(this.paymentStatusInterval);
    } else {
      this.paymentStatusInterval = setInterval(() => {
        if (this.apiPollSub) {
          this.apiPollSub.unsubscribe();
        }
        this.apiPollSub = this.apiRequest.pollBuildCardStatus(this.inProgressBuildCardId,this.installmentID).subscribe(data => {
          this.isRentalCardPaid = data.is_paid;
          if (this.isRentalCardPaid) {
            this.transactionId = data.transaction_id;
            clearInterval(this.paymentStatusInterval);
            if (!this.appDataService.urlParameters.is_freemium) {
              this.handleNextPrevForRentalFlow(4);
            }
            if (this.appDataService.urlParameters.upgrade_to_starter) {
              window.location.href = environment.FE_URL + '/thankyou?upgrade_to_starter=true&uniq_code=' + this.appDataService.urlParameters.uniq_code;
            }
            if (this.appDataService.urlParameters.upgrade_to_store) {
              window.location.href = environment.FE_URL + '/thankyou?upgrade_to_store=true&uniq_code=' + this.appDataService.urlParameters.uniq_code;
            }
            if (this.appDataService.urlParameters.is_freemium && !this.freemiumCardWithToken) {
              if (this.IsFromFreemium) {
                this.handleNextPrevForRentalFlow(5);
              }
              else { this.handleNextPrevForRentalFlow(6); }
            }
            this.windowToCLose.close();
          }
        }, error => {

        });
      }, 2000);
    }
  }

  public getRentaPromoMonths() {
    let rentalPromoMonths;
    if (this.isRentalBuildCard() && (this.dataService.buildCardData && this.dataService.buildCardData.promotion && this.dataService.buildCardData.promotion.type === 'MonthDiscountPromotion')) {
      rentalPromoMonths = this.dataService.buildCardData.promotion.months_discount;
    } else if (this.dataService.manualPromotion && this.dataService.manualPromotion.type === 'MonthDiscountPromotion') {
      rentalPromoMonths = this.dataService.manualPromotion.months_discount;
    } else if (this.dataService.homePageData.promotion && this.dataService.homePageData.promotion.type === 'MonthDiscountPromotion') {
      rentalPromoMonths = this.dataService.homePageData.promotion.months_discount;
    }
    return rentalPromoMonths;
  }

  getAdditionalFeaturesFroRental() {
    let totalFeatures = this.dataService.buildCardData.progress.features;
    let appFeatures = [];
    appFeatures = this.modelMapperService.getMappedArrayDataModel(appFeatures, this.dataService.buildCardData.progress.apps[0].features);

    let onlyInA = totalFeatures.filter(this.comparer(appFeatures));
    let onlyInB = appFeatures.filter(this.comparer(totalFeatures));

    this.additionalRentalFeatures = onlyInA.concat(onlyInB);
  }

  comparer(otherArray) {
    return function (current) {
      return otherArray.filter(function (other) {
        return other.id == current.id
      }).length == 0;
    }
  }

  getBuildCardPromotionMonth() {
    let rentalPromoMonths = 0;
    if (this.dataService.buildCardData.promotion && this.dataService.buildCardData.promotion.type === 'MonthDiscountPromotion') {
      rentalPromoMonths = this.dataService.buildCardData.promotion.months_discount;
    }
    return rentalPromoMonths;
  }

  getRentalBuildCardMonthsAfterDiscount() {
    return this.getMonthlyOrRentalMonths() - this.getBuildCardPromotionMonth();
  }

  resetTermsAndConditions() {
    this.contractTermsConditionsChecked = false;
  }

  checkIfAllContractTermsSelected() {
    if (this.contractTermsConditionsChecked) {
      return true;
    }
    return false;
  }

  setContractTermsAndConditions() {
    this.contractTermsConditionsChecked = true;
  }

  public convertCloudPriceBasedOnUserCurrency() {
    let cloudDetails = JSON.parse(JSON.stringify(this.builderCloudDetailsForUSD));
    const buildCardStatus = this.buildCardEditMode || this.dataService.isIncompleteCardEditMode || this.isFeatureRequest;
    let exchangeRate = buildCardStatus ? this.dataService.buildCardData.currency.exchange_rate : this.dataService.homePageData.currency.exchange_rate;

    if (cloudDetails) {
      for (const prop in cloudDetails) {
        let obj = cloudDetails[prop];
        obj.monthly_cost = obj.monthly_cost * exchangeRate;
        obj.minimum_cost = obj.minimum_cost * exchangeRate;
        obj.maximum_cost = obj.maximum_cost * exchangeRate;
        obj.weekly_cost = obj.weekly_cost * exchangeRate;
        obj['studiostore_cost'] = obj.wallet_credits * exchangeRate;
      }
      this.builderCloudDetails = cloudDetails;
    }
    const cloudIndex = this.builderCloudDetails[this.selectedIndexForCloudPrice];
    if (this.isRentalBuildCard() || (this.dataService.appDetails && this.dataService.appDetails.pricing_modal === "rental_price")) {
      this.selectedHash.cloud_price = this.builderCloudDetails[this.selectedIndexForCloudPrice].studiostore_cost;
    } else {
      if (this.dataService.isEditBuildCardAfterSecurityPaid() && !this.dataService.buildCardData.maximum_cloud_price) {
        this.selectedHash.cloud_price = cloudIndex.monthly_cost;
      } else {
        this.selectedHash.maximum_cloud_price = cloudIndex.maximum_cost;
        this.selectedHash.minimum_cloud_price = cloudIndex.minimum_cost;
      }
    }

  }

  timePromotionApplied() {
    return (this.finalPricenWeeks.weeks < this.finalPricenWeeks.actual_weeks);
  }

  fetchUserShowData() {
    this.apiRequest.getUserShowData().subscribe(resp => {
      this.dataService.userCurrencyAndCountries['available_currency'] = resp['available_currency'];
    });
    this.apiRequest.getUserCountryData().subscribe((data: any) => {
      this.dataService.userCurrencyAndCountries['available_country'] = data.data;
    }, error => {
      this.commonService.showError();
    });
  }

  getFirstNonZeroInstallmentAmount() {
    let nonZeroInstallment;
    if (this.paymentData && this.paymentData.installments) {
      nonZeroInstallment = this.paymentData.installments.find(installment => installment.type === 'installment' && installment.installment_amount !== 0);
    }
    if (nonZeroInstallment) {
      return nonZeroInstallment.installment_amount;
    }
    return 0;
  }

  getInstallmentCountExceptDeposit() {
    let installmentsCount = 0;
    if (this.paymentData && this.paymentData.installments) {
      installmentsCount = this.paymentData.installments.filter(installment => installment.type !== 'deposit').length;
    }
    return installmentsCount;
  }

  sendInvitationForOwnershipTransfer() {
    this.apiRequest.sendInvitationForOwnershipTransfer(this.emailToEnterForOwnershipTransfer).subscribe(data => {
      this.ownershipTransferInvitationSentMessage = true;
      this.ownershipTransferUserNotAvailableMessage = false;
    });
  }

  retryOwnershipTransfer() {
    this.showHideRemoveAllPopup();
    this.ownershipTransferPopup = true;
  }


  getOldFeatureCount() {
    if (this.dataService.buildCardData.modification_request) {
      return this.dataService.buildCardData.modification_request.base_build_card_data.feature_ids.length;
    } else {
      return this.previousSelectedFeatureCount;
    }
  }

  getCloudDetails(isStudioStore?) {

    const data = {
      currency: this.currencyForCAT,
      features: {
        builder_feature_ids: this.getFeatureIdsForCloudData(),
        custom_features: this.selectedFeatures.filter(feature => feature.user_requested)
      },
      location: this.selectedTeam ? this.selectedTeam.title : 'Anywhere',
      platforms: this.selectedPlatforms.map(platform => platform.title),
      templates: [this.dataService.appDetails.id]
    };
    if (isStudioStore) {
      data['build_card_type'] = "studio-store";
    }
    this.apiRequest.fetchBuilderCloudDetails(data).subscribe((resp: BuilderCloudModel) => {
      this.builderCloudDetails = resp;
      this.builderCloudDetailsForUSD = JSON.parse(JSON.stringify(resp));
      this.convertCloudPriceBasedOnUserCurrency();
      this.selectedHash.cloud_user_count = 500;
    });
  }


  getFeatureIdsForCloudData() {
    const featureIds = [];
    for (const feature of this.selectedFeatures) {
      if (!feature.user_requested) { featureIds.push(feature.id); }
    }
    return featureIds;
  }

  public isCurrentCurrencyForRentalApp(currency: CurrencyModel) {
    if (this.currencyForNow) {
      return (
        this.currencyForNow &&
        this.currencyForNow.id == currency.id
      );
    }
    return this.isCurrentCurrency(currency);
  }


  getArrayOfOneFieldFromArrayOfObj(arr, field) {
    let tempArr = [];
    if (Array.isArray(arr) && arr.length > 0) {
      tempArr = arr.map(obj => {
        return obj[field];
      });
    }
    return tempArr;
  }

  getDateTimeDifferenceFromToday(dateTimeToCompare) {
    //dateTimeToCompare = '2020-08-26 00:10:22';
    const today = this.moment();
    let ms = this.moment(today, 'YYYYMMDD HH:mm:ss').diff(this.moment(dateTimeToCompare, 'YYYYMMDD HH:mm:ss'));
    let d = this.moment.duration(ms);
    if (d.days() === 1) {
      return 'yesterday';
    } else if (d.days() > 1) {
      return this.moment(dateTimeToCompare).format('ll');
    } else if (d.hours() >= 1 && d.hours() < 24) {
      return (d.hours() === 1 ? (d.hours() + ' hour ago') : (d.hours() + ' hours ago'));
    } else if (d.minutes() >= 1 && d.minutes() < 60) {
      return (d.minutes() === 1 ? (d.minutes() + ' minute ago') : (d.minutes() + ' minutes ago'));
    } else if (d.minutes() < 1) {
      return 'less than a minute ago';
    }
  }

  closeTutorial(isGotItClicked?) {
    if (this.dataService.noMorePricingModal && this.commonService.isPlatformBrowser) {
      localStorage.setItem('show_dynamic_pricing_popup', 'NO');
    }
    this.dataService.showFeatureTour = false;
    this.dataService.showDynamicPriceModal = false;
    this.dataService.questionMarkClicked = false;
    this.dataService.notifyTutorialComing = false;
    this.dataService.dynamicPricingModalForAllRecommendedFeatures = false;
    this.dataService.dynamicPricingModalForSingleFeature = false;
  }

  applyFeaturePageSettings(isApplyClicked?) {
    this.dataService.dynamicPricingSetting.value = this.dataService.showDynamicPricingModalSetting;
    this.dataService.noMorePricingModal = !this.dataService.showDynamicPricingModalSetting;
    if (isApplyClicked) {
      this.dataService.showDynamicPriceModal = false;
      if (!this.dataService.noMorePricingModal && this.commonService.isPlatformBrowser) {
        localStorage.removeItem('show_dynamic_pricing_popup');
      } else if (this.dataService.noMorePricingModal && this.commonService.isPlatformBrowser) {
        localStorage.setItem('show_dynamic_pricing_popup', 'NO');
      }
    }
    this.dataService.dynamicPricingSetting.isChanged = true;
    this.dataService.featureRemovalConfirmSetting.value = this.dataService.showFeatureRemovalConfirmModalSetting;
    this.dataService.featureRemovalConfirmSetting.isChanged = true;
    this.dataService.featureRecommendationsConfirmSetting.value = this.dataService.showFeatureRecommendationsConfirmModalSetting;
    this.dataService.featureRecommendationsConfirmSetting.isChanged = true;
    let requestObj = {
      dynamic_pricing: this.dataService.dynamicPricingSetting.value,
      feature_removal_confirmation: this.dataService.featureRemovalConfirmSetting.value,
      is_recommendation_on: this.dataService.featureRecommendationsConfirmSetting.value
    };
    if (this.dataService.user) {
      this.apiRequest.updateUserSettingForFeaturePage(requestObj).subscribe((data) => {
      });
    }
    this.dataService.showSettingsPopup = false;
    this.dataService.natashaDropdownPanel = false;
  }

  getWordCount(text) {
    return text.split(' ').length;
  }

  getPulseCount(wordCount) {
    if (wordCount >= 1 && wordCount <= 20) {
      return 1;
    } else if (wordCount >= 21 && wordCount <= 30) {
      return 2;
    } else if (wordCount > 30) {
      return 3;
    }
  }

  getNatashaMessageDelay() {
    let totalWordCount = 0;
    for (let i = 0; i < this.natashaTempIntroMessagesList.length - 1; i++) {
      totalWordCount += this.natashaTempIntroMessagesList[i].wordCount;
    }
    let delay = 0.25 * totalWordCount;
    return delay;
  }

  resetNatashaBot() {
    this.natashaIntroMessagesList = [];
    this.natashaTempIntroMessagesList = [];
    this.recommendedFeaturesSliderIndex = 0;
  }

  getCurrentTimestamp() {
    return new Date().getTime();
  }

  openMeetNoeModal(event?) {
    this.trackEventForObject(this.analyticsSegment,  'book_a_spec_call_clicked', {
      screen_source: this.dataService.currentPage,
      user_id: this.dataService.user ?.id
    });
    if (event) {
      event.stopPropagation();
    }
    if (!this.dataService.user) {
      this.openCommonAuth = true;
      if (this.dataService.homePageData?.block_sign_up) {
        this.showSignin = true;
        this.showRegister = false;
      } else {
        this.showRegister = true;
        this.showSignin = false;
      }
      this.dataService.openMeetNowModal = true;
      return;
    }
    if (this.dataService.user.spec_call_nda_signed || this.showInstantSpecCallFlow) {
      this.dataService.showMeetNowModel = true;
    } else {
      this.showSignNDAPopup = true;
    }
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  getNatashaIdleQuestionCount() {
    this.natashaIdleQuestionCount = parseInt(localStorage.getItem('natashaIdleQuestionCount'));
    this.natashaIdleQuestionCount = isNaN(this.natashaIdleQuestionCount) ? 0 : this.natashaIdleQuestionCount;
    localStorage.setItem('natashaIdleQuestionCount', this.natashaIdleQuestionCount);
    return this.natashaIdleQuestionCount;
  }

  shouldShowWelcomePopup() {
    if (this.commonService.isPlatformBrowser) {
      if (localStorage.getItem('welcome_screen_expiry')) {
        let storedTime = parseInt(localStorage.getItem('welcome_screen_expiry'));
        storedTime = isNaN(storedTime) ? 0 : storedTime;
        let currentTime = Math.ceil(Number(Date.now() / 1000));
        let difference = currentTime - storedTime;
        if (difference - this.fiveDaySecondsConstant > 0) {
          localStorage.removeItem('welcome_screen_seen');
          localStorage.removeItem('welcome_screen_letstart');
          return true;
        }
        return false;
      }
      return true;
    }
  }

  getCurrentTimeInHoursMinutes() {
    let d = new Date();
    let h = (d.getHours() < 10 ? '0' : '') + d.getHours();
    let m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    return h + ':' + m;
  }

  cloneBuildCard(uniqueCode, currency, forSameCustomer?) {
    this.cardIdToBeCloned = uniqueCode;
    this.commonService.duplicate_for_same_customer = forSameCustomer;
    if (this.dataService.checkIfCPEUser()) {
      this.isCpeCloningCard = true;
      this.clonedCardCurrency = currency;
      this.commonService.isDuplicateCardFlow = false;
      return;
    }
    this.dataService.showHideLoader(true);
    const curr = this.clonedCardCurrency ? this.clonedCardCurrency.id : '';
    this.apiRequest.cloneBuildCard(uniqueCode, curr).subscribe(resp => {
      this.dataService.showHideLoader(false);
      this.commonService.isDuplicateCardFlow = false;
      window.open(`${window.location.origin}/build-card/${resp.data.attributes.uniq_code}`);
    }, (error) => {
      this.dataService.showHideLoader(false);
      this.commonService.showError('Something Went Wrong.');
      this.commonService.isDuplicateCardFlow = false;
    });
  }

  startPollingForSpecCall(isStop?) {
    let seconds = 0;
    const cancelApiCalling = setInterval(() => {
      seconds += 1;
    }, 1000);
    if (isStop) {
      clearInterval(this.specCallInterval);
    } else {
      this.specCallInterval = setInterval(() => {
        if (this.specCallApiSubPoll) {
          this.specCallApiSubPoll.unsubscribe();
        }
        this.specCallApiSubPoll = this.apiRequest.checkIfSpecCallSchedule(this.dataService.buildCardData.id).subscribe(resp => {
          if (resp['scheduled_date']) {
            resp['scheduled_date'] = this.moment(resp['scheduled_date']).format('dddd, DD MMM YYYY');
            this.scheduleCallData = resp;
            this.specCallIsScheduled = true;
            clearInterval(this.specCallInterval);
            clearInterval(cancelApiCalling);
          } else if (seconds > 3600) {
            clearInterval(this.specCallInterval);
            clearInterval(cancelApiCalling);
          }
        }, (error) => {
          clearInterval(this.specCallInterval);
          clearInterval(cancelApiCalling);
        });
      }, 2000);
    }
  }

  resetCustomFeatureFlowFromNatasha() {
    this.isCustomFeatureFlowFromNatasha = false;
    this.customFeatureObjectFromNatasha = {
      id: '',
      title: '',
      description: '',
      template_ids: [],
      difficulty: 6,
      algorithm: 'none',
      stream_media: false,
      interface_type: 'backend',
      reference_urls: [],
      selected: false
    };
    this.isShowCustomFeatureTitleQuestion = false;
    this.isShowCustomFeatureDescriptionQuestion = false;
    this.isShowCustomFeatureSimilarAppsQuestion = false;
  }

  resetNatashaChatsOnFeaturePage() {
    this.natashaChatsList = {};
    this.natashaGroupIdentifier = '';
    this.natashaGroupChatId = 1;
    this.natashaGroupIdText = 'natashaGroupId';
  }

  checkPopupBlockedAndRedirect(url) {
    const popup_window = window.open(url, '_blank', "noopener");
    try {
      popup_window.focus();
    } catch (e) {
      this.isPopUpBlockerEnabled = true;
    }
  }

  showIntercomChat() {
    this.trackEventForObject(this.analyticsSegment, 'live_chat_initiated', {
      screen_source: this.dataService.currentPage,
      user_id: this.dataService.user ?.id
    });
    this.specCallCreationData = null;
    const evt = new CustomEvent('MyEventType', { detail: '1234' });
    window.dispatchEvent(evt);
    this.showIntercomChatWindow = false;
    setTimeout(() => {
      this.showIntercomChatWindow = true;
    }, 2000);
    this.showNav = false;
  }

  getPhaseTitle() {
    this.phaseTitle = [];
    this.selectedPhases.forEach(phase => {
      const title = phase.title;
      this.phaseTitle.push(title);
    });
    return this.phaseTitle;
  }

  checkCareType() {
    if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.is_under_support !== null) {
      this.selectedHash.is_under_support = this.dataService.modifiedBuildCardData.is_under_support;
      return this.setDefaultCare(this.dataService.modifiedBuildCardData);
    } else if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.is_under_support == null) {
      return this.setDefaultCare(this.dataService.buildCardData.modification_request.base_build_card_data);
    } else {
      return this.setDefaultCare(this.dataService.buildCardData);
    }
  }

  setDefaultCare(buildCardData) {
    if (!buildCardData.is_under_support || (buildCardData.is_under_support && !buildCardData.care_type)) {
      buildCardData['care_type'] = this.setCareAccordingUser();
    }
    if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.support_maintenance_price.new) {
      if(this.dataService.modifiedBuildCardData['care_type'] && this.dataService.modifiedBuildCardData['care_type']['add']) {
        return this.dataService.modifiedBuildCardData['care_type']['add'];
      }

    }
    return buildCardData['care_type'];
  }

  setCareAccordingUser() {
    if (this.checkIfUnPaidMaxCardPage()) {
      return (this.dataService.buildCardData && (this.dataService.buildCardData.enterprise_lead === 'true')) ? 'enterprise_care_plus' : 'standard_care';
    } else {
      return this.dataService.checkIfEnterpriseUserOrCard() ? 'enterprise_care_plus' : 'standard_care';
    }
  }

  checkIfUnPaidMaxCardPage() {
    if ((this.dataService.buildCardData.status == 'card_initialized' || this.dataService.buildCardData.status == 'card_completed') && (this.dataService.currentPage !== 'features') && (this.dataService.currentPage !== 'delivery')) {
      return true;
    }
  }

  getDiscountedPriceForCards(data) {
    const buildCardData = this.dataService.buildCardData;
    const checkForChangeCost = !this.dataService.isEditBuildCardAfterSecurityPaid() && !this.isFeatureRequest;
    const changeCost = (buildCardData.change_price_status ? buildCardData.change_amount : -buildCardData.change_amount);
    if (data.new_care_flow || this.dataService.buildCardData.new_care_flow) {
      return checkForChangeCost ? (data.bcard_discounted_price + changeCost) : data.bcard_discounted_price;
    } else {
      return checkForChangeCost ? (data.discounted_price + changeCost) : data.discounted_price;
    }
  }

  getPriceForCards(data) {
    if (data.new_care_flow || this.dataService.buildCardData.new_care_flow) {
      return data.bcard_price;
    } else {
      return data.price;
    }
  }

  get isStoreFeatureRequest() {
    return (this.isFeatureRequest && (this.dataService.buildCardData.type === 'rental_price'));
  }

  checkIfNatashaShownInStudioStore() {
    //return this.dataService.user && this.appDetails && (this.appDetails.id === 9785) && (this.appDetails.title === 'E Commerce App & Website');
    return this.dataService.user && this.dataService.appDetails && this.appDataService.urlParameters.is_dls_store_flow;
  }

  checkIfSelfServeUser() {
    return (this.appDataService.urlParameters.is_self_serve || this.dataService.user.is_self_serve_user);
  }
}

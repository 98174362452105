import {
  BehaviorSubject,
  Observable,
  throwError as observableThrowError,
} from "rxjs";
import { forkJoin } from "rxjs";
import moment from "moment";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpRequests } from "./http-requests.service";
import { Constants } from "../utils/constants";
import { DataService } from "./data.service";
import { AppModel } from "../models/app-model/app.model";
import { HomepageDataModel } from "../models/homepage-data-model/homepage-data.model";
import { environment } from "../../../../environments/environment";
import { SpecCallModel } from "../models/spec-call-model/spec-call.model";
import { IndsectiondataModel } from "../models/indsectiondata-model/indsectiondata.model";
import { Router } from "@angular/router";
import { UserModel } from "@models/user-model/user.model";
import { PromotionModel } from "@models/homepage-data-model/promotion.model";
import { BundleModel } from "@models/bundle-model/bundle-model";
import { FeatureModel } from "@models/feature-model/feature.model";
import { ModelMapperService } from "@shared/services/model-mapper.service";
import { BuildCardModel } from "@models/build-card-model/build-card.model";
import { BuildCardPhasesAttributesModel } from "@models/build-card-model/build-card-phases-attributes.model";
import { AppDataService } from "@rootmodule/app-data.service";
import { TemplateDetailModel } from "@models/template-detail-model/template.detail.model";
import { CommonService } from "@shared/services/common.service";
import { ChangeFeatureRequestModel } from "@models/feature-model/change-feature-request.model";
import { PrototypeInstallmentModel } from "@models/prototype-installment-model/prototype-installment.model";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ExitIntentFeedbackModel } from "@models/Exit-intent-feedback-model/exit-intent-feedback-model";
// import { identifierModuleUrl } from '@angular/compiler';
declare let window: any;

/**
 * Created by nitin on 18/12/17.
 */
@Injectable()
export class ApiRequests {
  public promotionData: PromotionModel;
  buildCardSectionForRental = {};
  moment: any;

  constructor(
    public appDataService: AppDataService,
    public httpRequest: HttpRequests,
    public dataService: DataService,
    private router: Router,
    private modelMapperService: ModelMapperService,
    private commonService: CommonService,
    public http: HttpClient
  ) {
    this.promotionData = new PromotionModel();
    this.moment = moment;
  }

  public getUserProfile(isPayment?) {
    let API = environment.API_URL + Constants.apiEndPoints.user;
    if (isPayment) {
      API =
        API +
        Constants.PARAM_CONSTANTS.BUILD_CARD_ID +
        this.dataService.buildCardData.id;
    }
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs(null, true))
      .pipe(
        map((res) => {
          this.dataService.errorMessage = false;
          this.setUpUserData(res, true, isPayment);
        }),
        catchError((error: any) => {
          this.showErrorMessage(error, true);
          this.dataService.user = null;
          return observableThrowError(error);
        })
      );
  }

  public fetchPercentagePromotionList() {
    const API =
      environment.API_URL + Constants.apiEndPoints.percentage_promotion_list;
    return this.httpRequest.get(API).pipe(
      map((data) => {
        this.dataService.promotionList = [];
        if (data && data.promotion && data.promotion.data) {
          this.dataService.promotionList =
            this.modelMapperService.getMappedArrayModel(
              this.dataService.promotionList,
              data.promotion.data
            );
        }
        return this.dataService.promotionList;
      }),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  public fetchExperiments() {
    const API =
      environment.API_URL + Constants.apiEndPoints.experiment_promotion;
    return this.httpRequest.get(API).pipe(
      map((data) => {
        if (data.page_content) {
          this.dataService.signupModalDynamicContent =
            this.modelMapperService.getMappedArrayModel(
              this.dataService.signupModalDynamicContent,
              data.page_content.data
            );
        }
        if (data.promotion && data.promotion.data) {
          const exData = data.promotion.data;
          for (const k in exData) {
            const promoObj: PromotionModel = new PromotionModel();
            exData[k] = this.modelMapperService.getPromotionModelMapped(
              promoObj,
              exData[k].attributes
            );
            const promo_type = exData[k]["promotion_type"];
            this.dataService.experimentPromotion[promo_type] = exData[k];
          }
        }
        return this.dataService.experimentPromotion;
      }),
      catchError((error: any) => {
        if (this.commonService.isPlatformBrowser) {
          if (
            error &&
            (error.status === 0 ||
              error.status === 500 ||
              error.status === 502 ||
              error.status === 504) &&
            (this.dataService.currentPage === "" ||
              this.dataService.currentPage === "home") &&
            this.commonService.isPlatformBrowser
          ) {
            this.dataService.errorMessage = false;
            this.router.navigate(["maintenance"]);
          }
        }
        return observableThrowError(error);
      })
    );
  }

  public fetchHomePageData() {
    if (this.commonService.isPlatformBrowser) {
      if (this.appDataService.urlParameters.is_freemium) {
        this.appDataService.urlParameters.promotionCode =
          this.dataService.user?.currency.default_store_promo_code;
      }
      let API = environment.API_URL + Constants.apiEndPoints.homepage;
      /*if (this.appDataService.urlParameters.uniq_code) {
        //API += (Constants.PARAM_CONSTANTS.UNIQ_CODE + this.appDataService.urlParameters.uniq_code);
      }*/
      if (this.appDataService.urlParameters.promotionCode) {
        API +=
          Constants.PARAM_CONSTANTS.PROMOTION +
          this.appDataService.urlParameters.promotionCode.toLowerCase();
      }
      if (this.appDataService.urlParameters.expCode) {
        let expCode = Constants.PARAM_CONSTANTS.EXP_CODE;
        if (API.includes("?")) {
          expCode = "&exp=";
        }
        API += expCode + this.appDataService.urlParameters.expCode.toLowerCase();
      }
      if (this.appDataService.urlParameters.currency_id) {
        this.dataService.currencyInUrl =
          this.appDataService.urlParameters.currency_id;
        API += API.includes("?")
          ? "&currency_id=" + this.appDataService.urlParameters.currency_id
          : "?currency_id=" + this.appDataService.urlParameters.currency_id;
      }
      return this.httpRequest
        .get(API, this.appDataService.getRequestOptionArgs())
        .pipe(
          map((data) => {
            this.dataService.expertImages = data.productologists;
            if (
              this.dataService.expertImages &&
              this.dataService.expertImages.length > 0
            ) {
              let randomNum = this.dataService.getRandomValueForList(
                this.dataService.expertImages
              );
              this.dataService.expertIcon =
                this.dataService.expertImages[randomNum];
            }
            this.dataService.setupHomePageData(data as HomepageDataModel);
            if (
              this.dataService.homePageData.multiplier_experiment &&
              this.dataService.homePageData.multiplier_experiment.id
            ) {
              this.appDataService.urlParameters.expCode =
                this.dataService.homePageData.multiplier_experiment.code;
            }
            if (this.dataService.user) {
              this.getUserShowData().subscribe((resp) => {
                this.dataService.userCurrencyAndCountries["available_currency"] =
                  resp["available_currency"];
              });
              this.getUserCountryData().subscribe(
                (res: any) => {
                  this.dataService.userCurrencyAndCountries["available_country"] =
                    res.data;
                },
                (error) => {
                  this.commonService.showError();
                }
              );
              this.appDataService.multiplierChange.next(true);
            }
            return data;
          }),
          catchError((error: any) => {
            if (this.commonService.isPlatformBrowser) {
              if (
                error &&
                (error.status === 0 ||
                  error.status === 500 ||
                  error.status === 502 ||
                  error.status === 504)
              ) {
                this.router.navigate(["maintenance"]);
              } else {
                this.showErrorMessage(error);
              }
            }
            return observableThrowError(error);
          })
        );
    }
  }

  public fetchNewHomePageData(authToken?) {
    let API = environment.API_URL + Constants.apiEndPoints.newhomepage;
    if (this.appDataService.urlParameters.uniq_code) {
      API +=
        Constants.PARAM_CONSTANTS.UNIQ_CODE +
        this.appDataService.urlParameters.uniq_code;
    }
    if (this.appDataService.urlParameters.promotionCode) {
      API +=
        Constants.PARAM_CONSTANTS.PROMOTION +
        this.appDataService.urlParameters.promotionCode.toLowerCase();
    }
    if (authToken && authToken !== "") {
      return this.httpRequest
        .get(API, this.appDataService.getRequestOptionArgs())
        .pipe(
          map((data) => {
            return data;
          }),
          catchError((error: any) => {
            if (
              error &&
              (error.status === 0 ||
                error.status === 500 ||
                error.status === 502 ||
                error.status === 504) &&
              (this.dataService.currentPage === "" ||
                this.dataService.currentPage === "home") &&
              this.commonService.isPlatformBrowser
            ) {
              this.router.navigate(["maintenance"]);
            }
            return observableThrowError(error);
          })
        );
    } else {
      return this.httpRequest.get(API).pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          if (
            error &&
            (error.status === 0 ||
              error.status === 500 ||
              error.status === 502 ||
              error.status === 504) &&
            (this.dataService.currentPage === "" ||
              this.dataService.currentPage === "home") &&
            this.commonService.isPlatformBrowser
          ) {
            this.router.navigate(["maintenance"]);
          }
          return observableThrowError(error);
        })
      );
    }
  }

  public fetchNewHomePageDataById(
    id,
    authToken?,
    currencyId?,
    newSelectedProductId?
  ): Observable<IndsectiondataModel> {
    let API =
      environment.API_URL + Constants.apiEndPoints.newhomepage + "/" + id;
    if (this.appDataService.urlParameters.uniq_code) {
      API +=
        Constants.PARAM_CONSTANTS.UNIQ_CODE +
        this.appDataService.urlParameters.uniq_code;
    }
    if (this.appDataService.urlParameters.promotionCode) {
      API +=
        Constants.PARAM_CONSTANTS.PROMOTION +
        this.appDataService.urlParameters.promotionCode.toLowerCase();
    }
    if (
      this.appDataService.urlParameters.expCode ||
      this.dataService.buildCardData.multiplier_experiment
    ) {
      API = this.setMultiplierExperimentInQueryParams(
        API,
        API.includes("?") ? "&" : "?"
      );
    }
    if (currencyId) {
      const querySign = API.includes("?") ? "&" : "?";
      API += querySign + "currency_id=" + currencyId;
      if (newSelectedProductId) {
        API += "&product_id=" + newSelectedProductId;
      }
    }

    if (authToken && authToken !== "") {
      return this.httpRequest
        .get(API, this.appDataService.getRequestOptionArgs())
        .pipe(
          map((data: IndsectiondataModel) => {
            data = new IndsectiondataModel(data.section, data.section_details);

            // this.dataService.setupHomePageData(data as HomepageDataModel);
            return data;
          }),
          catchError((error: any) => {
            if (
              error &&
              (error.status === 0 ||
                error.status === 500 ||
                error.status === 502 ||
                error.status === 504) &&
              (this.dataService.currentPage === "" ||
                this.dataService.currentPage === "home") &&
              this.commonService.isPlatformBrowser
            ) {
              this.router.navigate(["maintenance"]);
            }
            return observableThrowError(error);
          })
        );
    } else {
      return this.httpRequest.get(API).pipe(
        map((data: IndsectiondataModel) => {
          data = new IndsectiondataModel(data.section, data.section_details);
          // this.dataService.setupHomePageData(data as HomepageDataModel);
          return data;
        }),
        catchError((error: any) => {
          if (
            error &&
            (error.status === 0 ||
              error.status === 500 ||
              error.status === 502 ||
              error.status === 504) &&
            (this.dataService.currentPage === "" ||
              this.dataService.currentPage === "home") &&
            this.commonService.isPlatformBrowser
          ) {
            this.router.navigate(["maintenance"]);
          }
          return observableThrowError(error);
        })
      );
    }
  }

  public fetchPrototypeInstallments(installment_id) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.installment_prototype +
      "/" +
      installment_id;
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data.data.attributes;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public fetchInstallments(buildCardId, phaseId?) {
    let API = environment.API_URL + Constants.apiEndPoints.installments;
    API = API.replace("[build_card_id]", buildCardId);
    if (
      this.dataService.buildCardData &&
      this.dataService.buildCardData.specing &&
      !this.dataService.buildCardData.specing_paid
    ) {
      API = API + "&status=specing";
    }
    if (phaseId) {
      API = API + "&build_card_phase_id=" + phaseId;
    }
    if (location.href.includes('paid_using_bnpl')) {
      API = API + '&paid_using_bnpl=true';
    }
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public fetchCategories(productId?): any {
    const buildCardType =
        this.dataService.isEditBuildCard ||
        this.dataService.isIncompleteCardEditMode,
      currencyId = buildCardType
        ? this.dataService.buildCardData.currency.id
        : this.dataService.homePageData.currency.id;
    let API = "";
    if (productId) {
      API = `${environment.API_URL}${Constants.apiEndPoints.categories}?currency_id=${currencyId}`;
    } else {
      API = `${environment.API_URL}categories?currency_id=${currencyId}`;
    }
    API = API.replace("product_id", productId);
    return this.httpRequest.get(API).pipe(
      map((data) => {
        return data;
      }),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  public validateEmail(email) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.emailValidator.replace("[email]", email);
    return this.httpRequest.get(API).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        this.dataService.user = null;
        return observableThrowError(error);
      })
    );
  }

  public fetchProductsList(keyword) {
    const API = Constants.productListUrl.replace("[query]", keyword);
    return this.httpRequest.get(API).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  public fetchTemplates(
    product_id?,
    filterArr?: any[],
    page?,
    per_page?,
    application_ids?
  ) {
    let apiUrl;
    const buildCardType =
        this.dataService.isEditBuildCard ||
        this.dataService.isIncompleteCardEditMode,
      currencyid = buildCardType
        ? this.dataService.buildCardData.currency.id
        : this.dataService.homePageData.currency &&
          this.dataService.homePageData.currency.id
        ? this.dataService.homePageData.currency.id
        : "";
    if (product_id) {
      apiUrl =
        environment.API_URL +
        Constants.apiEndPoints.templates +
        Constants.PARAM_CONSTANTS.PRODUCT_ID +
        product_id +
        Constants.PARAM_CONSTANTS.CURRENCY_ID +
        currencyid;
    } else {
      apiUrl =
        environment.API_URL +
        Constants.apiEndPoints.templates +
        "?currency_id=" +
        currencyid;
    }
    if (
      this.appDataService.urlParameters.expCode ||
      this.dataService.buildCardData.multiplier_experiment
    ) {
      apiUrl = this.setMultiplierExperimentInQueryParams(apiUrl, "&");
    }

    if (filterArr && filterArr.length > 0) {
      filterArr.forEach((x) => {
        apiUrl = apiUrl + `&${x.key}=${x.value}`;
      });
    }

    if (page) {
      apiUrl = apiUrl + "&page=" + page;
    }
    if (per_page) {
      apiUrl = apiUrl + "&per_page=" + per_page;
    }
    if (application_ids && application_ids.length > 0) {
      apiUrl = apiUrl + "&selected_templates_ids=" + application_ids.toString();
    }

    if (this.dataService.user && this.dataService.user.authtoken) {
      return this.httpRequest
        .get(apiUrl, this.appDataService.getRequestOptionArgs())
        .pipe(
          map((data) => {
            if (page === 1) {
              this.dataService.apps = data;
            } else {
              this.dataService.apps.push(...data);
            }
            return data;
          })
        );
    } else {
      return this.httpRequest.get(apiUrl).pipe(
        map((data) => {
          if (page === 1) {
            this.dataService.apps = data;
          } else {
            this.dataService.apps.push(...data);
          }
          return data;
        })
      );
    }
  }

  public searchTemplates(
    productId,
    searchObj: { query: string; sortBy: string; order: string }
  ) {
    const buildCardType =
        this.dataService.isEditBuildCard ||
        this.dataService.isIncompleteCardEditMode,
      currencyid = buildCardType
        ? this.dataService.buildCardData.currency.id
        : this.dataService.homePageData.currency.id;
    let apiUrl = `${environment.API_URL}templates/search${Constants.PARAM_CONSTANTS.PRODUCT_ID}${productId}${Constants.PARAM_CONSTANTS.CURRENCY_ID}${currencyid}`;
    if (searchObj) {
      if (searchObj.query) {
        apiUrl += `&query=${searchObj.query}`;
      }
      if (searchObj.sortBy) {
        apiUrl += `&sort_by=${searchObj.sortBy}&order=${searchObj.order}`;
      }
    } else {
      apiUrl = `${environment.API_URL}templates/search${Constants.PARAM_CONSTANTS.PRODUCT_ID}${productId}${Constants.PARAM_CONSTANTS.CURRENCY_ID}${this.dataService.homePageData.currency.id}`;
    }
    if (
      this.appDataService.urlParameters.expCode ||
      this.dataService.buildCardData.multiplier_experiment
    ) {
      apiUrl = this.setMultiplierExperimentInQueryParams(apiUrl, "&");
    }
    return this.httpRequest.get(apiUrl).pipe(
      map((data) => {
        this.dataService.apps = data as AppModel[];
      })
    );
  }

  public fetchTeams() {
    const API = environment.API_URL + Constants.apiEndPoints.teams;
    return this.httpRequest.get(API).pipe(
      map((data) => {
        return data;
      }),
      catchError((error: any) => {
        this.dataService.user = null;
        this.showErrorMessage(error);
        return observableThrowError(error);
      })
    );
  }

  public fetchFeatures(
    templates?,
    selectedFeatures?,
    filterParams?: any,
    template_filter_ids?: any
  ) {
    const params = new FormData();
    if (
      templates != null &&
      templates.constructor === Array &&
      templates.length > 0
    ) {
      const underScoredKey = "template_ids";
      for (const item of templates) {
        params.append(
          underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
          item
        );
      }
    }
    if (
      template_filter_ids != null &&
      template_filter_ids.constructor === Array &&
      template_filter_ids.length > 0
    ) {
      const underScoredKey = "template_filter_ids";
      for (const item of template_filter_ids) {
        params.append(
          underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
          item
        );
      }
    }
    if (selectedFeatures && selectedFeatures.constructor === Array) {
      const underScoredKey = "selected_feature_ids";
      if (this.dataService.isEditBuildCard && selectedFeatures.length == 0) {
        params.append(
          underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
          "[]"
        );
      } else {
        for (const item of selectedFeatures) {
          params.append(
            underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
            item
          );
        }
      }
      params.append("is_filter", filterParams ? "true" : "false");
    } else if (filterParams) {
      const underScoredKey = "selected_feature_ids";
      params.append(underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS, "");
      params.append("is_filter", "true");
    }
    if (filterParams) {
      for (const item of Object.keys(filterParams)) {
        const value = filterParams[item];
        if (item === "feature_bundle_ids" || item === "feature_tag_ids") {
          if (value.length > 0) {
            const underScoredKey = item;
            for (const bundleId of value) {
              params.append(
                underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
                bundleId
              );
            }
          }
        } else {
          const underScoredKey = item;
          params.append(underScoredKey, value);
        }
      }
    }
    this.setCurrencyId(params);
    this.setMultiplierExperimentParams(params);
    let API_URL = environment.API_URL + Constants.apiEndPoints.features;
    if (this.appDataService.urlParameters.featureRequest && (this.dataService.buildCardData.type === 'rental_price')) {
      API_URL = API_URL + (API_URL.includes('?') ? '&' : '?') + 'building_block_store=true&build_card_id=' + this.dataService.buildCardData.id;
    }
    return this.httpRequest.post(API_URL, params).pipe(
      map((data) => {
        const bundleSize = data.length;
        this.dataService.featureBundles = [];
        this.dataService.filteredFeatures = [];
        if (filterParams) {
          this.modelMapperService.getMappedArrayModel(
            this.dataService.filteredFeatures,
            data.data
          );
        } else {
          // this.modelMapperService.getMappedArrayModel(this.dataService.featureBundles, data.data);
          data.forEach((bundle) => {
            const tempBundle = new BundleModel();
            for (const key in bundle) {
              tempBundle[key] = bundle[key];
            }
            this.dataService.featureBundles.push(tempBundle);
          });
          if (data && data.length > 0) {
            this.dataService.featureBundles[0].features =
              new Array<FeatureModel>();
            this.modelMapperService.getMappedArrayModel(
              this.dataService.featureBundles[0].features,
              data[0].features.data
            );
            if (this.appDataService.urlParameters.featureRequest) {
              this.dataService.bundlesFeatures = JSON.parse(
                JSON.stringify(this.dataService.featureBundles[0].features)
              );
              this.setParametersForRequestedFeatures();
            }
          }
        }
      })
    );
  }

  setParametersForRequestedFeatures() {
    this.dataService.featureBundles[0].features.forEach((feature) => {
      if (feature.selected === true) {
        feature["disabled"] = true;
        return feature;
      } else {
        feature["disabled"] = false;
        return feature;
      }
    });
    if (this.dataService.buildCardData.change_requests) {
      const requestedFeaturesIDs =
        this.dataService.buildCardData.change_requests.map(
          (feature) => feature.id
        );
      this.dataService.featureBundles[0].features.forEach((feature) => {
        if (requestedFeaturesIDs.indexOf(feature.id) !== -1) {
          feature.selected = true;
          feature.disabled = false;
        }
        return feature;
      });
    }
  }

  public fetchSelectedFeatures(templates?, selectedFeatures?) {
    const params = new FormData();
    if (
      templates != null &&
      templates.constructor === Array &&
      templates.length > 0
    ) {
      const underScoredKey = "template_ids";
      for (const item of templates) {
        params.append(
          underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
          item
        );
      }
    }

    if (selectedFeatures && selectedFeatures.constructor === Array) {
      const underScoredKey = "selected_feature_ids";
      if (this.dataService.isEditBuildCard && selectedFeatures.length == 0) {
        params.append(
          underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
          "[]"
        );
      } else {
        for (const item of selectedFeatures) {
          params.append(
            underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
            item
          );
        }
      }
      params.append("is_filter", "false");
    }

    this.setCurrencyId(params);
    this.setMultiplierExperimentParams(params);

    const API_URL =
      environment.API_URL + Constants.apiEndPoints.selected_features;

    return this.httpRequest.post(API_URL, params).pipe(map((data) => data));
  }

  public fetchFeatureFilters(templates) {
    let params = new HttpParams();
    if (
      templates != null &&
      templates.constructor === Array &&
      templates.length > 0
    ) {
      const underScoredKey = "template_ids";
      for (const item of templates) {
        params = params.append(
          underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
          item
        );
      }
    }
    params = this.setCurrencyIdForFilterData(params);
    params = this.setMultiplierExperimentParamsForFilterData(params);

    const API_URL =
      environment.API_URL +
      Constants.apiEndPoints.feature_filter +
      Constants.PARAM_CONSTANTS.QUESTION_MARK +
      params;
    return this.httpRequest.get(API_URL).pipe(map((data) => data));
  }

  public searchFeatures(query, templates, selectedFeatures?) {
    const params = new FormData();
    let API = environment.API_URL + Constants.apiEndPoints.feature_search;
    if (
      this.appDataService.urlParameters.expCode ||
      this.dataService.buildCardData.multiplier_experiment
    ) {
      API = this.setMultiplierExperimentInQueryParams(API, "?");
    }
    params.append("query", query);

    if (
      templates != null &&
      templates.constructor === Array &&
      templates.length > 0
    ) {
      const underScoredKey = "template_ids";
      for (const item of templates) {
        params.append(
          underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
          item
        );
      }
    }

    if (
      selectedFeatures &&
      selectedFeatures.constructor === Array &&
      selectedFeatures.length > 0
    ) {
      const underScoredKey = "selected_feature_ids";
      for (const item of selectedFeatures) {
        params.append(
          underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
          item.id ? item.id : item
        );
      }
    } else {
      const underScoredKey = "selected_feature_ids";
      params.append(underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS, "");
    }
    if (this.appDataService.urlParameters.featureRequest && (this.dataService.buildCardData.type === 'rental_price')) {
      API = API + (API.includes('?') ? '&' : '?') + 'building_block_store=true&build_card_id=' + this.dataService.buildCardData.id;
    }
    this.setCurrencyId(params);
    return this.httpRequest.post(API, params).pipe(
      map((data) => {
        this.dataService.featureList = [];
        this.modelMapperService.getMappedArrayModel(
          this.dataService.featureList,
          data.data
        );
      })
    );
  }

  getCardTime(card) {
    const startTime = this.moment
      .unix(
        new Date(
          card.scheduled_date + " " + card.scheduled_call_start + " GMT"
        ).getTime() / 1000
      )
      .format("h:mm A");
    card.scheduled_call_start = startTime;

    const date = this.moment(
      new Date(card.scheduled_date + " " + card.scheduled_call_start + " GMT")
    ).format("DD-MM-YYYY");
    card.scheduled_date = date;
    card["timezome"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public fetchBuildCards(page?, perPage?, status?) {
    let API;
    if (page && perPage) {
      API =
        environment.API_URL +
        Constants.apiEndPoints.build_card +
        "?page=" +
        page +
        "&per_page=" +
        perPage +
        "&status=" +
        status;
    } else if (perPage && status === "spec_calls") {
      API =
        environment.API_URL +
        Constants.apiEndPoints.build_card +
        "?page=" +
        page +
        "&per_page=" +
        perPage +
        "&status=" +
        status;
    } else {
      API =
        environment.API_URL +
        Constants.apiEndPoints.build_card +
        "?status=" +
        status;
    }
    if (this.dataService.searchText) {
      API = API + `&query=${this.dataService.searchText}`;
    }

    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          if (page) {
            if (status === "card_initialized") {
              if (data.card_initialized_build_card.length > 0) {
                for (const k in data.card_initialized_build_card) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data.card_initialized_build_card[k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data.card_initialized_build_card[k]
                    );
                }
                this.dataService.dashboardCards.card_initialized_build_card.push(
                  ...data.card_initialized_build_card
                );
              } else {
                this.dataService.loadMore = false;
              }
            } else if (status === "card_completed") {
              if (data.card_completed_build_card.length > 0) {
                for (const k in data.card_completed_build_card) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data.card_completed_build_card[k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data.card_completed_build_card[k]
                    );
                }
                this.dataService.dashboardCards.card_completed_build_card.push(
                  ...data.card_completed_build_card
                );
              } else {
                this.dataService.loadMore = false;
              }
            } else if (status === "started,under_development,on_hold") {
              if (data.running_build_card.length > 0) {
                for (const k in data.running_build_card) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data.running_build_card[k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data.running_build_card[k]
                    );
                }
                this.dataService.dashboardCards.running_build_card.push(
                  ...data.running_build_card
                );
              } else {
                this.dataService.loadMore = false;
              }
            } else if (status === "archived") {
              if (data.archived_build_card.length > 0) {
                for (const k in data.archived_build_card) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data.archived_build_card[k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data.archived_build_card[k]
                    );
                }
                this.dataService.dashboardCards.archived_build_card.push(
                  ...data.archived_build_card
                );
              } else {
                this.dataService.loadMore = false;
              }
            } else if (status === "invited") {
              if (data.invited_build_card.length > 0) {
                for (const k in data.invited_build_card) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data.invited_build_card[k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data.invited_build_card[k]
                    );
                }
                this.dataService.dashboardCards.invited_build_card.push(
                  ...data.invited_build_card
                );
              } else {
                this.dataService.loadMore = false;
              }
            } else if (status === "spec_calls") {
              if (data.spec_calls.length > 0) {
                let demoCalls = data.spec_calls.filter(
                  (c) => c.call_type === "demo"
                );
                let specCalls = data.spec_calls.filter(
                  (c) => c.call_type === "spec"
                );
                this.dataService.dashboardCards.spec_calls.push(...specCalls);
                this.dataService.dashboardCards.demo_calls.push(...demoCalls);
              } else {
                this.dataService.loadMore = false;
              }
            } else if (status === "prototype") {
              if (data.prototype_build_card.length > 0) {
                for (const k in data.prototype_build_card) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data.prototype_build_card[k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data.prototype_build_card[k]
                    );
                }
                this.dataService.dashboardCards.prototype_build_card.push(
                  ...data.prototype_build_card
                );
              } else {
                this.dataService.loadMore = false;
              }
            } else if (status === "custom_prototype") {
              if (data.custom_prototype_build_card.length > 0) {
                for (const k in data.custom_prototype_build_card) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data.custom_prototype_build_card[k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data.custom_prototype_build_card[k]
                    );
                }
                this.dataService.dashboardCards.custom_prototype_build_card.push(
                  ...data.custom_prototype_build_card
                );
              } else {
                this.dataService.loadMore = false;
              }
            } else if (status === "completed") {
              if (data.completed_build_card.length > 0) {
                for (const k in data.completed_build_card) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data.completed_build_card[k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data.completed_build_card[k]
                    );
                }
                this.dataService.dashboardCards.completed_build_card.push(
                  ...data.completed_build_card
                );
              } else {
                this.dataService.loadMore = false;
              }
            } else if (status === "freemium") {
              if (data.freemium_build_card.length > 0) {
                for (const k in data.freemium_build_card) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data.freemium_build_card[k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data.freemium_build_card[k]
                    );
                }
                this.dataService.dashboardCards.freemium_build_card.push(
                  ...data.freemium_build_card
                );
              } else {
                this.dataService.loadMore = false;
              }
            }
          } else {
            for (const key in data) {
              if (key == "archived_build_card") {
                for (const k in data["archived_build_card"]) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data["archived_build_card"][k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data["archived_build_card"][k]
                    );
                }
              } else if (key == "running_build_card") {
                for (const k in data["running_build_card"]) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data["running_build_card"][k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data["running_build_card"][k]
                    );
                }
              } else if (key == "invited_build_card") {
                for (const k in data["invited_build_card"]) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data["invited_build_card"][k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data["invited_build_card"][k]
                    );
                }
              } else if (key == "card_completed_build_card") {
                for (const k in data["card_completed_build_card"]) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data["card_completed_build_card"][k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data["card_completed_build_card"][k]
                    );
                }
              } else if (key == "card_initialized_build_card") {
                for (const k in data["card_initialized_build_card"]) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data["card_initialized_build_card"][k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data["card_initialized_build_card"][k]
                    );
                }
              } else if (key == "prototype_build_card") {
                for (const k in data["prototype_build_card"]) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data["prototype_build_card"][k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data["prototype_build_card"][k]
                    );
                }
              } else if (key == "custom_prototype_build_card") {
                for (const k in data["custom_prototype_build_card"]) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data["custom_prototype_build_card"][k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data["custom_prototype_build_card"][k]
                    );
                }
              } else if (key == "completed_build_card") {
                for (const k in data["completed_build_card"]) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data["completed_build_card"][k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data["completed_build_card"][k]
                    );
                }
              } else if (key == "completed") {
                for (const k in data["completed"]) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data["completed"][k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data["completed"][k]
                    );
                }
              } else if (key == "freemium_build_card") {
                for (const k in data["freemium_build_card"]) {
                  const dashboardObj: BuildCardModel = new BuildCardModel();
                  data["freemium_build_card"][k] =
                    this.modelMapperService.getBuildCardModelMapped(
                      dashboardObj,
                      data["freemium_build_card"][k]
                    );
                }
              }

              if (key === "spec_calls") {
                this.dataService.dashboardCards[key] = data.spec_calls.filter(
                  (c) => c.call_type === "spec"
                );
                this.dataService.dashboardCards["demo_calls"] =
                  data.spec_calls.filter((c) => c.call_type === "demo");
              } else {
                this.dataService.dashboardCards[key] = data[key];
              }
            }
            this.dataService.buildCardCountData = data.build_card_count;
            if (data.spec_calls_count) {
              let demoCallsLength = data.spec_calls.filter(
                (c) => c.call_type === "demo"
              ).length;
              this.dataService.specCallCount =
                data.spec_calls_count - demoCallsLength;
              this.dataService.demoCallCount = demoCallsLength;
            }
            this.dataService.isSpecCallAPiResolved = true;
          }
          this.dataService.allBuildCards = data;
          // this.dataService.setBuildCardPhaseData(this.dataService.allBuildCards);
          this.dataService.filterData();
          this.dataService.loadFilteredData = true;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public fetchSingleBuildCardData(card_id) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.build_card +
      Constants.PARAM_CONSTANTS.SLASH +
      card_id;
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          const buildObj: BuildCardModel = new BuildCardModel();
          this.dataService.buildCardData =
            this.modelMapperService.getBuildCardModelMapped(
              buildObj,
              data.data.attributes
            );
          this.dataService.modifiedBuildCardData = null;
          if (this.dataService.buildCardData.modification_request) {
            const formattedData: any = this.formatPromotion(
              this.dataService.buildCardData.modification_request.modify_data
            );
            this.dataService.modifiedBuildCardData = formattedData;
            this.dataService.buildCardData.modification_request.modify_data =
              formattedData;
          }
          return this.dataService.buildCardData;
        }),
        catchError((error: any) => {
          if (error.status === 404) {
            this.dataService.invalidAccess = true;
            this.router.navigate(["invalidaccess"]);
          }
          this.dataService.showHideLoader(false);
          return observableThrowError(error);
        })
      );
  }

  public fetchAdditionalFeatureInstallments(payload, isPlatformRequest?) {
    const requestType = isPlatformRequest ? Constants.apiEndPoints.additional_platform_request :
      Constants.apiEndPoints.additional_feature_request;
    let API = environment.API_URL + requestType + Constants.PARAM_CONSTANTS.SLASH +
      Constants.apiEndPoints.additional_feature_installments;
    if (this.dataService.totalAdditionalFeaturesCost && (this.dataService.totalAdditionalFeaturesWeek || (this.dataService.totalAdditionalFeaturesWeek === 0))) {
      API += '?weeks=' + this.dataService.totalAdditionalFeaturesWeek;
      if (this.dataService.buildCardData.subscription_flow) {
        API += '&price=' + this.dataService.priceWithoutCareAndSubs;
      } else {
        API += '&price=' + this.dataService.totalAdditionalFeaturesCost;
      }
      API += '&build_card_id=' + payload.card_id;
      if (payload.subscription_price) { API += '&subscription_price=' + payload.subscription_price; }
      if (payload.builder_care_amount) { API += '&builder_care_amount=' + payload.builder_care_amount; }
    }
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          if (error.status === 404) {
            this.dataService.invalidAccess = true;
            this.router.navigate(["invalidaccess"]);
          }
          this.dataService.showHideLoader(false);
          return observableThrowError(error);
        })
      );
  }

  public fetchContractDetails(card_id) {
    const API =
      environment.API_URL +
      "build_card" +
      "/" +
      card_id +
      "/contracts" +
      (this.appDataService.urlParameters.isCustomPrototype
        ? "?is_custom_prototype=true"
        : "");
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          this.dataService.errorMessage = false;
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error, true);
          return observableThrowError(error);
        })
      );
  }

  public deleteCard(cardId) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.build_card +
      Constants.PARAM_CONSTANTS.SLASH +
      cardId;
    return this.httpRequest
      .delete(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public uploadQuote(quotePrice, fileList, inProgressBuildCardId) {
    const leadData = new FormData();
    let underScoredKey = "build_card[quote]";
    leadData.append(underScoredKey, quotePrice);
    underScoredKey = "build_card[attachments_attributes]";
    for (const item of fileList) {
      leadData.append(
        underScoredKey + Constants.PARAM_CONSTANTS.BRACKET_FILE,
        item.file,
        item.file.name
      );
    }
    let API = environment.API_URL + Constants.apiEndPoints.build_card;
    API += "/" + inProgressBuildCardId;
    return this.httpRequest
      .put(API, leadData, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          this.dataService.unsavedChanges = false;
          this.dataService.buildCardData = data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public renameBuildCard(newName, inProgressBuildCardId) {
    const leadData = new FormData();
    const underScoredKey = "build_card[project_name]";
    leadData.append(underScoredKey, newName);
    let API = environment.API_URL + Constants.apiEndPoints.build_card;
    API += "/" + inProgressBuildCardId;

    return this.httpRequest
      .put(API, leadData, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          this.dataService.unsavedChanges = false;
          this.dataService.buildCardData = data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public createUpdateBuildCard(
    selectedHash,
    inProgressBuildCardId,
    fromBot?,
    typeofupdate?
  ) {
    const event = null;
    if (this.appDataService.urlParameters) {
      if (this.appDataService.urlParameters.utm_source) {
        selectedHash.utm_source = this.appDataService.urlParameters.utm_source;
      }
      if (this.appDataService.urlParameters.utm_campaign) {
        selectedHash.utm_campaign =
          this.appDataService.urlParameters.utm_campaign;
      }
      if (this.appDataService.urlParameters.utm_medium) {
        selectedHash.utm_medium = this.appDataService.urlParameters.utm_medium;
      }
      if (this.appDataService.urlParameters.utm_content) {
        selectedHash.utm_content =
          this.appDataService.urlParameters.utm_content;
      }
      if (
        this.appDataService.urlParameters.expCode &&
        this.dataService.multiplierExperiment
      ) {
        selectedHash.multiplier_experiment_id =
          this.dataService.multiplierExperiment.id;
      }
      if (
        this.dataService.buildCardData.multiplier_experiment &&
        !this.dataService.buildCardData.multiplier_experiment.is_default
      ) {
        selectedHash.multiplier_experiment_id =
          this.dataService.buildCardData.multiplier_experiment.id;
      }
      if (
        this.dataService.appDetails &&
        this.dataService.appDetails.pricing_modal === "rental_price"
      ) {
        selectedHash.multiplier_experiment_id = "";
      }
    }
    if (
      selectedHash &&
      this.dataService.user &&
      this.dataService.user.currency &&
      !this.dataService.checkIfCPEUser() &&
      !inProgressBuildCardId
    ) {
      selectedHash.currency_id = this.dataService.user.currency.id;
      if (selectedHash.pricing_params) {
        const userCurr = this.dataService.user.currency["attributes"];
        selectedHash.pricing_params.currency.multiplier = userCurr
          ? userCurr.multiplier
          : this.dataService.user.currency.multiplier;
        selectedHash.pricing_params.currency.exchange_rate = userCurr
          ? userCurr.exchange_rate
          : this.dataService.user.currency.exchange_rate;
        selectedHash.pricing_params.currency.code = userCurr
          ? userCurr.code
          : this.dataService.user.currency.code;
        selectedHash.pricing_params.currency.custom_prototype_price = userCurr
          ? userCurr.custom_prototype_price
          : this.dataService.user.currency.custom_prototype_price;
      }
    }
    if (!selectedHash.is_under_support && selectedHash.care_type) {
      selectedHash.care_type = "";
      if (selectedHash.pricing_params) {
        delete selectedHash.pricing_params.care_type;
      }
    }
    if (
      typeofupdate !== "rename_build_card" &&
      typeofupdate !== "update_competitors" &&
      typeofupdate !== "promotionApply" &&
      typeofupdate !== "updatefromemi" &&
      typeofupdate !== "modificationPending" &&
      typeofupdate !== "changeFrequency" &&
      !selectedHash.nda_signed
    ) {
      if (this.dataService.emailIdToShareWith) {
        selectedHash.other_user_email = this.dataService.emailIdToShareWith;
        selectedHash.build_card_owner = this.dataService.isEmailToBeOwner;
      }
      const selectedPhases = [];
      selectedHash.build_card_phases_attributes = [];
      if (
        selectedHash.build_phase_vars &&
        selectedHash.build_phase_vars.length > 0
      ) {
        selectedHash.build_phase_vars.forEach((id, index) => {
          if (
            this.dataService.homePageData.build_phases &&
            this.dataService.homePageData.build_phases.length > 0
          ) {
            const phase = this.dataService.homePageData.build_phases.find(
              (phase) => phase.index === id
            );
            if (
              phase &&
              phase.platforms &&
              phase.features &&
              phase.platforms.length &&
              phase.features.length
            ) {
              selectedPhases.push(phase);
              if (phase) {
                let featureIds = [];
                let platformIds = [];
                if (phase.features) {
                  featureIds = phase.features.map((f) => f.id);
                  featureIds = featureIds.filter(
                    (item, index) => featureIds.indexOf(item) === index
                  );
                }
                if (phase.platforms) {
                  platformIds = phase.platforms.map((p) => p.id);
                }
                selectedHash.build_card_phases_attributes[index] =
                  new BuildCardPhasesAttributesModel();
                selectedHash.build_card_phases_attributes[index][
                  "is_parallel_development"
                ] = true;
                selectedHash.build_card_phases_attributes[index]["index"] =
                  phase.index;
                if (phase.id <= 5) {
                  selectedHash.build_card_phases_attributes[index][
                    "build_phase_id"
                  ] = phase.id;
                  if (this.dataService.isEditBuildCardAfterSecurityPaid()) {
                    selectedHash.build_card_phases_attributes[index][
                      "feature_ids"
                    ] = featureIds;
                    selectedHash.build_card_phases_attributes[index][
                      "platform_ids"
                    ] = platformIds;
                    selectedHash.build_card_phases_attributes[index]["title"] =
                      phase.title;
                  }
                }
                if (
                  phase.id <= 5 &&
                  phase.id !== 4 &&
                  !this.dataService.isEditBuildCardAfterSecurityPaid()
                ) {
                  selectedHash.build_card_phases_attributes[index][
                    "platform_ids"
                  ] = platformIds;
                }
                selectedHash.build_card_phases_attributes[index]["speed_id"] =
                  phase.speed.id;
                selectedHash.build_card_phases_attributes[index]["price"] =
                  phase.price;
                selectedHash.build_card_phases_attributes[index][
                  "delivery_time"
                ] = phase.delivery_time;
                if (phase.id > 5) {
                  selectedHash.build_card_phases_attributes[index][
                    "phase_type"
                  ] = "custom";
                  selectedHash.build_card_phases_attributes[index]["type"] =
                    "BuildCardMvpPhase";
                  selectedHash.build_card_phases_attributes[index]["title"] =
                    phase.title;
                }
                if (phase.id === 4) {
                  selectedHash.build_card_phases_attributes[index][
                    "phase_type"
                  ] = "system";
                  selectedHash.build_card_phases_attributes[index]["type"] =
                    "BuildCardMvpPhase";
                  selectedHash.build_card_phases_attributes[index]["title"] =
                    phase.title;
                }
                if (phase.id === 3 && phase.is_tailor_made) {
                  selectedHash.build_card_phases_attributes[index][
                    "is_tailor_made"
                  ] = phase.is_tailor_made;
                } else if (phase.id === 3 && !phase.is_tailor_made) {
                  selectedHash.build_card_phases_attributes[index][
                    "is_tailor_made"
                  ] = false;
                }

                if (inProgressBuildCardId) {
                  const buildCardPhase = selectedHash.build_card_phases.find(
                    (card) => card.build_phase_id === phase.id
                  );
                  if (buildCardPhase) {
                    if (buildCardPhase.id !== buildCardPhase.build_phase_id) {
                      selectedHash.build_card_phases_attributes[index]["id"] =
                        buildCardPhase.id;
                    }
                  }
                }
                if (phase.id > 5 || phase.id === 4) {
                  phase.features.forEach((feature) => {
                    if (!feature.phaseId) {
                      feature.phaseId = [];
                    }
                  });
                  const platformsIds = [];
                  phase.features.forEach((feature) => {
                    feature.phaseId.forEach((fId) => {
                      if (fId.includes(phase.id.toString())) {
                        platformsIds.push(fId.charAt(fId.length - 1));
                      }
                    });
                  });
                  const phasePlatformsIds = phase.platforms.map((p) => p.id);
                  const featurePlatforms = [];
                  phase.features = JSON.parse(JSON.stringify(phase.features));
                  phase.features.forEach((feature) => {
                    feature.platforms.forEach((platform, i) => {
                      if (phasePlatformsIds.indexOf(platform.id) === -1) {
                        feature.platforms.splice(i, 1);
                      }
                    });
                  });
                  phase.features.forEach((f) => {
                    f.platforms.forEach((p) => {
                      if (featurePlatforms.indexOf(p.id === -1)) {
                        featurePlatforms.push(p.id);
                      }
                    });
                  });
                  featurePlatforms.forEach(() => {
                    for (const feature of phase.features) {
                      for (const platform of feature.platforms) {
                        if (
                          platformsIds.indexOf(platform.id.toString()) === -1
                        ) {
                          feature.platforms.splice(
                            feature.platforms.findIndex(
                              (p) => p.id === platform.id
                            ),
                            1
                          );
                        }
                      }
                    }
                  });

                  selectedHash.build_card_phases_attributes[index][
                    "build_card_phase_features_attributes"
                  ] = [];
                  phase.features.forEach((feature) => {
                    const platforms = feature.platforms.length
                      ? feature.platforms
                      : phase.platforms;
                    selectedHash.build_card_phases_attributes[index][
                      "build_card_phase_features_attributes"
                    ].push({
                      feature_id: feature.id,
                      platform_ids: [
                        ...new Set(platforms.map((platform) => platform.id)),
                      ],
                    });
                  });
                }
              }
            }
          }
        });
      }

      if (
        inProgressBuildCardId &&
        !this.dataService.isEditBuildCardAfterSecurityPaid()
      ) {
        let counter = selectedHash.build_card_phases_attributes.length;
        this.dataService.homePageData.build_phases.forEach((ph) => {
          let buildPhaseIds = null;
          const buildCardPhasesId = selectedHash.build_phase_vars.find(
            (id) => id === ph.index
          );
          buildPhaseIds = this.dataService.getBuildByIndex(buildCardPhasesId);
          if (
            !buildPhaseIds ||
            ((buildPhaseIds["title"] === "MVP" ||
              buildPhaseIds["type"] === "custom_phase") &&
              (!buildPhaseIds["features"] ||
                buildPhaseIds["features"].length === 0))
          ) {
            if (ph.build_card_phase_id) {
              const phaseId = selectedHash.build_card_phases.find(
                (phase) => phase.build_phase_id === ph.build_card_phase_id
              );
              if (phaseId) {
                selectedHash.build_card_phases_attributes[counter] =
                  new BuildCardPhasesAttributesModel();
                selectedHash.build_card_phases_attributes[counter]["id"] =
                  phaseId.id;
                selectedHash.build_card_phases_attributes[counter]["_destroy"] =
                  true;
                counter++;
              }
            }
          }
        });
        selectedHash.build_card_phases_attributes.forEach((phase) => {
          if (phase["phase_type"] === "custom" || phase["title"] === "MVP") {
            const phaseFeatures = phase[
              "build_card_phase_features_attributes"
            ].map((f) => f.feature_id);
            phase["build_card_phase_features_attributes"].forEach((f) => {
              this.dataService.buildCardData.progress[
                "buildcardphases"
              ].forEach((ph) => {
                if (phase.id === ph.id) {
                  ph.features.forEach((fe) => {
                    if (f.feature_id === fe.feature_id) {
                      f["id"] = fe.id;
                    }
                  });
                  ph.features.forEach((fe) => {
                    if (!phaseFeatures.includes(fe.feature_id)) {
                      phase["build_card_phase_features_attributes"].push({
                        id: fe.id,
                        _destroy: true,
                      });
                    }
                  });
                }
              });
            });
          }
        });
      }
      selectedHash.build_phase_ids = selectedPhases.map((phase) => {
        if (phase.id < 6) {
          return phase.id;
        }
      });
      selectedHash.build_phase_ids = selectedHash.build_phase_ids.filter(
        (id) => typeof id === "number"
      );
      if (selectedHash.feature_ids) {
        if (inProgressBuildCardId) {
          selectedHash.build_card_features_attributes = [];
          if (!this.appDataService.urlParameters.cpe_user) {
            this.dataService.buildCardData.progress[
              "buildcardfeatures"
            ].forEach((feature) => {
              const buildCardFeature =
                selectedHash.pricing_params.features.find(
                  (ftr) => ftr.id === feature["feature_id"]
                );
              let featureObject = {};
              if (!buildCardFeature) {
                selectedHash.build_card_features_attributes.push({
                  feature_id: feature["feature_id"],
                  _destroy: true,
                  id: feature["id"],
                });
              } else if (buildCardFeature) {
                featureObject = {
                  feature_id: feature["feature_id"],
                  feature_note: buildCardFeature.feature_note,
                  feature_attachment: buildCardFeature.feature_attachment,
                  feature_count: buildCardFeature.feature_count,
                  _destroy: false,
                  id: feature["id"],
                };
                if (!featureObject["feature_count"]) {
                  delete featureObject["feature_count"];
                }
                selectedHash.build_card_features_attributes.push(featureObject);
              }
            });
          }
          const buildCardFeatures = this.dataService.buildCardData.progress[
            "buildcardfeatures"
          ].map((f) => f["feature_id"]);
          if (selectedHash.pricing_params.features) {
            selectedHash.pricing_params.features.forEach((f) => {
              if (!buildCardFeatures.includes(f.id)) {
                const newFeature = f.feature_count
                  ? {
                      feature_id: f.id,
                      feature_count: f.feature_count,
                    }
                  : { feature_id: f.id };
                newFeature['feature_set_id'] = f?.feature_set_id;
                if (f.feature_note) {
                  newFeature["feature_note"] = f.feature_note;
                }
                if (f.feature_attachment?.length > 0) {
                  newFeature["feature_attachment"] = f.feature_attachment;
                }
                selectedHash.build_card_features_attributes.push(newFeature);
              }
            });
          }
        }/* else if (selectedHash.pricing_params.features) {
          selectedHash.build_card_features_attributes =
            selectedHash.pricing_params.features.map((feature) => {
              const fData = {
                feature_id: feature.id,
                feature_set_id: feature?.feature_set_id,
                feature_set_alias_name: feature?.feature_set_alias_name
              };
              if (feature.feature_count) {
                fData['feature_count'] = feature.feature_count;
              }
              return fData;
            });
        }*/
      }
    }
    if (!selectedHash.prototype_promo) {
      delete selectedHash.prototype_promo;
    }
    if (selectedHash.is_advance) {
      let pIds = [];
      for (const phase of selectedHash.build_card_phases_attributes) {
        if (phase.platform_ids) {
          phase.platform_ids.forEach((id) => {
            pIds.push(id);
          });
        }
      }
      selectedHash.platform_ids = [...new Set(pIds)];
    }

    const finalData: any = {
      build_card: {},
      event: "",
      activities: {},
      pricing_params: {},
    };
    if (
      this.appDataService.urlParameters.cpe_user &&
      this.dataService.currentPage === "summary"
    ) {
      finalData.pricing_params = this.buildCardSectionForRental
        ? this.buildCardSectionForRental
        : "";
    } else {
      finalData.pricing_params = selectedHash.pricing_params
        ? selectedHash.pricing_params
        : "";
    }
    if (
      selectedHash &&
      selectedHash.pricing_params &&
      typeofupdate !== "promotionApply"
    ) {
      selectedHash.application_ids =
        selectedHash.pricing_params.application_ids;
    }
    selectedHash.pricing_params = null;
    finalData.build_card = selectedHash;
    if (sessionStorage.getItem("start_project_referral_points")) {
      finalData.build_card.reward_point = sessionStorage.getItem(
        "start_project_referral_points"
      );
      sessionStorage.removeItem("start_project_referral_points");
    }
    if (selectedHash.status === Constants.buildCardStatus.CARD_COMPLETED) {
      finalData.event = "complete";
    }
    if (
      this.dataService.recommendedFeatureIdsAdded &&
      this.dataService.recommendedFeatureIdsAdded.length > 0 &&
      !typeofupdate
    ) {
      finalData.activities = {
        feature_activities: this.dataService.recommendedFeatureIdsAdded,
      };
    }
    let API = environment.API_URL + Constants.apiEndPoints.build_card;
    if (this.appDataService.urlParameters.is_freemium) {
      API = environment.API_URL + Constants.apiEndPoints.freemium_build_card;
      finalData.build_card.build_card_phases_attributes.forEach(
        (p) => (p.speed_id = 2)
      );
      finalData.build_card.cloud_opted = true;
      finalData.build_card.speed_id = 2;
      delete finalData.build_card.cloud_price;
      delete finalData.pricing_params;
    }
    if (
      Object.keys(finalData.build_card).length === 3 &&
      !finalData.build_card.pricing_params &&
      finalData.build_card.promotion_id &&
      finalData.build_card.multiplier_experiment_id
    ) {
      delete finalData.build_card.multiplier_experiment_id;
    }
    let editBuildCardAfterSecurityPaidType: string;
    if (typeofupdate === "changeFrequency") {
      if (selectedHash.frequency) {
        finalData.build_card = { frequency: selectedHash.frequency };
      }
    }

    if (this.dataService.isEditBuildCardAfterSecurityPaid()) {
      delete finalData.build_card.multiplier_experiment_id;
      delete finalData.build_card.pricing_params;
    }
    if (typeofupdate === "modificationPending") {
      finalData["status"] = "pending";
    }

    if (
      this.dataService.isEditBuildCardAfterSecurityPaid() &&
      this.dataService.buildCardData.modification_request &&
      this.dataService.buildCardData.modification_request.status === "initial"
    ) {
      editBuildCardAfterSecurityPaidType = "edit";
      API =
        environment.API_URL +
        Constants.apiEndPoints.build_card_modification +
        "/" +
        this.dataService.buildCardData.modification_request.id;
      finalData["build_card_id"] = inProgressBuildCardId;
      if (
        finalData["build_card"] &&
        finalData["build_card"]["build_card_features_attributes"] &&
        finalData["build_card"]["build_card_features_attributes"].length
      ) {
        finalData["build_card"]["build_card_features_attributes"] = finalData[
          "build_card"
        ]["build_card_features_attributes"].filter((f) => !f._destroy);
      }
    } else if (this.dataService.isEditBuildCardAfterSecurityPaid()) {
      editBuildCardAfterSecurityPaidType = "new";
      API =
        environment.API_URL +
        Constants.apiEndPoints.build_card_modification +
        "?build_card_id=" +
        inProgressBuildCardId;
      if (
        finalData["build_card"] &&
        finalData["build_card"]["build_card_features_attributes"] &&
        finalData["build_card"]["build_card_features_attributes"].length
      ) {
        finalData["build_card"]["build_card_features_attributes"] = finalData[
          "build_card"
        ]["build_card_features_attributes"].filter((f) => !f._destroy);
      }
    } else {
      editBuildCardAfterSecurityPaidType = null;
    }
    if (inProgressBuildCardId) {
      if (editBuildCardAfterSecurityPaidType === "new") {
        return this.httpRequest
          .post(API, finalData, this.appDataService.getRequestOptionArgs())
          .pipe(
            map((data) => {
              this.dataService.errorMessage = false;
              const formattedData: any = this.formatPromotion(
                data.data.attributes.modify_data
              );
              this.dataService.buildCardData.modification_request =
                data.data.attributes;
              this.dataService.buildCardData.modification_request.modify_data =
                formattedData;
              this.dataService.modifiedBuildCardData = formattedData;
              return this.dataService.modifiedBuildCardData;
            }),
            catchError((error: any) => {
              this.showErrorMessage(error, true);
              return observableThrowError(error);
            })
          );
      } else if (editBuildCardAfterSecurityPaidType === "edit") {
        return this.httpRequest
          .put(API, finalData, this.appDataService.getRequestOptionArgs())
          .pipe(
            map((data) => {
              this.dataService.errorMessage = false;
              const formattedData: any = this.formatPromotion(
                data.data.attributes.modify_data
              );
              this.dataService.buildCardData.modification_request =
                data.data.attributes;
              this.dataService.buildCardData.modification_request.modify_data =
                formattedData;
              this.dataService.modifiedBuildCardData = formattedData;
              return this.dataService.modifiedBuildCardData;
            }),
            catchError((error: any) => {
              this.showErrorMessage(error, true);
              return observableThrowError(error);
            })
          );
      } else {
        API += "/" + inProgressBuildCardId;
        return this.httpRequest
          .put(API, finalData, this.appDataService.getRequestOptionArgs())
          .pipe(
            map((data) => {
              this.dataService.errorMessage = false;
              if (fromBot) {
                const buildObj: BuildCardModel = new BuildCardModel();
                return this.modelMapperService.getBuildCardModelMapped(
                  buildObj,
                  data.data.attributes
                );
              } else {
                this.dataService.unsavedChanges = false;
                const buildObj: BuildCardModel = new BuildCardModel();
                this.dataService.buildCardData =
                  this.modelMapperService.getBuildCardModelMapped(
                    buildObj,
                    data.data.attributes
                  );
                this.updateFrequency();
                this.dataService.emailIdToShareWith = "";
                return this.dataService.buildCardData;
              }
            }),
            catchError((error: any) => {
              this.showErrorMessage(error, true);
              return observableThrowError(error);
            })
          );
      }
    } else {
      return this.httpRequest
        .post(API, finalData, this.appDataService.getRequestOptionArgs())
        .pipe(
          map((data) => {
            this.dataService.errorMessage = false;

            if (fromBot) {
              const buildObj: BuildCardModel = new BuildCardModel();
              return this.modelMapperService.getBuildCardModelMapped(
                buildObj,
                data.data.attributes
              );
            } else {
              this.dataService.unsavedChanges = false;
              const buildObj: BuildCardModel = new BuildCardModel();
              this.dataService.buildCardData =
                this.modelMapperService.getBuildCardModelMapped(
                  buildObj,
                  data.data.attributes
                );
              this.dataService.emailIdToShareWith = "";
            }
          }),
          catchError((error: any) => {
            this.showErrorMessage(error, true);
            return observableThrowError(error);
          })
        );
    }
  }
  updateFrequency() {
    const frequency = this.dataService.buildCardData.frequency;
    if (frequency === 'weekly') {
      this.dataService.showWeekly = true;
      this.dataService.showMonthly = false;
      this.dataService.showUpfront = false;
    } else if (frequency === 'monthly') {
      this.dataService.showMonthly = true;
      this.dataService.showWeekly = false;
      this.dataService.showUpfront = false;
    } else if (frequency === 'upfront') {
      this.dataService.showMonthly = false;
      this.dataService.showWeekly = false;
      this.dataService.showUpfront = true;
    }
  }
  formatPromotion(data) {
    if (data.promotion.add) {
      const promotion = data.promotion.add.data.attributes;
      Object.keys(promotion).forEach((element) => {
        if (promotion[element] && promotion[element].hasOwnProperty("data")) {
          promotion[element] = promotion[element]["data"];
        }
      });
      data.promotion.add = promotion;
    }
    return data;
  }

  public convertModelToFormData(data = {}, leadData: FormData, namespace = "") {
    for (const propertyName in data) {
      if (!data.hasOwnProperty(propertyName)) {
        continue;
      }
      const formKey = namespace
        ? `${namespace}[${propertyName}]`
        : propertyName;
      if (typeof data[propertyName] === "object") {
        this.convertModelToFormData(data[propertyName], leadData, formKey);
      } else {
        leadData.append(formKey, data[propertyName]);
      }
    }
  }

  public createUpdateMasterContract(contract, contract_id) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.master_contract +
      "/" +
      contract_id;
    const data: any = {
      signature_request_guid: contract.signature_request_guid,
      signature_request_status: contract.signature_request_status,
      nda_signed: contract.nda_signed,
    };

    return this.httpRequest
      .put(API, data, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public addNewCategory(category) {
    const API = environment.API_URL + Constants.apiEndPoints.add_new_category;
    const featureData = new FormData();
    for (const key in category) {
      if (category.hasOwnProperty(key)) {
        const value = category[key];
        const underScoredKey = key;
        if (value != null && value.constructor === Array) {
          for (const item of value) {
            featureData.append(
              underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
              item
            );
          }
        } else if (value != null) {
          featureData.append(underScoredKey, value);
        }
      }
    }

    return this.httpRequest
      .post(
        API,
        featureData.toString(),
        this.appDataService.getRequestOptionArgs(
          "application/x-www-form-urlencoded"
        )
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public addNewTemplate(template: any, platforms) {
    let API = environment.API_URL;
    if (template.id) {
      API = API + Constants.apiEndPoints.updateTemplate;
      API = API.replace("id", template.id);
    } else {
      API = API + Constants.apiEndPoints.add_new_templates;
    }
    const featureData = new FormData();
    for (const key in template) {
      if (template.hasOwnProperty(key)) {
        const value = template[key];
        const underScoredKey = key;
        if (value != null && value.constructor === Array) {
          if (key === "reference_urls") {
            for (const item of Object.keys(value)) {
              const nesv = value[item];
              for (const k of Object.keys(nesv)) {
                const v = nesv[k];
                featureData.append(
                  underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS + k,
                  v
                );
              }
            }
          } else {
            for (const item of value) {
              featureData.append(
                underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
                item
              );
            }
          }
        } else if (value != null) {
          featureData.append(underScoredKey, value);
        }
      }
    }

    if (platforms) {
      platforms.forEach((platform) => {
        featureData.append("[][platform_ids][]", platform.id);
      });
    }

    if (template.id) {
      return this.httpRequest.put(API, featureData).pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
    } else {
      return this.httpRequest.post(API, featureData).pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
    }
  }

  public addNewFeature(feature) {
    const API = environment.API_URL + Constants.apiEndPoints.add_new_feature;
    const featureData = new FormData();
    this.payloadForAddNewFeature(feature, featureData);
    return this.httpRequest.post(API, featureData).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      })
    );
  }

  public editFeature(feature: any) {
    const API = `${environment.API_URL}${Constants.apiEndPoints.add_new_feature}/${feature.id}`;
    const featureData = new FormData();
    for (const key in feature) {
      if (feature.hasOwnProperty(key)) {
        const value = feature[key];
        const underScoredKey = key;
        if (value != null && value.constructor === Array) {
          if (key === "reference_urls") {
            for (const item of Object.keys(value)) {
              const nesv = value[item];
              for (const k of Object.keys(nesv)) {
                const v = nesv[k];
                featureData.append(
                  underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS + k,
                  v
                );
              }
            }
          } else {
            for (const item of value) {
              featureData.append(
                underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
                item
              );
            }
          }
        } else if (value != null) {
          featureData.append(underScoredKey, value);
        }
      }
    }
    this.setCurrencyId(featureData);
    this.setMultiplierExperimentParams(featureData);
    return this.httpRequest.put(API, featureData).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      })
    );
  }

  public login_signup(userdetails, option) {
    if (this.commonService.isPlatformBrowser) {
      if (userdetails.first_name)
        userdetails.first_name = userdetails.first_name.capitalize();
      if (userdetails.last_name)
        userdetails.last_name = userdetails.last_name.capitalize();
      if (userdetails.firstName)
        userdetails.firstName = userdetails.firstName.capitalize();
      if (userdetails.lastName)
        userdetails.lastName = userdetails.lastName.capitalize();
    }

    if (option == "signup") {
      userdetails.phone_number = userdetails.phone_number.replace(/\s/g, "");
    }
    let API;
    let data = {};
    if (option === "login") {
      API = environment.API_URL + Constants.apiEndPoints.user_signin;
      for (const key in userdetails) {
        if (userdetails.hasOwnProperty(key)) {
          const value = userdetails[key];
          if (value != null) {
            data[key] = value;
          }
        }
      }
    } else if (option === "signup") {
      API = environment.API_URL + Constants.apiEndPoints.user_signup;
      data = { user: {} };
      for (const key in userdetails) {
        if (userdetails.hasOwnProperty(key)) {
          const value = userdetails[key];
          if (value != null) {
            data["user"][key] =
              key === "phone_number"
                ? value.replace(
                    this.dataService.countryNameCode,
                    this.dataService.countryNameCode + "-"
                  )
                : value;
          }
          if (this.appDataService.urlParameters.owner_token) {
            data["user"]["owner_transfer_token"] = "true";
          }
        }
      }
    } else if (option === "forgot") {
      API = environment.API_URL + Constants.apiEndPoints.user_forgot;
      for (const key in userdetails) {
        if (userdetails.hasOwnProperty(key)) {
          const value = userdetails[key];
          if (value != null) {
            data[key] = value;
          }
        }
      }
    } else if (option === "reset") {
      API = environment.API_URL + Constants.apiEndPoints.user_reset;
      for (const key in userdetails) {
        if (userdetails.hasOwnProperty(key)) {
          const value = userdetails[key];
          if (value != null) {
            data[key] = value;
          }
        }
      }
    }

    return this.httpRequest
      .post(
        API,
        data,
        this.appDataService.getRequestOptionArgs("application/json")
      )
      .pipe(
        map((res) => {
          this.dataService.errorMessage = false;
          const userObj: UserModel = new UserModel();
          if (res.user) {
            res.user = this.modelMapperService.getMappedModel(
              userObj,
              res.user
            );
          }
          if (option === "forgot" || option === "reset") {
            return res;
          } else {
            if (option === "signup") {
              this.dataService.signupData = res.user;
            }
            this.setUpUserData(res);
            return res;
          }
        }),
        catchError((error: any) => {
          this.showErrorMessage(error, true);
          this.dataService.user = null;
          return observableThrowError(error);
        })
      );
  }

  public linkedIn_user(postData) {
    const API = environment.API_URL + "/user/linkedin";
    return this.httpRequest.post(API, postData).pipe(
      map((res) => {
        this.dataService.errorMessage = false;
        const userObj: UserModel = new UserModel();
        if (res.user) {
          res.user = this.modelMapperService.getMappedModel(userObj, res.user);
        }
        this.setUpUserData(res);
        return res;
      }),
      catchError((error: any) => {
        this.dataService.user = null;
        this.dataService.socialSignUser = null;
        this.showErrorMessage(error);
        return observableThrowError(error);
      })
    );
  }

  public shareInviteCard(emails, id, isShare, redirectUrl) {
    let API = environment.API_URL;
    if (isShare) {
      API =
        API +
        Constants.apiEndPoints.build_card +
        Constants.PARAM_CONSTANTS.SLASH +
        id +
        Constants.PARAM_CONSTANTS.SHARE;
    } else {
      API =
        API +
        Constants.apiEndPoints.build_card +
        Constants.PARAM_CONSTANTS.SLASH +
        id +
        Constants.PARAM_CONSTANTS.INVITE;
    }
    const data = new FormData();
    for (const value of emails) {
      data.append(Constants.PARAM_CONSTANTS.EMAIL_BRACKET, value);
    }
    data.append("frontend_host", redirectUrl);

    return this.httpRequest
      .post(API, data, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public updateProfile(
    userdetails,
    currencyId?,
    phone_number?,
    email?,
    profile_image?
  ) {
    const data = new FormData();
    const API = environment.API_URL + Constants.apiEndPoints.user_signup;
    if (currencyId) {
      data.append("user[currency_id]", currencyId);
      if(phone_number) { data.append("user[phone_number]", phone_number) }
      if( email ){ data.append("user[email]", email) }
    } else if (profile_image) {
      if( profile_image ) {data.append("user[profile_image]", profile_image) }
    } else {
      for (const key in userdetails) {
        if (userdetails.hasOwnProperty(key)) {
          const value = userdetails[key];
          if (value) {
            const underScoredKey =
              Constants.PARAM_CONSTANTS.USER_BRACKET +
              key +
              Constants.PARAM_CONSTANTS.CLOSE_BRACKET;
            if (value != null) {
              data.append(underScoredKey, value);
            }
          }
        }
      }
    }

    return this.httpRequest
      .put(API, data, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          this.setUpUserData(res);
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public signout_user() {
    // remove cookie here
    // localStorage.removeItem('user');
    const API =
      environment.API_URL +
      Constants.apiEndPoints.user_signout +
      Constants.PARAM_CONSTANTS.AUTHTOKEN +
      this.dataService.user.authtoken;
    return this.httpRequest.delete(API).pipe(
      map((res) => {
        this.dataService.user = null;
        this.dataService.socialSignUser = null;
        this.dataService.unsetCookie();
      }),
      catchError((error: any) => {
        this.dataService.user = null;
        this.dataService.socialSignUser = null;
        this.showErrorMessage(error);
        return observableThrowError(error);
      })
    );
  }

  public fetchDocuSignUrl(parameters) {
    const API = environment.API_URL + Constants.apiEndPoints.docusign_url;
    const headers = this.appDataService.getRequestOptionArgs();
    let userData = new HttpParams();
    for (const key in parameters) {
      if (parameters.hasOwnProperty(key)) {
        const value = parameters[key];
        const underScoredKey = key;
        if (value != null) {
          userData = userData.append(underScoredKey, value);
        }
      }
    }
    const options = {
      headers: headers,
      params: userData,
    };
    return this.httpRequest.get(API, null, options).pipe(
      map((data) => {
        this.dataService.docuSignUrl = data.docusign_url;
      }),
      catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      })
    );
  }

  public fetchMasterContractDocuSignUrl(parameters) {
    const API =
      environment.API_URL + Constants.apiEndPoints.mastercontractdocusign_url;
    const headers = this.appDataService.getRequestOptionArgs();
    let userData = new HttpParams();
    for (const key in parameters) {
      if (parameters.hasOwnProperty(key)) {
        const value = parameters[key];
        const underScoredKey = key;
        if (value != null) {
          userData = userData.append(underScoredKey, value);
        }
      }
    }
    userData = userData.append("admin_access", "true");
    const options = {
      headers: headers,
      params: userData,
    };
    return this.httpRequest.get(API, null, options).pipe(
      map((data) => {
        this.dataService.mastercontractdocuSignUrl = data.signing_url;
      }),
      catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      })
    );
  }

  public getUserforBilling() {
    const API = environment.API_URL + Constants.apiEndPoints.user;

    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs(null, true))
      .pipe(
        map((res) => {
          const userObj: UserModel = new UserModel();
          this.dataService.user = this.modelMapperService.getMappedModel(
            userObj,
            res
          );
          this.dataService.storeUserObject();
        }),
        catchError((error: any) => {
          this.dataService.user = null;
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public setUpUserData(data, isNotFromLogin?, isPayment?) {
    if (data && data.user) {
      const userObj: UserModel = new UserModel();
      this.dataService.user = isNotFromLogin
        ? this.modelMapperService.getMappedModel(userObj, data)
        : this.modelMapperService.getMappedModel(userObj, data.user);
    }
    if (isPayment) {
      this.appDataService.urlParameters.authToken =
        this.dataService.user.authtoken;
    }
    this.dataService.storeUserObject();

    if (
      this.dataService.user &&
      this.dataService.user.setting &&
      this.dataService.user.setting.avatar_details &&
      this.dataService.user.setting.avatar_details.color_codes
    ) {
      this.dataService.natashaAvatarBackground =
        this.dataService.user.setting.avatar_details.color_codes.background;
      this.dataService.natashaAvatarChatHandle =
        this.dataService.user.setting.avatar_details.color_codes.chat_handle;
      this.dataService.natashaAvatarInitials =
        this.dataService.user.setting.avatar_details.initials;
    }

    if (this.dataService.user && !this.dataService.user.currency) {
      if (
        this.dataService.homePageData.promotion &&
        this.dataService.homePageData.promotion.currency
      ) {
        this.dataService.showCurrencyAfterLogin = false;
        this.dataService.homePageData.currency =
          this.dataService.homePageData.promotion.currency;
        this.dataService.defaultCurrency =
          this.dataService.homePageData.promotion.currency;
        this.dataService.user.currency = this.dataService.homePageData.currency;
        this.updateProfile(
          this.dataService.user,
          this.dataService.user.currency.id
        ).subscribe((data: any) => {});
      } else {
        this.dataService.showCurrencyAfterLogin = true;
      }
    } else {
      if (this.dataService.currentPage === "home") {
        if (this.dataService.user) {
          this.dataService.previousCurrency = this.dataService.user.currency;
        }
      } else {
        this.dataService.previousCurrency =
          this.dataService.homePageData.currency;
      }

      if (
        this.dataService.user &&
        !this.appDataService.urlParameters.currency_id
      ) {
        this.dataService.homePageData.currency = this.dataService.user.currency;
      } else if (
        this.dataService.user &&
        this.dataService.checkIfCPEUser() &&
        this.appDataService.urlParameters.currency_id
      ) {
        this.dataService.homePageData.currency =
          this.dataService.homePageData.currencies.find(
            (curr) =>
              curr.id.toString() ===
              this.appDataService.urlParameters.currency_id
          );
      }
      this.dataService.defaultCurrency = this.dataService.homePageData.currency;
      this.dataService.setLanguage();
    }
  }

  public getCookie() {
    if (this.commonService.isPlatformBrowser) {
      const nameEQ = this.dataService.user_cookie + "=";
      const ca = decodeURI(document.cookie).split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
          if (JSON.parse(localStorage.getItem(this.dataService.user_cookie))) {
            return JSON.parse(
              localStorage.getItem(this.dataService.user_cookie)
            );
          } else {
            this.dataService.user = JSON.parse(
              c.substring(nameEQ.length, c.length)
            );
            this.fetchUser().subscribe(() => {
              this.getUserData();
            });
            break;
          }
        }
      }
      return null;
    }
    return null;
  }

  public getUserData() {
    this.dataService.user = this.getCookie();
    if (
      this.dataService.user &&
      this.dataService.user.currency &&
      this.dataService.user.currency.symbol
    ) {
      this.dataService.user.currency.symbol = decodeURIComponent(
        this.dataService.user.currency.symbol
      );
    }

    if (!this.dataService.user && !this.appDataService.urlParameters.is_etisalat_flow && this.commonService.isPlatformBrowser) {
      localStorage.removeItem(this.dataService.user_cookie);
      document.cookie = `${this.dataService.user_cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.dataService.cookie_domain};`;
    }
  }

  public handleCurrency() {
    if (this.dataService.user) {
      if (!this.dataService.user.currency) {
        if (this.dataService.homePageData.promotion) {
          this.dataService.showCurrencyAfterLogin = false;
          this.dataService.homePageData.currency =
            this.dataService.homePageData.promotion.currency;
          this.dataService.user.currency =
            this.dataService.homePageData.currency;
          this.updateProfile(
            this.dataService.user,
            this.dataService.user.currency.id
          ).subscribe((data: any) => {});
        } else {
          this.dataService.showCurrencyAfterLogin = true;
        }
      } else {
        if (!this.dataService.currencyInUrl) {
          this.dataService.previousCurrency =
            this.dataService.homePageData.currency;
          this.dataService.homePageData.currency =
            this.dataService.user.currency;
        } else {
          this.dataService.previousCurrency =
            this.dataService.homePageData.currency;
          this.dataService.defaultCurrency =
            this.dataService.homePageData.currency;
        }
        this.dataService.setLanguage();
      }
    }
  }

  public getUserProfiles() {
    const uri = `${environment.API_URL}user_profiles`;
    return this.httpRequest
      .get(uri, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public updateUserProfile(user, profiles, address?) {
    const uri = `${environment.API_URL}users`;
    let obj;
    if (profiles === '') {
      obj = { user: user};
    } else {
      if (address) {
        obj = {
          user: {
            address_attributes: {
              address: address.address,
              state: address.state,
              city: address.city,
              pincode: address.pincode,
              phone: address.phone,
            },
          },
        };
      } else {
        obj = {
          user: {
            first_name: user.first_name,
            last_name: user.last_name,
            user_profiles_attributes: profiles,
          },
        };
      }
    }

    return this.httpRequest
      .put(uri, obj, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((response) => {
          this.setUpUserData(response);
          return response;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public updateUserAddress(address, isNDASigned?, taxDetails?) {
    address.contact = address.contact.replace(/\s/g, "");
    const uri = `${environment.API_URL}users`;
    let obj = {
      user: {
        address_attributes: {
          first_name: address.firstname,
          last_name: address.lastname,
          address: address.address,
          street: address.street,
          apartment: address.apartment,
          state: address.state,
          city: address.city,
          pincode: address.pincode,
          phone: address.contact.replace(
            this.dataService.countryNameCode,
            this.dataService.countryNameCode + "-"
          ),
          country: address.country,
          gst_number: address.gstNumber,
          address_line_2: address.address_line_2,
          company_name: address.companyName ? address.companyName : "",
          billed_as: address.billed_as ? address.billed_as : ""
        },
      },
    };
    if (isNDASigned) {
      obj.user["spec_call_nda_signed"] = true;
    }
    if (this.commonService.twoPayCompanyDetails) {
      obj.user['address_attributes']['company_id'] = this.commonService.twoPayCompanyDetails.id;
    }
    if (taxDetails) {
      let tempObj = Object.assign(obj.user.address_attributes, taxDetails.user.address_attributes);
      obj.user.address_attributes = tempObj;
    }
    return this.httpRequest
      .put(uri, obj, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public createSharableUrl(url, id) {
    const API = environment.API_URL + Constants.apiEndPoints.sharable_urls;
    const obj = { url: url, id: id };
    return this.httpRequest
      .post(API, obj, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public fetchSharableUrl(id) {
    const API =
      environment.API_URL + Constants.apiEndPoints.sharable_urls + "/" + id;
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public sendOTPAfterSignup() {
    const API = environment.API_URL + Constants.apiEndPoints.regenerate_otp;
    return this.httpRequest
      .post(API, null, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public confirmOTP(otp) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.verify_user_otp +
      "?otp_token=" +
      otp;
    return this.httpRequest
      .post(API, null, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public triggerEditBuildCardEmail(buildCardId) {
    const API =
      environment.API_URL + Constants.apiEndPoints.edit_buildcard_email;
    const obj = {
      id: buildCardId,
    };
    return this.httpRequest
      .post(API, obj, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public isDownloadAvailable(id) {
    if (id) {
      const API =
        environment.API_URL +
        Constants.apiEndPoints.build_card_pdf_status +
        "?build_card_id=" +
        id;
      return this.httpRequest
        .get(API, this.appDataService.getRequestOptionArgs())
        .pipe(
          map((data) => {
            return data;
          }),
          catchError((error: any) => {
            return observableThrowError(error);
          })
        );
    }
  }

  public getTimeline(buildCardId) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.get_timeline +
      "?build_card_id=" +
      buildCardId;
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public deactivateTimeline(buildCardId) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.inactive_timeline +
      "?build_card_id=" +
      buildCardId;
    return this.httpRequest
      .post(API, null, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public deactivateTimelineFromDashboard() {
    const API =
      environment.API_URL + Constants.apiEndPoints.inactive_timeline_dashboard;
    return this.httpRequest
      .post(API, null, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public transferOwnership(buildCardId, email) {
    const API = environment.API_URL + Constants.apiEndPoints.ownership_tranfer;
    const obj = {
      id: buildCardId,
      email: email,
    };
    return this.httpRequest
      .post(API, obj, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public fetchPromotion(promocode) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.fetchpromotion +
      "?promotion_type=" +
      promocode.toLowerCase();
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          data.promotion = this.modelMapperService.getMappedModel(
            this.promotionData,
            data.promotion.data.attributes
          );
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public fetchPromotionData(promocode, isAdvanceMode?, weeks?) {
    let API =
      environment.API_URL +
      Constants.apiEndPoints.get_promotion +
      "?promo_code=" +
      encodeURI(promocode.toLowerCase());
    if (isAdvanceMode) {
      API = API + "&is_advance=true";
    }
    API = API + "&build_card_id=" + this.dataService.buildCardData.id;
    if (weeks) {
      API = API + "&weeks=" + weeks;
    }
    if (this.dataService.homePageData) {
      const selectedPhases = this.dataService.homePageData.build_phases.filter(
        (p) => p.selected
      );
      const isProtoType =
        selectedPhases &&
        selectedPhases.length === 1 &&
        selectedPhases[0].title === "Professional Prototype";
      API = API + (isProtoType ? "&is_prototype=true" : "");
    }
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          if (data.promotion) {
            const promoObj: PromotionModel = new PromotionModel();
            data.promotion = this.modelMapperService.getPromotionModelMapped(
              promoObj,
              data.promotion.data.attributes
            );
          }
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public applyRemovePromotion(id, promocode, isApplied) {
    const API = environment.API_URL + Constants.apiEndPoints.apply_promotion;
    const obj = {
      id: id,
      promo_code: promocode.toLowerCase(),
      applied: isApplied,
    };

    return this.httpRequest
      .post(API, obj, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          if (data.build_card) {
            const buildObj: BuildCardModel = new BuildCardModel();
            data.build_card = this.modelMapperService.getBuildCardModelMapped(
              buildObj,
              data.build_card.data.attributes
            );
          }
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public removeInvitedUser(inviteId, buildCardId, inviteeEmail) {
    const API =
      environment.API_URL + Constants.apiEndPoints.remove_invited_user;
    const obj = {
      invite_id: inviteId,
      id: buildCardId,
      email: inviteeEmail,
    };

    return this.httpRequest
      .post(API, obj, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public sendCardForPartnerApproval(buildCardId) {
    const API =
      environment.API_URL + Constants.apiEndPoints.send_for_partner_approval;
    const obj = {
      id: buildCardId,
    };
    return this.httpRequest
      .post(API, obj, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public applySpecPromotion(promoCode) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.spec_apply_promotion.replace(
        "{promocode}",
        promoCode.toLowerCase()
      );
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public createSpecCall(instant?, source?, feedback?, callType?) {
    const API = environment.API_URL + Constants.apiEndPoints.create_spec_call;
    let obj = {};
    if (
      !this.dataService.isEditBuildCard &&
      (this.dataService.currentPage === "home" ||
        this.dataService.currentPage === "apps" ||
        this.dataService.currentPage === "features" ||
        this.dataService.currentPage === "delivery")
    ) {
      obj = {
        spec_type: instant === "instant" ? "instant" : "normal",
        source: source,
        build_card_id:
          this.dataService.buildCardData && this.dataService.buildCardData.id
            ? this.dataService.buildCardData.id
            : "",
        feedback: feedback ? feedback : "",
        time_to_spec_call: this.dataService.timeSpentBeforeSpecCallCreation,
      };
    } else {
      obj = {
        spec_type: instant === "instant" ? "instant" : "normal",
        source: source,
        build_card_id: this.dataService.buildCardData
          ? this.dataService.buildCardData.id
          : "",
        feedback: feedback ? feedback : "",
        time_to_spec_call: this.dataService.timeSpentBeforeSpecCallCreation,
      };
    }
    if (this.appDataService.urlParameters.utm_source) {
      obj["utm_source"] = this.appDataService.urlParameters.utm_source;
    }
    if (this.appDataService.urlParameters.utm_medium) {
      obj["utm_medium"] = this.appDataService.urlParameters.utm_medium;
    }
    if (this.appDataService.urlParameters.utm_campaign) {
      obj["utm_campaign"] = this.appDataService.urlParameters.utm_campaign;
    }
    if (this.appDataService.urlParameters.utm_content) {
      obj["utm_content"] = this.appDataService.urlParameters.utm_content;
    }
    if (this.appDataService.urlParameters.red === "ip_now") {
      obj["spec_type"] = "prototype";
    }
    if (callType) {
      obj["call_type"] = callType;
    }
    if (
      this.dataService.buildCardData.type === "rental_price" &&
      this.dataService.buildCardData.source === "app_detail"
    ) {
      obj["studio_store"] = true;
      obj["template_name"] =
        this.dataService.buildCardData.progress.apps[0].title.toLocaleLowerCase();
    }
    return this.httpRequest
      .post(API, obj, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data: SpecCallModel) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public getSpecCallDetails(specId) {
    const API = `${environment.API_URL}${Constants.apiEndPoints.create_spec_call}/${specId}`;
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data: SpecCallModel) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public uploadContracts(contractType, id, contract) {
    const API = environment.API_URL + "build_cards/" + id + "/documents";
    const formData = new FormData();
    if (contractType === "nda") {
      formData.append("[][nda_document]", contract);
    } else {
      formData.append("[][contract_nda_document]", contract);
      if (this.appDataService.urlParameters.isCustomPrototype) {
        formData.append("[][is_custom_prototype]", "true");
      }
    }

    return this.httpRequest
      .post(API, formData, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public getApplicationSuggestion(searchText) {
    let API =
      environment.API_URL +
      Constants.apiEndPoints.application_search_suggestion +
      "?title=" +
      searchText;
    if (
      this.appDataService.urlParameters.expCode ||
      this.dataService.buildCardData.multiplier_experiment
    ) {
      API = this.setMultiplierExperimentInQueryParams(API, "&");
    }
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public getApplicationsByProduct(section_id, product_id) {
    let API =
      environment.API_URL +
      Constants.apiEndPoints.newhomepage +
      "/" +
      section_id +
      "?product_id=" +
      product_id +
      "&currency_id=" +
      this.dataService.homePageData.currency.id;
    if (
      this.appDataService.urlParameters.expCode ||
      this.dataService.buildCardData.multiplier_experiment
    ) {
      API = this.setMultiplierExperimentInQueryParams(API, "&");
    }
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public searchTemplatesChatbot(query) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.autocomplete_templates +
      "?title=" +
      query;
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data: any) => {
          this.dataService.apps = data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public checkEmailRegistered(email) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.check_email +
      "?email=" +
      email;
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  clearBitApiCall(email, httpOptions) {
    const APIURL =
      "https://person-stream.clearbit.com/v2/combined/find?email=" + email;
    return this.httpRequest.get(APIURL, httpOptions).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  public getCurrencyByIp() {
    const API = environment.API_URL + "currencies/ip_currencies";
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public addScheduleDate(build_care_id, data) {
    const API =
      environment.API_URL +
      "/build_cards/" +
      build_care_id +
      "/set_scheduled_date";
    const formData = new FormData();
    formData.append("[scheduled_date]", data.date);
    formData.append("[scheduled_call_start]", data.slot_start);
    formData.append("[scheduled_call_end]", data.slot_end);
    if (
      this.dataService.buildCardData.type === "rental_price" &&
      this.dataService.buildCardData.source === "app_detail"
    ) {
      formData.append("[studio_store]", "true");
    }
    return this.httpRequest
      .post(API, formData, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  setUniquePhaseIdsInFeatures(phase) {
    const platformsIds = phase.platforms.map((p) => p.id);
    const phasePlatformsIds = phase.platforms.map((p) => p.id);
    const featurePlatforms = [];
    phase.features = JSON.parse(JSON.stringify(phase.features));
    phase.features.forEach((feature) => {
      feature.platform_ids.forEach((platform, i) => {
        if (phasePlatformsIds.indexOf(platform) === -1) {
          feature.platform_ids.splice(i, 1);
        }
      });
    });
    phase.features.forEach((f) => {
      f.platform_ids.forEach((p) => {
        if (featurePlatforms.indexOf(p === -1)) {
          featurePlatforms.push(p);
        }
      });
    });
    featurePlatforms.forEach(() => {
      for (const feature of phase.features) {
        for (const platform of feature.platform_ids) {
          if (platformsIds.indexOf(platform) === -1) {
            feature.platform_ids.splice(
              feature.platform_ids.findIndex((p) => p === platform),
              1
            );
          }
        }
      }
    });
  }

  public getBuildCardPrice(data) {
    Object.keys(data.build_card_phases).forEach((phase, index) => {
      if (phase === "mvp") {
        this.setUniquePhaseIdsInFeatures(
          Object.values(data.build_card_phases)[index]
        );
      } else if (phase === "custom_phases") {
        Object.values(Object.values(data.build_card_phases)[index]).forEach(
          (p) => {
            this.setUniquePhaseIdsInFeatures(p);
          }
        );
      }
    });
    if (this.dataService.getUserPriceMultiplier()) {
      data["user_price_multiplier"] = this.dataService.getUserPriceMultiplier();
    }
    const endPoints = Constants.apiEndPoints;
    let API =
      environment.API_URL +
      (this.dataService.homePageData.pricing_logic === "new"
        ? endPoints.new_build_card_price
        : endPoints.old_build_card_price);
    return this.httpRequest
      .post(API, data, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          this.dataService.errorMessage = false;
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error, true);
          return observableThrowError(error);
        })
      );
  }

  public fetchFeaturesForBundle(
    id: number,
    featureIds?: Number[],
    filterParams?: any,
    template_filter_ids?: any
  ) {
    const params = new FormData();
    params.append("bundle_id", String(id));
    if (
      featureIds &&
      featureIds.constructor === Array &&
      featureIds.length > 0
    ) {
      const underScoredKey = "selected_feature_ids";
      for (const item of featureIds) {
        params.append(
          underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
          String(item)
        );
      }
    }

    if (
      template_filter_ids != null &&
      template_filter_ids.constructor === Array &&
      template_filter_ids.length > 0
    ) {
      const underScoredKey = "template_ids";
      for (const item of template_filter_ids) {
        params.append(
          underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
          item
        );
      }
    }

    if (filterParams) {
      for (const item of Object.keys(filterParams)) {
        const value = filterParams[item];
        if (item === "feature_bundle_ids" || item === "feature_tag_ids") {
          if (value.length > 0) {
            const underScoredKey = item;
            for (const bundleId of value) {
              params.append(
                underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
                bundleId
              );
              params.append("is_filter", "true");
            }
          }
        } else {
          const underScoredKey = item;
          params.append(underScoredKey, value);
        }
      }
    }

    this.setCurrencyId(params);
    this.setMultiplierExperimentParams(params);
    let API_URL = environment.API_URL + Constants.apiEndPoints.features;
    if (this.appDataService.urlParameters.featureRequest && (this.dataService.buildCardData.type === 'rental_price')) {
      API_URL = API_URL + (API_URL.includes('?') ? '&' : '?') + 'building_block_store=true&build_card_id=' + this.dataService.buildCardData.id;
    }
    return this.httpRequest.post(API_URL, params).pipe(map((data) => data));
  }

  setCurrencyId(params) {
    let currencyId;
    if (this.dataService.isEditRequestedFeatures) {
      currencyId =
        this.dataService.changeFeatureRequestModel.build_card.currency.id;
    } else {
      const buildCardType =
        this.dataService.isEditBuildCard ||
        this.dataService.isIncompleteCardEditMode ||
        this.dataService.isChangeRequest;
      currencyId = buildCardType
        ? this.dataService.buildCardData.currency.id
        : this.dataService.homePageData.currency.id;
    }
    params.append("currency_id", String(currencyId));
    return params;
  }

  setCurrencyIdForFilterData(params) {
    let currencyId;
    if (this.dataService.isEditRequestedFeatures) {
      currencyId =
        this.dataService.changeFeatureRequestModel.build_card.currency.id;
    } else {
      const buildCardType =
        this.dataService.isEditBuildCard ||
        this.dataService.isIncompleteCardEditMode ||
        this.dataService.isChangeRequest;
      currencyId = buildCardType
        ? this.dataService.buildCardData.currency.id
        : this.dataService.homePageData.currency.id;
    }
    params = params.append("currency_id", String(currencyId));
    return params;
  }

  setMultiplierExperimentParams(params) {
    if (this.dataService.getUserPriceMultiplier()) {
      params.append(
        "user_price_multiplier",
        this.dataService.getUserPriceMultiplier()
      );
    }
    if (
      this.appDataService.urlParameters.expCode ||
      this.dataService.buildCardData.multiplier_experiment
    ) {
      const buildCardType =
        this.dataService.isEditBuildCard ||
        this.dataService.isIncompleteCardEditMode ||
        this.appDataService.urlParameters.featureRequest;
      this.dataService.multiplierExperiment = buildCardType
        ? this.dataService.buildCardData.multiplier_experiment
        : this.dataService.homePageData.multiplier_experiment;
      const multiplierExperiment = this.dataService.multiplierExperiment;
      if (this.dataService.multiplierExperiment) {
        params.append(
          "duration_multiplier",
          multiplierExperiment.duration_multiplier
            ? multiplierExperiment.duration_multiplier
            : ""
        );
        params.append(
          "price_multiplier",
          multiplierExperiment.price_multiplier
            ? multiplierExperiment.price_multiplier
            : ""
        );
        params.append(
          "custom_price_multiplier",
          multiplierExperiment.custom_price_multiplier
            ? multiplierExperiment.custom_price_multiplier
            : ""
        );
      }
    }
    return params;
  }

  setMultiplierExperimentParamsForFilterData(params) {
    if (this.dataService.getUserPriceMultiplier()) {
      params = params.append(
        "user_price_multiplier",
        this.dataService.getUserPriceMultiplier()
      );
    }
    if (
      this.appDataService.urlParameters.expCode ||
      this.dataService.buildCardData.multiplier_experiment
    ) {
      const buildCardType =
        this.dataService.isEditBuildCard ||
        this.dataService.isIncompleteCardEditMode ||
        this.appDataService.urlParameters.featureRequest;
      this.dataService.multiplierExperiment = buildCardType
        ? this.dataService.buildCardData.multiplier_experiment
        : this.dataService.homePageData.multiplier_experiment;
      const multiplierExperiment = this.dataService.multiplierExperiment;
      if (this.dataService.multiplierExperiment) {
        params = params.append(
          "duration_multiplier",
          multiplierExperiment.duration_multiplier
            ? multiplierExperiment.duration_multiplier
            : ""
        );
        params = params.append(
          "price_multiplier",
          multiplierExperiment.price_multiplier
            ? multiplierExperiment.price_multiplier
            : ""
        );
        params = params.append(
          "custom_price_multiplier",
          multiplierExperiment.custom_price_multiplier
            ? multiplierExperiment.custom_price_multiplier
            : ""
        );
      }
    }
    return params;
  }

  returnMultiplierParamsObject() {
    const obj = {};
    if (
      !this.appDataService.urlParameters.featureRequest &&
      (this.appDataService.urlParameters.expCode ||
        this.dataService.buildCardData.multiplier_experiment)
    ) {
      const buildCardType =
        this.dataService.isEditBuildCard ||
        this.dataService.isIncompleteCardEditMode;
      this.dataService.multiplierExperiment = buildCardType
        ? this.dataService.buildCardData.multiplier_experiment
        : this.dataService.homePageData.multiplier_experiment;
      const multiplierExperiment = this.dataService.multiplierExperiment;
      if (this.dataService.multiplierExperiment) {
        obj["duration_multiplier"] = multiplierExperiment.duration_multiplier
          ? multiplierExperiment.duration_multiplier
          : "";
        obj["price_multiplier"] = multiplierExperiment.price_multiplier
          ? multiplierExperiment.price_multiplier
          : "";
        obj["custom_price_multiplier"] =
          multiplierExperiment.custom_price_multiplier
            ? multiplierExperiment.custom_price_multiplier
            : "";
      }
      if (this.dataService.getUserPriceMultiplier()) {
        obj["user_price_multiplier"] =
          this.dataService.getUserPriceMultiplier();
      }
    }
    return obj;
  }

  setMultiplierExperimentInQueryParams(API, signName) {
    const buildCardType =
      this.dataService.isEditBuildCard ||
      this.dataService.isIncompleteCardEditMode ||
      this.appDataService.urlParameters.featureRequest;
    this.dataService.multiplierExperiment = buildCardType
      ? this.dataService.buildCardData.multiplier_experiment
      : this.dataService.homePageData.multiplier_experiment;
    const multiplierExperiment = this.dataService.multiplierExperiment;
    if (multiplierExperiment) {
      API =
        API +
        (signName +
          "duration_multiplier=" +
          (multiplierExperiment.duration_multiplier
            ? multiplierExperiment.duration_multiplier
            : "") +
          "&price_multiplier=" +
          (multiplierExperiment.price_multiplier
            ? multiplierExperiment.price_multiplier
            : "") +
          "&custom_price_multiplier=" +
          (multiplierExperiment.custom_price_multiplier
            ? multiplierExperiment.custom_price_multiplier
            : ""));
      return this.dataService.getUserPriceMultiplier()
        ? (API +=
            "&user_price_multiplier=" +
            this.dataService.getUserPriceMultiplier())
        : API;
    } else if (this.dataService.getUserPriceMultiplier()) {
      return (API +=
        "?user_price_multiplier=" + this.dataService.getUserPriceMultiplier());
    } else {
      return API;
    }
  }

  setSpeedForTemplates() {
    const multiplier = this.dataService.homePageData.multiplier_experiment,
      isUSDUser = (this.dataService.user?.currency?.id === 2);
    if (this.dataService.user?.enterprise_lead && multiplier && multiplier?.is_default && multiplier?.speed_id) {
      return multiplier?.speed_id;
    } else if (this.dataService.user?.enterprise_lead || isUSDUser) {
      return 1;
    } else {
      return 4;
    }
  }

  public fetchTemplateDetails(
    templateAlias,
    currenyid?,
    currencyChangeForRental?
  ) {
    let API =
      environment.API_URL +
      Constants.apiEndPoints.template_detail +
      Constants.PARAM_CONSTANTS.SLASH +
      templateAlias;
    if (this.appDataService.urlParameters.expCode) {
      API = this.setMultiplierExperimentInQueryParams(API, "?");
    }
    if (
      currenyid &&
      this.dataService.homePageData &&
      this.dataService.homePageData.currency &&
      this.dataService.homePageData.currency.id
    ) {
      API += API.includes("?")
        ? "&currency_id=" + this.dataService.homePageData.currency.id
        : "?currency_id=" + this.dataService.homePageData.currency.id;
    } else if (!currencyChangeForRental) {
      API += API.includes("?")
        ? "&currency_id=" + this.dataService.defaultCurrency.id
        : "?currency_id=" + this.dataService.defaultCurrency.id;
    }
    if (
      this.dataService.homePageData &&
      this.dataService.homePageData.promotion
    ) {
      API += API.includes("?")
        ? "&promotion_code=" + this.dataService.homePageData.promotion.code
        : "?promotion_code=" + this.dataService.homePageData.promotion.code;
    }
    if (this.appDataService.urlParameters.uniq_code) {
      API += API.includes("?")
        ? "&uniq_code=" + this.appDataService.urlParameters.uniq_code
        : "?uniq_code=" + this.appDataService.urlParameters.uniq_code;
    }
    if (this.appDataService.urlParameters.app_sub_type) {
      API += API.includes("?")
        ? "&app_sub_type=" + this.appDataService.urlParameters.app_sub_type
        : "?app_sub_type=" + this.appDataService.urlParameters.app_sub_type;
    }
    API += (API.includes("?") ? '&' : '?') + 'speed_id=' + this.setSpeedForTemplates();
    return this.httpRequest.get(API, this.appDataService.getRequestOptionArgs())
      .pipe(map((data) => {
          this.dataService.templateDetailModel = data.data;
          this.dataService.templateDetailModel.features =
            this.dataService.templateDetailModel.feature_sets.reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue.features),
            []);
          if (this.dataService.templateDetailModel.redirection_url) {
            window.location.href = this.dataService.templateDetailModel.redirection_url;
          }
        })
      );
  }

  fetchBuildCardForReqestedFeatures() {
    const APIURL =
      environment.API_URL + Constants.apiEndPoints.additional_feature_request;
    return this.httpRequest
      .get(APIURL, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          const changeFeatureArray: ChangeFeatureRequestModel[] = [];
          this.dataService.changeFeatureRequestArrayModel =
            this.modelMapperService.getAdditionalFeaturesArrayModelMapped(
              changeFeatureArray,
              data.data
            );
          this.dataService.changeRequestDataLoaded = true;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  buildCardChangeFeatureRequest(changeRequestBody, isPlatformRequest?) {
    let requestType = Constants.apiEndPoints.additional_feature_request;
    if (isPlatformRequest) {
      requestType = Constants.apiEndPoints.additional_platform_request;
    }
    const APIURL = environment.API_URL + requestType;
    const requestId = this.appDataService.urlParameters.changeRequest_id;
    if (requestId && this.dataService.buildCardData.change_requests_status !== 'rejected') {
      const UPDATEAPIURL = environment.API_URL + '/change_requests/' + requestId;
      return this.httpRequest.put(UPDATEAPIURL, changeRequestBody,
        this.appDataService.getRequestOptionArgs()).pipe(map((data) => data),
          catchError((error: any) => {
            return observableThrowError(error);
          })
        );
    }
    return this.httpRequest.post(APIURL, changeRequestBody,
      this.appDataService.getRequestOptionArgs()).pipe(map((data) => data),
        catchError((error: any) => {
          if (error.status === 422) {
            this.showErrorMessage(error);
          }
          return observableThrowError(error);
        })
      );
  }

  getProposalInstallments(buildCardId) {
    let APIURL;
    if (this.dataService.buildCardData.modification_request) {
      APIURL =
        environment.API_URL +
        Constants.apiEndPoints.build_card_modification +
        "/installments?id=" +
        this.dataService.buildCardData.modification_request.id;
    } else {
      APIURL =
        environment.API_URL +
        Constants.apiEndPoints.build_card +
        "/" +
        buildCardId +
        Constants.apiEndPoints.proposal_installments;
    }
    return this.httpRequest
      .get(APIURL, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => data),
        catchError((error: any) => {
          this.showErrorMessage(error, true);
          if (this.commonService.isPlatformBrowser) {
            if (error.status === 401) {
              this.commonService.showError(error);
            }
          }
          return observableThrowError(error);
        })
      );
  }

  public fetchPrototypeInstallment(buildCardID, promoCode, remove?) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.build_card +
      "/" +
      buildCardID +
      Constants.apiEndPoints.prototype_installment +
      (remove ? "?prototype_promo=" + promoCode : "?refresh=true");

    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map(
          (data) => {
            const prototypeInstallment: PrototypeInstallmentModel =
              new PrototypeInstallmentModel();
            this.dataService.prototypeInstallmentModel =
              this.modelMapperService.getMappedModel(
                prototypeInstallment,
                data.data.attributes
              );
            return data;
          },
          catchError((error: any) => {
            this.showErrorMessage(error);
            return observableThrowError(error);
          })
        )
      );
  }

  public checkforNewFeatures(featureIds: [], buildCardId) {
    const APIURL =
      environment.API_URL + Constants.apiEndPoints.check_for_build_card_update;
    let params = { id: buildCardId, feature_ids: featureIds };
    const multiplierObj = this.returnMultiplierParamsObject();
    if (multiplierObj) {
      params = Object.assign(params, multiplierObj);
    }
    return this.httpRequest
      .post(APIURL, params, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          const selectedFeatures = new Array<FeatureModel>();
          if (data && data.selected_features) {
            data.selected_features =
              this.modelMapperService.getMappedArrayModel(
                selectedFeatures,
                data.selected_features.data
              );
          }
          return data;
        })
      );
  }

  /*applyOrRemoveSpecPromotion(isApplied, isInstantPromo, specId, promoCode) {
    const apiUrl = environment.API_URL + Constants.apiEndPoints.spec_call_promotion;
    const requestParams = {
      spec_promotion: isApplied ? promoCode : ((isApplied && this.appDataService.urlParameters.spec_promotion) ? this.appDataService.urlParameters.spec_promotion : ''),
      id: specId ? specId : ''
    };
    if (this.appDataService.urlParameters.red === 'ip_now') {
      requestParams['prototype'] = 'true';
    } else {
      requestParams['is_instant_spec'] = isInstantPromo ? 'true' : '';
    }
    return this.httpRequest.post(apiUrl, requestParams, this.appDataService.getRequestOptionArgs()).pipe(map((data) => {
      return data;
    }, catchError((error: any) => {
      return observableThrowError(error);
    })));
  }*/

  updateSpecCallForZeroAmount(specCallId) {
    const apiUrl =
      environment.API_URL +
      Constants.apiEndPoints.create_spec_call +
      "/" +
      specCallId;
    const requestParams = { id: specCallId, status: "paid" };
    return this.httpRequest
      .put(apiUrl, requestParams, this.appDataService.getRequestOptionArgs())
      .pipe(
        map(
          (data) => {
            return data;
          },
          catchError((error: any) => {
            return observableThrowError(error);
          })
        )
      );
  }

  fetchSpecCallData() {
    let apiUrl = environment.API_URL + "spec_calls/user_spec_call";
    this.appDataService.urlParameters.red === "ip_now"
      ? (apiUrl += "?prototype=true")
      : (apiUrl += "?is_instant_spec=true");
    return this.httpRequest
      .get(apiUrl, this.appDataService.getRequestOptionArgs())
      .pipe(
        map(
          (data) => {
            return data;
          },
          catchError((error: any) => {
            return observableThrowError(error);
          })
        )
      );
  }

  fetchBuildcardSpecCallData() {
    const buildcard_id = this.dataService.buildCardData.id;
    if (!buildcard_id) return;
    let apiUrl = environment.API_URL + `build_cards/${buildcard_id}/spec_calls`;
    return this.httpRequest
      .get(apiUrl, this.appDataService.getRequestOptionArgs())
      .pipe(
        map(
          (data) => {
            return data;
          },
          catchError((error: any) => {
            return observableThrowError(error);
          })
        )
      );
  }

  public updateInstallments(installmentID) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.updateinstallment +
      "/" +
      installmentID;
    return this.httpRequest
      .put(API, installmentID, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  fetchPaymentCardsList() {
    let APIURL = environment.API_URL + Constants.apiEndPoints.payment_cards;
    if (this.dataService.user.currency.code === "INR") {
      APIURL = APIURL + "/ccavenue_cards";
    }
    return this.httpRequest
      .get(APIURL, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => data),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  addPaymentCard(cardData) {
    const APIURL = environment.API_URL + Constants.apiEndPoints.save_card;
    return this.httpRequest
      .post(APIURL, cardData, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => data),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  makePaymentCardDefaultOrDelete(cardId, deleteCard?) {
    let APIURL = environment.API_URL;
    if (deleteCard) {
      APIURL += Constants.apiEndPoints.remove_saved_card;
      if (this.dataService.user.currency.code === "INR") {
        APIURL = APIURL.replace("remove_saved_card", "remove_ccavenue_card");
      }
    } else {
      APIURL += Constants.apiEndPoints.default_mark_card;
    }
    return this.httpRequest
      .post(
        APIURL,
        { card_id: cardId },
        this.appDataService.getRequestOptionArgs()
      )
      .pipe(
        map((data) => data),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public feedbackSubmission(reason, buildCardID?) {
    let API = environment.API_URL;
    const feedback = { reason: reason, build_card_id: buildCardID };
    API = API + Constants.apiEndPoints.feedBack_Submission;

    return this.httpRequest
      .post(API, feedback, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  public getPaymentExitFeedbackOptions(optionType) {
    const API =
      environment.API_URL +
      "feedback/feedback_options?option_type=" +
      optionType;
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map(
          (resp) => {
            const feedbackOptionsArray = new Array<ExitIntentFeedbackModel>();
            if (resp && resp.feedback_option) {
              return this.modelMapperService.getMappedArrayModel(
                feedbackOptionsArray,
                resp.feedback_option.data
              );
            }
          },
          catchError((error: any) => {
            return observableThrowError(error);
          })
        )
      );
  }

  public showErrorMessage(error, message?) {
    if (this.commonService.isPlatformBrowser) {
      if (
        error &&
        (error.status === 0 ||
          error.status === 502 ||
          error.status === 500 ||
          error.status === 504 ||
          error.status === 422)
      ) {
        if (message) {
          this.dataService.errorMessage = true;
        }
        this.commonService.showError(error);
      }
    }
  }

  public syncBuildcardToNow(build_card_id) {
    const API =
      environment.API_URL +
      "build_cards/" +
      build_card_id +
      "/sync_with_builder_now";
    return this.httpRequest
      .post(API, {}, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  createCustomPrototypeInstallment() {
    const prototypePhase =
      this.dataService.buildCardData.progress.buildcardphases.find(
        (phase) => phase.title === "Professional Prototype"
      );
    let API =
      environment.API_URL +
      "/builder_now/build_cards/" +
      this.dataService.buildCardData.id +
      "/custom_prototype";
    if (prototypePhase && prototypePhase["is_tailor_made"]) {
      API = API + "?type=tailor_made_prototype";
    } else {
      API = API + "?type=custom_prototype";
    }
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  createBuilderCareSupportInstallment(buildCardId) {
    const API = environment.API_URL + "installments/support_installment";
    const headers = this.appDataService.getRequestOptionArgs();
    const requestParams = { build_card_id: buildCardId };
    const requestOptions = {
      headers: headers,
      params: requestParams,
    };
    return this.httpRequest.get(API, null, requestOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  fetchBuilderCloudDetails(data) {
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", environment.CLOUD_AUTH_TOKEN);
    const API = environment.CLOUD_API_URL;
    return this.httpRequest.post(API, data, headers).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  createAndSyncBuildCardToNow(appName) {
    if (appName.indexOf(" ") !== -1) {
      appName = appName.split(" ").join("-").toLowerCase();
    }
    const urlArray = appName.split("#");
    const applicationName = urlArray[0];
    const API =
      environment.API_URL +
      "templates/" +
      applicationName +
      "/create_build_card";
    return this.httpRequest
      .post(API, null, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          const buildObj: BuildCardModel = new BuildCardModel();
          this.dataService.buildCardData =
            this.modelMapperService.getBuildCardModelMapped(
              buildObj,
              res.data.attributes
            );
          return this.dataService.buildCardData;
        }),
        catchError((error: any) => {
          /*this.showErrorMessage(error , true);*/
          this.commonService.showError(error);
          return observableThrowError(error);
        })
      );
  }

  rateExperienceFeedback(feedbackData) {
    const APIURL =
      environment.API_URL + Constants.apiEndPoints.rate_experience_feedback;
    return this.httpRequest
      .post(APIURL, feedbackData, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => data),
        catchError((error: any) => {
          return observableThrowError(error);
        })
      );
  }

  updateNDA(inProgressBuildCardId, name) {
    let API = environment.API_URL + Constants.apiEndPoints.build_card;
    if (inProgressBuildCardId) {
      API += "/" + inProgressBuildCardId;
    }
    if (inProgressBuildCardId) {
      return this.httpRequest
        .put(
          API,
          {
            build_card: {
              meta_data: {
                studio_store_nda_signed: true,
              },
              project_name: name,
            },
          },
          this.appDataService.getRequestOptionArgs()
        )
        .pipe(
          map((data) => {
            this.dataService.errorMessage = false;
            const buildObj: BuildCardModel = new BuildCardModel();
            this.dataService.buildCardData =
              this.modelMapperService.getBuildCardModelMapped(
                buildObj,
                data.data.attributes
              );
            this.dataService.emailIdToShareWith = "";
          }),
          catchError((error: any) => {
            this.showErrorMessage(error, true);
            return observableThrowError(error);
          })
        );
    }
  }

  sendPaymentDetailsAndPoll(paymentData: any) {
    const apiUrl =
      environment.PAYMENT_URL + Constants.apiEndPoints.get_payment_url;
    return this.httpRequest
      .post(apiUrl, paymentData, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          this.dataService.paymentUrl = data.url;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error, true);
          return observableThrowError(error);
        })
      );
  }

  pollBuildCardStatus(inProgressBuildCardId: any, installmentId?) {
    let apiUrl = environment.API_URL + "build_cards/{id}/poll_status";
    apiUrl = apiUrl.replace("{id}", inProgressBuildCardId);
    const headers = this.appDataService.getRequestOptionArgs();
    const requestParams = { installment_id: installmentId };
    const requestOptions = {
      headers: headers,
      params: requestParams,
    };
    return this.httpRequest.get(apiUrl, null, requestOptions).pipe(
      map((data) => {
        return data;
      }),
      catchError((error: any) => {
        this.showErrorMessage(error, true);
        return observableThrowError(error);
      })
    );
  }

  updateRentalFrequency(data) {
    const API =
      environment.API_URL +
      Constants.apiEndPoints.build_card +
      "/" +
      this.dataService.buildCardData.id;
    return this.httpRequest
      .put(API, data, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          this.dataService.errorMessage = false;
          const buildObj: BuildCardModel = new BuildCardModel();
          this.dataService.buildCardData =
            this.modelMapperService.getBuildCardModelMapped(
              buildObj,
              data.data.attributes
            );
          return this.dataService.buildCardData;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error, true);
          return observableThrowError(error);
        })
      );
  }

  signNDAAgreedForSpecCall(specCallId) {
    const apiUrl =
      environment.API_URL +
      Constants.apiEndPoints.create_spec_call +
      "/" +
      specCallId;
    const requestParams = { contract_signed: "true" };
    return this.httpRequest
      .put(apiUrl, requestParams, this.appDataService.getRequestOptionArgs())
      .pipe(
        map(
          (data) => {
            return data;
          },
          catchError((error: any) => {
            this.showErrorMessage(error, true);
            return observableThrowError(error);
          })
        )
      );
  }

  setResetContractsSigned(id, reqParams) {
    const API = environment.API_URL + "build_cards/" + id + "/documents";
    return this.httpRequest
      .post(API, reqParams, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {}),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  getUserShowData() {
    const API = environment.API_URL + "user";
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          const userObj: UserModel = new UserModel();
          if (data) {
            data["user"] = this.modelMapperService.getMappedModel(
              userObj,
              data
            );
          }
          this.setUpUserData(data);
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  fetchUser() {
    const API = environment.API_URL + "/loggedin_user";
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          const userObj: UserModel = new UserModel();
          if (res.user) {
            res.user = this.modelMapperService.getMappedModel(
              userObj,
              res.user
            );
          }
          this.setUpUserData(res);
          this.dataService.setCookie();
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  fetchVersion() {
    const API = environment.API_URL + "/version";
    return this.httpRequest
      .get(API, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  fetchQuestionsForInstantSpecCall() {
    let apiUrl = environment.API_URL + "questions";
    return this.httpRequest
      .get(apiUrl, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          this.dataService.questionsDataForInsantSpecCall =
            this.modelMapperService.getMappedArrayDataModel(
              this.dataService.questionsDataForInsantSpecCall,
              data
            );
        }),
        catchError((error: any) => {
          this.showErrorMessage(error, true);
          return observableThrowError(error);
        })
      );
  }

  saveResponseForInstantSpecCallQuestion(requestObject, questionId) {
    const API =
      environment.API_URL + "questions/" + questionId + "/save_response";
    return this.httpRequest
      .post(API, requestObject, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  saveBantAnswer(postData) {
    const API = environment.API_URL + "users/bant_answer";
    return this.httpRequest
      .post(API, postData, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public sendInvitationForOwnershipTransfer(email) {
    const API = environment.API_URL + "user/send_invitation";
    const obj = {
      email: email,
    };
    return this.httpRequest
      .post(API, obj, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public fetchNatashaFAQs(query, selectedFeatures?, addedNewFeature?) {
    let API = environment.API_URL + "articles/search";
    if (selectedFeatures && !addedNewFeature) {
      if (
        this.appDataService.urlParameters.expCode ||
        this.dataService.buildCardData.multiplier_experiment
      ) {
        API = this.setMultiplierExperimentInQueryParams(API, "?");
      }
    }
    const params = new FormData();
    if (addedNewFeature) {
      this.payloadForNatashaSearch(query, params, selectedFeatures);
      this.payloadForAddNewFeature(addedNewFeature, params);
    } else {
      this.payloadForNatashaSearch(query, params, selectedFeatures, true);
    }
    if (this.dataService.buildCardData?.id) {
      params.append('build_card_id', this.dataService.buildCardData?.id.toString());
    }
    return this.httpRequest
      .post(
        API,
        params,
        this.dataService.user
          ? this.appDataService.getRequestOptionArgs()
          : null
      )
      .pipe(
        map((data) => {
          this.dataService.errorMessage = false;
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error, true);
          return observableThrowError(error);
        })
      );
  }

  public saveNatashaFAQResponse(obj) {
    const API = environment.API_URL + "articles/save_response";
    return this.httpRequest
      .post(
        API,
        obj,
        this.dataService.user
          ? this.appDataService.getRequestOptionArgs()
          : null
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  public getRecommendedFeatures(requestPayload) {
    const API = environment.API_URL + "features/recommendation";
    // const params = new FormData();

    // params.append('build_card_id', requestPayload.build_card_id);

    // if (requestPayload.application_ids && requestPayload.application_ids.length > 0) {
    //   const underScoredKey = 'application_ids';
    //   for (const item of requestPayload.application_ids) {
    //     params.append(underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS, item);
    //   }
    // }

    // if (requestPayload.feature_ids && requestPayload.feature_ids.length > 0) {
    //   const underScoredKey = 'feature_ids';
    //   for (const item of requestPayload.feature_ids) {
    //     params.append(underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS, item);
    //   }
    // }
    // if (requestPayload.last_action) {
    //   this.convertModelToFormData(requestPayload.last_action, params);
    //   //let lastAction = JSON.parse(JSON.stringify(requestPayload.last_action));
    //   //params.append('last_action', lastAction);
    // }

    if (
      requestPayload["last_action"] &&
      requestPayload["last_action"].length === 0
    ) {
      requestPayload["session_alive"] = false;
    }

    let currencyId = this.getCurrencyId();
    if (currencyId) {
      requestPayload["currency_id"] = currencyId;
    }

    let multipliers = this.getMultiplierExperiment();
    if (multipliers) {
      requestPayload["duration_multiplier"] = multipliers.duration_multiplier;
      requestPayload["price_multiplier"] = multipliers.price_multiplier;
      requestPayload["custom_price_multiplier"] =
        multipliers.custom_price_multiplier;
    }

    // this.setCurrencyId(params);
    // this.setMultiplierExperimentParams(params);
    return this.httpRequest
      .post(API, requestPayload, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          this.dataService.errorMessage = false;
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error, true);
          return observableThrowError(error);
        })
      );
  }

  public updateUserSettingForFeaturePage(obj) {
    if (this.dataService.user) {
      const API = environment.API_URL + "user/update_setting";
      return this.httpRequest
        .post(API, obj, this.appDataService.getRequestOptionArgs())
        .pipe(
          map((response) => {
            this.setUpUserData(response);
            return response;
          }),
          catchError((error: any) => {
            this.showErrorMessage(error);
            return observableThrowError(error);
          })
        );
    }
  }

  getCurrencyId() {
    let currencyId;
    if (this.dataService.isEditRequestedFeatures) {
      currencyId =
        this.dataService.changeFeatureRequestModel.build_card.currency.id;
    } else {
      const buildCardType =
        this.dataService.isEditBuildCard ||
        this.dataService.isIncompleteCardEditMode ||
        this.dataService.isChangeRequest;
      currencyId = buildCardType
        ? this.dataService.buildCardData.currency.id
        : this.dataService.homePageData.currency.id;
    }
    return currencyId;
  }

  getMultiplierExperiment() {
    let obj;
    if (
      this.appDataService.urlParameters.expCode ||
      this.dataService.buildCardData.multiplier_experiment
    ) {
      const buildCardType =
        this.dataService.isEditBuildCard ||
        this.dataService.isIncompleteCardEditMode ||
        this.appDataService.urlParameters.featureRequest;
      this.dataService.multiplierExperiment = buildCardType
        ? this.dataService.buildCardData.multiplier_experiment
        : this.dataService.homePageData.multiplier_experiment;
      const multiplierExperiment = this.dataService.multiplierExperiment;
      if (this.dataService.multiplierExperiment) {
        obj = {
          duration_multiplier: multiplierExperiment.duration_multiplier
            ? multiplierExperiment.duration_multiplier
            : "",
          price_multiplier: multiplierExperiment.price_multiplier
            ? multiplierExperiment.price_multiplier
            : "",
          custom_price_multiplier: multiplierExperiment.custom_price_multiplier
            ? multiplierExperiment.custom_price_multiplier
            : "",
        };
      }
    }
    return obj;
  }

  scheduleCallPolling(inProgressBuildCardId: any) {
    let apiUrl =
      environment.PM_DASH_URL +
      "clients/projects/project_polling?build_card_id={id}";
    apiUrl = apiUrl.replace("{id}", inProgressBuildCardId);
    return this.httpRequest.get(apiUrl, this.getPollingRequestArgs()).pipe(
      map((data) => {
        return data;
      }),
      catchError((error: any) => {
        this.showErrorMessage(error, true);
        return observableThrowError(error);
      })
    );
  }

  getPollingRequestArgs() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "secret-key": environment.PM_DASHBOARD_AUTH.secret_key,
      "partner-code": "e-ai",
    });
    return headers;
  }

  updateFreemiuimBuildCard(updatedData, buildCardId) {
    const API =
      environment.API_URL +
      `${Constants.apiEndPoints.freemium_build_card}/${buildCardId}`;
    return this.httpRequest
      .put(API, updatedData, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((data) => {
          const buildObj: BuildCardModel = new BuildCardModel();
          this.dataService.buildCardData =
            this.modelMapperService.getBuildCardModelMapped(
              buildObj,
              data.data.attributes
            );
          return data;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  convertToFreemium(updatedData, uniqCode) {
    const API =
      environment.API_URL +
      "api/v1/build_cards/" +
      uniqCode +
      "/convert_in_freeminm";
    return this.httpRequest
      .put(API, updatedData, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  convertToStudioStore(updatedData, uniqCode) {
    const API =
      environment.API_URL +
      "api/v1/build_cards/" +
      uniqCode +
      "/convert_in_studio_store";
    return this.httpRequest
      .put(API, updatedData, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          const buildObj: BuildCardModel = new BuildCardModel();
          this.dataService.buildCardData =
            this.modelMapperService.getBuildCardModelMapped(
              buildObj,
              res.data.attributes
            );
          return res;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  tokenResolve(data) {
    const API = environment.API_URL + "user/token_resolve";
    return this.httpRequest
      .post(API, data, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  kickOffCancelReason(cancelReason, uniqCode) {
    const API =
      environment.API_URL +
      "api/v1/build_cards/" +
      uniqCode +
      "/kickoff_cancel_reason";
    return this.httpRequest
      .put(API, cancelReason, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  uploadFeatureNoteFile(data) {
    const API = environment.API_URL + "api/v1/documents";
    return this.httpRequest
      .post(API, data, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  emailConfirm(data) {
    const API = environment.API_URL + "user/email_confirmation";
    return this.httpRequest
      .post(API, data, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  markFreemiumCardAsPaid(data, id) {
    const API =
      environment.API_URL + "freemium/build_cards/" + id + "/mark_paid";
    return this.httpRequest
      .post(API, data, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  cloneBuildCard(uniqueCode, currencyId?) {
    let URL = environment.API_URL + `build_cards/${uniqueCode}/duplicate`;
    if (currencyId && this.dataService.checkIfCPEUser()) {
      URL = URL + `?currency_id=${currencyId}`;
    }
    const forSameCustomer = this.commonService.duplicate_for_same_customer;
    if (forSameCustomer || (forSameCustomer === false)) {
      URL = (URL.includes('?') ? (URL + `&duplicate_for_self=${forSameCustomer}`) :
        (URL + `?duplicate_for_self=${forSameCustomer}`));
    }
    return this.httpRequest.post(
      URL,
      "",
      this.appDataService.getRequestOptionArgs()
    );
  }

  saveNatashaTempFeedBack(feedbackData) {
    const URL = environment.API_URL + "api/v1/templates/feedback";
    return this.http.post(URL, feedbackData);
  }

  makeZeroPriceBuildCard(uniqueCode) {
    const URL =
      environment.API_URL + `build_cards/${uniqueCode}/auto_pay_deposit`;
    return this.httpRequest.post(
      URL,
      "",
      this.appDataService.getRequestOptionArgs()
    );
  }

  saveProjectBrief(projectData, buildCardId) {
    const headers = new HttpHeaders({
      "secret-key": environment.PM_DASHBOARD_AUTH.secret_key,
    });
    const URL =
      environment.PM_DASH_URL +
      `clients/projects/update_project_brief_and_attachments?build_card_id=${buildCardId}`;
    return this.httpRequest.post(URL, projectData, headers);
  }

  checkIfSpecCallSchedule(buildCardId) {
    const URL =
      environment.API_URL + `build_cards/${buildCardId}/schedule_call`;
    return this.httpRequest.get(
      URL,
      this.appDataService.getRequestOptionArgs()
    );
  }

  getUserCountryData() {
    const URL = environment.API_URL + `user/available_country`;
    return this.httpRequest.get(
      URL,
      this.appDataService.getRequestOptionArgs()
    );
  }

  getCountriesList(url) {
    return this.httpRequest.get(url);
  }

  cancelSpecCall(specCallId) {
    const apiUrl =
      environment.API_URL +
      Constants.apiEndPoints.create_spec_call +
      "/" +
      specCallId;
    const requestParams = { id: specCallId, status: "cancelled" };
    return this.httpRequest
      .put(apiUrl, requestParams, this.appDataService.getRequestOptionArgs())
      .pipe(
        map(
          (data) => {
            return data;
          },
          catchError((error: any) => {
            return observableThrowError(error);
          })
        )
      );
  }

  upgradeToStore(uniqCode, data) {
    const API =
      environment.API_URL +
      "api/v1/build_cards/" +
      uniqCode +
      "/upgrade_to_store";
    const headers = this.appDataService.getRequestOptionArgs();
    const requestParams = { upfront: data };
    const requestOptions = {
      headers: headers,
      params: requestParams,
    };
    return this.httpRequest.get(API, null, requestOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      })
    );
  }

  upgradeToStarter(uniqCode, data) {
    const API =
      environment.API_URL +
      "api/v1/build_cards/" +
      uniqCode +
      "/upgrade_to_starter";
    const headers = this.appDataService.getRequestOptionArgs();
    const requestParams = { upfront: data };
    const requestOptions = {
      headers: headers,
      params: requestParams,
    };
    return this.httpRequest.get(API, null, requestOptions).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      })
    );
  }

  unArchiveCard(buildCardId) {
    const APIURL = `${environment.API_URL}/build_cards/${buildCardId}/unarchive`;
    return this.httpRequest.post(
      APIURL,
      "",
      this.appDataService.getRequestOptionArgs()
    );
  }

  payloadForNatashaSearch(
    query,
    params,
    selectedFeatures?,
    isFromNatashaSearchOnly?
  ) {
    params.append("query", query);

    if (selectedFeatures) {
      if (
        selectedFeatures &&
        selectedFeatures.constructor === Array &&
        selectedFeatures.length > 0
      ) {
        const underScoredKey = "selected_feature_ids";
        for (const item of selectedFeatures) {
          params.append(
            underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
            item.id ? item.id : item
          );
        }
      } else {
        const underScoredKey = "selected_feature_ids";
        params.append(underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS, "");
      }
      if (isFromNatashaSearchOnly) {
        this.setCurrencyId(params);
      }
    }
    return params;
  }

  payloadForAddNewFeature(feature, featureData) {
    for (const key in feature) {
      if (feature.hasOwnProperty(key)) {
        const value = feature[key];
        const underScoredKey = key;
        if (value != null && value.constructor === Array) {
          if (key === "reference_urls") {
            for (const item of Object.keys(value)) {
              const nesv = value[item];
              for (const k of Object.keys(nesv)) {
                const v = nesv[k];
                featureData.append(
                  underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS + k,
                  v
                );
              }
            }
          } else {
            for (const item of value) {
              featureData.append(
                underScoredKey + Constants.PARAM_CONSTANTS.BRACKETS,
                item
              );
            }
          }
        } else if (value != null) {
          featureData.append(underScoredKey, value);
        }
      }
    }
    this.setCurrencyId(featureData);
    this.setMultiplierExperimentParams(featureData);
  }

  confirmSpecCallBooked(callType, specCallId) {
    const headers = new HttpHeaders({
      "secret-key": environment.SCHEDULAR_SECRET_KEY,
    });
    const apiUrl =
      environment.SCHEDULAR_BASE_URL +
      "calendar/external/show_booking?generic_id=" +
      specCallId +
      "&booking_type=" +
      callType;
    return this.httpRequest.get(apiUrl, headers).pipe(
      map(
        (data) => {
          return data;
        },
        catchError((error: any) => {
          return observableThrowError(error);
        })
      )
    );
  }

  updateBuildAsKey(address) {
    const uri = `${environment.API_URL}users`;
    const obj = {
      user: {
        address_attributes: {
          id: this.dataService.user.address.id,
          company_name: address.companyName ? address.companyName : "",
          billed_as: address.companyName ? "company" : "individual",
        },
      },
    };
    return this.httpRequest
      .put(uri, obj, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  checkUKVAT(vatNumber) {
    let headers = new HttpHeaders();
    headers = headers.append(
      "Accept",
      "application/vnd.hmrc.1.0+application/json"
    );
    const url =
      "https://api.service.hmrc.gov.uk/organisations/vat/check-vat-number/lookup/" +
      vatNumber;
    return this.httpRequest.get(url, headers);
  }

  updateTaxOrPreferencesData(data) {
    const uri = `${environment.API_URL}users`;
    return this.httpRequest
      .put(uri, data, this.appDataService.getRequestOptionArgs())
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: any) => {
          this.showErrorMessage(error);
          return observableThrowError(error);
        })
      );
  }

  getCitiesList() {
    const URL = environment.API_URL + 'get_cities_data';
    return this.httpRequest.get(URL).pipe(
      map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  createCapchaseAccount(payload) {
    const APIURL = environment.CAPCHASE_URL + 'api/v1/company/billing_account';
    return this.httpRequest.post(APIURL, payload, this.appDataService.setHeaderFor2PayAndCapchase()).pipe(map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  fetchCapchaseInstallments(payload) {
    const APIURL = environment.CAPCHASE_URL + 'api/v1/repayment_plan/preview';
    return this.httpRequest.post(APIURL, payload, this.appDataService.setHeaderFor2PayAndCapchase()).pipe(map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  getPaymentLinkForCapchase(ID) {
    const APIURL = environment.CAPCHASE_URL + `api/v1/repayment_plan/${ID}/embedabble_payment_link`;
    return this.httpRequest.get(APIURL, this.appDataService.setHeaderFor2PayAndCapchase()).pipe(map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  createRepaymentPlanForCapchase(payload) {
    const APIURL = environment.CAPCHASE_URL + 'api/v1/repayment_plan';
    return this.httpRequest.post(APIURL, payload, this.appDataService.setHeaderFor2PayAndCapchase()).pipe(map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  capcahseStatusPolling(ID) {
    const APIURL = environment.CAPCHASE_URL + `api/v1/repayment_plan/${ID}`;
    return this.httpRequest.get(APIURL, this.appDataService.setHeaderFor2PayAndCapchase()).pipe(map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  capchaseFullfillpolling(ID) {
    const APIURL = environment.CAPCHASE_URL + `api/v1/repayment_plan/${ID}/fulfil`;
    const payload = { build_card_id: this.dataService.buildCardData.id }
    return this.httpRequest.put(APIURL, payload, this.appDataService.setHeaderFor2PayAndCapchase()).pipe(map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  capchaseFeatureCheck(ID) {
    const APIURL = environment.CAPCHASE_URL + `api/v1/company/billing_account/${ID}/feature_check`;
    return this.httpRequest.get(APIURL, this.appDataService.setHeaderFor2PayAndCapchase()).pipe(map((response) => {
      return response;
    }), catchError((error: any) => {
      this.showErrorMessage(error);
      return observableThrowError(error);
    }));
  }

  getCompanies(searchedText) {
    const APIURL = `${environment.TWOPAY_URL}v1/companies/search?q=${searchedText}&offset=0&limit=100`;
    return this.httpRequest.get(APIURL).pipe(
      map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  creditCheck(payload) {
    const APIURL = `${environment.TWOPAY_URL}v1/company/credit_check`;
    return this.httpRequest.post(APIURL, payload, this.appDataService.setHeaderFor2PayAndCapchase()).pipe(
      map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  fetchTwoPayInstallments(payload) {
    const APIURL = `${environment.TWOPAY_URL}v1/repayment_plan/preview`;
    return this.httpRequest.post(APIURL, payload, this.appDataService.setHeaderFor2PayAndCapchase()).pipe(
      map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  makeTwoPayPayment(payload) {
    const APIURL = `${environment.TWOPAY_URL}v1/repayment_plan`;
    return this.httpRequest.post(APIURL, payload, this.appDataService.setHeaderFor2PayAndCapchase()).pipe(
      map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  twoPayStatusPoling(ID) {
    const APIURL = `${environment.TWOPAY_URL}v1/repayment_plan/${ID}/status`;
    return this.httpRequest.get(APIURL, this.appDataService.setHeaderFor2PayAndCapchase()).pipe(
      map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  twoPayFullfillPoling(ID) {
    const APIURL = `${environment.TWOPAY_URL}v1/repayment_plan/${ID}/fulfil`;
    const payload = { build_card_id: this.dataService.buildCardData.id };
    return this.httpRequest.put(APIURL, payload, this.appDataService.setHeaderFor2PayAndCapchase()).pipe(
      map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  getTwoPayBillingID(payload) {
    const APIURL = `${environment.TWOPAY_URL}v1/company/billing_account`;
    return this.httpRequest.post(APIURL, payload, this.appDataService.setHeaderFor2PayAndCapchase()).pipe(
      map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  getCustomFeatureComplexity(payload) {
    const APIURL = `${environment.API_URL}features/eval_complexity`;
    return this.httpRequest.post(APIURL, payload, this.appDataService.getRequestOptionArgs()).pipe(
      map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

  customToCoreSearch(payload) {
    let APIURL = `${environment.API_URL}features/custom_to_core_search`;
    if (this.appDataService.urlParameters.expCode || this.dataService.buildCardData.multiplier_experiment) {
      APIURL = this.setMultiplierExperimentInQueryParams(APIURL, '?');
    }
    return this.httpRequest.post(APIURL, payload, this.appDataService.getRequestOptionArgs()).pipe(
      map((response) => {
        return response;
      }), catchError((error: any) => {
        this.showErrorMessage(error);
        return observableThrowError(error);
      }));
  }

}

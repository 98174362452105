
import { ChangeDetectorRef, Component, DoCheck, Input, OnInit } from '@angular/core';
import { PhaseService } from '../../services/phase.service';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Angulartics2Segment } from "angulartics2/segment";
import { ApiRequests } from "@shared/services/api-requests.service";
import { Subscription } from "rxjs";
import { AppDataService } from '@rootmodule/app-data.service';
import { FeatureModel } from '@models/feature-model/feature.model';
import { Constants } from '@shared/utils/constants';
import { CommonService } from '@shared/services/common.service';
import { Intercom } from 'ng-intercom';
import { TeamsModel } from '@models/teams-model/teams.model';
import { ModelMapperService } from '@shared/services/model-mapper.service';
import { HomeTemplateService } from '@shared/services/home-template.service';
import { BuildCardModel } from '@models/build-card-model/build-card.model';
import moment from 'moment';

const ID = 'id';
const ISTAILORMADE = 'is_tailor_made';


@Component({
  selector: 'app-bottom-bar',
  templateUrl: 'bottom-bar.component.html',
  styleUrls: ['bottom-bar.component.scss']
})

export class BottomBarComponent implements OnInit {
  @Input('currentUrl') currentUrl;
  public showApplyPromotion = false;
  public showBottomPipe = false;
  public showPrompt: boolean;
  public isShowConfirmChangeModalAfterSecurityPaid = false;
  public buildCardPreviousPrice: number;
  public discriptionBlockPopup = false;
  moment: any;
  showFixedCostTooltip = false;

  constructor(public phaseService: PhaseService,
    public router: Router,
    public dataService: DataService,
    public analyticsSegment: Angulartics2Segment,

    public appDataService: AppDataService,
    public apiRequest: ApiRequests,
    public cdRef: ChangeDetectorRef,
    private modelMapperService: ModelMapperService,
    private commonService: CommonService,
    public homeTemplateService: HomeTemplateService) {
      this.moment = moment;
  }

  public ngOnInit() {
    if (this.dataService.buildCardData && this.dataService.isEditBuildCardAfterSecurityPaid()) {
      this.buildCardPreviousPrice = this.phaseService.getDiscountedPriceForCards(this.dataService.buildCardData);
    }
    const buildCardData = this.dataService.buildCardData;
    const isUnpaidCard = this.dataService.isUnpaidCard();
    const isEntUserOrCard = (!this.dataService.user?.enterprise_lead && this.dataService.checkIfBuildCardisinvited() && (buildCardData.enterprise_lead === 'true'));
    if (isEntUserOrCard && buildCardData.promotion && (buildCardData.promotion.is_enterprise_promo) && isUnpaidCard) {
      this.removeManualPromotion();
    }
    const promo = this.dataService.buildCardData.promotion;
    if (promo && promo.code && (promo.type === 'SpecialEventPromotion')) {
      this.checkValidationOfMothersPromo();
    }
  }

  checkValidationOfMothersPromo() {
    const buildCardData = this.dataService.buildCardData;
    this.apiRequest.fetchPromotionData(buildCardData.promotion.code, this.phaseService.selectedHash.is_advance, this.phaseService.finalPricenWeeks.weeks).subscribe((resp) => {
      if (resp.message) {
        this.removeManualPromotion();
      }
    });
  }

  public getSelectedApps() {
    return this.phaseService.selectedApps;
  }

  public getPreventClick() {
    if (this.dataService.errorMessage) {
      this.phaseService.preventClick = true;
    }
    return this.phaseService.preventClick;
  }

  showBottomBarFields() {
    const currentPage = this.dataService.currentPage,
      selectedFeaturesLength = this.phaseService.isFeatureRequest ? this.dataService.additionalFeatures.length : this.phaseService.selectedFeatures.length;
    return ((((currentPage === 'apps') && this.phaseService.buildCardEditMode) || currentPage === 'features' || currentPage === 'delivery'));
  }

  showNavigateButtons() {
    const currentPage = this.dataService.currentPage,
      selectedFeaturesLength = this.phaseService.isFeatureRequest ? this.dataService.additionalFeatures.length : this.phaseService.selectedFeatures.length;
    return (((selectedFeaturesLength > 0) || this.phaseService.isPlatformRequest) && (currentPage === 'features' || currentPage === 'delivery' || currentPage === 'paymentPlan'));
  }


  showAppFooter() {
    return (this.dataService.currentPage === 'apps');
  }



  goToFeatures() {
    this.homeTemplateService.isBuildNowClickedFromTemplatePage = true;
    this.homeTemplateService.goToFeatures();
    // this.router.navigate([ 'build-card/5t6e']);
  }

  public setTempateName() {
    this.phaseService.selectedHash.customized_template_name = '';
    let index = 0;
    for (const app of this.phaseService.selectedApps) {
      if (index > 0) {
        if (index === this.phaseService.selectedApps.length - 1) {
          this.phaseService.selectedHash.customized_template_name += ' and ';
        } else {
          this.phaseService.selectedHash.customized_template_name += ', ';
        }
      }
      this.phaseService.selectedHash.customized_template_name += app.title;
      index++;
    }
  }

  public setBreadCrumbForApps() {
    const appStep = this.phaseService.headerBreadCrumb.find(step => step.title === 'Apps');
    appStep.selected = true;
  }

  public isBuildCardEditMode() {
    return this.phaseService.buildCardEditMode;
  }

  public onNextClicked() {
    if (this.phaseService.buildCardEditMode) {
      this.setTempateName();
      this.phaseService.gotoBuildcard(this.analyticsSegment,  this.router);
    } else {
      if (this.dataService.homePageData.promotion) {
        if (this.phaseService.isValidPromotion()) {
          this.phaseService.promotionValid = true;
          this.phaseService.flatPrice = this.dataService.homePageData.promotion.discount;
          this.phaseService.selectedHash.promotion_id = this.dataService.homePageData.promotion.id;
        } else {
          this.phaseService.promotionValid = false;
          this.phaseService.flatPrice = 0;
          this.phaseService.selectedHash.promotion_id = '';
        }
      }

      this.setTempateName();
      this.phaseService.showCurrency = false;
      this.setBreadCrumbForApps();
      this.phaseService.showHideBottomBarLoader = true;
      sessionStorage.setItem('show_natasha_again', 'from_template_page');
      this.router.navigate(['feature']);
    }
  }

  public getSelectedFeatures(): FeatureModel[] {
    return this.phaseService.selectedFeatures;
  }

  public getEditMode() {
    return this.phaseService.buildCardEditMode;
  }

  public getFeatureTutorialStatus() {
    if (this.dataService.currentPage === 'features' && !this.phaseService.buildCardEditMode && this.commonService.isPlatformBrowser) {
      return ((localStorage.getItem('tutorial_seen') !== 'true') ? true : false);
    } else {
      return false;
    }
  }

  public gotoTeam() {
    // window.sessionStorage.removeItem('isShowMaxFeaturePopUp')
    const eventData = {
      Feature_name: this.phaseService.selectedFeatures.map(f => f.title).join(),
      Manual_feature_added: this.getManualFeatureAdded().map(f => f.title).join(),
      Natasha_suggested_feature: this.phaseService.selectedFeatures.filter(f => f.is_recommended).map(f => f.title),
      default_features: this.getDefaultFeaturesName().join()
    };
    this.phaseService.trackEventForObject(this.analyticsSegment, "delivery_plan_selected", {
      user_id : this.dataService.user?.id,
      delivery_plan_selected : eventData
    });
    this.checkIfApiUp();
    this.dataService.showHidePhaseBreakUp = false;

    if (!this.getPreventClick()) {
      if (this.phaseService.buildCardEditMode && this.phaseService.getIdOrUniqCode()) {
        this.router.navigate(['delivery/' + this.phaseService.getIdOrUniqCode()]);
      } else {
        this.phaseService.showCurrency = false;
        this.router.navigate(['delivery']);
      }
    } else {
      this.commonService.showError();
    }
  }

  getDefaultFeaturesName() {
    const featuresName = [];
    this.phaseService.selectedApps.forEach(apps => {
      if (apps.features) {
        const features = apps.features['data'] ? apps.features['data'] : apps.features;
        [...features].forEach(f => featuresName.push(f.title ? f.title : f.attributes.title));
      }
    });
    return featuresName;
  }

  getManualFeatureAdded() {
    const defaultFeatures = [];
    this.phaseService.selectedApps.forEach(apps => {
      if (apps.features) {
        const features = apps.features['data'] ? apps.features['data'] : apps.features;
        [...features].forEach(f => defaultFeatures.push(f.id));
      }
    });
    return this.phaseService.selectedFeatures.filter(f => !defaultFeatures.includes(f.id.toString()));
  }

  public submitPhases(signUpClickedSrc?, isForAdditionalFeatures?) {
    if (this.phaseService.isPlatformRequest) {
      const uniqueCode = this.dataService.buildCardData.uniq_code;
      this.router.navigate([`buildcard-payment/${uniqueCode}/` + 'paymentPlan']);
      return;
    }
    if (isForAdditionalFeatures) {
      if (this.dataService.buildCardData.type === 'rental_price') {
        this.router.navigate(['buildcard-payment/' + this.phaseService.getIdOrUniqCode() + '/paymentPlan']);
      } else {
        this.router.navigate(['build-card/' + this.phaseService.getIdOrUniqCode()]);
      }
    } else {
      const eventData = {
        Feature_name: this.phaseService.selectedFeatures.map(f => f.title).join(),
        Manual_feature_added: this.getManualFeatureAdded().map(f => f.title).join(),
        Natasha_suggested_feature: this.phaseService.selectedFeatures.filter(f => f.is_recommended).map(f => f.title),
        default_features: this.getDefaultFeaturesName().join()
      };
      this.phaseService.trackEventForObject(this.analyticsSegment,  'delivery_plan_completed', {
        user_id : this.dataService.user?.id,
        phase_selected : this.phaseService.getPhaseTitle()
      });
      this.phaseService.isInstallmentsChanged = true;
      this.dataService.showHidePhaseBreakUp = false;
      this.phaseService.buildCardEditMode = this.dataService.buildCardData.status === Constants.buildCardStatus.CARD_INITIALISED ? false : this.phaseService.buildCardEditMode;
      if (this.phaseService.buildCardEditMode) {
        this.phaseService.gotoBuildcard(this.analyticsSegment,  this.router);
      } else {
        this.dataService.totalSelectedFeatures = 0;
        this.showPrompt = false;
        if (this.dataService.errorMessage) {
          this.dataService.showHideLoader(true);
          this.phaseService.createBuildCardPriceHash();
          this.apiRequest.getBuildCardPrice(this.phaseService.buildCardSelections).subscribe((data) => {
            this.dataService.showHideLoader(false);
            this.proceedToBuildCard(signUpClickedSrc);
          }, error => { this.dataService.showHideLoader(false); });
        } else {
          if (this.appDataService.urlParameters.cpe_user) {
            this.phaseService.createBuildCardPriceHash();
          }
          this.proceedToBuildCard(signUpClickedSrc);
        }
      }
    }
  }

  public proceedToBuildCard(signUpClickedSrc?) {

    if (!this.getPreventClick()) {
      this.phaseService.preventClick = true;
      this.phaseService.selectedHash.status = Constants.buildCardStatus.CARD_COMPLETED;
      this.apiRequest.getUserProfile().subscribe(
        (data) => {
          if (!this.phaseService.selectedTeam) {
            this.checkTeam(true);
          } else {
            this.gotoBuildcard();
          }
        },
        error => {
          if (error.status === 401) {
            this.checkTeam();
          }
          this.dataService.signupSrcName = signUpClickedSrc;
          this.phaseService.preventClick = false;
          if (this.dataService.homePageData?.block_sign_up) {
            this.phaseService.showSignin = true;
            this.phaseService.showRegister = false;
          } else {
            this.phaseService.showRegister = true;
            this.phaseService.showSignin = false;
          }
          this.phaseService.openCommonAuth = true;
          this.phaseService.reachedUptoPhases = true;
          this.phaseService.isNdaFlow = false;
        }
      );
    }
  }
  public checkTeam(nextStep?) {
    this.apiRequest.fetchTeams().subscribe((data: any) => {
      if (data && data.teams) {
        this.dataService.teams = this.modelMapperService.getTeamMappedModel(
          new TeamsModel(),
          data.teams
        );
        this.phaseService.selectedTeam = this.dataService.teams.default;
        this.phaseService.selectedHash.team_id = this.dataService.teams.default.id;
        this.dataService.errorMessage = false;
        this.phaseService.preventClick = false;
        if (nextStep) {
          this.gotoBuildcard();
        }
      }
    });
  }
  public gotoBuildcard() {
    if (this.dataService.buildCardData.project_name === 'My Builder Project') {
      this.phaseService.isNewBuildCard = true;
    } else {
      this.phaseService.isNewBuildCard = false;
    }
    this.phaseService.isFromDelivery = true;
    this.dataService.showDeliveryLoading = true;
    this.phaseService.preventClick = true;
    if (!this.phaseService.isFeatureRequest && (this.phaseService.selectedHash.project_name === 'My Builder Project') && (this.phaseService.isNewBuildCard || !this.dataService.buildCardData.id)) {
    this.phaseService.showProjectNamePopup = true;
    }
    this.apiRequest.createUpdateBuildCard(this.phaseService.selectedHash, this.phaseService.inProgressBuildCardId)
      .subscribe(
        (data: any) => {
          this.trackCardEvents();
          this.phaseService.paymentData = { pending_installment: {}, general_info: {}, installments: [] };
          this.phaseService.preventClick = false;
          this.phaseService.dontSetCareConfig = false;
          this.dataService.unsavedChanges = false;
          this.dataService.landingPage = '';
          this.phaseService.selectedDataForSidePanel = [];
          this.phaseService.setUpInFullBuildCard();
          this.phaseService.isReadyToProceed = true;
          if (!this.phaseService.showProjectNamePopup) {
            this.dataService.showDeliveryLoading = false;
            this.router.navigate([
              'build-card/' + this.phaseService.getIdOrUniqCode()
            ]);
          }
        },
        error => {
          if (error.status === 403 || error.status === 401) {
            this.dataService.user = null;
            this.dataService.unsetCookie();
            this.phaseService.clearLocalStorage();
            this.phaseService.showSignin = true;
            this.phaseService.openCommonAuth = true;
            this.phaseService.reachedUptoPhases = true;
            this.phaseService.preventClick = false;
          } else {
            this.phaseService.preventClick = false;
          }
          this.dataService.showDeliveryLoading = false;
          this.phaseService.showProjectNamePopup = false;
        }
      );
  }
  public ngOnDestroy() {
    this.phaseService.isFromDelivery = false;
  }

  public trackCardEvents() {
    // this.homeTemplateService.trackCardEvents();
  }

  public getPerWeekPriceForCard() {
    return this.phaseService.getDiscountedPrice();
  }

  public getLocaledPrice(price: any): any {
    return this.dataService.getLocaledPrice(price, this.dataService.buildCardData.currency);
  }

  public getFinalWeeks() {
    return this.phaseService.finalPricenWeeks.weeks;
  }

  public getTotalWithSpecing() {
    return this.phaseService.getTotalWithSpecing();
  }


  getCurrency() {
    const buildCardStatus = this.phaseService.buildCardEditMode || this.dataService.isIncompleteCardEditMode || this.phaseService.isFeatureRequest;
    if (this.dataService.homePageData && this.dataService.homePageData.currency) {
      return buildCardStatus ? this.dataService.buildCardData.currency.symbol : this.dataService.homePageData.currency.symbol;
    } else if (this.dataService.user && this.dataService.user.currency) {
      return buildCardStatus ? this.dataService.buildCardData.currency.symbol : this.dataService.user.currency.symbol;
    }
  }
  getFeedbackFlag(value) {
    this.dataService.showHidePhaseBreakUp = false;
  }

  public ApplyPromotion() {
    this.phaseService.promoError = null;
    this.phaseService.promotionError = null;
    this.showApplyPromotion = true;
    this.phaseService.openSideNav = true;
    this.phaseService.fetchPromotionList();
  }

  public removeManualPromotion() {
    this.phaseService.promotionApplied = false;
    this.phaseService.isManualPromotionApplied = false;
    this.phaseService.promotionValid = false;
    this.dataService.manualPromotion = null;
    this.phaseService.manualPromoCode = null;
    this.phaseService.selectedHash.promotion_id = null;
    this.dataService.homePageData.promotion = null;
    this.phaseService.promotionError = null;
    this.appDataService.urlParameters.promotionCode = null;
    this.phaseService.urlBanner = null;
    this.phaseService.updatePrice();
    this.showApplyPromotion = false;
    if (this.dataService.modifiedBuildCardData) {
      this.dataService.modifiedBuildCardData.promotion.add = null;
    }
  }

  public getDiscountedWithSpecing() {
    return this.phaseService.getDiscountedWithSpecing().toFixed(2);
  }

  public phasebreakBox(pageName) {
    let eventValue: string;
    switch (pageName) {
      case 'delivery':
        eventValue = 'Delivery';
        break;
      case 'features':
        eventValue = 'Feature';
    }
    this.phaseService.trackEventForObject(this.analyticsSegment,  'cost_breakdown_viewed', {
      user_id : this.dataService.user?.id,
      screen_source : eventValue
    });
    this.dataService.showHidePhaseBreakUp = !this.dataService.showHidePhaseBreakUp;
    this.discriptionBlockPopup = false;
    this.showFixedCostTooltip = false;
  }

  public isPartnerSellThrough() {
    return (
      this.dataService.homePageData.builder_partner &&
      this.dataService.homePageData.builder_partner.partner_type ===
      'sell_through'
    );
  }

  public isShowCurrencyChooser() {
    return (
      this.dataService.showCurrencyAfterLogin &&
      this.dataService.homePageData &&
      this.dataService.homePageData.currencies &&
      this.dataService.homePageData.currencies.length > 0
    );
  }
  public checkIfApiUp() {
    if (this.getPreventClick()) {
      this.phaseService.updatePrice();
    }
  }

  isCpeInvitedForBuildCard() {
    return (
      (this.dataService.user && this.dataService.user.roles === 'CPE')
      && (this.dataService.buildCardData && this.dataService.buildCardData.type === 'rental_price')
    );
  }

  showConfirmChangeModalAfterSecurityPaid() {
    const currentPage = this.dataService.currentPage;
    const build_phases = this.dataService.homePageData.build_phases;
    if (!this.dataService.modifiedBuildCardData && (this.phaseService.getDiscountedPriceForCards(this.dataService.buildCardData) !== this.phaseService.finalPricenWeeks.total)) {
      this.isShowConfirmChangeModalAfterSecurityPaid = true;
      return;
    }
    if ((currentPage === 'features') && this.checkIfFeatureChanged() && this.checkIfFeatureCountChanged()) {
      this.router.navigate([
        'build-card/' + this.phaseService.getIdOrUniqCode()
      ]);
      return;
    }
    if ((currentPage === 'delivery') && this.checkIfPromotionChanged() && this.checkIfAnyChangesFromDeliveryPage() && this.checkIfFeatureCountChanged()) {
      this.router.navigate([
        'build-card/' + this.phaseService.getIdOrUniqCode()
      ]);
      return;
    }
    if ((currentPage === 'delivery') && (build_phases.filter(p => p.selected).length === 0)) {
      return;
    }
    if (this.phaseService.checkIfOnlyRapidPrototypeSelected() && this.dataService.isEditBuildCardAfterSecurityPaid()) {
      let error = {
        error: {
          message: 'Please select any other phase to continue'
        }
      };
      this.commonService.showError(error);
      return;
    } else {
      this.isShowConfirmChangeModalAfterSecurityPaid = true;
    }
  }

  checkIfFeatureCountChanged() {
    const updatedFeature = [];
    this.dataService.buildCardData.progress['buildcardfeatures'].forEach(feature => {
      const featureCount = this.phaseService.selectedFeatures.find(f => f.id === feature.feature_id);
      if (feature.feature_count && featureCount.feature_count && (feature.feature_count !== featureCount.feature_count)) {
        updatedFeature.push(featureCount.id);
      }
    });
    return (updatedFeature.length > 0) ? false : true;
  }

  checkIfFeatureChanged() {
    let addedFLength = 0;
    if (this.dataService.modifiedBuildCardData) {
      addedFLength = this.dataService.modifiedBuildCardData.features.add.data.length;
    }
    const progressFeatures = this.dataService.buildCardData.progress.features,
          selectedFeatures = this.phaseService.selectedFeatures;
    return (((progressFeatures.length + addedFLength) === selectedFeatures.length) && this.checkIfPromotionChanged() &&
          (progressFeatures.map(f => f.id).every(el => selectedFeatures.map(f => f.id).includes(el))) &&
          (selectedFeatures.map(f => f.id).every(el => progressFeatures.map(f => f.id).includes(el))));
  }

  checkIfAnyChangesFromDeliveryPage() {
    const selectedHash = this.phaseService.selectedHash;
    const buildCardData = this.dataService.buildCardData;
    const progress = this.dataService.buildCardData.progress;
    const build_phases = this.dataService.homePageData.build_phases;
    if (buildCardData.project_name !== selectedHash.project_name) {
      return false;
    }
    if ((build_phases.filter(p => p.selected).length !== progress.buildphases.length) || !(build_phases.filter(p => p.selected).map(p => p.id).every(el => progress.buildphases.map(p => p.id).includes(el)))) {
      return false;
    }
    if (progress.team[ID] !== selectedHash.team_id) {
      return false;
    }
    if (progress.speed[ID] !== selectedHash.speed_id) {
      return false;
    }
    const cloudPriceFromCard = buildCardData.minimum_cloud_price ? buildCardData.minimum_cloud_price : buildCardData.cloud_price;
    const cloudPriceFromHash = selectedHash.minimum_cloud_price ? selectedHash.minimum_cloud_price : selectedHash.cloud_price;
    if ((buildCardData.cloud_opted !== selectedHash.cloud_opted) || (Math.trunc(cloudPriceFromCard) !== Math.trunc(cloudPriceFromHash))) {
      return false;
    }
    if ((buildCardData.is_under_support !== selectedHash.is_under_support) || (this.fetchCareType() !== selectedHash.care_type)) {
      return false;
    }
    if ((progress.platforms.length !== selectedHash.platform_ids.length) || !progress.platforms.map(p => p.id).every(el => selectedHash.platform_ids.includes(el))) {
      return false;
    }
    if ((progress.apps.length !== selectedHash.application_ids.length) || !progress.apps.map(a => a.id).every(el => selectedHash.application_ids.includes(el))) {
      return false;
    }
    for (const phase of this.dataService.buildCardData.progress.buildcardphases) {
      const changedPhase = build_phases.filter(p => p.title === phase.title);
      if ((phase.platform_ids.length !== changedPhase[0].platforms.length) || !phase.platform_ids.map(id => id.toString()).every(el => changedPhase[0].platforms.map(p => p.id.toString()).includes(el))) {
        return false;
      }
      if (parseInt(phase.speed_id, 10) !== changedPhase[0].speed.id) {
        return false;
      }
      if (((phase.title === 'MVP') || (phase['type'] === 'custom_phase')) && (phase.features.length !== changedPhase[0].features.length) && !(phase.features.map(f => f.id).every(el => changedPhase[0].features.map(f => f.id).includes(el)))) {
        return false;
      }
      if (!phase[ISTAILORMADE]) { phase[ISTAILORMADE] = false; }
      if (!changedPhase[0][ISTAILORMADE]) { changedPhase[0][ISTAILORMADE] = false; }
      if ((phase.title === 'Professional Prototype') && (phase[ISTAILORMADE] !== changedPhase[0][ISTAILORMADE])) {
        return false;
      }
    }
    return true;
  }

  checkIfPromotionChanged() {
    if ((!this.dataService.buildCardData.promotion && this.phaseService.selectedHash.promotion_id) ||
      (this.dataService.buildCardData.promotion && !this.phaseService.selectedHash.promotion_id) ||
      (this.dataService.buildCardData.promotion && (this.dataService.buildCardData.promotion.id !== this.phaseService.selectedHash.promotion_id))) {
      return false;
    } else {
      return true;
    }
  }

  buildCardModificationRequestAfterSecurityPaid() {
    this.phaseService.createBuildCardPriceHash();
    this.apiRequest.createUpdateBuildCard(this.phaseService.selectedHash, this.phaseService.inProgressBuildCardId)
      .subscribe(
        (data: any) => {
          this.isShowConfirmChangeModalAfterSecurityPaid = false;
          this.trackCardEvents();
          this.phaseService.preventClick = false;
          this.dataService.unsavedChanges = false;
          this.dataService.landingPage = '';
          this.phaseService.selectedDataForSidePanel = [];
          this.phaseService.isReadyToProceed = true;
          this.setUpdatedDataAfterEdit();
          this.apiRequest.fetchSingleBuildCardData(this.dataService.buildCardData.uniq_code).subscribe((data: any) => {
            this.phaseService.inProgressBuildCardId = this.dataService.buildCardData.id;
            this.phaseService.setUpInFullBuildCard();
            this.phaseService.buildCardEditMode = true;
            this.dataService.isEditBuildCard = true;
          });
          this.router.navigate([
            'build-card/' + this.phaseService.getIdOrUniqCode()
          ]);
        }, (error) => {
          this.isShowConfirmChangeModalAfterSecurityPaid = false;
        });
  }

  setUpdatedDataAfterEdit() {
    this.dataService.modifiedBuildCardData.feature_ids.add.forEach((id) => {
      const index = this.phaseService.selectedFeatures.findIndex((feature) => {
        return feature.id === id;
      });
      if (index !== -1) {
        this.phaseService.selectedFeatures[index]['type'] = 'new';
      }
    });
  }

  showDynamicPricingModal() {
    this.dataService.questionMarkClicked = true;
     this.phaseService.trackEventForObject(this.analyticsSegment,  'customisation_cost_viewed', {
      user_id : this.dataService.user?.id,
      screen_source : this.dataService.currentPage
    });
    this.hideTotalCosttooltip();
    this.discriptionBlockPopup = false;
    this.showFixedCostTooltip = false;
  }

  totalAdditionalFeatureMonths() {
    return Math.ceil(this.dataService.totalAdditionalFeaturesWeek / 4);
  }

  clearTemplateSelections() {
    // let templates = this.phaseService.selectedApps.reduce((previousValue, currentValue) => previousValue + currentValue.title, '')
    let templates = this.phaseService.selectedApps.map(app => app.title).join();
    this.phaseService.trackEventForObject(this.analyticsSegment,  'template_removed', {
      user_id: this.dataService.user?.id, template_removed: templates, event_trigger: 'clear selection'});
    this.phaseService.showSpinner = false;
    this.phaseService.selectedApps.forEach(template => {
      template.selected = !template.selected;
    });
    this.phaseService.selectedHash.application_ids = [];
    this.phaseService.selectedApps = [];

  }

  removeTemplate(app) {
    this.phaseService.mandatoryFeatures = [];
    app.selected = !app.selected;
    this.phaseService.toggleSelectedAppsById(app);
    this.phaseService.toggleSelectedAppid(app.id);
    this.phaseService.selectedApps.filter((psection) => {
      psection.features = psection.features['data'] ? psection.features['data'].map(feature => feature.attributes) : psection.features;
      const mandatoryFeaturesIds = this.phaseService.mandatoryFeatures.map(feature => feature.id);
      if (this.phaseService.selectedHash.application_ids.indexOf(psection.id) > -1) {
        this.phaseService.mandatoryFeatures.push(...psection.features.filter(feature => {
          if (mandatoryFeaturesIds.indexOf(feature.id) === -1) {
            return feature.is_mvp_feature;
          }
        }));
      }
    });
  }
  getReusableCost() {
    if (!this.phaseService.isDiscountApplied()) {
      return (this.getTotalWithSpecing() - this.phaseService.featureReusableCost);
    } else {
      return (this.getDiscountedWithSpecing() - this.phaseService.featureReusableCost);
    }
  }
  showDiscriptionBlockPopup() {
    this.phaseService.trackEventForObject(this.analyticsSegment,  'care_pricing_viewed', {
      user_id : this.dataService.user?.id,
      screen_source : this.dataService.currentPage
    });
    this.discriptionBlockPopup = !this.discriptionBlockPopup;
    this.showFixedCostTooltip = false;
    this.hideTotalCosttooltip();
  }
  hideTotalCosttooltip(){
    this.dataService.showHidePhaseBreakUp = false;
  }

  getFinalDeliveryDate() {
    if (this.phaseService.timePromotionApplied()) {
      return this.moment().add((this.phaseService.finalPricenWeeks.weeks * 7), 'days').format('DD-MMM-YYYY');
    } else {
      return this.moment(this.phaseService.getFinalDeliveryDate()).format('DD-MMM-YYYY');
    }
  }

  checkIfNoPhaseSelected() {
    return this.dataService.homePageData.build_phases.filter(p => p.selected).length;
  }

  getTotalForStoreAdditionalRequest() {
    return this.dataService.buildCardData.rental_info.combo_price + this.phaseService.finalPricenWeeks.bcard_discounted_price;
  }

  isShowApplyPromotion() {
    return (this.dataService.currentPage !== 'features') && (!this.phaseService.isFeatureRequest || this.appDataService.urlParameters.cpe_user);
  }

  showCareType(careType) {
    if (careType === 'enterprise_care_plus') {
      return (this.dataService.buildCardData.subscription_flow ? 'Enterprise One+' : 'Enterprise Care Plus');
    } else if (careType === 'enterprise_care') {
      return 'Enterprise Care';
    } else if (careType === 'standard_care_plus') {
      return (this.dataService.buildCardData.subscription_flow ? 'Studio One+' : 'Standard Care Plus');
    } else if (careType === 'standard_care') {
      return 'Standard Care';
    }
  }

  showNoteOnConfirmChangesPopup() {
    let addedFLength = 0;
    if (this.dataService.modifiedBuildCardData) {
      addedFLength = this.dataService.modifiedBuildCardData.features.add.data.length;
    }
    if (addedFLength) {
      const removedFeatures = [];
      const selectedFeatures = this.phaseService.selectedFeatures.map(f => f.id);
      this.dataService.modifiedBuildCardData.features.add.data.forEach(ftr => {
        if (!selectedFeatures.includes(ftr.id)) {
          removedFeatures.push(ftr.id);
        }
      });
      return ((addedFLength === removedFeatures.length) && (this.dataService.buildCardData.progress.features.length === this.phaseService.selectedFeatures.length)) ? true : false;
    } else {
      return false;
    }
  }

  fetchCareType() {
    if (this.dataService.modifiedBuildCardData && this.dataService.modifiedBuildCardData.support_maintenance_price.new) {
      return this.dataService.modifiedBuildCardData['care_type']['add'];
    }
    return this.dataService.buildCardData.care_type;
  }

  toggleFixedCostTooltip() {
    this.dataService.showHidePhaseBreakUp = false;
    this.discriptionBlockPopup = false;
    this.showFixedCostTooltip = !this.showFixedCostTooltip;
  }

  showCareInBottomBar() {
    if (this.dataService.buildCardData.subscription_flow) {
      return this.showCarePlusInBottom();
    } else {
      return (this.dataService.buildCardData.new_care_flow && this.phaseService.builderCarePrice);
    }
  }

  showCarePlusInBottom() {
    const careType = this.phaseService.selectedHash.care_type;
    return ((this.phaseService.selectedHash.is_under_support) &&
      ((careType === 'enterprise_care_plus') || (careType === 'standard_care_plus')));
  }

  getFixedCost() {
    if (this.dataService.buildCardData.subscription_flow) {
      return (this.phaseService.featureReusableCost + this.phaseService.subscriptionCost);
    } else {
      return this.phaseService.featureReusableCost;
    }
  }

  getCarePrice() {
    if (this.dataService.buildCardData.subscription_flow) {
      return this.phaseService.totalCarePrice;
    } else {
      return this.phaseService.getBuilderCareForMonthlyOrDefaultPricing(this.phaseService.selectedHash.care_type, false);
    }
  }

  checkIfNoNewPlatformsAdded() {
    const buildCardData = this.dataService.buildCardData;
    if (this.phaseService.isPlatformRequest && buildCardData && buildCardData?.progress?.platforms?.length > 0) {
      const approvedPlatforms = buildCardData['progress']['platforms'];
      const allPlatforms = this.phaseService.selectedPlatforms;
      return (approvedPlatforms?.length === allPlatforms?.length);
    }
    return false;
  }

}

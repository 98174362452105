import {FeatureModel} from '@models/feature-model/feature.model';
import {PlatformModel} from '@models/platform-model/platform.model';
import {CurrencyModel} from '@models/currency-model/currency.model';
import {FeatureSetModel} from '@models/feature-set-model/feature-set.model';


/**
 * Created by Rakesh on 03/09/19.
 */

export class TemplateDetailModel {
  id: number;
  title: string;
  app_builder_icon_url: string;
  description: string;
  subtitle: string;
  reference_urls: string;
  user_requested: boolean;
  status: string;
  features: FeatureModel[];
  feature_sets: FeatureSetModel[];
  platform_ids: Array<number>;
  platforms: PlatformModel[];
  feature_count: number;
  mobile_cover_image_url: string;
  web_cover_image_url: string;
  direct_checkout: boolean;
  restrict_edit: boolean;
  meta_title: string;
  freemium_order_limit:number;
  starter_order_limit:number;
  meta_description: string;
  prototype_visible: boolean;
  pricing_modal: string;
  builder_care_price: number;
  monthly_price: number;
  month_count: number;
  currency_id: number;
  currency: CurrencyModel;
  rental_builder_care_price: number;
  rental_builder_cloud_price: number;
  builder_faqs: Array<string>;
  screenshots: Array<string>;
  category_names: Array<string>;
  template_device: Array<string>;
  complete_app_url: string;
  web_app_url: string;
  mobile_app_url: string;
  development_weeks: number;
  rental_prices: any;
  promotion: any;
  redirection_url: string;
  slug: string;
  starter_platforms: Array<string>;
  saas_service_inr: Array<string>;
  template_price: number;
}
